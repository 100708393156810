export const flat = (object, filterFunction = null) => {
    let flattenObj = [];

    Object.keys(object).map((key) => {
        if(!filterFunction){
            flattenObj.push(key);
            return null;
        }
        if(filterFunction(object, key)){
            flattenObj.push(key);
            return null;
        }
        return key;
    });
    return flattenObj;
};

export const toQueryUrl = (object, urlBase) => {
    const queryString = flat(object, (obj, key) => (obj[key] !== '') ).map(key => key + '=' + object[key]).join('&');
    const url = urlBase + ((queryString) ? '?' + queryString : '');
    return url;
};

export const generateFilename = (filename) => {
  const random = Math.floor(Math.random() * 1000000)
  const parts = filename.split('.')
  return `${parts[0]}${random}.${parts[1]}`
}
