import React from 'react';

function SvgComponent({ active, ...props }) {
  return (
    <svg width={18} height={18} viewBox="0 0 25 19" fill="none" {...props}>
      <path
        d="M21.0856 5.39827C20.7947 4.72467 19.9921 4.21291 19.2995 4.26177C18.607 4.31061 17.8065 4.03942 17.4522 3.7019C17.098 3.36437 16.81 2.5654 16.8362 1.8692C16.8618 1.173 16.3267 0.39297 15.6414 0.120332C14.9562 -0.152306 14.0311 0.0547226 13.5755 0.575954C13.1206 1.09647 12.3574 1.47917 11.8718 1.49377C11.3863 1.50835 10.6143 1.14458 10.1434 0.637204C9.67319 0.129812 8.73861 -0.0516976 8.06496 0.239902C7.39136 0.530773 6.87596 1.3334 6.9248 2.02594C6.97365 2.71848 6.70246 3.51893 6.36493 3.87322C6.02741 4.2275 5.22843 4.51182 4.53587 4.48922C3.84333 4.46663 3.05965 5.00025 2.78701 5.68552C2.51437 6.37005 2.7214 7.29587 3.2419 7.75144C3.76314 8.20633 4.14587 8.96958 4.16044 9.45292C4.17502 9.93624 3.81344 10.7104 3.30388 11.1814C2.7943 11.6516 2.61134 12.5847 2.90658 13.2618C3.20109 13.9391 4.00008 14.4508 4.69262 14.402C5.38516 14.3532 6.18561 14.6243 6.53989 14.9619C6.89418 15.2994 7.18214 16.0947 7.1559 16.7909C7.12966 17.4871 7.66547 18.2672 8.35219 18.5398C9.03891 18.8124 9.96255 18.6054 10.4181 18.0842C10.8737 17.5637 11.6348 17.181 12.1203 17.1737C12.6058 17.1664 13.3778 17.5265 13.8524 18.0339C14.3263 18.5413 15.2594 18.7148 15.9329 18.4239C16.6064 18.133 17.1219 17.334 17.073 16.6415C17.0242 15.9489 17.2954 15.1485 17.6329 14.7942C17.9704 14.4399 18.7672 14.1563 19.4634 14.184C20.1596 14.2118 20.9397 13.6745 21.2123 12.9878C21.4849 12.301 21.2816 11.3774 20.7567 10.9181C20.2325 10.4588 19.8498 9.70505 19.8367 9.21443C19.8236 8.72382 20.1859 7.95691 20.6969 7.48598C21.2167 6.92248 21.3677 6.11059 21.0856 5.39827Z"
        fill={active ? '#367ca3': '#9FB5C1' }
      />
      <circle cx={12} cy={9} r={4} fill='#FFFFFF'/>
    </svg>
  );
}

export default SvgComponent;
