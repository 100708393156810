import React, { useMemo, useCallback, useState } from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { Button, Header, Icon, Segment } from 'semantic-ui-react'

// Components
import Image from '../../../components/Image';
import MarketUnlock from '../../../features/media/components/MarketUnlock';

import ObfuscatedImages from '../../../assets/img/image_insights_obfuscated_1.png';

// Styles
/*
const StyledImageGrid = styled.div`
  display: grid;
  grid-template-columns: 197px 197px 128px 94px 94px;
  // grid-template-columns: 28.195vw 13.681vw 8.889vw 6.528vw 5vw;
  grid-template-rows: 406px;
  grid-column-gap: 10px;
  border-radius: 15px;
`;
*/
const StyledImageGrid = styled.div`
  display: grid;
  grid-template-columns: 181px 181px 181px 181px 181px 181px 114px;
  // grid-template-columns: 28.195vw 13.681vw 8.889vw 6.528vw 5vw;
  grid-template-rows: 372px;
  grid-column-gap: 10px;
  border-radius: 15px;
`;
const StyledImageGridDemo = styled.div`
  display: grid;
  grid-template-columns: 181px 1079px;
  grid-template-rows: 372px;
  grid-column-gap: 10px;
  border-radius: 15px;
`;
const ImageCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;
const ImageWrapper = styled.div`
  position: relative;
  border-radius: 4%;
  &:after {
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 4%;
    background: ${(props) => rgba(props.theme.joot.jootWhite, 0.2)};
    transition: 0.1s background ${({ theme }) => theme.transition.cubicEase};
  }
  &.isHighlight {
    &:after {
      display: none;
    }
    box-shadow: 0 0 0 3px ${({ theme }) => theme.joot.emeraldGreen};
  }
`;
const GridImageStyled = styled(Image)`
  width: 100%;
  display: block;
  border-radius: 4%;
  transition: 0.1s all ${({ theme }) => theme.transition.cubicEase};
  box-shadow: 0 0 0 0 ${({ theme }) => theme.joot.emeraldGreen};
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 0 3px ${({ theme }) => rgba(theme.joot.emeraldGreen, 0.2)};
  }
`;
const ObfImage = styled.div`
  min-height: 372px;
  min-width: 100%;
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-size: cover;
  padding: 40px 200px 20px 200px;
`;

const UnlockMarketPlaceholder = () => {

  const [showUnlock, setShowUnlock] = useState(false);
  const handleOnUnlock = useCallback(() => {
    setShowUnlock(true);
  }, [setShowUnlock]);
  const handleHide = useCallback(() => {
    setShowUnlock(false);
  }, [setShowUnlock]);

  return (
     <Segment placeholder small>
      <Header icon>
        <Icon name='images file outline' />
        Unlock this market to see all Images Insights.
      </Header>
      <Button inverted color="blue" animated="vertical" onClick={handleOnUnlock}>
        <Button.Content visible>Unlock this Market</Button.Content>
        <Button.Content hidden>$19.99 a month</Button.Content>
      </Button>
      {showUnlock && <MarketUnlock from={'image-insights'} isVisible={showUnlock} onHide={handleHide} />}
    </Segment>
  );
}

const LockImage = ({ onUnlockClick }) => {
  return (
    <ObfImage src={ObfuscatedImages}>
      <UnlockMarketPlaceholder />
    </ObfImage>
  );
};

const GridImage = ({ item, onPostClick, isHighlight, ...props }) => {
  const firstImage = useMemo(() => (item ? item.images[0] : {}), [item]);
  if (!item) {
    return null;
  }
  return (
    <ImageWrapper onClick={() => onPostClick(item)} className={isHighlight ? 'isHighlight' : ''}>
      <GridImageStyled
        {...props}
        alt={firstImage.group_name}
        src={firstImage?.image_urls?.url_400}
        src2x={firstImage?.image_urls?.url_800}
      />
    </ImageWrapper>
  );
};

const isHighlight = (pivot, item) => !pivot || !item || (pivot && pivot.grouping_number === item.grouping_number);

const ImageGrid = ({ onPostClick, groupings, postSelected, isDemo }) => {
  console.log("isDemo: " + isDemo);
  return (
    !isDemo ? (
      <StyledImageGrid>
        <ImageCol>
          <GridImage
            item={groupings[0]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[0])}
            height={141}
          />
          <GridImage
            item={groupings[1]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[1])}
            height={141}
          />
        </ImageCol>
        <ImageCol>
          <GridImage
            item={groupings[2]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[2])}
            height={141}
          />
          <GridImage
            item={groupings[3]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[3])}
            height={141}
          />
        </ImageCol>
        <ImageCol>
          <GridImage
            item={groupings[4]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[4])}
            height={141}
          />
          <GridImage
            item={groupings[5]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[5])}
            height={141}
          />
        </ImageCol>
        <ImageCol>
          <GridImage
            item={groupings[6]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[6])}
            height={141}
          />
          <GridImage
            item={groupings[7]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[7])}
            height={141}
          />
        </ImageCol>
        <ImageCol>
          <GridImage
            item={groupings[8]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[8])}
            height={141}
          />
          <GridImage
            item={groupings[9]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[9])}
            height={141}
          />
        </ImageCol>
        <ImageCol>
          <GridImage
            item={groupings[10]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[10])}
            height={141}
          />
          <GridImage
            item={groupings[11]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[11])}
            height={141}
          />
        </ImageCol>
        <ImageCol>
          <GridImage
            item={groupings[12]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[12])}
            height={94}
          />
          <GridImage
            item={groupings[13]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[13])}
            height={94}
          />
          <GridImage
            item={groupings[14]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[14])}
            height={94}
          />
        </ImageCol>
      </StyledImageGrid>
    ) : (
      <StyledImageGridDemo>
        <ImageCol>
          <GridImage
            item={groupings[0]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[0])}
            height={141}
          />
          <GridImage
            item={groupings[1]}
            onPostClick={onPostClick}
            isHighlight={isHighlight(postSelected, groupings[1])}
            height={141}
          />
        </ImageCol>
        <ImageCol>
          <LockImage />
        </ImageCol>
      </StyledImageGridDemo>
    )
  );
};
/*
const ImageGrid = ({ onPostClick, groupings, postSelected }) => {
  return (
    <StyledImageGrid>
      <ImageCol>
        <GridImage
          item={groupings[0]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[0])}
          height={197}
        />
        <GridImage
          item={groupings[1]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[1])}
          height={197}
        />
      </ImageCol>
      <ImageCol>
        <GridImage
          item={groupings[2]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[2])}
          height={197}
        />
        <GridImage
          item={groupings[3]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[3])}
          height={197}
        />
      </ImageCol>
      <ImageCol>
        <GridImage
          item={groupings[4]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[4])}
          height={128}
        />
        <GridImage
          item={groupings[5]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[5])}
          height={128}
        />
        <GridImage
          item={groupings[6]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[6])}
          height={128}
        />
      </ImageCol>
      <ImageCol>
        <GridImage
          item={groupings[7]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[7])}
          height={94}
        />
        <GridImage
          item={groupings[8]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[8])}
          height={94}
        />
        <GridImage
          item={groupings[9]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[9])}
          height={94}
        />
        <GridImage
          item={groupings[10]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[10])}
          height={94}
        />
      </ImageCol>
      <ImageCol>
        <GridImage
          item={groupings[11]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[11])}
          height={94}
        />
        <GridImage
          item={groupings[12]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[12])}
          height={94}
        />
        <GridImage
          item={groupings[13]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[13])}
          height={94}
        />
        <GridImage
          item={groupings[14]}
          onPostClick={onPostClick}
          isHighlight={isHighlight(postSelected, groupings[14])}
          height={94}
        />
      </ImageCol>
    </StyledImageGrid>
  );
};
*/

export default ImageGrid;
