import { useCallback, useEffect, useState } from 'react';

function useMenu({ labelField, emptySelectionText, initialSelection, value }) {
  const [selected, setSelected] = useState(initialSelection);
  const [selectedText, setSelectedText] = useState(emptySelectionText);

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value])

  const handleSelection = useCallback((item) => {
    setSelected(item);
  }, []);

  const isSelected = useCallback(
    (item) => {
      return selected[labelField] === item[labelField];
    },
    [selected, labelField],
  );

  useEffect(() => {
    setSelectedText(selected ? selected[labelField] : emptySelectionText);
  }, [selected, labelField, emptySelectionText]);

  return { selected, handleSelection, selectedText, isSelected };
}

export default useMenu;
