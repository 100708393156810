import React from 'react';
import styled from 'styled-components';
import composeRefs from '../../utils/composeRefs';

// Components
import DropdownIcon from '../../components/icons/DropdownIcon';
import OptionsMenu from '../../components/menus/OptionsMenu';

// Styles
const Toggle = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
`;
const Text = styled.span`
  color: ${({ theme }) => theme.color.black};
  font-size: 16px;
  font-weight: 600;
  margin-left: auto;
`;
const DropdownIconStyled = styled(DropdownIcon)`
  fill: ${({ theme }) => theme.joot.carolinaBlue};
  margin-left: 10px;
`;

const MarketMenu = React.forwardRef(function MarketMenu(props, ref) {
  const { options, value, initialSelection, title } = props;

  return (
    <OptionsMenu
      {...props}
      items={options}
      value={value}
      title={title}
      initialSelection={initialSelection}
    >
      {({ triggerRef, toggle, selectedText }) => (
        <Toggle ref={composeRefs(triggerRef, ref)} onClick={toggle} style={props.style}>
          <Text>{selectedText}</Text>
          <DropdownIconStyled fill={'white'} />
        </Toggle>
      )}
    </OptionsMenu>
  );
});

export default MarketMenu;
