import React from 'react';
import styled from 'styled-components';

// Components
import Title from '../../../components/Title';

const StyledSettingsBox = styled.article`
  position: relative;
  margin: 0 0 40px 0;
  padding: 15px 30px 50px 30px;
  border-radius: 15px;
  box-shadow: 0 8px 20px 0 rgba(160, 181, 193, 0.15);
  background: ${({ theme }) => theme.joot.white};
  &:last-of-type {
    margin-bottom: 0;
  }
`;
const SettingsTitle = styled(Title)`
  margin-bottom: 30px;
`;

const SettingsBox = ({ children, title }) => {
  return (
    <StyledSettingsBox>
      <SettingsTitle h5>{title}</SettingsTitle>
      {children}
    </StyledSettingsBox>
  );
};

export default SettingsBox;
