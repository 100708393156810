import React, { useMemo } from 'react'
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Components
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SettingsBox from '../components/SettingsBox';
import ProfileTab from '../components/ProfileTab';
import AccountsTab from '../components/AccountsTab';
import MarketsTab from '../components/MarketsTab';
import AccountPlanTab from '../components/AccountPlanTab';
import BillingTab from '../components/BillingTab';
import { useGetBillingDetail } from '../../../hooks/apiHooks/billingApiHook';
import { useAppContext } from '../../../contexts/AppContext';

// Styles
const StyledSettings = styled.section`
  padding: 30px 20px;
`;
const StyledTab = styled(Tab)`
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.23px;
  padding: 13px 30px;
  cursor: pointer;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.5)};
  transition: 0.2s all ${({ theme }) => theme.transition.cubicEase};
  border-bottom: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.2)};
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &.react-tabs__tab--selected {
    font-weight: 600;
    color: ${({ theme }) => theme.joot.potteryBlue};
    border-bottom-color: ${({ theme }) => theme.joot.potteryBlue};
    box-shadow: inset 0 -1px 0 0px ${({ theme }) => theme.joot.potteryBlue};
  }
`;
const StyledTabList = styled(TabList)`
  display: inline-flex;
  margin-bottom: 30px;
`;

export default function Settings() {
  const {
    paymentMethod: { card },
  } = useGetBillingDetail();
  const {
    jootAccount
  } = useAppContext();
  const isPersonalAccount = useMemo(
    () => jootAccount ? jootAccount.account_type === "PERSONAL" : true,
    [jootAccount]);

  return (
    <StyledSettings>
      <Tabs>
        <StyledTabList>
          <StyledTab>Settings</StyledTab>
          {card && <StyledTab>Billing</StyledTab>}
        </StyledTabList>
        <TabPanel>
          <SettingsBox title="Profile">
            <ProfileTab />
          </SettingsBox>
          {/*!isPersonalAccount && */
          <>
            <SettingsBox title="Markets">
              <MarketsTab />
            </SettingsBox>
            <SettingsBox title="Account Plan">
              {jootAccount && <AccountPlanTab/>}
            </SettingsBox>
            <SettingsBox title="Facebook Connection">
              <AccountsTab />
            </SettingsBox>
          </>
          }
        </TabPanel>
        {card && (<TabPanel>
          <SettingsBox title="Billing">
            <BillingTab />
          </SettingsBox>
        </TabPanel>)}
      </Tabs>
    </StyledSettings>
  );
}
