import React from 'react';
import styled, { css } from 'styled-components';

// Components
import AuthFooter from '../features/auth/AuthFooter';

// Styles
const AreaStyle = css`
  background: ${({ theme }) => theme.joot.mud};
  grid-area: main;
  display: grid;
  padding: 0;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  width: 100vw;
  position: relative;
  grid-template-areas: 'header' 'main' 'footer';
  overflow-y: auto;
`;
export const Main = styled.main`
  ${AreaStyle};
`;

export const Footer = styled.footer`
  ${AreaStyle};
  grid-area: footer;
  padding: 4vw 200px;
`;

const LayoutAuth = ({ children }) => (
  <Wrapper>
    <Main>{children}</Main>
    <Footer>
      <AuthFooter />
    </Footer>
  </Wrapper>
);

export default LayoutAuth;
