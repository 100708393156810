import React, { memo, useCallback, useState } from 'react';
import { ErrorMessage, FormControl } from '../../components/form';
import LoginLabel from './components/LoginLabel';
import LoginInput from './components/LoginInput';
import BaseButton from '../../components/Button';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import useFormTouched from '../../hooks/useFormTouched';
import { ReactComponent as ArrowLong } from '../../assets/svg/arrow-long.svg';
import styled from 'styled-components';
import { setProxyUser } from '../../services/userApiServices';

const Button = styled(BaseButton)`
  margin-bottom: 10px;
`

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email'),//.required('Email is required'),
});

const ProxyUserForm = ({ onChange, data }) => {
  const [error, setError] = useState('');
  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: data.proxy
    }
  });
  const { isValid } = formState;
  const [touched, handleBlur] = useFormTouched({
    email: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  async function onSubmit({ email }) {
    setIsLoading(true)
    setError("")
    try {
      await setProxyUser(email)
      onChange(email)
    } catch (e) {
      const errors = {
        400: "This email doesn't exists.",
        500: "Is not a valid email.",
      }
      if (e.response) {
        setError(errors[e.response.status])
      }
    }
    setIsLoading(false)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error && <ErrorMessage dark>{error}</ErrorMessage>}
      <FormControl controlid="email">
        <LoginLabel htmlFor="email">Proxy as user</LoginLabel>
        <LoginInput
          name="email"
          id="email"
          placeholder={data.currentEmail}
          type="email"
          autoFocus
          ref={register}
          hasError={touched.email && errors.email}
          onBlur={handleBlur}
        />
      </FormControl>
      <FormControl>
        {isLoading ? (
          <span>Processing...</span>
        ) : (
            <>
              <Button primary block disabled={!isValid || isLoading} type="submit">
                Continue login
                <ArrowLong />
              </Button>
            </>
          )}
      </FormControl>
    </form>
  );
};

export default memo(ProxyUserForm);
