import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { Input } from './form';

// Styles
const StyledInput = styled(Input)`
  background: ${({ theme }) => theme.joot.shark};
  border-color: ${({ theme }) => theme.joot.shark};
  border-radius: 5px;
`;

let timer;

const SearchInput = ({ onChange, value: originalValue, name, ...props }) => {
  const [currentValue, setCurrentValue] = useState(originalValue);
  const handleValue = (e) => {
    timer && clearTimeout(timer);
    const { value } = e.target;
    setCurrentValue(value);
    timer = setTimeout(() => {
      onChange({ target: { name, value } });
    }, 1000);
  };
  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return <StyledInput {...props} value={currentValue} onChange={handleValue} />;
};
SearchInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default memo(SearchInput);
