import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Assets
import { ReactComponent as RemoveTagSvg } from '../../../assets/svg/remove-tag.svg';

// Components
import Button from '../../../components/Button';
import { useAppContext } from '../../../contexts/AppContext';

// Styles
const StyledTag = styled.span`
  cursor: pointer;
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  border-radius: 25px;
  line-height: 20px;
  margin: 0 5px 5px 0;
  background: ${({ theme, color }) => (color ? theme.joot[color] : theme.joot.carolinaBlue)};
`;
const TagName = styled.span`
  margin: 0 10px;
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.joot.jootWhite};
`;
const RemoveTag = styled(Button)`
  background: ${(props) => rgba(props.theme.joot.jootBlack, 0.2)};
  border-radius: 100%;
  width: 16px;
  height: 16px;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  > svg {
    margin: 0;
  }
`;

export const getTagColor = (tag, tag_insights) => {
  if (tag_insights && tag) {
    if (typeof (tag) !== "string") {
      tag = tag.value
    }
    if (tag_insights.green.includes(tag)) {
      return "emeraldGreen"
    }
    if (tag_insights.red.includes(tag)) {
      return "cinnabar"
    }
    return "mustard"
  }
  return "carolinaBlue"
}

const Tag = ({ children, name, value, quantity, onRemove, onClick, color, hasRemove = true, ...props }) => {
  const handler = useCallback(
    eventHandler => event => {
      return eventHandler && eventHandler({ target: { name, value } }, event)
    },
    [value, name],
  )
  const { tag_insights } = useAppContext()
  if (!color)
    color = getTagColor(value, tag_insights)
  return (
    <StyledTag onClick={handler(onClick)} color={color} {...props}>
      <TagName>
        {children} {quantity > 1 && `(${quantity})`}
      </TagName>
      {hasRemove && (
        <RemoveTag icon onClick={handler(onRemove)}>
          <RemoveTagSvg />
        </RemoveTag>
      )}
    </StyledTag>
  );
};

export default Tag;
