import styled from 'styled-components';

// Components
import Button from './../Button';

// Styles
const Label = styled.label`
  display: block;
  font-size: 12px;
  font-family: ${(props) => props.theme.font.sansSerif};
  margin-bottom: 10px;
  color: ${(props) => props.theme.joot.jootBlack};
`;

const Action = styled(Button)`
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.joot.potteryBlue};
`;

const Error = styled.div`
  margin-top: 8px;
  display: block;
  font-size: 11px;
  font-family: ${(props) => props.theme.font.sansSerif};
  font-weight: 700;
  color: ${(props) => props.theme.joot.cinnabar};
`;

const FormControl = styled.div`
  position: relative;
  margin-bottom: 25px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

FormControl.Label = Label;
FormControl.Error = Error;
FormControl.Action = Action;

export default FormControl;
