import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Context
import { useAppContext } from '../../contexts/AppContext';
import { useAuthContext } from '../../contexts/AuthContext';

// Components
import ModalForm from '../../components/modals/ModalForm';
import useModal from '../../hooks/useModal';
import useAppToasts from '../../hooks/useToasts';
import SignupForm from '../../components/LoginForms/SignupForm';
import LoginForm from '../../components/LoginForms/LoginForm';
import LoginForgot from '../../components/LoginForms/LoginForgot';
//import WelcomeForm from '../../components/LoginForms/WelcomeForm';

const SIGN_UP = 'SIGN_UP_STEP';
const LOGIN = 'LOGIN_STEP';
const LOGIN_FORGOT = 'FORGOT_PASSWORD_STEP';
const WELCOME = 'WELCOME_STEP';

const FORM_INIT_DATA = {
  industry: '',
};

const Form = ({ from, onHide, skipInfo = false, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(LOGIN);
  const {
    isAuthenticated,
  } = useAuthContext();
  const {
    igAccount,
  } = useAppContext();
  const [form, setForm] = useState({ ...FORM_INIT_DATA });
  const { isVisible, toggle } = useModal(true);
  const { push } = useHistory();
  useEffect(() => {
    if (!isVisible) {
      setStep(LOGIN);
      setForm({ ...FORM_INIT_DATA });
    }
  }, [isVisible]);
  const handleChange = useCallback(({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  }, []);
  const handleOk = useCallback(async () => {
    if (step === WELCOME) {
      // TODO: Save the industry info
      onHideForm();
      return;
    } else if (step === LOGIN) {
      // TODO: check to see if the auth user is an admin
      onHideForm();
      return;
    } else if (step == SIGN_UP) { // SIGN_UP_STEP
      setStep(WELCOME);
    }
  }, [step]);
  const handleIndustryChange = useCallback((value) => handleChange({ target: { name: 'industry', value } }), [
    handleChange,
  ]);
  const onHideForm = useCallback(() => {
    // TODO: depending on if the form was canceled or closed, return to Market Insights or to the page clicked.
    //onHide();
    toggle();
    push('/scored-media');
  },[onHide, toggle, push]);
  const handleForgotPassword = useCallback(() => {
    setStep(LOGIN_FORGOT);
  },[]);
  const handleCreateAccount = useCallback(() => {
    //setStep(SIGN_UP);
    // Send the user to the pricing page on getjoot.com
  },[]);
  const handleSignIn = useCallback(() => {
    setStep(LOGIN);
  },[]);
  const handlePasswordChanged = useCallback(() => {
    setStep(LOGIN);
  },[]);

  return (
    <ModalForm
      {...props}
      onHide={onHideForm}
      isVisible={isVisible}
      hideOk={true}
      okText="ok"
      onOk={handleOk}
      title="Join Joot"
      isLoading={isLoading}
      hideCancel={true}
      hideBack={true}
      maxWidth="500px"
    >
      <>
        {step === LOGIN && <LoginForm onForgotPassword={handleForgotPassword} onCreateAccount={handleCreateAccount}/>}
        {step === SIGN_UP && <SignupForm onSignIn={handleSignIn}/>}
        {step === LOGIN_FORGOT && <LoginForgot onPasswordChanged={handlePasswordChanged} />}
      </>
    </ModalForm>
  );
};

const AuthModal = ({ from, onHide, ...props }) => {
  return (
    <Form from={from} onHide={onHide} {...props} />
  );
};

export default memo(AuthModal);