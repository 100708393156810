import * as React from 'react';

function LogoJoot(props) {
  return (
<svg width="134" height="23" viewBox="0 0 160 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip1)">
<path d="M88.3382 18.0023C88.185 21.8048 85.9339 23.2634 83.4668 23.2634C80.9368 23.2634 78.5953 21.7034 78.5953 17.6084V11.7584H87.3953V6.96537H78.5953V0H72.9893V18.0023C72.9893 25.0886 78.23 28.8599 83.4668 28.8599C88.7035 28.8599 93.9443 25.0886 93.9443 18.0023C93.9443 18.0023 88.3382 17.8502 88.3382 18.0023Z" fill="black"/>
<path d="M34.8001 6.75085C28.9544 6.75085 23.2305 10.8575 23.2305 17.7917C23.2305 24.7259 28.9544 28.8286 34.7726 28.8286C40.5908 28.8286 46.3148 24.7259 46.3148 17.7917C46.3148 10.8575 40.5594 6.75085 34.8001 6.75085ZM34.8001 23.9654C31.5748 23.9654 28.6676 21.7151 28.6676 17.7917C28.6676 13.8683 31.5905 11.618 34.8158 11.618C38.0412 11.618 40.968 13.8683 40.968 17.7917C40.968 21.7151 37.998 23.9654 34.8001 23.9654Z" fill="black"/>
<path d="M59.1652 6.75085C53.347 6.75085 47.623 10.8575 47.623 17.7917C47.623 24.7259 53.347 28.8286 59.1652 28.8286C64.9834 28.8286 70.7034 24.7259 70.7034 17.7917C70.7034 10.8575 64.952 6.75085 59.1652 6.75085ZM59.1652 23.9654C55.9359 23.9654 53.0131 21.7151 53.0131 17.7917C53.0131 13.8683 55.9359 11.618 59.1652 11.618C62.3945 11.618 65.3173 13.8683 65.3173 17.7917C65.3173 21.7151 62.3631 23.9654 59.1652 23.9654Z" fill="black"/>
<path d="M15.4643 0H3.13256V4.77358H15.4329V17.5772C15.4329 21.6839 13.0758 23.2322 10.5615 23.2322C8.0472 23.2322 5.69006 21.6722 5.69006 17.5772V14.2037H0.0839844V18.0023C0.0839844 25.0886 5.3247 28.8599 10.5615 28.8599C15.7983 28.8599 21.039 25.0886 21.039 18.0023V0H15.4643Z" fill="black"/>
<path d="M103.507 24.0551C103.507 26.4263 100.521 28.5245 99.912 28.5245C99.3031 28.5245 96.3174 26.4263 96.3174 24.0551C96.3174 23.5007 96.5384 22.9687 96.9323 22.5756C97.3261 22.1824 97.8607 21.96 98.4192 21.9569C98.7326 21.9572 99.0395 22.0462 99.3039 22.2133C99.5682 22.3804 99.7792 22.6189 99.912 22.9007C100.045 22.6189 100.256 22.3804 100.52 22.2133C100.785 22.0462 101.091 21.9572 101.405 21.9569C101.682 21.9559 101.957 22.0095 102.213 22.1147C102.469 22.22 102.702 22.3747 102.898 22.5699C103.093 22.7651 103.248 22.9969 103.352 23.2518C103.457 23.5068 103.509 23.7798 103.507 24.0551V24.0551Z" fill="#DF5141"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="17.058" height="6.18274" fill="black" transform="translate(103.534 4.85779)"/>
</clipPath>
<clipPath id="clip1">
<rect width="103.535" height="29" fill="black"/>
</clipPath>
</defs>
</svg>

  );
}

export default LogoJoot;
