import React from 'react';
import styled from 'styled-components';

// Assets
//import { ReactComponent as LogoMark } from '../../assets/svg/logo-word.svg';
import { ReactComponent as LogoMark } from '../../assets/svg/Joot Social Vision.svg';

// Components
import StepperNavigation from '../../components/StepperNavigation';
import { useSignupContext } from './context/SignupContext';

// Styles
const LogoWord = styled(LogoMark)`
  pointer-events: none;
  width: 220px;
  height: 60px;
`;

const StyledAuthHeader = styled.header`
  background: ${({ theme }) => theme.joot.mud};
  grid-area: header;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 50px;
  z-index: 2;
`;

const AuthHeader = () => {
  const {
    navigationSteps,
    errors,
    handlerGoToItem,
    handlerNext,
    disabledNext,
    loadingNext,
    currentStep,
  } = useSignupContext()
  return (
    <StyledAuthHeader>
      <LogoWord />
      {currentStep < 3 &&
      <StepperNavigation
        navigationSteps={navigationSteps}
        errors={errors}
        onGoToItem={handlerGoToItem}
        onNext={handlerNext}
        disabledNext={disabledNext}
        loading={loadingNext}
      />}
    </StyledAuthHeader>
  );
};

export default AuthHeader;
