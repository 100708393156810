import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../contexts/AppContext';
import { useAuthContext } from '../contexts/AuthContext';

function useRedirectionHandler(disableRedirections = false) {
  const { push } = useHistory();
  const {
    changeAppState,
    markets,
    loadingFacebook,
    facebookStatus,
    facebookError,
    facebook
  } = useAppContext();
  const { isAuthenticated } = useAuthContext();
  // onboarding redirections
  useEffect(() => {
    if (disableRedirections) {
      changeAppState('animateProgress', false);
      return;
    }
    if (!isAuthenticated || markets.MARKET_ACTIVE || loadingFacebook ) {
      return;
    }
    if (facebookError) {
      return push('/signup/one');
    }
    if (!loadingFacebook && facebookStatus !== 'idle' && (!facebook.igAccounts || facebook.igAccounts.length === 0)) {
      return push('/signup/one');
    }
    if (facebook.igAccounts && facebook.igAccounts.length === 0) {
      return push('/signup/two');
    }
    if (markets.MARKET_UNDEFINED) {
      return push('/signup/three');
    }
    /*if (markets.MARKET_UNPAID) {
      return push('/signup/four');
    }*/
  }, [
      disableRedirections,
      changeAppState,
      facebookError,
      markets,
      push,
      facebookStatus,
      isAuthenticated,
      loadingFacebook,
      facebook
  ]);
  // resolve animate progress
  useEffect(() => {
    let animate = false;
    if (loadingFacebook) {
      animate = true;
    }
    changeAppState('animateProgress', animate);
    // eslint-disable-next-line
  }, [loadingFacebook]);

  return {};
}

export default useRedirectionHandler;
