import React, { useCallback } from 'react';
import styled from 'styled-components';

// Hooks
import { useMediaContext, sortOptions } from './contexts/MediaContext';

// Components
import { FormControl } from '../../components/form';
import Tag from './components/Tag';
import SidebarItem from './components/SidebarItem';
import SortByMediaMenu from '../../components/menus/SortByMediaMenu';
import Search from './components/Search';
import TagsMenu from '../../components/menus/TagsMenu';

// Styles
const StyledTagsSidebar = styled.section`
  padding: 50px 0px 0px 0px;
`;
const StyledSidebarItem = styled.div`
  padding: 0px 20px 15px 20px;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 140px;
  background: ${({ theme }) => theme.joot.paleGray};
`;

const TagsSidebar = () => {
  const {
    filters,
    changeFilter,
    searchOptions,
    tags,
    tagsReadOnly,
    tagManager: { handleSave, detectedTags, detectedAITags, handleRemove },
  } = useMediaContext();
  const handleSearchClick = useCallback(
    ({ target: { name, value } }) => {
      changeFilter({ ...filters, [name]: value });
    },
    [changeFilter, filters],
  );
  const handleTagClick = useCallback(({ target: { value } }) => {
    if (filters.optionsSearch.find(item => item.value === value.value)) {
      return
    }
    handleSearchClick({ target: { name: 'optionsSearch', value: [value, ...filters.optionsSearch] } })
  }, [filters, handleSearchClick])

  return (
    <StyledTagsSidebar>
      <StyledSidebarItem>
        <FormControl>
          <FormControl.Label>Sort By</FormControl.Label>
          <SortByMediaMenu
            onChange={handleSearchClick}
            items={sortOptions}
            initialSelection={filters.sortBy}
            name="sortBy"
          />
      </FormControl>
      </StyledSidebarItem>
      <StyledSidebarItem>
        <FormControl>
          <Search
            name="optionsSearch"
            options={searchOptions}
            onChange={handleSearchClick}
            itemsSelected={filters.optionsSearch}
          />
        </FormControl>
      </StyledSidebarItem>
      <Divider />
      <StyledSidebarItem>
        <SidebarItem title="My Tags">
          <TagsMenu
            name="tabs"
            onChange={handleSave}
            items={tags}
            title={'Tags'}
            detectedTags={detectedTags}
            onRemove={handleRemove}
            onTagClick={handleTagClick}
            readOnly={tagsReadOnly}
          />
        </SidebarItem>
      </StyledSidebarItem>
      <Divider />
      <StyledSidebarItem>
        <SidebarItem title="Joot Tags">
          {detectedAITags.sort((a, b) => {
            if(a.name > b.name) return 1;
            if(a.name < b.name) return -1;
            return 0;
          }).map((item) => (
            <Tag
              onClick={handleTagClick}
              value={item}
              key={item.name}
              quantity={item.quantity}
              hasRemove={false}
            >
              {item.name}
            </Tag>
          ))}
        </SidebarItem>
      </StyledSidebarItem>
    </StyledTagsSidebar>
  );
};

export default TagsSidebar;
