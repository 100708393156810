import React from 'react';
import styled from 'styled-components';

// Components
import { Oval } from 'svg-loaders-react';

// Styles
const StyledLoader = styled.div`
  display: inline-block;
`;

const Loader = (props) => {
  return (
    <StyledLoader {...props}>
      <Oval {...props} />
    </StyledLoader>
  );
};

export default Loader;
