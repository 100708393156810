const dev = {
  apiName: 'joot',
  s3: {
    REGION: 'us-west-2',
    BUCKET: 'joot-resources-uploads-dev-imagesbucket-s6hi2172h7ya',
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://71bje0dozl.execute-api.us-west-2.amazonaws.com/dev/api/v1/',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_fm3TZPMhX',
    APP_CLIENT_ID: '2s6837j3phf474chtq9ojmg0hd',
    IDENTITY_POOL_ID: 'us-west-2:f2f4f970-d802-4fae-8d5a-9bf7c1ce4c04',
  },
  stripe: {
    key: 'pk_test_51HPZs6E6uao5ghYGLCrd9bzzUjdHIgwIBRuDLZhnLoltWgkLNDFVftOntJvpvfkdECSgltBsHNyWXKNqpV7LdEuf00gQ3rlwed',
  },
};

const prod = {
  apiName: 'joot',
  s3: {
    REGION: 'us-west-2',
    BUCKET: 'joot-resources-uploads-prod-imagesbucket-1w6ldehka9yqt',
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://hexe2h1zii.execute-api.us-west-2.amazonaws.com/prod/api/v1/',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_4ObdO8Dcu',
    APP_CLIENT_ID: '1v1j7qhtm2313v9itb8t1ans6h',
    IDENTITY_POOL_ID: 'us-west-2:8e465eaf-9dcc-43bf-a9a8-9b115c13e0f3',
  },
  stripe: {
    key: 'pk_live_51HPZs6E6uao5ghYGKo4LV3yHMrExusCs3468f8FFFrNycsWnnBpB2EhIkAxZjq2IfxkkaCjReFdd22lUA0ts9kGK00vaXOHOLX',
    //key: 'pk_test_51HPZs6E6uao5ghYGLCrd9bzzUjdHIgwIBRuDLZhnLoltWgkLNDFVftOntJvpvfkdECSgltBsHNyWXKNqpV7LdEuf00gQ3rlwed', // dev for now until we need to switch
  },
};

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

export default {
  // Add common config values here
  social: {
    // FB: '295553145142229', // test env
    FB: '2582975038647074', // prod
    // FB: "2132652943414873" // serverless demo
  },
  avatar: {
    AVATAR_REDIRECT_URL: 'https://avatar-redirect.appspot.com',
  },
  instagram: {
    API_SEARCH_URL: 'https://www.instagram.com/web/search/topsearch',
    API_URL: 'https://graph.facebook.com/v3.2',
  },
  google: {
    API_KEY: 'AIzaSyA4hS5a5dKzRTSa5qqw8icUfKSfQqwLYfc',
    PLACES_API_URL: 'https://maps.googleapis.com/maps/api/place/findplacefromtext/json',
    CLIENT_ID: '313271936090-idddsic74l5uv7n1vdnou4n8f44h4q54.apps.googleusercontent.com',
  },
  holidays: {
    API_URL:
      'https://kayaposoft.com/enrico/json/v2.0/?action=getHolidaysForMonth&month={month}&year={year}&country=us&region={region}&holidayType={holidayType}',
  },
  dropbox: {
    //KEY: '7kt3znjs0se2pd3',
     KEY: 'hdoujxb8575vjwj', // dropbox app key for GetJoot app (chris@getjoot.com dropbox login)
  },
  ...config
};