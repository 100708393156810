import * as React from 'react';

function DeleteIcon(props) {
  return (
    <svg viewBox={`0 0 ${props.width} ${props.height}`} {...props}>
      <path
        fill={props.fill}
        d='M12.33 1.4H7.94v-.13C7.94.57 7.38 0 6.67 0 5.97 0 5.4.57 5.4 1.27v.14H1.02c-.37 0-.67.3-.67.67 0 .36.3.67.67.67h11.3c.37 0 .68-.3.68-.67 0-.37-.3-.67-.67-.67zM11.84 3.67H1.5a.23.23 0 00-.23.26l1.17 9.4c.1.67.66 1.19 1.35 1.19h5.75c.68 0 1.25-.5 1.34-1.2l1.18-9.4a.23.23 0 00-.23-.25zm-7.3 8.86h-.02a.39.39 0 01-.4-.36l-.5-6.06a.41.41 0 01.36-.44c.23-.02.43.15.45.37l.5 6.05c0 .21-.17.41-.38.44zm2.55-.41c0 .23-.19.41-.42.41a.41.41 0 01-.4-.41V6.07c0-.23.17-.42.4-.42s.42.19.42.42v6.05zm2.15.03a.4.4 0 01-.4.37h-.03a.39.39 0 01-.36-.45l.49-6.03c.01-.23.2-.39.44-.37.23.02.4.21.36.44l-.5 6.04z'
      />
    </svg>
  );
}

export default DeleteIcon;
