import React from 'react';
import styled, { css } from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { motion } from 'framer-motion';

// Components
import NextIcon from '../icons/NextIcon';
import DraftsIcon from '../icons/DraftsIcon';
import CheckIcon from '../icons/CheckIcon';
import { Scrollbars } from 'react-custom-scrollbars';

// Styles
const MenuBase = styled(motion.ul)`
  transition: color 0.15s, background-color 0.15s, width 0.25s ease-in-out;
  position: absolute;
  width: ${(props) => props.width};
  margin: 0;
  padding: 0;
  list-style: none;
  background-clip: padding-box;
  backface-visibility: hidden;
  border-radius: 3px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  background-color: ${({ theme }) => theme.joot.jootWhite};
  color: ${({ theme }) => theme.joot.jootBlack};
  border: 1px solid ${({ theme }) => theme.joot.blueGray};
  font-family: ${({ theme }) => theme.font.sansSerif};
  z-index: 150;
  margin-top: ${({ offsetY }) => offsetY};
  margin-left: ${({ offsetX }) => offsetX};
`;

const ItemBase = styled.li`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 4px 10px;
  overflow: hidden;
  color: ${({ theme }) => theme.joot.jootBlack};
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 700;
  line-height: 30px;
  cursor: pointer;
  position: relative;
  background-color: ${(p) => (p.isOpen ? '#ECF0FF' : 'transparent')};
  font-family: ${({ theme }) => theme.font.sansSerif};
  &:hover {
    background-color: ${(props) => rgba(props.theme.joot.blueGray, 0.3)};
    color: ${({ theme }) => theme.joot.jootBlack};
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${(props) => rgba(props.theme.joot.blueGray, 0.3)};
      color: ${theme.joot.jootBlack};
    `}
`;
const NextIconStyled = styled(NextIcon)`
  margin: 0 15px 0 auto;
  align-self: center;
`;
const EditIconStyled = styled(DraftsIcon)`
  margin: 0 15px 0 auto;
  align-self: center;
`;
const ItemLabel = styled.li`
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  padding: 4px 10px 4px 20px;
  line-height: 30px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  margin-left: -24px;
  position: absolute;
`;

const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Menu = React.forwardRef(function Menu(
  { className, style, arrowStyle, layerSide, children, label, offsetY, width, ...rest },
  ref,
) {
  return (
    <MenuBase
      ref={ref}
      className={className}
      style={style}
      width={width}
      label={label}
      initial={{ opacity: 0, scale: 0.85 }} // animate from
      animate={{ opacity: 1, scale: 1 }} // animate to
      exit={{ opacity: 0, scale: 0.85 }} // animate exit
      offsetY={offsetY}
      transition={{
        type: 'spring',
        stiffness: 800,
        damping: 35,
        staggerChildren: false,
      }}
      {...rest}
    >
      {' '}
      <Scrollbars
        autoHide
        autoHeight
        thumbMinSize={30}
        universal
        autoHeightMax={300}
        // hide horizontal scroll
        renderTrackHorizontal={() => <div />}
        renderThumbHorizontal={() => <div />}
      >
        {label ? <ItemLabel>{label}</ItemLabel> : null}
        {children}
      </Scrollbars>
    </MenuBase>
  );
});

export const MenuItem = React.forwardRef(function MenuItem(
  { className, style, children, isOpen, nested, hideCheck, width, canEdit, onEdit, ...rest },
  ref,
) {
  return (
    <ItemBase ref={ref} className={className} style={style} isOpen={isOpen} {...rest}>
      <MenuItemContainer>
        {rest.isSelected && !hideCheck && <StyledCheckIcon stroke={'#1a1a1a'} />}
        <div>{children}</div>
      </MenuItemContainer>
      {canEdit ? <EditIconStyled onClick={(e) => {e.stopPropagation(); onEdit(e);}}/> : null}
      {nested ? <NextIconStyled /> : null}
    </ItemBase>
  );
});

Menu.defaultProps = {
  width: '220px',
};

export default Menu;
