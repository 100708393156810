import React from 'react';
import styled, { css } from 'styled-components';

const baseStyle = css`
  margin-bottom: ${(props) => props.noMargin && '0'};
  color: ${({ theme }) => theme.joot.jootBlack};
  font-family: ${({ theme }) => theme.font.sansSerif};
  font-weight: 700;
  margin-top: 0;
  max-width: 100%;
  text-align: ${(props) => {
    if (props.center) return 'center';
    if (props.right) return 'right';
    return 'left';
  }};
`;

const TitleOne = styled.h1`
  font-size: 42px;
  margin-bottom: 25px;
  ${baseStyle};
  @media (max-width: 480px) {
    font-size: 40px;
  }
`;

const TitleTwo = styled.h2`
  font-size: 36px;
  margin-bottom: 20px;
  ${baseStyle};
`;

const TitleThree = styled.h3`
  font-size: 25px;
  margin-bottom: 15px;
  letter-spacing: -0.77px;
  ${baseStyle};
`;

const TitleFour = styled.h4`
  font-size: 22px;
  margin-bottom: 10px;
  ${baseStyle};
`;

const TitleFive = styled.h5`
  font-size: 18px;
  margin-bottom: 5px;
  ${baseStyle};
`;

const TitleSix = styled.h6`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  ${baseStyle};
`;

const TitleSub = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 0 10px;
  letter-spacing: -0.4px;
  color: ${({ theme }) => theme.color.six};
`;

const TitleMenu = styled.div`
  margin-right: 10px;
  line-height: 50px;
  font-size: 13px;
  font-weight: 700;
`;

const Title = ({ h2, h3, h4, h5, h6, sub, menu, noMargin, right, center, ...props }) => {
  if (h2) return <TitleTwo noMargin={noMargin} right={right} center={center} {...props} />;
  if (h3) return <TitleThree noMargin={noMargin} right={right} center={center} {...props} />;
  if (h4) return <TitleFour noMargin={noMargin} right={right} center={center} {...props} />;
  if (h5) return <TitleFive noMargin={noMargin} right={right} center={center} {...props} />;
  if (h6) return <TitleSix noMargin={noMargin} right={right} center={center} {...props} />;
  if (sub) return <TitleSub noMargin={noMargin} right={right} center={center} {...props} />;
  if (menu) return <TitleMenu noMargin={noMargin} right={right} center={center} {...props} />;
  return <TitleOne noMargin={noMargin} right={right} center={center} {...props} />;
};

export default Title;
