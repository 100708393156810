import React from 'react';

const TooltipIcon = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 16A8 8 0 108 0a8 8 0 000 16z" fill="#CFD7DF" />
    <path
      d="M6.06 6a2 2 0 013.89.67c0 1.33-2 2-2 2M8 11.33h0"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TooltipIcon;
