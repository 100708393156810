import React, { memo } from 'react';
import styled from 'styled-components';

// Components
import ImageGrid from './ImageGrid';
import MediaFigure from '../../../components/media/MediaFigure';

// Styles
const GridBody = styled.div`
  display: grid;
  grid-template-columns: 937px 189px;
  grid-column-gap: 30px;
  align-content: flex-start;
`;
const StyledPalleteGrid = styled.div`
  display: grid;
  grid-row-gap: 10px;
  align-content: flex-start;
  border-left: 1px solid ${({ theme }) => theme.joot.blueGray};
  padding: 0 30px;
`;
const StyledMediaFigure = styled(MediaFigure)`
  border-radius: 4%;
  overflow: hidden;
`;

const GroupingGrid = ({ onPostClick, groupings, postSelected, similars, isDemo }) => {
  return (
    <GridBody>
      <ImageGrid onPostClick={onPostClick} postSelected={postSelected} groupings={groupings} isDemo={isDemo} />
      {/*
      <StyledPalleteGrid>
        {similars.map((item, uniqueId) => (
          <StyledMediaFigure key={uniqueId} mediaData={item} />
        ))}
      </StyledPalleteGrid>
      */}
    </GridBody>
  );
};

export default memo(GroupingGrid);
