import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import composeRefs from '../../../utils/composeRefs';

// Context
import { useAuthContext } from '../../../contexts/AuthContext';

// Components
import Avatar from 'react-avatar';
import { useAppContext } from '../../../contexts/AppContext';
import NavItem from '../../../components/nav/NavItem';
import AccountMenu from '../../../components/menus/AccountMenu';
import HelpMenu from '../../../components/menus/HelpMenu';
import SettingsIcon from '../../../components/icons/SettingsIcon';
import ExitIcon2 from '../../../components/icons/ExitIcon2';
import QuestionIconFilled from '../../../components/icons/QuestionIconFilled';
import OptionsMenu from '../../../components/menus/OptionsMenu';

// Styles
const StyledAccountToggle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;
const BottomNav = styled.div`
  display: flex;
  flex-flow: column;
  align-items: baseline;
  margin-top: auto;
`;
const Details = styled.div`
  margin-left: 10px;
`;
const Handle = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: -0.17px;
  color: ${({ theme }) => theme.joot.jootBlack};
`;
const Menu = styled.div`
  color: ${({ theme }) => theme.joot.potteryBlue};
  cursor: pointer;
  font-weight: 700;
  font-family: ${({ theme }) => theme.font.sansSerif};
  font-size: 13px;
`;
const Toggle = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
`;

const helpOptions = [
  { name: "Joot Support", value: "https://discord.com/invite/Jf28jTx" },
  { name: "Joot Resources", value: "https://www.youtube.com/channel/UCtSzFD9tasMCnCEkKnwgUoQ" },
  { name: "Pricing Guide", value: "https://www.getjoot.com/pricing" },
  { name: "Contact Us", value: "https://www.getjoot.com/forum" },
]

const AccountToggle = (props) => {
  const { jootAccount } = useAppContext();
  const history = useHistory();
  const { userHasAuthenticated } = useAuthContext();
  const handleLogout = useCallback(async () => {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push('/');
  }, [userHasAuthenticated, history]);
  const handleHelp = useCallback(async ({ target: { value : value }}) => {
    window.open(value.value); 
  }, []);

  return (
    <>
      <BottomNav>
        <NavItem to="/settings">
          <SettingsIcon />
          Settings
        </NavItem>
        <HelpMenu
          options={helpOptions}
          onChange={handleHelp}
        />
        <NavItem to="" onClick={handleLogout}>
          <ExitIcon2 />
          Logout 
        </NavItem>
      </BottomNav>
      <StyledAccountToggle {...props}>
        {jootAccount && (
          <Avatar
            name={jootAccount.account_name}
            src={jootAccount.profile_picture_url}
            size={43}
            round={true}
          />
        )}
        <Details>
          {<Handle>{jootAccount?.account_name ? (jootAccount.account_name.indexOf('@') > -1 ? `${jootAccount.account_name.match(/(.*?)@/)[1]}` : jootAccount.account_name) : ''}</Handle>}
          <AccountMenu>
            {({ toggle, triggerRef }) => (
              <Menu ref={triggerRef} onClick={toggle} style={{}}>
                Switch Account
              </Menu>
            )}
          </AccountMenu>
        </Details>
      </StyledAccountToggle>
    </>
  );
};

export default AccountToggle;
