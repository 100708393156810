import React from 'react';
import styled from 'styled-components';

// Components
import SignupLayout from '../features/auth/SignupLayout';
import SignupStepCreate from "../features/auth/SignupStepCreate";

const Layout = styled(SignupLayout)`
  grid-template-columns: 1fr 1fr;
  grid-gap: 7vw;
`;

export default function SignupCreate() {
  return (
    <Layout>
      <SignupStepCreate />
    </Layout>
  );
}
