import React from 'react';
import styled from 'styled-components';

// Components
import Button from '../../../components/Button';

const StyledImageActions = styled.div`
  display: flex;
  align-items: center;
`;
const ActionButton = styled(Button)`
  margin: 0 20px 0 20px;
`;

const ImageRankActions = ({ hasUnscoredImagesSelected, onRank, hasImages }) => {
  return (
    <StyledImageActions>
      <ActionButton small green onClick={onRank} disabled={!hasUnscoredImagesSelected} hidden={!hasImages}>
        {hasUnscoredImagesSelected ? 'Rank Selected Images' : 'Select Images to Rank'}
      </ActionButton>
    </StyledImageActions>
  );
};

export default ImageRankActions;
