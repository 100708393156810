import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';

// Hooks
import { useInstagramAccountDetails } from '../../hooks/apiHooks/instagramQueryHooks';

// Components
import { FormControl, Input } from '../form';

// Styles
const Div = styled.div`
  margin-bottom: 15px;
`;

const InputCompetitor = memo(({ name, onChange, placeholder }) => {
  const [search, setSearch] = useState('');
  const [error, setError] = useState('');
  const { submit, isLoading } = useInstagramAccountDetails();
  const handleSearch = useCallback(async () => {
    setError('');
    try {
      // remove the '@' from input
      const competitor = await submit(search ? search.replace('@', '') : search);

      if (competitor.media_count < 250) {
        setError('Account should have at least 300 posts.');
      } else {
        onChange({ target: { name, value: competitor } });
      }
    } catch (e) {
      setError('Account not found or is not an Instagram Professional/Creator account.');
    }
  }, [submit, search, name, onChange]);
  const handleKeyDown = useCallback(
    ({ key }) => {
      if (key === 'Enter') {
        handleSearch();
      }
    },
    [handleSearch],
  );
  const handleChange = useCallback(({ target: { value } }) => {
    setSearch(value);
  }, []);

  return (
    <Div>
      <Input
        isLoading={isLoading}
        onChange={handleChange}
        value={search}
        onBlur={handleSearch}
        onKeyDown={handleKeyDown}
        placeholder={placeholder || "Type a handle and press Enter..."}
        hasError={!!error}
      />
      {error && <FormControl.Error>{error}</FormControl.Error>}
    </Div>
  );
});

export default InputCompetitor;
