import { useCallback, useState } from "react";
import { useStripeCard } from "../../../components/StripeCard";
import { useAppContext } from "../../../contexts/AppContext";
import { createSetupIntention, linkInstagramAccounts, removeAccount } from "../../../services";
import { throwError } from "../../../utils/exception";
import { useMarket } from "./useMarket";

export const useIgAccount = () => {
  const [loading, setLoading] = useState(false);
  const { invalidateIgAccounts, facebook } = useAppContext();
  const { save } = useMarket();
  const { saveCard } = useStripeCard();
  
  const create = useCallback(async form => {
    setLoading(true);
    try {
      await linkInstagramAccounts(form.ig.username);
      await invalidateIgAccounts();
      await facebook.invalidateQuery();
      const clientSecret = await createSetupIntention(form.ig.username, !form.use_existing_card); // setup billing
      if (!form.use_existing_card)
        await saveCard(form.card_name, clientSecret);
      await save(form.ig.username, form.market, form.competitors, undefined, undefined, form.subscription_type);
      setLoading(false);
    } catch (e) {
      await removeAccount(form.ig.username);
      await invalidateIgAccounts();
      setLoading(false);
      throw throwError(e);
    }
  }, [save, invalidateIgAccounts, saveCard, facebook]);

  return {
    create,
    loading,
  }
};