import React, { memo, useCallback, useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Header, Icon, Segment, Popup } from 'semantic-ui-react'

// Components
import MarketMenu from '../../../components/menus/MarketMenu';
import { useMarketInsights } from '../hooks/useInsights';
import Title from '../../../components/Title';
import MarketInsightsRow from '../components/MarketInsightsRow';
import MarketUnavailable from '../components/MarketUnavailable';
import SortByMediaMenu from '../../../components/menus/SortByMediaMenu';
import AccountUpgrade from '../components/AccountUpgrade';
import { FormControl, Checkbox } from '../../../components/form';
import { useAppContext } from '../../../contexts/AppContext';
import EmptyMarketInsights from '../components/EmptyMarketInsights';
import MarketHandles from '../../../components/menus/marketplace/MarketHandles';
import MarketUnlock from '../../../features/media/components/MarketUnlock';

// Assets
import { ReactComponent as SortUp } from '../../../assets/svg/sort-up.svg';
import { ReactComponent as SortDown } from '../../../assets/svg/sort-down.svg';

// Styles
const StyledMarketInsights = styled.section`
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 10px;
  margin: 0px 0px 20px 0px;
`;
const ToolTitle = styled(Title)`
  margin-right: 10px;
`;
const InsightHeader = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 200px;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: ${({ theme }) => theme.joot.white};
`;
const InsightTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 12px 0px;
`;
const HeaderLabelStyles = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  color: ${({ theme }) => theme.joot.jootBlack};
  ${({ ordering = true }) => (ordering ? 'cursor: pointer' : '')}
`;
const Sticky = styled.div`
  position: sticky;
  z-index: 10;
  top: 0;
  background: ${({ theme }) => theme.joot.white};
`;
const Actions = styled.div`
  margin-left: auto;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 150px 200px;
  grid-column-gap: 15px;
`;
const Text = styled.label`
  margin: 0 0 0 5px;
`;
const StyledSortDown = styled(SortDown)`
  width: 8px;
  margin-left: 4px;
  opacity: 0.5;
`;
const StyledSortUp = styled(SortUp)`
  width: 8px;
  margin-left: 4px;
  opacity: 0.5;
`;
const MediaHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  align-self: flex-end;
`;
const HDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0px;
  background: ${({ theme }) => theme.joot.paleGray};
`;
const MenuContainer= styled.div`
  display: flex;
  width: 600px;
  flex-flow: column;
  align-items: baseline;
`;
const MenuTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-right: 10px;
  letter-spacing: -0.37px;
  color: ${({ theme }) => theme.joot.grayIce};
`;
const MarketHandlesWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 15px;
`;
const UnlockMarketWrapper = styled.div`
  padding: 20px;
`;

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const HeaderLabel = memo(({ children, data, name, onChange }) => {
  const active = name === data?.name;
  const handleChange = useCallback(() => {
    onChange && onChange({ name, value: data.value * -1 });
  }, [data, name, onChange]);
  return (
    <HeaderLabelStyles onClick={handleChange}>
      {children}
      {active && <>{data?.value === 1 ? <StyledSortDown /> : <StyledSortUp />}</>}
    </HeaderLabelStyles>
  );
});

const columnConfig = [
  {
    label: "Followers",
    field: "followers",
    transform: (value) => numberWithCommas(value),
  },
  {
    label: "Engagement",
    field: "engagement",
    transform: (value) => value.toFixed(2) + '%',
  },
  {
    label: "Avg. Likes",
    field: "avg_likes",
    transform: (value) => value.toFixed(2),
  },
  {
    label: "Avg. Comments",
    field: "avg_comments",
    transform: (value) => value.toFixed(2),
  },
  {
    label: "Avg. Hashtags",
    field: "avg_hashtags",
    transform: (value) => value.toFixed(2),
  },
  {
    label: "Avg. Emojis",
    field: "avg_emojies",
    transform: (value) => value.toFixed(2),
  },
  {
    label: "Avg. Words",
    field: "avg_word_count",
    transform: (value) => value.toFixed(2),
  },
  {
    label: "Giveaways",
    field: "avg_giveaways",
    transform: (value) => (value * 100).toFixed(2) + "%",
  }
]

const createOption = (item) => ({
  item,
  value: item.market_name,
  name: item.market_name,
})

const UnlockMarketPlaceholder = (currentMarket) => {

  const [showUnlock, setShowUnlock] = useState(false);
  const handleOnUnlock = useCallback(() => {
    setShowUnlock(true);
  }, [setShowUnlock]);
  const handleHide = useCallback(() => {
    setShowUnlock(false);
  }, [setShowUnlock]);

  return (
    <Segment placeholder small>
      <Header icon>
        <Icon name='pie graph' />
        Unlock this market to view insights for all {currentMarket.currentMarket.ig_handles.length} accounts in this market.
      </Header>
      <MarketHandlesWrapper>
        <MarketHandles market_accounts={currentMarket.currentMarket.ig_handles}/>
      </MarketHandlesWrapper>
      <Button inverted color="blue" animated="vertical" onClick={handleOnUnlock}>
        <Button.Content visible>Unlock this Market</Button.Content>
        <Button.Content hidden>$19.99 a month</Button.Content>
      </Button>;
      {showUnlock && <MarketUnlock from={'market-insights'} isVisible={showUnlock} onHide={handleHide} />}
    </Segment>
  );
}

export default function MarketInsights() {
  const {
    competitors,
    userData,
    isReady,
    market,
    sortPostOptions,
    sortPostBy,
    setSortPostBy,
    preparePost,
    showGiveaways,
    sortBy,
    setSortBy,
    setShowGiveaways,
  } = useMarketInsights();
  const {
    markets: { data: marketsObjects },
    setCurrentMarket,
    currentMarket,
    jootAccount,
    accountMarkets
  } = useAppContext();
  const markets = useMemo(
    () => accountMarkets ? accountMarkets.map(item => createOption(item)) : [],
    [accountMarkets]
  )
  const initialMarketSelection = useMemo(
    () => { 
      return markets.length > 0 ? markets[0] : null;
    },
    [markets]
  )
  const handleSort = useCallback(
    ({ target: { value } }) => {
      setSortPostBy(value);
    },
    [setSortPostBy],
  );
  const handleShowGiveaways = useCallback(
    ({ target: { checked } }) => {
      setShowGiveaways(!checked);
    },
    [setShowGiveaways],
  );
  const handleMarketChange = useCallback(({ target: { value: { item } } }) => {
    setCurrentMarket(item);
  }, [setCurrentMarket]);
  const isProfessionalAccount = useMemo(() => jootAccount?.account_type === "PROFESSIONAL", [jootAccount]);
  const hasAccountMarkets = useMemo(() => accountMarkets?.length !== 0, [accountMarkets]);
  useEffect(() => {
    markets.length && console.log("set the current market to: " + JSON.stringify(markets[0].name)) && setCurrentMarket(markets[0].item);
    console.log("current market 2: " + currentMarket?.market_name);
  }, [markets, setCurrentMarket, currentMarket]);

  return (
    <StyledMarketInsights>
      <Sticky>
        {/*!isProfessionalAccount && <AccountUpgrade from={'market insights'} isVisible={true} onHide />*/}
        {/*isProfessionalAccount && !hasAccountMarkets && <AccountUpgrade from={'market insights'} isVisible={true} onHide />*/}
        <MediaHeader>
          <InsightTitle>
            <ToolTitle h3 noMargin>Market Insights</ToolTitle>
            <Popup
              trigger={<Icon name='help' size='small' color="grey" circular />}
              content='Use this the Market Insights tool to view and compare statistics across the Instagram accounts within the selected market. Gain insights into key techniques used by accounts that have higher engagement than others.  These insights can help you determine where you might make similar adjustments to your social posts when targeting this market audience.' 
              position='right'
            />
          </InsightTitle>
          <Actions>
            <Checkbox checked={showGiveaways} onChange={handleShowGiveaways}>
              <Text>Show Giveaways</Text>
            </Checkbox>
            <FormControl>
              <FormControl.Label htmlFor="dropdown">Images Sorted By</FormControl.Label>
              <SortByMediaMenu
                onChange={handleSort}
                items={sortPostOptions}
                initialSelection={sortPostBy}
                name="sortBy"
              />
            </FormControl>
          </Actions>
        </MediaHeader>
        <HDivider/>
        {currentMarket && currentMarket.market_system_type === "SYSTEM" && !currentMarket.exists_insights &&
          <EmptyMarketInsights></EmptyMarketInsights>
        }
        {currentMarket && currentMarket.market_system_type !== "SYSTEM" && !isReady && 
          <MarketUnavailable loading={true}
            title={market ? `Gathering Market Insights.  This may take a few minutes.` : ``}
          />}
        {currentMarket && isReady && (
          <>
            <InsightHeader>
              <HeaderLabelStyles ordering={false}>Account</HeaderLabelStyles>
              {columnConfig && columnConfig.map(({ label, field }) => (
                <HeaderLabel key={field} data={sortBy} name={field} onChange={setSortBy}>
                  {label}
                </HeaderLabel>
              ))}
              <HeaderLabelStyles>Media Type</HeaderLabelStyles>
            </InsightHeader>
            {userData && (
              <MarketInsightsRow
                data={userData}
                imageOpened={true}
                preparePost={preparePost}
                columns={columnConfig}
              />
            )}
          </>
        )}
      </Sticky>
      {currentMarket && isReady && competitors &&
        competitors.map((item, i) => (
          <React.Fragment key={i}>
            {item && (
              <MarketInsightsRow
                data={item}
                imageOpened={i === 0}
                preparePost={preparePost}
                columns={columnConfig}
              />
            )}
          </React.Fragment>
        ))}
      {currentMarket && isReady && currentMarket.is_locked &&
        <UnlockMarketWrapper>
          <UnlockMarketPlaceholder currentMarket={currentMarket}/>
        </UnlockMarketWrapper>
      }
    </StyledMarketInsights>
  );
}
