import React from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import ReactDOM from 'react-dom';

// Utils
import { fadeIn } from './form/mixins/animations';

// Context
import { useAppContext } from '../contexts/AppContext';

// Components
import Loader from './Loader';

// Styles
const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: ${(props) => rgba(props.theme.joot.jootBlack, 0.8)};
  pointer-events: none;
  z-index: 10;
  animation: ${fadeIn} 0.15s ease-out;
`;

const AppLoader = (props) => {
  const { loadingJootAccounts } = useAppContext();

  const show = loadingJootAccounts;

  return show
    ? ReactDOM.createPortal(
        <Overlay>
          <Loader />
        </Overlay>,
        document.body,
      )
    : null;
};

export default AppLoader;
