import { useCallback, useState } from "react";
import { useAppContext } from "../../../contexts/AppContext";
import { useBuyMarket, useUnlockMarket } from "../../../hooks/apiHooks/billingApiHook";
import { createMarket, removeMarket, updateMarket } from "../../../services";
import { throwError } from "../../../utils/exception";

export const useMarket = () => {
  const { submit: buyMarket } = useBuyMarket();
  const { submit: unlockMarket } = useUnlockMarket();
  const [loading, setLoading] = useState(false);
  const {
    markets: { data: markets, invalidateQuery: invalidateMarkets, changeCache: changeMarketCache },
    currentMarket,
    setCurrentMarket,
  } = useAppContext();
  const save = useCallback(async ( accountName, market_name, competitors, primaryAccount, market, subscriptionType) => {
    setLoading(true);
    try {
      if (market) { // is editing.
        await updateMarket(accountName, market_name, market.market_name, competitors);
        if (currentMarket.market_name === market.market_name) {
          setCurrentMarket({
            ...currentMarket,
            market_name,
          })
        }
      } else {
        await createMarket(accountName, market_name, competitors, primaryAccount, true);
      }
      invalidateMarkets();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw throwError(e);
    }
  }, [setLoading, setCurrentMarket, currentMarket, invalidateMarkets]);

  const buy = useCallback(async ( accountName, market_name, is_business) => {
    setLoading(true);
    try {
      console.log("accountName: " + accountName + ", market_name" + market_name + " is_business: " + is_business);
      await buyMarket({ accountName, market_name, is_business }); // not going to buy the market here anymore
      invalidateMarkets();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw throwError(e);
    }

  }, [buyMarket, setLoading, invalidateMarkets]);

  const unlock = useCallback(async ( accountName, market_name, subscriptionType) => {
    setLoading(true);
    try {
      console.log("accountName: " + accountName + ", market_name" + market_name + " subscriptionType: " + subscriptionType);
      await unlockMarket({ accountName, market_name, subscriptionType }); // not going to buy the market here anymore
      invalidateMarkets();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw throwError(e);
    }

  }, [unlockMarket, setLoading, invalidateMarkets]);

  const remove = useCallback(async (removeName, accountName) => {
    setLoading(true);
    try {
      if (currentMarket.market_name === removeName) {
        setCurrentMarket(null)
      }
      await removeMarket(accountName, removeName);
      invalidateMarkets();
      changeMarketCache(markets.filter((item) => item.market_name !== removeName));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw throwError(e);
    }
  }, [setCurrentMarket, changeMarketCache, currentMarket, invalidateMarkets, markets]);

  return {
    save,
    buy,
    unlock,
    remove,
    loading,
  }
};