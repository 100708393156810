import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// Components
import { FormControl } from '../../components/form';
import Button from '../../components/Button';
import AuthIntro from './AuthIntro';
import Title from '../../components/Title';
import Avatar from 'react-avatar';
import useAppToasts from '../../hooks/useToasts';
import { useAcceptInvite } from '../../hooks/apiHooks/jootUserApiHooks';
import { DEFAULT_REDIRECT_PATH } from '../../utils/constants';
import { useAppContext } from '../../contexts/AppContext';

const AltTitle = styled(Title)`
  margin-bottom: 10px;
`;
const Text = styled.p`
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.fourtySeven};
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  padding-top: 15px;
`;
const AccountControl = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.twentyOne};
  margin-bottom: 50px;
  padding-bottom: 30px;
`;
const AccountTitle = styled(Title)`
  margin-left: 10px;
`;
const AcceptButton = styled(Button)`
  margin-left: auto;
`;
const ButtonControl = styled(FormControl)`
  display: flex;
  justify-content: flex-end;
`;

export default function SignupStepCreate() {
  const { errorToast } = useAppToasts();
  const { push } = useHistory();
  const { userInvites } = useAppContext();

  // todo: could be many invitations
  const organization = userInvites && userInvites.invitations.length > 0 ? userInvites.invitations[0] : {};

  const { isLoading, submit } = useAcceptInvite(organization.accountName);

  const createAccountHandler = useCallback(() => {
    push('/signup/one');
  }, [push]);

  const acceptInviteHandler = useCallback(async () => {
    try {
      await submit();
      push(DEFAULT_REDIRECT_PATH);
    } catch (error) {
      errorToast(error.message);
    }
  }, [errorToast, push, submit]);

  return (
    <>
      <AuthIntro />
      <div>
        <AltTitle h3>Accept Invite</AltTitle>
        <Text>
          It looks you have an invite to join Joot within the organization{' '}
          <span style={{ fontWeight: 'bold' }}>{organization.firstName ? organization.firstName : organization.accountName}</span>. Would you
          like to accept the invite or start a new organization.
        </Text>
        <AccountControl style={{ marginTop: `25px` }}>
          {userInvites && userInvites.invitations && userInvites.invitations.map((organization, idx) => (
            <Flex key={idx}>
              <Avatar name={organization.firstName ? organization.firstName : organization.accountName} size={36} round={true} />
              <AccountTitle h6>{organization.firstName ? `${organization.firstName} ${organization.lastName}` : organization.accountName}</AccountTitle>
              <AcceptButton outline disabled={isLoading} onClick={acceptInviteHandler}>
                Accept Invite
              </AcceptButton>
            </Flex>
          ))}
        </AccountControl>
        <ButtonControl>
          <Button blank type="button" onClick={createAccountHandler}>
            Create New Account
          </Button>
          <Button outline type="button" onClick={createAccountHandler}>
            Next
          </Button>
        </ButtonControl>
      </div>
    </>
  );
}
