import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import ResizeObserver from 'resize-observer-polyfill';
import { useHover, ToggleLayer, Arrow } from 'react-laag';
import { motion, AnimatePresence } from 'framer-motion';

const TooltipBox = styled(motion.div)`
  background: ${({ theme }) => theme.color.two};
  color: white;
  font-size: 12px;
  line-height: 17px;
  padding: 16px 20px;
  border-radius: 6px;
  transform-origin: center center;
  z-index: 1000;
  max-width: ${(props) => props.width}px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
`;

const TooltipText = styled.span`
  cursor: help;
`;

function Tooltip({ children, text, width = 200, anchor = 'TOP_CENTER' }) {
  const [show, hoverProps] = useHover({ delayEnter: 300, delayLeave: 200 });
  const themeContext = useContext(ThemeContext);
  let arrowColor = themeContext.color.two;

  return (
    <ToggleLayer
      isOpen={show}
      ResizeObserver={ResizeObserver}
      fixed
      placement={{
        anchor,
        autoAdjust: true,
        triggerOffset: 15,
      }}
      renderLayer={({ isOpen, layerProps, layerSide, arrowStyle }) => {
        return (
          <AnimatePresence>
            {isOpen && (
              <TooltipBox
                {...layerProps}
                initial={{
                  opacity: 0,
                }}
                width={width}
                animate={{ opacity: 1 }}
                exit={{
                  opacity: 0,
                }}
                transition={{
                  type: 'spring',
                  damping: 30,
                  stiffness: 500,
                }}
              >
                {text}
                <Arrow style={arrowStyle} layerSide={layerSide} backgroundColor={`${arrowColor}`} roundness={1} />
              </TooltipBox>
            )}
          </AnimatePresence>
        );
      }}
    >
      {({ triggerRef }) => (
        <TooltipText ref={triggerRef} {...hoverProps}>
          {children}
        </TooltipText>
      )}
    </ToggleLayer>
  );
}

export default Tooltip;
