import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation, queryCache } from 'react-query';
import { pages, link, /*facebookConnections,*/ /*igAccounts,*/ jootAccounts, getFacebookData } from '../../services';
import { DEFAULT_QUERY_OPTIONS } from './api-hooks-utils';
import { useAuthContext } from '../../contexts/AuthContext';

export const useFacebookPages = (accountName) => {
  return useQuery('facebook_pages', async () => pages(accountName), DEFAULT_QUERY_OPTIONS);
};

export const useJootAccounts = () => {
  const { isAuthenticated } = useAuthContext();
  const [jootAccount, changeJootAccount] = useState();
  const query = 'joot_accounts';
  const invalidateQuery = useCallback(() => {
    queryCache.invalidateQueries(query);
  }, [query]);
//  const { data, ...state } = useQuery(query, async () => igAccounts(), {
//    enabled: isAuthenticated,
//    ...DEFAULT_QUERY_OPTIONS,
//  });
  const { data, ...state } = useQuery(query, async () => jootAccounts(), {
    enabled: isAuthenticated,
    ...DEFAULT_QUERY_OPTIONS,
  });
  useEffect(() => {
    if (data) {
      changeJootAccount(data[0]);
    }
  }, [data]);

  return {
    invalidateQuery,
    jootAccount,
    changeJootAccount,
    data,
    ...state,
  };
};

export const useFacebookData = () => {
  const { isAuthenticated } = useAuthContext();
  const query = 'facebook_data';
  const { data = {},
          status: facebookStatus,
          error: facebookError,
          refetch: refetchFacebook,
          isFetching: loadingFacebook,
          ...rest } = useQuery(query, async () => getFacebookData(), {
    ...DEFAULT_QUERY_OPTIONS,
    enabled: isAuthenticated,
  });
  const igAccountsNoConnected = useMemo(
    () => (data.ig_accounts ? data.ig_accounts.filter((item) => !item.linked_to_joot_account) : null),
    [data.ig_accounts],
  );
  const igAccountsConnected = useMemo(
    () => (data.ig_accounts ? data.ig_accounts.filter((item) => item.linked_to_joot_account) : null),
    [data.ig_accounts],
  );
  const igAccounts = useMemo(
    () => (data.ig_accounts ? data.ig_accounts : null),
    [data.ig_accounts],
  );
  const invalidateQuery = useCallback(() => {
    queryCache.invalidateQueries(query);
  }, [query]);

  return {
    data: {
      ...data,
      igAccounts,
      igAccountsNoConnected,
      igAccountsConnected,
    },
    invalidateQuery,
    refetchFacebook,
    loadingFacebook,
    facebookStatus,
    facebookError,
    ...rest,
  };
};

/*
export const useFacebookConnections = (accountName) => {
  return useQuery(['facebook_cx', accountName], async () => facebookConnections(accountName), {
    enabled: accountName,
    ...DEFAULT_QUERY_OPTIONS,
  });
};
*/

export const useFacebookLink = () => {
  const [mutate, { status, data, error, reset }] = useMutation(async ({ accessToken }) => link(accessToken));

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
};
