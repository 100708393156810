import React, { memo, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import ImgsViewer from 'react-images-viewer';
import rgba from 'polished/lib/color/rgba';
import { Button, Accordion, Icon, Header, Divider } from 'semantic-ui-react';

// Contexts
import { useAppContext } from '../../contexts/AppContext';

// Components
import MediaFigure from '../../components/media/MediaFigure';
import Loader from '../../components/Loader';

const height = '200px';

// Styles
const Section = styled.section`
  margin-bottom: 40px;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-gap: 16px;
`;
const HDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0px;
  background: ${({ theme }) => theme.joot.paleGray};
`;
const UnscoredDivider = styled.div`
  display: flex;
  flex-flow: row;
  margin: 20px 0 20px 0;
  white-space: nowrap;
`;
const UnscoredTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: ${({ theme }) => theme.joot.grayIce};
`;
const MediaLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  background-color: ${(props) => rgba(props.theme.joot.jootBlack, 0.1)};
  height: 200px;
`;

const MediaList = ({ list, sampleImages, showSampleImages, activeImports, isSelected, selectionChangeHandler, starSingleImage, onDeleteOne, onAddTags, onShowSampleImages, onShowRankedImages, onShowUnrankedImages }) => {
  const { marketName } = useAppContext();
  const [currImg, setCurrImg] = useState(-1);
  const [showSamples, setShowSamples] = useState(showSampleImages);
  const [showRanked, setShowRanked] = useState(true);
  const [showUnranked, setShowUnranked] = useState(true);
  const handleClick = useCallback(
    (img) => {
      const index = list.findIndex((item) => item.id === img.id);
      setCurrImg(index);
    },
    [list],
  );
  const toggleShowSampleImages = useCallback(() => {
      if (onShowSampleImages)
        onShowSampleImages(!showSamples);
      setShowSamples(!showSamples);
    },
    [showSamples],
  );
  const toggleShowRankedImages = useCallback(() => {
      if (onShowRankedImages)
        onShowRankedImages(!showRanked);
      setShowRanked(!showRanked);
    },
    [showRanked],
  );
  const toggleShowUnrankedImages = useCallback(() => {
      if (onShowUnrankedImages)
        onShowUnrankedImages(!showUnranked);
      setShowUnranked(!showUnranked);
    },
    [showUnranked],
  );
  const numScoredImages = useMemo(
    () => 
          list && list.filter((item) => item.order != undefined && item.order >= 0).length,
    [list]
  );
  const numUnscoredImages = useMemo(
    () => 
          list && list.filter((item) => item.order == undefined || item.order == -1).length,
    [list]
  );
  const nextImg = useCallback(() => setCurrImg((prev) => prev + 1), []);
  const prevImg = useCallback(() => setCurrImg((prev) => prev - 1), []);

  return (
    <>
      {list && (
        <ImgsViewer
          width={2000}
          imgs={list}
          currImg={currImg}
          spinner={Loader}
          isOpen={currImg > -1}
          onClickPrev={prevImg}
          onClickNext={nextImg}
          onClose={() => setCurrImg(-1)}
        />
      )}
      <Section>
          <Accordion fluid>
            {(sampleImages && sampleImages.length > 0) && (
              <>
              <Accordion.Title
                active={showSamples === true}
                index={0}
                onClick={() => toggleShowSampleImages()}
              >
                <UnscoredDivider>
                  <UnscoredTitle>
                    <Icon name='dropdown' />
                    Top Ranked Images from Instagram Accounts in this market - {marketName}
                  </UnscoredTitle>
                  <HDivider/>
                </UnscoredDivider>
              </Accordion.Title>
              <Accordion.Content active={showSamples === true}>
                <Grid>
                  {sampleImages.map((item) => (
                      <MediaFigure
                        key={item.id}
                        mediaData={item}
                        onView={() => handleClick(item)}
                      />
                    ))}
                </Grid>
              </Accordion.Content>
              </>
            )}
            {list && list.find((item) => item.order >= 0) && (
              <>
              <Accordion.Title
                active={showRanked === true}
                index={1}
                onClick={() => toggleShowRankedImages()}
              >
                <UnscoredDivider>
                  <UnscoredTitle>
                    <Icon name='dropdown' />
                    Your Ranked Images ({numScoredImages})
                  </UnscoredTitle>
                  <HDivider/>
                </UnscoredDivider>
              </Accordion.Title>
              <Accordion.Content active={showRanked === true}>
                <Grid>
                  {list && list.filter((item) => item.order >= 0)
                    .map((item) => (
                      <MediaFigure
                        key={item.id}
                        mediaData={item}
                        isSelected={isSelected(item)}
                        starSingleImage={() => starSingleImage(item)}
                        onSelect={() => selectionChangeHandler(item)}
                        onDelete={() => onDeleteOne(item)}
                        onTag={() => onAddTags(item)}
                        onClick={() => selectionChangeHandler(item)/*handleClick*/}
                        onView={() => handleClick(item)}
                        whileHover="hover"
                        multiSelect
                      />
                    ))}
                </Grid>
              </Accordion.Content>
              </>
            )}
            {(activeImports > 0 || numUnscoredImages > 0) && (
              <>
              <Accordion.Title
                active={showUnranked === true}
                index={2}
                onClick={() => toggleShowUnrankedImages()}
              >
                <UnscoredDivider>
                  <UnscoredTitle>
                    <Icon name='dropdown' />
                    {showUnranked === true ? (
                      'Select images below to rank them in this market - ' + marketName + ' (' + numUnscoredImages + ')' + (activeImports > 0 ? (' - Importing ... ' + activeImports) : '')
                    ) : (
                      'Your Unranked Images (' + numUnscoredImages + ')' + (activeImports > 0 ? (' - Importing ... ' + activeImports) : '')
                    )}
                  </UnscoredTitle>
                  <HDivider/>
                </UnscoredDivider>
              </Accordion.Title>
              <Accordion.Content active={showUnranked === true}>
                <Grid>
                  {list &&
                    list.filter((item) => item.order == undefined || item.order == -1)
                    .map((item) => (
                      <MediaFigure
                        key={item.id}
                        mediaData={item}
                        isSelected={isSelected(item)}
                        starSingleImage={() => starSingleImage(item)}
                        onSelect={() => selectionChangeHandler(item)}
                        onDelete={() => onDeleteOne(item)}
                        onTag={() => onAddTags(item)}
                        onClick={() => selectionChangeHandler(item)/*handleClick*/}
                        onView={() => handleClick(item)}
                        whileHover="hover"
                        multiSelect
                      />
                    ))}
                  {list &&
                    list.filter((item) => item.isLoading == true)
                    .map((item) => (
                      <MediaFigure
                        key={item.id}
                        mediaData={{isLoading: true}}
                        whileHover="hover"
                      />
                    ))}
                  {activeImports > 0 && 
                    [...Array(activeImports)].map((x, i) =>
                      <MediaLoading key={x}>
                        <Loader />
                      </MediaLoading>
                    )
                  }
                </Grid>
              </Accordion.Content>
              </>
            )}
          </Accordion>
      </Section>
    </>
  );
};

export default memo(MediaList);
