import React from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Components
import AlertIcon from '../icons/AlertIcon';

// Styles
const StyledErrorMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.joot.cinnabar};
  background: ${({ theme, dark }) => (dark ? rgba(theme.joot.lightPink, 0.08) : theme.joot.lightPink)};
  border-radius: 3px;
  font-size: 12px;
  font-weight: 700;
  margin: 0 0 30px 0;
  padding: 10px 20px;
`;

const AlertIconSvg = styled(AlertIcon)`
  margin-left: 10px;
`;

const ErrorMessage = ({ children, ...props }) => {
  return (
    <StyledErrorMessage dark={props.dark}>
      {children} <AlertIconSvg />
    </StyledErrorMessage>
  );
};

export default ErrorMessage;
