import React, { memo } from 'react';
import styled from 'styled-components';

// Hooks
import { useSearch } from '../hooks/useSearch';

// Assets
import { ReactComponent as SearchIcon } from '../../../assets/svg/search.svg';

// Components
import Tag from './Tag';
import ByCategory from '../../../components/menus/ByCategory';
import SearchInput from '../../../components/SearchInput';

// Styles
const SelectedContainer = styled.div`
  margin-top: 2px;
  padding: 10px 10px 5px 10px;
  border-radius: 5px;
  background: ${({ theme }) => theme.joot.shark};
`;
const colorByType = {
  date: 'mustard',
  tag: 'carolinaBlue',
  ai: 'emeraldGreen',
};

const ItemsSelected = ({ items = [], onRemove }) => {
  return items.map((item) => {
    if (item.type === 'tag')
      return (<Tag key={item.value} color={colorByType[item.type]} onRemove={() => onRemove(item)}>
          {item.name}
        </Tag>);
    else
      return (<Tag key={item.value} value={item} onRemove={() => onRemove(item)}>
          {item.name}
        </Tag>);
  });
};

const Search = ({ name, options: optionsIn, itemsSelected = [], onChange }) => {
  const { options, handleRemove, handleChange, handleFilterChange, filterValue } = useSearch(
    optionsIn,
    name,
    itemsSelected,
    onChange,
  );

  return (
    <ByCategory options={options} onSelectItemMenu={handleChange}>
      {({ triggerRef, toggle }) => (
        <>
          <div ref={triggerRef} onFocus={toggle} onBlur={toggle}>
            <SearchInput
              name="search"
              placeholder="Search..."
              IconComponent={SearchIcon}
              onFocus={toggle}
              onBlur={toggle}
              onChange={handleFilterChange}
              value={filterValue}
            />
          </div>
          {itemsSelected.length > 0 && (
            <SelectedContainer>
              <ItemsSelected items={itemsSelected} onRemove={handleRemove} />
            </SelectedContainer>
          )}
        </>
      )}
    </ByCategory>
  );
};

export default memo(Search);
