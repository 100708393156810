import React, { createContext, useState, useContext, useCallback } from "react";
import ConfirmModal from '../components/modals/ConfirmModal';

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [show, setShow] = useState(false)
  const [onCancel, setOnCancel] = useState(null)
  const [onConfirm, setOnConfirm] = useState(null)
  const [message, setMessage] = useState('')
  const open = (message, onConfirm, onCancel) => {
    setOnCancel(() => onCancel)
    setOnConfirm(() => onConfirm)
    setMessage(message)
    setShow(true)
  }
  const handleBtn = useCallback(method => {
    setShow(false)
    method && method()
    setOnCancel(null)
    setOnConfirm(null)
  }, [])

  return (
    <ModalContext.Provider value={{
      open,
    }}>
      <ConfirmModal
        isVisible={show}
        message={message}
        onCancel={() => handleBtn(onCancel)}
        onConfirm={() => handleBtn(onConfirm)}
      />
      {children}
    </ModalContext.Provider>
  )
}

export const useModalConfirm = () => {
  const ctx = useContext(ModalContext);
  if (!ctx) {
    throw Error('The `useModalConfirm` hook must be called from a descendent of the `ModalProvider`.');
  }

  return {
    open: ctx.open,
  }
}