// temp warnings disabled
/* eslint-disable no-unused-vars */
import React, { useContext, createContext, useEffect, useCallback, useMemo, useState } from 'react';
import { Auth } from 'aws-amplify';

// Hooks
import { /*useLinkedCompetitorsList, useSocialAccountList,*/ useGetCreateMarket } from '../hooks/apiHooks/instagramQueryHooks';
//import { useGetCreateLibrary } from '../hooks/apiHooks/mediaApiHooks';
import useStateVars from '../hooks/useStateVars';
import { useFacebookData, useJootAccounts } from '../hooks/apiHooks/facebookQueryHooks';
import { useAuthContext } from './AuthContext';
import { useQuery } from 'react-query';
import { getColorTags } from '../services/insightsApiServices';

const AppContext = createContext(null);

const initialState = {
  //instagramAccounts: [], // linked IG accounts
  user: null, // current logged user
  //fbTokenDetails: {},
  //hasFbAccounts: false,
  //selectedCompetitors: [],
  animateProgress: true,
  jootAccount: null,
  savedPost: {},
};

export function AppContextProvider(props) {
  const { isAuthenticated } = useAuthContext();
  const { state, changeState: changeAppState } = useStateVars(initialState);
  const { data: jootAccounts, jootAccount, changeJootAccount, isFetching: loadingJootAccounts, invalidateQuery: invalidateJootAccounts } = useJootAccounts();
  const accountName = useMemo(() => jootAccount?.account_name, [jootAccount])
  const markets = useGetCreateMarket(jootAccount);
//  const libraries = useGetCreateLibrary(jootAccount);
  const { data: facebook, refetchFacebook, invalidateQuery: invalidateFacebook, loadingFacebook, facebookStatus, facebookError } = useFacebookData();
  const marketName = useMemo(() => markets.currentMarket?.market_name, [markets.currentMarket]);
  const marketType = useMemo(() => markets.currentMarket?.market_system_type ? markets.currentMarket.market_system_type : "ACCOUNT", [markets.currentMarket]);
//  const libraryName = useMemo(() => libraries.currentLibrary?.library_name, [libraries.currentLibrary]);
  const accountMarkets = useMemo(() => markets.data?.filter((item) => item.market_system_type != "SYSTEM"), [markets.data]);
  const [uploads, setUploads] = useState(jootAccount?.image_uploads_remaining);
  const { data: tag_insights } = useQuery(
    ['market-colors', accountName, marketName, marketType],
    () => getColorTags(accountName, marketName, marketType),
  )

  // This must be an old function
  /*const { data: competitors, isFetching: loadingLinkedCompetitors } = useLinkedCompetitorsList(
    state.jootAccount ? state.jootAccount.accountName : null,
    state.currentIgAccount.username,
  );
  const {
    data: facebookConnections,
    refetch: refetchFacebookConnections,
    isFetching: loadingFacebookConnections,
  } = useSocialAccountList(state.jootAccount ? state.jootAccount.accountName : null);
  */

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const fetchUser = async () => {
      return await Auth.currentAuthenticatedUser();
    };

    const fetchInfo = async () => {
      return await Auth.currentUserInfo();
    };

    fetchUser().then((userAuth) => {
      fetchInfo().then((userInfo) => {
        userAuth.attributes = userInfo.attributes;
        changeAppState('user', userAuth);
      });
    }).catch(() => { });
  }, [changeAppState, isAuthenticated]);

  const updateUserAttributes = useCallback(
    (attr) => {
      let user = state.user;
      user.attributes = attr;
      changeAppState('user', user);
    },
    [changeAppState, state.user],
  );

  return (
    <AppContext.Provider
      value={{
        ...state,
        currentMarket: markets.currentMarket,
        setCurrentMarket: markets.setCurrentMarket,
//        setLibrarytMarket: markets.setLibraryMarket,
//        currentLibrary: libraries.currentLibrary,
//        setCurrentLibrary: libraries.setCurrentLibrary,
        isSystemMarket: markets.currentMarket?.market_system_type == "SYSTEM",
        changeAppState,
        uploads,
        setUploads,
        markets,
        accountMarkets,
//        libraries,
        accountName,
        //igUserName,
        //competitors,
        loadingJootAccounts,
        jootAccounts,
        refetchFacebook,
        //loadingLinkedCompetitors,
        loadingFacebook,
        invalidateFacebook,
        facebookStatus,
        facebookError,
        //facebookConnection,
        updateUserAttributes,
        invalidateJootAccounts,
        jootAccount,
        changeJootAccount,
        marketName,
        marketType,
//        libraryName,
        facebook,
        tag_insights,
      }}
      {...props}
    />
  );
}

export function useAppContext() {
  const {
    changeAppState,
    //instagramAccounts,
    //competitors,
    //selectedCompetitors,
    jootAccount,
    //hasFbAccounts,
    refetchFacebook,
    animateProgress,
    //loadingLinkedCompetitors,
    loadingFacebook,
    invalidateFacebook,
    facebookStatus,
    facebookError,
    //facebookConnection,
    //fbTokenDetails,
    user,
    updateUserAttributes,
    savedPost,
    loadingJootAccounts,
    jootAccounts,
    invalidateJootAccounts,
    accountName,
    //igUserName,
    markets,
    accountMarkets,
//    libraries,
    currentMarket,
    setCurrentMarket,
//    currentLibrary,
//    setCurrentLibrary,
    changeJootAccount,
    marketName,
    marketType,
//    libraryName,
    facebook,
    tag_insights,
    uploads,
    setUploads,
    isSystemMarket
  } = useContext(AppContext);

  // for code autocomplete purpose
  return {
    changeAppState,
    //instagramAccounts,
    //competitors,
    //selectedCompetitors,
    jootAccount,
    //hasFbAccounts,
    refetchFacebook,
    animateProgress,
    //loadingLinkedCompetitors,
    loadingFacebook,
    invalidateFacebook,
    facebookStatus,
    facebookError,
    //facebookConnection,
    //fbTokenDetails,
    user,
    updateUserAttributes,
    savedPost,
    loadingJootAccounts,
    jootAccounts,
    invalidateJootAccounts,
    accountName,
    //igUserName,
    markets,
    accountMarkets,
//    libraries,
    currentMarket,
    setCurrentMarket,
//    currentLibrary,
//    setCurrentLibrary,
    changeJootAccount,
    marketName,
    marketType,
//    libraryName,
    facebook,
    tag_insights,
    uploads,
    setUploads,
    isSystemMarket
  };
}
