import React from 'react';

// Context
import { useAppContext } from '../../contexts/AppContext';

// Components
import AvatarMenu from './AvatarMenu';

const AccountMenu = ({ children }) => {
  const { igAccounts, igAccount, changeIgAccount } = useAppContext();

  return (
    <>
      {igAccounts && igAccount && (
        <AvatarMenu
          items={igAccounts}
          selected={igAccount}
          onSelect={(selected) => changeIgAccount(selected)}
          placement={{ anchor: 'TOP_CENTER', }}
          avatarNameField="name"
          srcField="profile_picture_url"
        >
          {children}
        </AvatarMenu>
      )}
    </>
  );
};

export default AccountMenu;
