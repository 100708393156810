import React, { memo } from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Components
import Title from '../Title';

// Styles
const StepBody = styled.div`
  padding: 20px 75px 20px 75px;
`;
const AccountTitle = styled(Title)`
  line-height: 20px;
  > span {
    margin-top: 60px;
    display: block;
    font-weight: 800;
    font-size: 38pt;
    text-align: center;
  }
`;
const SubTitle = styled(Title)`
  line-height: 25px;
  > span {
    margin-top: 60px;
    display: block;
    font-weight: 600;
    font-size: 20pt;
    text-align: center;
  }
`;
const TitleArea = styled.div`
  align-items: center;
`;
const Text = styled.p`
  font-size: 17px;
  line-height: 23px;
  margin-top: 28px;
  font-weight: 500;
  letter-spacing: -0.26px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;

const SuccessForm = ({ title, header, message, ...props }) => {

  return (
    <>
      <TitleArea>
        <AccountTitle h5 noMargin>
          <span>{title} &#127881;</span>
        </AccountTitle>
        <SubTitle h5 noMargin>
          <span>{header}</span>
        </SubTitle>
      </TitleArea>
      <StepBody>
        <Text>{message}</Text>
      </StepBody>
    </>
  );
};

export default memo(SuccessForm);
