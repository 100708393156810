import React, { memo } from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Components
import Title from '../Title';

// Styles
const StepBody = styled.div`
  padding: 20px 70px 70px 70px;
`;

const VideoArea = styled.div`
  align-items: center;
`;

const AccountTitle = styled(Title)`
  line-height: 20px;
  > span {
    margin-top: 20px;
    margin-bottom: 50px;
    display: block;
    font-weight: 400;
    font-size: 40pt;
    text-align: center;
  }
`;
const TitleArea = styled.div`
  align-items: center;
`;

const Text = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  margin-top: 20px;
  margin-bottom: 30px;
  letter-spacing: -0.26px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;

const OverviewList = styled.ul`
  margin-top: 20px;
  margin-left: 20px;
  letter-spacing: -0.26px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;

const ListItem = styled.li`
  font-size: 17px;
  line-height: 4px;
  margin-top: 20px;
  letter-spacing: -0.26px;
  list-style-type: disc;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;

const SupportOverviewForm= () => {

  return (
    <>
      <TitleArea>
        <AccountTitle h3 noMargin>
          <span>Joot Discord &amp; Support</span>
        </AccountTitle>
      </TitleArea>
      <VideoArea h5 noMargin>
        <iframe src="https://player.vimeo.com/video/536178919" width="640" height="304" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
      </VideoArea>
      <StepBody>
        <Text>Benefits of being in the Joot Discord:</Text>
        <OverviewList>
          <ListItem>Receive immediate customer support</ListItem>
          <ListItem>Access Joot Creator Community</ListItem>
          <ListItem>One click away from a library of learning resources</ListItem>
        </OverviewList>
      </StepBody>
    </>
  );
};

export default memo(SupportOverviewForm);
