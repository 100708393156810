import React, { memo } from 'react';
import { Grid, Image, Icon, Segment, Header } from 'semantic-ui-react'

// Components
import EmptyMessage from './EmptyMessage';

const EmptyScoredMedia = ({ children }) => {
  return (
    <>
      <Segment placeholder>
        <Header icon>
          <Icon name='cloud upload' size='massive' color='grey' />
          Upload Images to get Ranking
        </Header>
        <Segment.Inline>
          {children}
        </Segment.Inline>
      </Segment>
    </>
  );
};

export default memo(EmptyScoredMedia);
