import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import config from '../../../utils/config';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Hooks
import useModal from '../../../hooks/useModal';
import useAppToasts from '../../../hooks/useToasts';
import { useUpgradeMarket } from '../hooks/useUpgradeMarket';
import { useGetBillingDetail } from '../../../hooks/apiHooks/billingApiHook';

// Context
import { useAppContext } from '../../../contexts/AppContext';

// Components
import SelectedSetting from './SelectedSetting';
import SubscriptionPlan from '../../../components/WelcomeForms/SubscriptionPlan';
import PaymentForm from '../../../components/WelcomeForms/PaymentForm';
import SuccessForm from '../../../components/WelcomeForms/SuccessForm';
import ModalForm from '../../../components/modals/ModalForm';

const CARD_STEP = 'CARD_STEP';
const SUCCESS_STEP = 'SUCCESS_STEP';

// Styles
const StyledAccountPlanTab = styled.div`
  display: flex;
`;

const FORM_INIT_DATA = {
  card_name: '',
  use_existing_card: false,
};

const Form = () => {
  const { errorToast } = useAppToasts();
  const { isVisible, toggle } = useModal();
  const [hideCancel, setHideCancel] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(CARD_STEP);
  const [form, setForm] = useState({ ...FORM_INIT_DATA });
  const {
    jootAccount,
    invalidateJootAccounts
  } = useAppContext();
  useMemo(() => {
    setForm((prev) => ({ ...prev, plan: jootAccount?.account_plan_type}));
  }, [jootAccount]);
  const {
    promoCode,
    invalidateQuery
  } = useGetBillingDetail();
  useEffect(() => {
    if (!isVisible) {
      setStep(CARD_STEP);
    }
  }, [isVisible]);
  const { upgrade } = useUpgradeMarket();
  const handlePlanChange = useCallback((value) => {
    form.plan_type = value;
    toggle();
  }, [form, toggle]);
  const handleOk = useCallback(async () => {
    if (step === SUCCESS_STEP) {
      //onHide();
      toggle();
      return;
    }
    if (step === CARD_STEP) {
      if (form.use_existing_card || form.card_name) {
        setIsLoading(true);
        try {
          await upgrade(jootAccount.account_name, form.market, form, false); // upgrade to pro and create a market credit
          invalidateQuery();
        } catch (e) {
          console.log(e);
          errorToast('There was a problem upgrading the account. Contact Joot Support.');
          setIsLoading(false);
          //onHideForm();
          return;
        }
        invalidateJootAccounts();
        setIsLoading(false);
        setStep(SUCCESS_STEP);
      }
    }
  }, [step, form, invalidateJootAccounts, upgrade, toggle, jootAccount, invalidateQuery, errorToast]);
  const buttonText = useMemo(() => {
    if (step === CARD_STEP) {
      return 'Purchase Upgrade';
    }
    setHideCancel(true);
    return 'Finish';
  }, [step, setHideCancel]);
  const handleChange = useCallback(({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  }, []);
  const handleCardChange = useCallback((value) => handleChange({ target: { name: 'card_name', value } }),
  [ handleChange ]);
  const handleUseExistingCardChange = useCallback((value) => {
    handleChange({ target: { name: 'use_existing_card', value } })
  }, [ handleChange ]);

  console.log("jootAccount.account_plan_type: " + JSON.stringify((jootAccount?.account_plan_type)));

  return (
    <StyledAccountPlanTab>
      <SubscriptionPlan
        onPlanChange={handlePlanChange}
        value={jootAccount?.account_plan_type === undefined ? "free" : jootAccount.account_plan_type}
        upgradePath={true}
        />

      {
        <ModalForm
          isVisible={isVisible}
          title={'Upgrade Account Plan'}
          maxWidth="800px"
          okText={buttonText}
          onHide={() => toggle()}
          onOk={handleOk}
          hideCancel={hideCancel}
          isLoading={isLoading}
          hideBack={true}
        >
          <>
            {step === CARD_STEP && <PaymentForm 
              onCardChange={handleCardChange}
              onUseExistingCardChange={handleUseExistingCardChange}
              cardName={form.card_name}
              planType={form.plan_type}/>}
            {step === SUCCESS_STEP && <SuccessForm
              title="Awesomeness has arrived"
              header="We're ready to build customized AI models for your market."
              message="Begin by creating a Custom Market and then relax. We'll send you an email when it's ready." />}
          </>
        </ModalForm>
      }
    </StyledAccountPlanTab>
  );
};

const AccountPlanTab = ({ from, show, onHide, ...props }) => {
  const stripePromise = loadStripe(config.stripe.key);
  return (
    <Elements stripe={stripePromise}>
      <Form />
    </Elements>
  );
};

export default memo(AccountPlanTab);