import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Components
import OptionsMenu from './OptionsMenu';

// Styles
const Section = styled.div`
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1.83px;
  margin: 5px 20px;
  padding: 8px 0;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.6)};
  border-bottom: 1px solid ${({ theme }) => theme.joot.blueGray}; ;
`;
const ItemValue = styled.div`
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  padding: 4px 10px 4px 20px;
  line-height: 25px;
`;

const ItemMenu = ({ onClick, name, isSection }) => {
  return isSection ? <Section>{name}</Section> : <ItemValue onClick={onClick}>{name}</ItemValue>;
};

const ByCategory = ({ children, options, onSelectItemMenu }) => {
  const items = useMemo(() => {
    const resulst = [];
    options.forEach(({ seccion, items }) => {
      resulst.push({ name: seccion, isSection: true });
      items.forEach((item) => resulst.push(item));
    });
    return resulst;
  }, [options]);
  return (
    <OptionsMenu items={items} MenuItemCmp={ItemMenu} onSelectItemMenu={onSelectItemMenu} width="234px">
      {(props) => children(props)}
    </OptionsMenu>
  );
};

export default memo(ByCategory);
