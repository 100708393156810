import React from 'react';
import styled, { css } from 'styled-components';

// Hooks
import usePasswordInput from '../../hooks/usePasswordInput';

// Components
import { Input as NormalInput } from './index';
import EyeIcon from '../icons/EyeIcon';
import EyeClosedIcon from '../icons/EyeClosedIcon';

// Styles
const IconWrapper = styled.div`
  position: relative;
`;

export const inputIconStyle = css`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const StyledEyeIcon = styled(EyeIcon)`
  ${inputIconStyle}
`;

export const StyledEyeClosedIcon = styled(EyeClosedIcon)`
  ${inputIconStyle}
`;

const PasswordInput = React.forwardRef(function PasswordInput(props, ref) {
  const { showPassword, toggleShowPasswordHandler } = usePasswordInput();
  const Input = props.input || NormalInput;

  return (
    <IconWrapper>
      <Input {...props} type={showPassword ? 'text' : 'password'} ref={ref} />
      {showPassword && !props.hasError && <StyledEyeIcon onClick={toggleShowPasswordHandler} />}
      {!showPassword && !props.hasError && <StyledEyeClosedIcon onClick={toggleShowPasswordHandler} />}
    </IconWrapper>
  );
});

export default PasswordInput;
