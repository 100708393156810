import { queryCache, useMutation, useQuery } from 'react-query';
import { useTheme } from 'styled-components';
import {
  addCompetitorAccounts, igAccountDetails,
  jootIgAccountDetails,
  linkedCompetitors,
  linkInstagramAccounts,
  searchAccounts,
  socialAccountList,
  createMarket,
  getMarket,
  getModels,
  saveSelectedMarket
} from '../../services';
import { useAuthContext } from '../../contexts/AuthContext';
import { DEFAULT_QUERY_OPTIONS } from './api-hooks-utils';
import { useAppContext } from '../../contexts/AppContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateMutation } from '../useCreateMutation';
import { ItemMeta } from 'semantic-ui-react';

export const useLinkedCompetitorsList = (accountName, igUserName) => {
  const { isAuthenticated } = useAuthContext();

  return useQuery(
    ['joot_competitors', accountName, igUserName],
    async () => linkedCompetitors(accountName, igUserName),
    {
      enabled: accountName && isAuthenticated && igUserName,
      ...DEFAULT_QUERY_OPTIONS,
    },
  );
};

export const useInstagramAccountDetails = () => {
  return useCreateMutation(igUserName => jootIgAccountDetails(igUserName), {
    throwOnError: true,
  });
};

/*
export const useIgAccountDetails = (igUsername) => {
  const { currentIgAccount, fbTokenDetails } = useAppContext();

  return useQuery(
    ['ig_api_account_details', igUsername, currentIgAccount && currentIgAccount.username],
    async () => igAccountDetails(igUsername, currentIgAccount && currentIgAccount.id, fbTokenDetails.access_token),
    {
      enabled: igUsername && currentIgAccount && currentIgAccount.id && fbTokenDetails && fbTokenDetails.access_token,
      ...DEFAULT_QUERY_OPTIONS,
    },
  );
};
*/

export const useInstagramSearchAccount = (query) => {
  return useQuery(['ig_search', query], async () => searchAccounts(query), {
    enabled: query,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useSocialAccountList = (jootAccountName) => {
  return useQuery(['linked_social_accounts', jootAccountName], async () => socialAccountList(jootAccountName), {
    enabled: jootAccountName,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useAccountList = () => {
  return useQuery(['joot_accounts'], async () => socialAccountList(), {
    //enabled: jootAccountName,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useInstagramLinkAccount = () => {
  const [
    mutate,
    { status, data, error, reset },
  ] = useMutation(async ({ ig_username }) =>
    linkInstagramAccounts(ig_username),
  );

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
};

export const useAddCompetitorAccounts = () => {
  const [
    mutate,
    { status, data, error, reset },
  ] = useMutation(async ({ accountName, igUsername, competitorsAccounts }) =>
    addCompetitorAccounts(accountName, igUsername, competitorsAccounts),
  );

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
};

export const useCreateMarket = () => {
  const { jootAccount } = useAppContext()
  const [
    mutate,
    { status, data, error, reset },
  ] = useMutation(async ({ market_name, competitors }) =>
    createMarket(jootAccount.account_name, market_name, competitors),
    { throwOnError: true }
  );

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
}

const marketReadyFuncs = {
  marketInsights: market => market && market.status && ['content_ready_for_image_processing', 'social_concepts_complete', 'ready_to_build_model', 'building_model', 'model_ready_scoring_content', 'image_scoring_complete'].includes(market.status),
  imageInsights: market => market && market.status && ['social_concepts_complete'].includes(market.status)
};

//const isMarketReady = market => market && market.status && !['new', 'ready_to_build_model', 'building_model','suspended_do_to_billing','downloading_posts'].includes(market.status);

const doRequest = async (accountName, currentMarket) => {
  if (!accountName) {
    return null;
  }
  const markets = await getMarket(accountName);
  return markets.find(item => item.market_name === currentMarket?.market_name);
}

export const useRetryMarket = (marketReadyFor) => {
  const { accountName, currentMarket, isSystemMarket, setCurrentMarket } = useAppContext();
  useEffect(() => {
    console.log("marketReadyFor?: " + currentMarket?.market_name);
    if (isSystemMarket || (currentMarket &&  marketReadyFuncs[marketReadyFor](currentMarket))) {
      return;
    }
    const id = setInterval(async () => {
      const market = await doRequest(accountName, currentMarket);
      if (market && marketReadyFuncs[marketReadyFor](market)) {
        clearInterval(id);
        setCurrentMarket(market);
      }
    }, 30000); // 30 seconds
    return () => clearInterval(id);
  }, [accountName, currentMarket, isSystemMarket, setCurrentMarket, marketReadyFor]);
  const isReady = useMemo(() => marketReadyFuncs[marketReadyFor](currentMarket) || currentMarket?.exists_insights, [currentMarket, marketReadyFor]);

  return {
    market: currentMarket,
    isReady,
  };
}

export const useGetCreateMarket = (jootAccount, options = {}) => {
  const MARKET_UNDEFINED = useMemo(() => jootAccount && (!jootAccount.market_status || jootAccount.market_status === 'MARKET_UNDEFINED'), [jootAccount]);
  const MARKET_UNPAID = useMemo(() => jootAccount && jootAccount.market_status && jootAccount.market_status === 'MARKET_UNPAID', [jootAccount]);
  const MARKET_ACTIVE = useMemo(() => jootAccount && jootAccount.market_status && jootAccount.market_status === 'MARKET_ACTIVE', [jootAccount]);
  const [currentMarket, setCurrentMarket] = useState(null);
  const [marketplace, setMarketplace] = useState({});
  const { isAuthenticated } = useAuthContext();
  const query = ['markets', jootAccount?.account_name];
  const { data, ...state } = useQuery(
    query,
    async () => {
      console.log("calling getMarket(" + jootAccount?.account_name + ")");
      return getMarket(jootAccount?.account_name);
    },
    {
      enabled: isAuthenticated && jootAccount?.account_name,
      ...DEFAULT_QUERY_OPTIONS,
      ...options,
    },
  );
  const invalidateQuery = useCallback(() => {
    return queryCache.invalidateQueries(query);
  }, [query]);
  const changeCache = useCallback((data) => queryCache.setQueryData(query, data), [query]);
  const { joot } = useTheme();
  useEffect(() => {
    setCurrentMarket(() => {
      if (currentMarket) {
        console.log("currentMarket already set: " + currentMarket.market_name);
        return currentMarket;
      }
      if (!currentMarket && data && data.length > 0 && jootAccount.selected_market_name) {
        const foundMarket = data.find((item) => item.market_name == jootAccount.selected_market_name && item.market_system_type == jootAccount.selected_market_type)
        console.log("setting the current market to " + JSON.stringify(foundMarket.market_name));
        return foundMarket;
      }
      return null;
    });
    setMarketplace(() => {
      const mp = {}
      if (data && data.length > 0) {
        for (const market of data) {

          if (market.stats) {
            market.stats.chartData = [
              { key: 'Video', color: joot.mustard, value: market.stats.perc_videos * 100 },
              { key: 'Carousel', color: joot.cinnabar, value: market.stats.perc_carousel * 100 },
              { key: 'Image', color: joot.carolinaBlue, value: market.stats.perc_images * 100 },
            ];
          }

          if (market.market_system_type && market.market_system_type == "SYSTEM") {
            mp[market.market_category] = mp[market.market_category] ? [market,...mp[market.market_category]] : [market];
            if (market.is_available && !market.is_locked) {
              mp['Available Markets'] = mp['Available Markets'] ? [...mp['Available Markets'],market] : [market];
            }
          } else {
            mp['My Custom Markets'] = mp['My Custom Markets'] ? [...mp['My Custom Markets'],market] : [market];
            mp['Available Markets'] = mp['Available Markets'] ? [...mp['Available Markets'],market] : [market];
          }
        }
      }
      if (!mp['My Custom Markets']) mp['My Custom Markets'] = [];
      return mp;
    });
  }, [data]);
  useEffect(() => {
    if (currentMarket) {
      console.log("Saving selected market: " + currentMarket.market_name);
      saveSelectedMarket(jootAccount.account_name, currentMarket.market_name, currentMarket.market_system_type);
    }
  }, [currentMarket]);

  return {
    ...state,
    data,
    MARKET_UNDEFINED,
    MARKET_UNPAID,
    MARKET_ACTIVE,
    currentMarket,
    marketplace,
    setCurrentMarket,
    invalidateQuery,
    changeCache,
  }
};
