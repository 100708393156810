import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

// Hooks
import { useForm } from 'react-hook-form';
import { useDownloadInstagramPosts} from '../../../hooks/apiHooks/mediaApiHooks';
//import { useCreateLibrary } from '../../../hooks/apiHooks/mediaApiHooks';

// Context
import { useAppContext } from '../../../contexts/AppContext';

// Components
import Modal from '../../../components/Modal';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import SortByMediaMenu from '../../../components/menus/SortByMediaMenu';
import { ErrorMessage, FormControl } from '../../../components/form';
import Competitor from '../../../components/marketForm/Competitor';

// Styles
const ModalHeader = styled.div`
  display: block;
  padding: 40px;
`;
const ModalBody = styled.div`
  display: block;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 0px;
`;
const ModalFooter = styled.div`
  display: flex;
  padding: 40px;
  justify-content: flex-end;
  overflow: hidden;
`;
const Text = styled.p`
  display: block;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.26px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.6)};
`;
const Warning = styled.span`
  color: ${(props) => rgba(props.theme.joot.cinnabar, 0.6)};
`;
const PurchaseButton = styled(Button)`
  min-width: 170px;
  justify-content: center;
`;

export const importOptions = [
  { value: '10', name: '10 Posts' },
  { value: '25', name: '25 Posts' },
  { value: '50', name: '50 Posts' },
  { value: '100', name: '100 Posts' },
];

const createOption = (item) => (
  item ? 
  {
    item,
    value: item.library_name,
    name: item.library_name
  } : {}
)

const createMarketOption = (item) => (
  item ? 
  {
    item,
    value: item.library_name,
    name: item.library_name
  } : {}
)

const schema = yup.object().shape({
  usernames: yup.array().test(
    'test-name',
    () => {
      return 'Invalid username or Instagram account is a non-Professional/Creator account.';
    },
    function (value) {
      return value[0] != null;
    },
  ),
});

const ModalImportInstagramImages= ({ onCancel, isVisible, onHide, ...props }) => {
  const { control, watch, formState, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const { isValid } = formState;
  const { submit: downloadPosts, isLoading: isDownloading } = useDownloadInstagramPosts();
  const { 
//    currentLibrary,
//    libraries: { invalidateQuery: invalidateLibraries, data: libraryObjects },
    marketName,
  } = useAppContext();
  //const { submit: createLibrary, isLoading } = useCreateLibrary();
  const [usernameError, setUsernameError] = useState('');
  const [optionSelected, setOptionSelected] = useState({ value: '10', name: '10 Posts' });
//  const [librarySelected, setLibrarySelected] = useState();
  const [downloading, setDownloading] = useState(false);
  const [usernames, setUsernames] = useState([null]);
//  const libraries = useMemo(() => { 
//    return libraryObjects ? libraryObjects.map(item => createOption(item)) : [];
//  }, [libraryObjects]);
  useEffect(() => {
    if (!isVisible) {
      setUsernameError('');
    }
  }, [isVisible, watch]);
  const handleGetPosts = useCallback(async () => {
    setDownloading(true);
    //const username = watch('usernames')[0].username;
    //const libraryName = "@" + username;
    console.log("marketName: " + marketName);
    try {
      const username = watch('usernames')[0].username;
      //await createLibrary({ library_name: libraryName});
      await downloadPosts({ 
        username: username,
        market_name: marketName,
        count: optionSelected.value
      });
      //await invalidateLibraries();
    } catch (e) {
      console.error(e);
      setDownloading(false);
      return setUsernameError('Unable to import images');
    }
//    invalidateQuery();
    setDownloading(false);
    onHide(/*marketName*/);
  }, [marketName, downloadPosts, setDownloading, /*invalidateQuery*/, optionSelected, onHide]);
  const handleOptionSelected = useCallback((value) => {
    setOptionSelected(value);
  }, []);
  /*
  const handleLibrarySelected = useCallback((value) => {
    setLibrarySelected(value);
  }, []);
  */


  return (
    <Modal onCancel={onCancel} onHide={onHide} isVisible={isVisible} {...props}>
      <ModalHeader>
        <Title h5>Instagram Images</Title>
        <Text>Import recent images from any Professional or Creator Instragram account into a new Image Collection. <Warning>Videos will not be imported</Warning>.</Text>
      </ModalHeader>
      <ModalBody>
        <FormControl>
          <FormControl.Label>Import most recent:</FormControl.Label>
          <SortByMediaMenu
            onChange={handleOptionSelected}
            items={importOptions}
            initialSelection={optionSelected}
            name="importCount"
          />
        </FormControl>
        <FormControl>
          <FormControl.Label>From Instagram Account:</FormControl.Label>
          {
          <Controller
            as={Competitor}
            name="usernames"
            control={control}
            defaultValue={usernames}
            readonly={false}
          />
          }
        </FormControl>
        {/*
        <FormControl>
          <FormControl.Label>With Market:</FormControl.Label>
          <SortByMediaMenu
            onChange={handleOptionSelected}
            items={markets}
            initialSelection={createOption(currentMarket)}
            name="selectedMarket"
            selectedText="Select a Market.."
          />
        </FormControl>
        */}
        {/*}
        <FormControl>
          <FormControl.Label>Import into:</FormControl.Label>
          <SortByMediaMenu
            onChange={handleLibrarySelected}
            items={libraries}
            initialSelection={createOption(currentLibrary)}
            name="library"
          />
        </FormControl>
        */}
      </ModalBody>
      <ModalFooter>
        <Button secondary onClick={onCancel}>
          Cancel
        </Button>
        <PurchaseButton
          primary
          disabled={!isValid}
          loading={downloading}
          onClick={handleGetPosts}
          type="submit"
        >
          {!downloading && (
            <>{isValid ? `Import images` : 'Please enter an Instagram User'}</>
          )}
        </PurchaseButton>
      </ModalFooter>
    </Modal>
  );
};

export default ModalImportInstagramImages;
