import React, { useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { motion } from 'framer-motion';

// Assets
import { ReactComponent as Tag } from '../../assets/svg/tag.svg';
import { ReactComponent as Trash } from '../../assets/svg/trash.svg';
import { ReactComponent as View } from '../../assets/svg/expand.svg';

// Components
import SelectedIcon from '../icons/SelectedIcon';
import StarIcon from '../icons/StarIcon';

// Styles
const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  will-change: opacity;
  cursor: pointer;
  &.selected {
    opacity: 1 !important;
    background: transparent;
    box-shadow: 0 0 0 3px ${({ theme }) => theme.joot.carolinaBlue};
    svg {
      transform: scale(1);
    }
  }
  &.starred {
    opacity: 1 !important;
  }
  svg {
    transform: scale(0.9);
    transform-origin: center;
    transition: 0.2s transform ${({ theme }) => theme.transition.cubicEase};
  }
`;
const SelectLink = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 7px 40px 40px 7px;
  z-index: 1;
  cursor: pointer;
  &:hover {
    svg {
      transform: scale(1);
    }
  }
`;
const Actions = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: ${({ theme }) => rgba(theme.joot.jootBlack, 0.4)};
`;
const ActionLink = styled.div`
  cursor: pointer;
  padding: 10px 9px;
  display: flex;
  align-self: flex-end;
  &:hover {
    svg {
      transform: scale(1);
    }
  }
`;
const SelectedStar = styled.div`
  position: absolute;
  right: 9px;
  bottom: 8px;
`;
const NumericOrder = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: ${({ theme }) => rgba(theme.joot.jootBlack, 0.6)};
  color: ${({ theme }) => theme.joot.jootWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
`;

const overlayMotion = {
  rest: {
    opacity: 0,
    transition: {
      type: 'spring',
      delay: 0,
      stiffness: 500,
      damping: 60,
      mass: 1,
    },
  },
  hover: {
    opacity: 1,
    transition: {
      type: 'spring',
      delay: 0,
      stiffness: 500,
      damping: 60,
      mass: 1,
    },
  },
};

const MediaOverlay = ({
  onSelectClick,
  onClick,
  isstared,
  isSelected,
  onDelete,
  onTag,
  onView,
  hideOptions,
  order,
  starSingleImage,
}) => {
  const { joot } = useTheme();
  const handleClick = useCallback((evt, action) => {
    evt.stopPropagation();
    action && action();
  }, []);

  return (
    <>
      <>
        {isstared && (
          <SelectedStar>
            <StarIcon active={isstared} />
          </SelectedStar>
        )}
      </>
      <Overlay
        onClick={(evt) => handleClick(evt, onClick)}
        variants={overlayMotion}
        isSelected={isSelected}
        className={isSelected ? 'selected' : null}
      >
        <SelectLink onClick={(evt) => handleClick(evt, onSelectClick)}>
          {!order && (
            <SelectedIcon fill={isSelected ? joot.carolinaBlue: joot.jootBlack} fillOpacity={isSelected ? 1 : 0.4} />
          )}
          {order && isSelected && <NumericOrder>{order}</NumericOrder>}
        </SelectLink>
        {!isSelected && !hideOptions && (
          <Actions>
            <ActionLink onClick={(evt) => handleClick(evt, onView)}>
              <View />
            </ActionLink>
            <ActionLink onClick={(evt) => handleClick(evt, onDelete)}>
              <Trash />
            </ActionLink>
            <ActionLink onClick={(evt) => handleClick(evt, onTag)}>
              <Tag />
            </ActionLink>
            <ActionLink onClick={(evt) => handleClick(evt, starSingleImage)}>
              <StarIcon active={isstared} />
            </ActionLink>
          </Actions>
        )}
      </Overlay>
    </>
  );
};

export default MediaOverlay;
