import React, { memo } from 'react';
import styled from 'styled-components';

// Componetns
import PostAi from './PostAi';
import PostDetail from './PostDetail';

// Styles
const DetailBody = styled.div`
  display: grid;
  //grid-template-columns: 140px 1fr;
  grid-template-columns: 140px 950px;
  grid-column-gap: 10px;
  align-content: flex-end;
  margin-bottom: 20px;
`;
const Divider = styled.span`
  width: 1px;
  height: 100%;
  margin: 0 5px;
  background: ${({ theme }) => theme.joot.paleGray};
`;

const PostSelected = ({ post, setImageSelected, imageSelected }) => {
  return (
    <DetailBody>
      <PostAi images={post.images} onSelected={setImageSelected} imageSelected={imageSelected} />
      <PostDetail image={imageSelected} />
    </DetailBody>
  );
};

export default memo(PostSelected);
