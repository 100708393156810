import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import EmptyMessage from './EmptyMessage';
import Image from 'react-simple-image';
import Button from '../../../components/Button';

import screenshot from '../../../assets/img/MarketInsightsScreenshot.png';
import MarketUpgrade from './MarketUpgrade';

const EmptyWrapper = styled(EmptyMessage)`
  margin-top: 0px;
`;
const ScreenshotImage = styled(Image)`
  border-radius: 4px;
  grid-area: image;
  width: 500px;
  margin-top: 40px;
  border: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.1)};
`;
const Text = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  margin-top: 40px;
  margin-bottom: 20px;
  letter-spacing: -0.26px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;
const Emph = styled.span`
  font-weight: 700;
  color: ${(props) => rgba(props.theme.joot.emeraldGreen, 0.9)};
`;
const Deemph = styled.span`
  font-weight: 700;
  color: ${(props) => rgba(props.theme.joot.cinnabar, 0.9)};
`;
const Bold = styled.span`
  font-weight: 700;
`;
const StepBody = styled.div`
  padding: 20px 10px 20px 10px;
`;
const FlexRow = styled.div`
  margin: 15px auto 0px auto;
  letter-spacing: 0.26px;
  line-height: 20px;
  align-items: center;
  width: 760px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.7)};
`;
const OkButton = styled(Button)`
  margin: 40px 0 0 20px;
  min-width: 170px;
  justify-content: center;
`;
const Panels = styled.div`
  display: flex;
  flex-flow: row;
  padding: 0px;
`;
const LeftPanel = styled.div`
  display: flex;
  flex-flow: column;
  width: 500px;
`;
const RightPanel = styled.div`
  display: flex;
  flex-flow: column;
  width: 250px;
  margin-left: 20px;
  margin-top: 40px;
`;
const OverviewList = styled.ul`
  margin-top: 00px;
  margin-left: 10px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;

const ListItem = styled.li`
  &: {
    font-size: 12px;
    list-style: none;
    color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
  }
  &:before {
    content: '✓';
    font-size: 12px;
    font-weight: 600;
    color: ${(props) => rgba(props.theme.joot.emeraldGreen, 0.9)};
  }
  & span {
    position: relative;
    left: 10px;
    line-height: 25px;
  }
`


const EmptyImageInsights = ({ children }) => {

  const [showUpgrade, setShowUpgrade] = useState(false);
  const handleOnUpgrade = useCallback(({ target: { value: { item } } }) => {
    setShowUpgrade(true);
  }, [setShowUpgrade]);


  return (
    <EmptyWrapper
      title={`Know Thine Enemy.`}
      subtitle={`How does your engagement stack up against your competitors on Instagram?`}
      message={'Market Insights will be availble shortly for this Joot Market.  Check back soon.'}
    >
      {/*}
      <FlexRow>
        Define you own market and learn ways to improve your post engagement through a detailed comparison of effective techniques used by competing Instagram accounts. Knowing is <Bold>more than</Bold> half the battle.
      </FlexRow>
      */}
      {/*
      <ScreenshotImage
        src={screenshot} 
        srcSet={{
          '1x': screenshot,
          '2x': screenshot,
        }}
      />
      {children}
      <OkButton primary onClick={handleOnUpgrade}>
        Upgrade to Professional
      </OkButton>
      */}
      <Panels>
        <LeftPanel>
          <ScreenshotImage
            src={screenshot} 
            srcSet={{
              '1x': screenshot,
              '2x': screenshot,
            }}
          />
        </LeftPanel>
        <RightPanel>
          <StepBody>
            <OverviewList>
              <ListItem><span>Learn what's working and not workring on Instagram</span></ListItem>
              <ListItem><span>Understand competitors content and caption strategies</span></ListItem>
              <ListItem><span>Quickly view audience preferences for a given market</span></ListItem>
            </OverviewList>
          </StepBody>
          {/*}
          <OkButton primary onClick={handleOnUpgrade}>
            Upgrade to Professional
          </OkButton>
          */}
        </RightPanel>
      </Panels>
      {/*showUpgrade && <MarketUpgrade from={'scored media'} isVisible={true} onHide />*/}
    </EmptyWrapper>
  );
};

export default memo(EmptyImageInsights);
