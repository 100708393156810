import React from 'react';
import styled from 'styled-components';
import InputAppearance from './mixins/InputAppearance';

// Components
import Tooltip from './Tooltip';
import AlertIcon from '../icons/AlertIcon';
import TooltipIcon from '../icons/TooltipIcon';
import PropTypes from 'prop-types';
import SpinnerIcon from '../icons/SpinnerIcon';
import { FormControl } from './index';

// Styles
const InputWrapper = styled.div`
  position: relative;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.position === 'right' ? `right: 20px;` : `left: 10px;`)}
`;

const StyledInput = styled.input`
  ${InputAppearance};
  position: relative;
  ${(props) => props.hasIcon && `padding-left: 35px;`}
`;

const Input = React.forwardRef(({ hasError, IconComponent, tooltip, isLoading, ...rest }, ref) => {
  return (
    <>
      <InputWrapper hasError={!!hasError}>
        <StyledInput hasError={!!hasError} hasIcon={!!IconComponent} ref={ref} {...rest} />
        {!!tooltip && (
          <Icon position="right">
            <Tooltip.Trigger>
              <TooltipIcon />
              <Tooltip.Message>{tooltip}</Tooltip.Message>
            </Tooltip.Trigger>
          </Icon>
        )}
        {!!IconComponent && (
          <Icon>
            <IconComponent />
          </Icon>
        )}
        {!!hasError && !isLoading && (
          <Icon position="right">
            <AlertIcon />
          </Icon>
        )}
        {isLoading && (
          <Icon position="right">
            <SpinnerIcon />
          </Icon>
        )}
      </InputWrapper>
      {hasError && <FormControl.Error>{hasError.message}</FormControl.Error>}
    </>
  );
});

Input.propTypes = {
  IconComponent: PropTypes.any,
  hasError: PropTypes.any,
  tooltip: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default Input;
