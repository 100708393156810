import { useMutation } from 'react-query';
import { Auth } from 'aws-amplify';

export const useForgotPassword = (email) => {
  const [mutate, { status, data, error, reset }] = useMutation(async () => Auth.forgotPassword(email.toLowerCase()), {
    throwOnError: true,
  });

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
};

export const useForgotPasswordConfirm = (params) => {
  const [mutate, { status, data, error, reset }] = useMutation(
    async () => Auth.forgotPasswordSubmit(params.username, params.code, params.password),
    {
      throwOnError: true,
    },
  );

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
};

export const useCompleteNewPassword = (params) => {
  const [mutate, { status, data, error, reset }] = useMutation(
    async () => Auth.completeNewPassword(params.user, params.newPassword, {}), {
      throwOnError: true,
    },
  );

  console.log("useCompleteNewPassword params:" + JSON.stringify(params));

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
};

export const useSignup = (params) => {
  const [mutate, { status, data, error, reset }] = useMutation(async () => {
    params.username = params.username ? params.username.toLowerCase() : params.username;
    Auth.signUp(params);
  }, {
    throwOnError: true,
  });

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
};

export const useSignupConfirm = (username, code) => {
  const [mutate, { status, data, error, reset }] = useMutation(async () => Auth.confirmSignUp(username ? username.toLowerCase() : username, code), {
    throwOnError: true,
  });

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
};
