import styled, { css } from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import hideVisually from 'polished/lib/mixins/hideVisually';

const HiddenSelectableAppearance = css`
  ${hideVisually()};
`;

const SelectableAppearance = css`
  width: 16px;
  height: 16px;
  border: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.4)};
  background-color: ${(props) => props.theme.joot.jootWhite};
  transition: background-color 0.1s ease-out;
  box-shadow: 0 0.125rem 0.25rem ${rgba('black', 0.04)};
  cursor: pointer;
  ${(props) =>
    props.checked &&
    `
    background-color: ${props.theme.color.white};
    background-image: url('data:image/svg+xml,%3Csvg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M6.44568 0.530225C6.70516 0.739567 6.7458 1.11962 6.53646 1.37909L3.30929 5.37909C3.20551 5.50772 3.05354 5.58818 2.88882 5.60169C2.7241 5.6152 2.56105 5.56059 2.4377 5.45059L0.598139 3.81017C0.349311 3.58828 0.327474 3.20669 0.549365 2.95786C0.771256 2.70903 1.15285 2.68719 1.40168 2.90908L2.76788 4.12739L5.59682 0.620998C5.80616 0.361523 6.18621 0.320883 6.44568 0.530225Z" fill="%23494C9E"/%3E%3C/svg%3E%0A');
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid ${(props) => props.theme.joot.emeraldGreen};

    
  `}
`;

const RadioAppearance = css`
  width: 23px;
  height: 23px;
  background-color: ${(props) => props.theme.joot.jootWhite};
  box-shadow: inset 0 0 0 1px ${(props) => rgba(props.theme.joot.jootBlack, 0.4)};
  transition: all 0.1s ease-out;
  cursor: pointer;
  ${(props) =>
    props.checked &&
    `
    box-shadow: inset 0 0 0 7px ${props.theme.joot.emeraldGreen};
  `}
`;

const ControlContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.font.sansSerif};
`;

const Label = styled.label`
  display: block;
  margin: 0;
  font-size: 11px;
  color: ${(props) => props.theme.joot.jootBlack};
`;

export { ControlContainer, SelectableAppearance, RadioAppearance, HiddenSelectableAppearance, Label };
