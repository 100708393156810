import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// Context
import { useSignupContext } from './context/SignupContext';

// Components
import Navigation from '../../components/Navigation';
import Box from '../../components/box/Box';
import ChooseIgAccount from '../../components/ChooseIgAccount';
import { useAppContext } from '../../contexts/AppContext';

// Styles
const StepBox = styled(Box)`
  width: 700px;
  border-radius: 5px;
  margin: 0 auto;
  padding: 40px 25px;
`;

const Footer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row-reverse;
  flex-content: flex-start;
  align-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
`;

const SignupStepPickIGAccount = () => {
  const { push } = useHistory();
  const { 
    selectIgAccount,
    setOnNext,
    changeState,
    handlerNext,
    loadingNext,
    disabledNext,
    errors,
   } = useSignupContext(2);
  const {
    facebook: { data: facebookData, invalidateQuery: invalidateFacebook },
    invalidateIgAccounts,
    markets,
  } = useAppContext();
  useEffect(() => {
    if (markets.MARKET_ACTIVE) {
      push('/signup/three');
    }
  }, [markets, push]);
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    setOnNext(async () => {
      if (!selected) {
        return false;
      }
      try {
        await selectIgAccount(selected);
        await invalidateFacebook();
        await invalidateIgAccounts();
        return true;
      } catch (e) {
        return false;
      }
    });
    return () => setOnNext(null);
  }, [setOnNext, selectIgAccount, selected, invalidateFacebook, invalidateIgAccounts]);
  useEffect(() => {
    if (facebookData.ig_accounts?.length === 1) {
      push('/signup/three');
    }
  }, [facebookData, push]);
  const handleChange = useCallback(({ target: { value } }) => {
    setSelected(value);
  }, []);
  useEffect(() => {
    changeState('disabledNext', !selected);
  }, [selected, changeState]);

  return (
    <>
      <StepBox>
        <ChooseIgAccount
          onChange={handleChange}
          value={selected}
          facebookData={facebookData}
          helpText="Select which Instagram Account you would like to use in this Joot Account.\n You can always add more Instagram Accounts later in Settings."
        />
        <Footer>
          <Navigation loading={loadingNext} onNext={handlerNext} disabledNext={disabledNext} errors={errors}/>
        </Footer>
      </StepBox>
    </>
  );
};

export default SignupStepPickIGAccount;
