import React, { useEffect, useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import config from '../../../utils/config';
import rgba from 'polished/lib/color/rgba';
import { Button as UIButton, Icon, Label, Popup } from 'semantic-ui-react'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useDropzone } from 'react-dropzone';

// Hooks
import { useGetBillingDetail } from '../../../hooks/apiHooks/billingApiHook';
import { /*useSetLibraryMarket, */useSetShowSampleImages } from '../../../hooks/apiHooks/mediaApiHooks';
import { useMediaContext } from '../contexts/MediaContext';
import useModal from '../../../hooks/useModal';

// Components
import MarketMenu from '../../../components/menus/MarketMenu';
import MarketplaceMenu from '../../../components/menus/marketplace/MarketplaceMenu';
import MediaList from '../MediaList';
import Button from '../../../components/Button';
import Title from '../../../components/Title';
import UploadOptions from '../components/UploadOptions';
import UploadOptionsInitial from '../components/UploadOptionsInitial';
import ImageActions from '../components/ImageActions';
import ImageRankActions from '../components/ImageRankActions';
import ModalAddUploads from '../components/ModalAddUploads';
import ModalImportInstagramImages from '../components/ModalImportInstagramImages';
//import LibraryForm from '../components/LibraryForm';
//import SetupImportForm from '../components/SetupImportForm';
import EmptyScoredMedia from '../components/EmptyScoredMedia';
import Loader from '../../../components/Loader';
import AuthModal from '../../auth/AuthModal';
import SelectedSetting from '../../../features/media/components/SelectedSetting';
import { useAppContext } from '../../../contexts/AppContext';
import { useAuthContext } from '../../../contexts/AuthContext';
import MarketUpgrade from '../../../features/media/components/MarketUpgrade';

// Styles
const Sticky = styled.div`
  position: sticky;
  z-index: 10;
  top: 0;
  background: ${({ theme }) => theme.joot.white};
`;
const ToolTitle = styled(Title)`
  margin-right: 10px;
`;
const StyledScoredMedia = styled.section`
  display: block;
`;
const MediaHeaderWrapper = styled.div`
  padding: 0 0 0 0;
`;
const MediaTitle = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 12px 0;
`;
const MediaHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  align-self: flex-end;
  white-space: nowrap;
`;
const ScoreHeader = styled(MediaHeader)`
  height: 40px;
`;
const UIButtonWrapper = styled.div`
  margin-top: 2px;
`;
const MediaUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-star;
  font-size: 14px;
`;
const MediaContent = styled.div`
  padding: 20px;
`;
const UploadsAvailable = styled.span`
  font-size: 22px;
  font-weight: 600;
  margin: 0 6px;
`;
const UploadButton = styled(Button)`
  font-size: 12px;
  color: ${({ theme }) => theme.joot.potteryBlue};
  margin-left: 10px;
`;
const UploadButtonInitial = styled(Button)`
  font-size: 12px;
  background: ${({ theme }) => theme.joot.emeraldGreen};
  &:hover {
    cursor: auto;
    opacity: 1;
  }
`;
const MenuContainer= styled.div`
  display: flex;
  min-width: 250px;
  flex-flow: column;
  align-items: baseline;
`;
const Divider = styled.span`
  width: 1px;
  height: 20px;
  margin: 0 20px;
  background: ${({ theme }) => theme.joot.blueGray};
`;
const HDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0px;
  background: ${({ theme }) => theme.joot.paleGray};
`;
const Filler = styled.span`
  width: 100%;
`;
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
`;
const UploadOptionsWrapper = styled.div`
  display: flex;
  flex-flow; row;
`;
const StyledSelectedSetting = styled(SelectedSetting)`
  margin-left: 20px;
  padding: 10px 8px;
  background-color: ${({ theme, isOptionProps }) =>
    isOptionProps?.selected ? rgba(theme.joot.emeraldGreen, 0.05) : theme.joot.jootWhite};
  border-color: ${({ theme, isOptionProps }) =>
    isOptionProps?.selected ? theme.joot.emeraldGreen : rgba(theme.joot.jootBlack, 0.1)};
  box-shadow: inset 0 0 0 1px
    ${({ theme, isOptionProps }) => (isOptionProps?.selected ? theme.joot.emeraldGreen : 'transparent')};
  > button {
    color: ${({ theme, isOptionProps }) => (isOptionProps?.selected ? theme.joot.emeraldGreen : theme.joot.potteryBlue)};
  }
`;

const EXTENSIONS = ['.png', '.jpg', '.jpeg'];

const createOption = (item) => ({
  item,
  value: item.market_name,
  name: item.market_name,
})

/*
const createLibraryOption = (item) => ({
  item,
  value: item.library_name,
  name: item.library_name,
})
*/

export default function ScoredMedia() {
  const stripePromise = loadStripe(config.stripe.key);
  const {
    selectionChangeHandler,
    selectedMedias,
    onDeleteOne,
    onDeleteMany,
    onRankMany,
    onAddTags,
    handleUpload,
    imagesList,
    sampleImages,
    showSampleImages,
    activeImports,
    continuePolling,
    isSelected,
    selectDeselectAll,
    handleUploadByUrl,
    starSingleImage,
    starImageSelected,
    isLoadedImages,
  } = useMediaContext();
  const { isAuthenticated } = useAuthContext();
  const {
    markets: { data: marketsObjects },
    markets: { marketplace },
//    libraries: { data: libraryObjects },
//    libraries: { invalidateQuery: invalidateLibraries },
    currentMarket,
    setCurrentMarket,
    //currentLibrary,
    //setCurrentLibrary,
    uploads,
    setUploads,
    jootAccount,
  } = useAppContext();
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleUpload,
    noClick: true,
    noKeyboard: true,
    accept: EXTENSIONS,
  });
//  const { submit: setLibraryMarket, isSuccess: isSetLibrarySuccess } = useSetLibraryMarket();
  const { submit: setShowSampleImages, isSuccess: isSetShowSampleImagesSuccess } = useSetShowSampleImages();
  const { data: { image_uploads_remaining } = {} } = useGetBillingDetail();
  const { isVisible, toggle } = useModal();
  const { isVisible: isVisibleImportIG, toggle: toggleImportIG} = useModal();
//  const { isVisible: isVisibleLibraryForm, toggle: toggleLibraryForm} = useModal();
  //const { isVisible: isVisibleImportForm, toggle: toggleSetupImport} = useModal();
  //const [predictOnUploadSelected, setPredictOnUpload] = useState();
  //const [selectLibraryName, setSelectLibraryName] = useState();
  //const [libraryToEdit, setLibraryToEdit] = useState();
  const [showUpgrade, setShowUpgrade] = useState(false);
  //const [hideImportCallback, setHideImportCallback] = useState();
  const markets = useMemo(() => marketsObjects ? marketsObjects.map(item => createOption(item)) : [], [marketsObjects]);
  //const libraries = useMemo(() => libraryObjects ? libraryObjects.map(item => createLibraryOption(item)) : [], [libraryObjects]);
  const initialMarketSelection = useMemo( () => currentMarket ? { name: currentMarket.market_name, value: currentMarket.market_name } : null, [currentMarket]);
  //const initialLibrarySelection = useMemo( () => currentLibrary ? { name: currentLibrary.library_name, value: currentLibrary.library_name } : null, [currentLibrary]);

  /*
  useEffect(() => {
    if (libraryObjects && currentLibrary &&  selectLibraryName && selectLibraryName != currentLibrary.library_name) {
      const newLibrary = libraryObjects.find((item) => item.library_name === selectLibraryName);
      newLibrary && setCurrentLibrary(newLibrary);
      !newLibrary && console.log("library not found");
    }
    console.log("uploads: " + uploads);
  }, [selectLibraryName]);
  */

  useEffect(() => {
    console.log("currentMarket: " + (currentMarket && currentMarket.market_name) || undefined);
    if (!currentMarket && marketsObjects ) {
      //const defaultMarket = marketsObjects.find((item) => item.market_name === "Mens Clothing");
      //setCurrentMarket(defaultMarket);
    }
  }, [currentMarket, marketsObjects]);

  /*
  useEffect(() => {
    if (currentLibrary && marketsObjects && currentLibrary.default_market_name ) {
      const defaultMarket = marketsObjects.find((item) => item.market_name === currentLibrary.default_market_name);
      console.log("currentLibrary: " + currentLibrary.library_name + " defaultMarket: " + defaultMarket.market_name);
      setCurrentMarket(defaultMarket);
    }
  }, [currentLibrary, marketsObjects]);
  */

//  useEffect(() => {isSetLibrarySuccess && invalidateLibraries()}, [isSetLibrarySuccess]);
  useEffect(() => {setUploads(jootAccount?.image_uploads_remaining)}, [jootAccount]);

  const handleMarketChange = useCallback(({ target: { value: { item } } }) => {
    console.log(item);
    setCurrentMarket(item);
    //currentLibrary.defaut_market_name = item.market_name;
    //setLibraryMarket({ library_name: currentLibrary.library_name, market_name: item.market_name });
  }, [setCurrentMarket /*, currentLibrary, setLibraryMarket*/]);

  const handleShowSampleImages = useCallback((show) => {
    setShowSampleImages({ library_name: currentMarket.market_name, show: show });
  }, [setShowSampleImages, currentMarket]);

  /*
  const handleLibraryChange = useCallback(({ target: { value: { item } } }) => {
    setCurrentLibrary(item);
    const marketOption = item.default_market_name && markets.find((item) => item.name === item.default_market_name);
    marketOption && currentMarket.market_name !== marketOption.name && setCurrentMarket(marketOption.Item);
  }, [setCurrentLibrary, currentMarket, setCurrentMarket]);
  */

  /*
  const handleEditLibrary = useCallback(({ target: { value: { item } } }) => {
    setLibraryToEdit(item);
    toggleLibraryForm();
  }, [setCurrentLibrary]);
  */

  /*
  const onHideLibraryForm = useCallback((libraryName, market) => {
    libraryName && setSelectLibraryName(libraryName);
    market && setCurrentMarket(market);
    setLibraryToEdit(undefined);
    toggleLibraryForm();
  }, [setSelectLibraryName, toggleLibraryForm, setCurrentMarket]);
  */

  const onHideIGImport= useCallback((/*libraryName, market*/) => {
    //setSelectLibraryName(libraryName);
    //market && setCurrentMarket(market);
    toggleImportIG();
  }, [/*setSelectLibraryName,*/ toggleImportIG, /*setCurrentMarket*/]);

  const onHideUpgrade= useCallback((libraryName, market) => {
    setShowUpgrade(false);
  }, [setShowUpgrade]);

  const handleCreateMarket = useCallback(() => {
    setShowUpgrade(true);
  }, [setShowUpgrade]);
  /*
  const onHideImportForm = useCallback(() => {
    console.log("onHideImportForm");
    hideImportCallback();
    toggleLibraryForm();
  }, [setNewLibraryName, toggleLibraryForm, setCurrentMarket]);
  const showSetupImport = useCallback((onSetupComplete) => {
    setHideImportCallback(onSetupComplete);
    toggleSetupImport();
  }, [setNewLibraryName, toggleLibraryForm, setCurrentMarket]);
  */
  /*
  const togglePredictOnUpload = useCallback((value) => {
    setPredictOnUpload(!predictOnUploadSelected);
  }, [predictOnUploadSelected]);
  */

  //  {isAuthenticated && <AuthModal from={'scored media'} isVisible={true} onHide />}

  return (
    <StyledScoredMedia>
    {!isAuthenticated && (<AuthModal from={'scored media'} isVisible={true} onHide />)}
      <Sticky>
        <MediaHeaderWrapper>
          <ScoreHeader>
            <MediaTitle>
              <ToolTitle h3 noMargin>
              Image Rank 
              </ToolTitle>
              <Popup
                trigger={<Icon name='help' size='small' color="grey" circular />}
                content='Use this the Image Rank tool to predict the success of image engagement within the selected market. Upload images from your local drive, Dropbox, Google Drive or Instagram and Joot will analyze, score and rank the images you choose. We recommend using the highest ranked images for social media posts, advertising and landing pages.'
                position='right'
              />
            </MediaTitle>
            <Filler />
            <ImageRankActions
              hasUnscoredImagesSelected={selectedMedias.filter((item)=> item.order === -1).length}
              onRank={onRankMany}
              hasImages={imagesList.length}
            />
            <Filler />
            {/*
            <MediaUpload>
              <UploadsAvailable>{uploads}</UploadsAvailable> rankings 
              <UploadButton onClick={toggle}>Add More..</UploadButton>
            </MediaUpload>
            */}
            <UIButtonWrapper>
              <UIButton as='div' labelPosition='left' onClick={toggle}>
                <Label as='a' basic pointing='right'>
                  {uploads}
                </Label>
                <UIButton>
                  &nbsp;Rankings 
                </UIButton>
              </UIButton>
            </UIButtonWrapper>
            <Elements stripe={stripePromise}>
              <ModalAddUploads maxWidth="700px" isVisible={isVisible} onHide={toggle} onCancel={toggle} />
            </Elements>
            <ModalImportInstagramImages maxWidth="500px" isVisible={isVisibleImportIG} onHide={onHideIGImport} onCancel={toggleImportIG} />
            {/*<LibraryForm maxWidth="500px" library={libraryToEdit} isVisible={isVisibleLibraryForm} onHide={onHideLibraryForm} onCancel={toggleLibraryForm} />*/}
            <MarketUpgrade from={'scored media'} onHide={onHideUpgrade} isVisible={showUpgrade}/>
            {/*<SetupImportForm maxWidth="500px" isVisible={isVisibleImportForm} onHide={onHideImportForm} onCancel={toggleSetupImport} />*/}
          </ScoreHeader>
          <HDivider />
          <MediaHeader>
            <UploadOptionsWrapper>
              <UploadOptions
                onComputerLoad={open}
                onDropboxUpload={(images) => handleUploadByUrl({ images, type: 'dropbox' })}
                onGoogleUpload={(images) => handleUploadByUrl({ images, type: 'google' })}
                onInstagramUpload= {(e) => { e.stopPropagation(); toggleImportIG(e)}}
                extensions={EXTENSIONS}
                hasImages={imagesList.length}
                small={true}
              />
            </UploadOptionsWrapper>
            <Filler />
            <ImageActions
              onSelectDeselectAll={selectDeselectAll}
              hasImagesSelected={selectedMedias.length}
              onDelete={onDeleteMany}
              onRank={onRankMany}
              starImageSelected={starImageSelected}
              hasImages={imagesList.length}
            />
          </MediaHeader>
          <HDivider />
        </MediaHeaderWrapper>
      </Sticky>
      {currentMarket && currentMarket.market_type !== 'free' && (
        <MediaContent {...getRootProps()}>
          {((imagesList && (imagesList.length > 0 || continuePolling)) || (sampleImages && (sampleImages.length > 0))) 
            && (
            <MediaList
              list={imagesList}
              sampleImages={sampleImages}
              showSampleImages={showSampleImages}
              activeImports={activeImports}
              isSelected={isSelected}
              selectionChangeHandler={selectionChangeHandler}
              onDeleteOne={onDeleteOne}
              onAddTags={onAddTags}
              onShowSampleImages={handleShowSampleImages}
              starSingleImage={starSingleImage}
            />
          )}
          {(imagesList && imagesList.length === 0 && !continuePolling) && (
            <>
              {isLoadedImages && (
                <EmptyScoredMedia>
                  <UploadOptionsWrapper>
                    <UploadOptions
                      onComputerLoad={open}
                      onDropboxUpload={(images) => handleUploadByUrl({ images, type: 'dropbox' })}
                      onGoogleUpload={(images) => handleUploadByUrl({ images, type: 'google' })}
                      onInstagramUpload= {toggleImportIG}
                      extensions={EXTENSIONS}
                      hasImages={imagesList.length}
                      hideTitle={true}
                    />
                  </UploadOptionsWrapper>
                </EmptyScoredMedia>
              )}
              {!isLoadedImages && (
                <LoaderWrapper>
                  <Loader stroke="#4aa6e2" />
                </LoaderWrapper>
              )}
            </>
          )}
          <input {...getInputProps()} />
        </MediaContent>
      )}
    </StyledScoredMedia>
  );
}
