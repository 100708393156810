import React from 'react';
import styled from 'styled-components';
import { ControlContainer, HiddenSelectableAppearance, Label, RadioAppearance } from './mixins/SelectableAppearance';

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  ${HiddenSelectableAppearance};
`;

const StyledRadio = styled.div`
  ${RadioAppearance};
  border-radius: 50%;
`;

export default React.forwardRef((props, ref) => {
  const { id, children, checked, onChange, ...rest } = props;
  return (
    <ControlContainer>
      <HiddenRadio id={id} checked={checked} ref={ref} readOnly {...rest} />
      <StyledRadio checked={checked} onClick={onChange} />
      <Label onClick={onChange} htmlFor={id}>
        {children}
      </Label>
    </ControlContainer>
  );
});
