import React from 'react';
import styled from 'styled-components';

// Components
import { FormControl } from '../../../components/form';

// Styles
const StyledLoginLabel = styled(FormControl.Label)`
  color: ${({ theme }) => theme.joot.jootBlack};
  font-size: 15px;
  letter-spacing: -0.26px;
`;

const LoginLabel = ({ children, ...props }) => {
  return <StyledLoginLabel {...props}> {children}</StyledLoginLabel>;
};

export default LoginLabel;
