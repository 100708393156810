import { useCallback, useState } from 'react';
import axios from 'axios';
import { FB_ME_URL } from '../utils/constants';

function useFacebookConnect(callbackFbData) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const reset = useCallback(() => setData(null), []);

  const handleError = useCallback((error) => {
    setIsLoading(false);
    setError(error);
  }, []);

  const handleResponse = useCallback(async (data) => {
    setIsLoading(false);
    const user = await axios.get(
      `${FB_ME_URL}?fields=id,name,email,first_name,picture&access_token=${data.accessToken}`
    );
    const { data: { data: { url: picture_url } } } = await axios.get(
      `${FB_ME_URL}/picture?redirect=false&width=132&access_token=${data.accessToken}`
    );
    setIsLoading(false);
    const result = { ...data, ...user.data, picture_url }
    setData(result);
    callbackFbData && callbackFbData(result)
  }, [callbackFbData]);

  const statusChangeCallback = useCallback(
    (response) => {
      if (response.status === 'connected') {
        handleResponse(response.authResponse);
      } else {
        handleError(response);
      }
    },
    [handleError, handleResponse],
  );

  const checkLoginState = useCallback(() => {
    console.log("checkLoginState");
    window.FB.getLoginStatus(statusChangeCallback);
  }, [statusChangeCallback]);

  const facebookConnectHandler = useCallback(() => {
    console.log("facebook connect handler");
    setIsLoading(true);
    window.FB.login(checkLoginState, {
      scope: 'email, pages_show_list, instagram_basic, instagram_manage_insights, public_profile',
    });
  }, [checkLoginState]);

  const facebookDisconnectHandler = useCallback(() => {
    setIsLoading(true);
    window.FB.logout(function (response) {
      setIsLoading(false);
      setData(null);
      setError(null);
      // Person is now logged out
    });
  }, []);

  return {
    facebookConnectHandler,
    isLoading,
    data,
    setData,
    error,
    facebookDisconnectHandler,
    reset,
  };
}

export default useFacebookConnect;
