import { useState, useCallback } from "react";

export default (fields) => {
  const [touched, setTouched] = useState(fields)
  const handleBlur = useCallback(({ target: { name, value } }) => {
    if (value) {
      setTouched(prev => ({ ...prev, [name]: true }))
    }
  }, []);

  return [
    touched,
    handleBlur,
  ]
}