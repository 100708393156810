import React, { memo, useCallback, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

// Assets
import { ReactComponent as CarrotDown } from '../../../assets/svg/carrot-down.svg';

// Components
import Button from '../../../components/Button';
import Avatar from 'react-avatar';
import MarketInsightsChart from './MarketInsightsChart';
import Image from '../../../components/Image';
import Title from '../../../components/Title';

const InsightBody = styled.div`
  margin: 0px 20px 0px 20px;
`;
const Account = styled.article`
  padding: 10px 20px;
  border-radius: 15px;
  box-shadow: 0 8px 20px 0 rgba(160, 181, 193, 0.15);
  background: ${({ theme }) => theme.joot.white};
`;
const AccountInsights = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 200px;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const Values = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.joot.jootBlack};
  &:first-child {
    width: 52px;
  }
`;
const Name = styled.span`
  font-size: 10px;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
`;
const AccountMedia = styled.div`
  border-top: 1px solid ${({ theme }) => theme.joot.paleGray};
`;
const ExpandTrigger = styled(Button)`
  transform: ${({ up }) => (up ? 'rotate(180deg)' : 'rotate(0deg)')};
  margin-top: 15px;
  margin-bottom: ${({ up }) => (up ? '15px' : '0')};
  width: 100%;
  svg {
    display: block;
    margin: 0 auto;
  }
`;
const Images = styled(motion.div)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 6px;
`;
const ImageLabel = styled.span`
  position: absolute;
  top: -25px;
  left: 0;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: -0.23px;
  color: ${({ theme }) => theme.joot.jootBlack};
`;
const PostImage = styled(Image)`
  transition: 0.2s box-shadow, opacity ${({ theme }) => theme.transition.cubicEase};
  box-shadow: 0 0 0 0 ${({ theme }) => theme.joot.emeraldGreen};
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.joot.emeraldGreen};
  }
`;
const Graphs = styled.div`
  display: flex;
  align-items: center;
`;
const Chart = styled.div`
  width: 30%;
`;
const Legend = styled.ul`
  padding-left: 20px;
`;
const Li = styled.li`
  position: relative;
  font-size: 12px;
  margin-bottom: 10px;
  padding-left: 15px;
  &:last-child {
    margin-bottom: 0;
  }
  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 9px;
    height: 9px;
    border-radius: 100%;
    display: block;
    content: '';
    background: ${({ color }) => color};
    font-weight: bold;
    display: inline-block;
  }
`;
const Empty = styled(Title)`
  align-self: center;
  justify-self: center;
  opacity: 0.3;
  grid-column: 1 / 11;
  margin-top: 15px;
`;
const EmptyChart = styled(Title)`
  opacity: 0.3;
`;

const MarketInsightsRow = ({
  data: {
    media_type,
    ig_id,
    profile_picture_url,
    username,
    ...item
  },
  columns,
  preparePost,
  imageOpened = false,
}) => {
  const { joot } = useTheme();
  const chartData = useMemo(
    () => [
      { key: 'Video', color: joot.mustard, value: media_type.ratio_video },
      { key: 'Carousel', color: joot.cinnabar, value: media_type.ratio_carousel },
      { key: 'Image', color: joot.carolinaBlue, value: media_type.ratio_image },
    ],
    [joot, media_type],
  );
  const showChart = useMemo(() => media_type.ratio_video + media_type.ratio_carousel + media_type.ratio_image > 0, [
    media_type,
  ]);
  const [showImage, setShowImage] = useState(imageOpened);
  const expandImages = useCallback(() => {
    setShowImage((prev) => !prev);
  }, []);
  const posts = preparePost(item)

  return (
    <InsightBody>
      <Account>
        <AccountInsights>
          <Values>
            <Avatar name={username} instagramId={ig_id} src={profile_picture_url} size={52} round={true} />
            <Name>{username}</Name>
          </Values>
          {columns.map(({ field, transform }) => <Values key={field}>{item[field] ? transform(item[field]) : ""}</Values>)}
          <Graphs>
            {!showChart && (
              <EmptyChart h6 noMargin>
                No data
              </EmptyChart>
            )}
            {showChart && (
              <>
                <Chart>
                  <MarketInsightsChart chartData={chartData} />
                </Chart>
                <Legend>
                  {chartData.map(({ value, color, key }) => (
                    <Li key={key} color={color}>
                      {key} ({value.toFixed(2)}%)
                    </Li>
                  ))}
                </Legend>
              </>
            )}
          </Graphs>
        </AccountInsights>
        <AccountMedia>
          <ExpandTrigger up={showImage} onClick={expandImages}>
            <CarrotDown />
          </ExpandTrigger>
          <AnimatePresence>
            {showImage && (
              <Images
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: 'auto' },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{
                  duration: 0.3,
                  type: 'spring',
                  delay: 0,
                  stiffness: 500,
                  damping: 60,
                  mass: 1,
                }}
              >
                <ImageLabel>Top Ten Posts</ImageLabel>
                {posts.length === 0 && (
                  <Empty h4 noMargin>
                    No images found
                  </Empty>
                )}
                {posts.map(({ image_url, ig_post_url }, index) => (
                  <a key={index} href={ig_post_url} target="_blank" rel="noopener noreferrer">
                    <PostImage src={image_url} src2x={image_url} width={'150px'} height={'150px'} />
                  </a>
                ))}
              </Images>
            )}
          </AnimatePresence>
        </AccountMedia>
      </Account>
    </InsightBody>
  );
};

export default memo(MarketInsightsRow);
