import React from 'react';
import styled from 'styled-components';

// Components
import Overflow from '../ Overflow';

// Styles
const StyledArticle = styled.article`
  grid-area: article;
  position: relative;
  overflow: hidden;
`;

const Article = ({ children, ...props }) => {
  return (
    <StyledArticle>
      <Overflow padding={props.padding}>{children}</Overflow>
    </StyledArticle>
  );
};

export default Article;
