import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import ResizeObserver from 'resize-observer-polyfill';
import composeRefs from '../../utils/composeRefs';

// Assets
import { ReactComponent as AddTag } from '../../assets/svg/add-tag.svg';

// Hooks
import useMenu from '../../hooks/useMenu';

// Components
import Menu from './Menu';
import { ToggleLayer } from 'react-laag';
import Tag from '../../features/media/components/Tag';

// Export
export const MenuItem = React.forwardRef(function MenuItem(
  { className, style, children, isOpen, nested, width, ...rest },
  ref,
) {
  return (
    <div ref={ref} className={className} style={style} {...rest}>
      <div>{children}</div>
    </div>
  );
});

// Styles
const TagWrapper = styled.div`
  padding: 0 20px 10px 20px;
  width: 100%;
`;
const TagMenuItem = styled(MenuItem)`
  display: inline-flex;
  cursor: pointer;
`;
const AddTagSvg = styled(AddTag)`
  position: absolute;
  top: 1px;
  left: 1px;
`;
const TagInpuWrapper = styled.label`
  display: inline-flex;
  align-items: center;
  padding: 3px;
  line-height: 20px;
  position: relative;
  border-radius: 25px;
  border: 1px solid ${({ theme }) => theme.joot.carolinaBlue};
  background: ${({ theme }) => theme.joot.jootWhite};
  cursor: pointer;
`;
const TagInput = styled.input`
  cursor: pointer;
  margin: 0;
  padding: 0 4px 0 20px;
  border: none;
  font-size: 10px;
  letter-spacing: -0.37px;
  width: 68px;
  font-weight: 600;
  font-family: ${(props) => props.theme.font.sansSerif};
  color: ${({ theme }) => theme.joot.carolinaBlue};
  background: transparent;
  &::placeholder {
    font-weight: 600;
    color: ${({ theme }) => theme.joot.carolinaBlue};
  }
`;

// Values
const PLACEMENT = {
  anchor: 'BOTTOM_LEFT',
  snapToAnchor: false,
  triggerOffset: 15,
  scrollOffset: 16,
  preferX: 'LEFT',
};

const TagsMenu = React.forwardRef(function TagsMenu(props, ref) {
  const { items: optionsIn, onTagClick, readOnly, name, onChange, title, children, initialSelection, detectedTags, onRemove } = props;
  const [filter, setFilter] = useState('');
  const labelField = 'name';
  const containerRef = useRef();
  const { selectedText, handleSelection, selected } = useMenu({
    labelField,
    emptySelectionText: '',
    initialSelection: !initialSelection ? { value: 'mr', name: 'Most Recent' } : initialSelection,
  });
  const options = useMemo(() => {
    let foundIt = false;
    const filteredOptions = optionsIn.filter((item) => {
      if (filter === item.name) {
        foundIt = true;
      }
      return item.name.toLowerCase().startsWith(filter.toLowerCase());
    });
    if (!filter || foundIt) {
      return filteredOptions;
    }
    return [{ name: filter, isNew: true }, ...filteredOptions];
  }, [filter, optionsIn]);
  const sendChange = useCallback(
    (value) => {
      onChange({ target: { name, value } });
      setFilter('');
    },
    [name, onChange],
  );
  const handleClose = useCallback(() => setFilter(''), []);
  const [isOpen, setOpen] = useState(false)
  const toggle = useCallback(() => setOpen(prop => !prop), [])
  useEffect(() => {
    const onEscape = ({ keyCode }) => {
      if (keyCode === 27) {
        setOpen(false);
      }
    }
    document.addEventListener('keydown', onEscape);
    return () => document.removeEventListener('keydown', onEscape);
  });
  const handleChange = useCallback(({ target: { value } }) => {
    setFilter(value);
    setOpen(true)
  }, []);
  const handleInputKeyPress = useCallback(({ key }) => {
    if (key === 'Enter') {
      sendChange({ name: filter, isNew: true })
      setOpen(false)
    }
  }, [sendChange, filter])
  const handleRemove = useCallback((event, originalEvent) => {
    originalEvent.stopPropagation()
    onRemove(event)
  }, [onRemove])

  return (
    <div ref={containerRef}>
      <ToggleLayer
        fixed={true}
        isOpen={isOpen}
        ResizeObserver={ResizeObserver}
        onOutsideClick={toggle}
        renderLayer={(props) => {
          function handleClick(item) {
            return function onClick() {
              handleSelection(item);
              sendChange(item);
              toggle();
            };
          }
          return (
            <AnimatePresence>
              {props.isOpen ? (
                <Menu
                  ref={props.layerProps.ref}
                  style={props.layerProps.style}
                  arrowStyle={props.arrowStyle}
                  layerSide={props.layerSide}
                  label={title}
                  width={'234px'}
                >
                  <TagWrapper>
                    {options.map((item, i) => (
                      <TagMenuItem key={i} onClick={handleClick(item)}>
                        <Tag isNew={item.isNew} hasRemove={false}>
                          <span>{item[labelField]}</span>
                        </Tag>
                      </TagMenuItem>
                    ))}
                  </TagWrapper>
                </Menu>
              ) : null}
            </AnimatePresence>
          );
        }}
        placement={PLACEMENT}
      >
        {({ isOpen, triggerRef }) => {
          return (
            <>
              {!children && (
                <div ref={composeRefs(triggerRef, ref)}>
                  <>
                    {detectedTags.map((item) => (
                      <Tag
                        onRemove={handleRemove}
                        value={item}
                        color={'carolinaBlue'}
                        key={item.name}
                        quantity={item.quantity}
                        onClick={onTagClick}
                        hasRemove={!readOnly}
                      >
                        {item.name}
                      </Tag>
                    ))}
                  </>
                  {!readOnly && (
                    <TagInpuWrapper>
                      <AddTagSvg onClick={handleClose} />
                      <TagInput
                        onClick={toggle}
                        value={filter}
                        onChange={handleChange}
                        placeholder="Add Tag"
                        onKeyPress={handleInputKeyPress}
                      />
                    </TagInpuWrapper>
                  )}
                </div>
              )}
              {children && children({ isOpen, triggerRef, toggle, selectedText, selected })}
            </>
          );
        }}
      </ToggleLayer>
    </div>
  );
});

export default TagsMenu;
