import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

// Hooks
import useModal from '../../../hooks/useModal';
import useAppToasts from '../../../hooks/useToasts';
import { useMarket } from '../hooks/useMarket';
import { useGetBillingDetail } from '../../../hooks/apiHooks/billingApiHook';

// Context
import { useAppContext } from '../../../contexts/AppContext';

// Components
import SelectedSetting from './SelectedSetting';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import MarketUpgrade from '../../../features/media/components/MarketUpgrade';
import ModalForm from '../../../components/modals/ModalForm';
import MarketForm from '../../../components/marketForm';

// Styles
const StyledMarketsTab = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`;

const MarketsTab = () => {
  const { errorToast } = useAppToasts();
  const { isVisible, toggle } = useModal();
  const [market, setMarket] = useState();
  const [form, setForm] = useState();
  const handleAdd = useCallback(() => {
    setMarket(null);
    toggle();
  }, [toggle]);
  const {
//    markets: { data: markets },
    accountMarkets,
    igAccount,
    igUserName,
  } = useAppContext();
  const { save, remove, loading } = useMarket();
  const handleSave = useCallback(async () => {
    if (!form || form.errors.length !== 0) {
      console.log(!form ? "empty form" : "form has errors");
      return;
    }
    console.log("no errors");
    try {
      await save(igUserName, form.market, form.competitors, undefined, market);
    } catch (e) {
      errorToast(e.message);
    }
    toggle();
  }, [form, errorToast, toggle, market, save, igUserName]);
  const hanldeOnEdit = useCallback(
    (item) => {
      setMarket(item);
      toggle();
    },
    [toggle],
  );
  const [removeName, setRemoveName] = useState(false);
  const handleDelete = useMemo(() => {
    if (accountMarkets && accountMarkets.length > 1) {
      return ({ market_name }) => setRemoveName(market_name);
    }
    return null;
  }, [accountMarkets]);
  const deleteHandler = useCallback(async () => {
    try {
      await remove(removeName, igUserName);
      setRemoveName(false);
    } catch (e) {
      errorToast(e.message);
    }
  }, [removeName, remove, errorToast, igUserName]);
  const {
    paymentMethod: { card },
  } = useGetBillingDetail();
  const handleForm = useCallback((form) => setForm(form), []);

  return (
    <StyledMarketsTab>
      {accountMarkets &&
        accountMarkets.map((item) => (
          <SelectedSetting
            key={item.market_name}
            isMarket
            market={item}
            onEditClick={hanldeOnEdit}
            onDelete={handleDelete}
          />
        ))}
      <SelectedSetting isAddOn label="Add Market"  onAddClick={handleAdd} />
      <MarketUpgrade from={'settings'} onHide={() => toggle()} isVisible={isVisible}/>
      {/*
      <ModalForm
        isVisible={isVisible}
        title={market ? 'Edit Market' : 'Add New Market'}
        maxWidth="520px"
        okText={market ? 'Update' : 'Create Market'}
        onHide={() => toggle()}
        onOk={handleSave}
        isLoading={loading}
      >
        <MarketForm onChange={handleForm} igAccount={igAccount} card={!market && card} market={market} />
      </ModalForm>
      */}
      <ConfirmModal
        isVisible={!!removeName}
        onHide={() => setRemoveName(false)}
        onCancel={() => setRemoveName(false)}
        onConfirm={deleteHandler}
        message={'Delete this market?'}
        loading={loading}
      />
    </StyledMarketsTab>
  );
};

export default MarketsTab;
