import React from 'react';
import { Route } from 'react-router-dom';

export default function LayoutRoute({
  component: Component,
  layout: Layout,
  children,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
}
