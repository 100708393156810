import React from 'react';
import styled from 'styled-components';
import Avatar from 'react-avatar';

const CompetitorAccountWrapper = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0px 20px 0px 0px;
  z-index: 1;
`;
const Competitor = styled.li`
  position: relative;
  width: 50px;
  &:nth-child(1) {
    z-index: 1;
  }
  &:nth-child(2) {
    z-index: 2;
  }
  &:nth-child(3) {
    z-index: 3;
  }
  &:nth-child(4) {
    z-index: 4;
  }
  &:nth-child(5) {
    z-index: 5;
  }
  &:nth-child(6) {
    z-index: 6;
  }
  &:nth-child(7) {
    z-index: 7;
  }
  &:nth-child(8) {
    z-index: 8;
  }
  &:nth-child(9) {
    z-index: 9;
  }
  &:nth-child(10) {
    z-index: 10;
  }
`;

const MarketHandles = ({ market_accounts }) => {

  return (
    <CompetitorAccountWrapper>
      {market_accounts && market_accounts
        .map((member) => (
          <Competitor key={member.username}>
            <Avatar
              name={member.username}
              src={member.profile_picture_url}
              size={47}
              round={true}
            />
          </Competitor>
        ))}
    </CompetitorAccountWrapper>
  );
};

export default MarketHandles;