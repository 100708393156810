import React, { memo, useCallback, useState, useMemo } from 'react';
import { FormControl, Input, Checkbox } from '../form';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import StripeCardDetail from '../StripeCardDetail';
import StripeCardForm from '../StripeCardForm';
import { useGetBillingDetail } from '../../hooks/apiHooks/billingApiHook';
import LockIcon from '../icons/LockIcon';
import PoweredByStripeIcon from '../icons/PoweredByStripeIcon';
import Title from '../Title';

const StyledPoweredByStripe = styled(PoweredByStripeIcon)`
  margin: 30px auto 0px auto;
  position: relative;
`;
const Section = styled.div`
  flex-flow: column;
  margin-bottom: 20px;
`;
const Panels = styled.div`
  display: flex;
  flex-flow: row;
  padding: 0px;
`;
const LeftPanel = styled.div`
  display: flex;
  flex-flow: column;
  width: 600px;
  border-radius: 3px;
  border: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.1)};
  padding: 12px;
`;
const RightPanel = styled.div`
  display: flex;
  flex-flow: column;
  width: 500px;
  margin-left: 20px;
  margin-top: 20px;
`;
const Text = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 23px;
  white-space: nowrap;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;
const Text16 = styled(Text)`
  font-size: 16px;
`;
const LockText = styled(Text)`
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.5)};
  margin-left: 5px;
`;
const Plan = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => rgba(props.theme.joot.mustard, 1.0)};
`;
const PlanItem = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => rgba(props.theme.joot.potteryBlue, 0.9)};
  margin: 10px 0 0 0;
`;
const PlanDescription= styled(Text)`
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
  margin: 8px 0;
  white-space: normal
`;
const PlanLineItem = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0 0 10px;
`;
const PlanLineValue = styled.div`
  padding: 8px;
  background: ${({ theme }) => theme.joot.paleGray};
  font-weight: 400;
  text-align: center;
  margin: 10px 0 0 10px;
`;
const PaymentTitle = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => rgba(props.theme.joot.mustard, 1.0)};
  margin: 10px 0 10px 0;
`;
const HDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0px;
  background: ${({ theme }) => theme.joot.paleGray};
`;
const StepBody = styled.div`
  padding: 10px;
`;
const FlexRow = styled.div`
  margin: 0px auto 0px auto;
  display: flex;
  flex-flow: row;
  align-items: center;
`;
const StripeRow = styled(FlexRow)`
  margin-top: 20px
`;
const Filler = styled.div`
  width: 100%;
`;


const defaultSubscription = { value: 'monthly', name: '$19.99 Monthly Subscription' };

const UnlockMarketPaymentForm = ({ market, value, onCardChange, onUseExistingCardChange }) => {
  const {
    paymentMethod: { card },
  } = useGetBillingDetail();
  const handleCardChange = useCallback((value) => {
    onCardChange(value)
  }, [onCardChange]);
  useMemo(() => {
    onUseExistingCardChange && onUseExistingCardChange(!!card);
  }, [card]);
  const { joot } = useTheme();
  let lockColor = joot.moonDust;

  return (
    <>
      <Panels>
        <LeftPanel>
          <StepBody>
            <Section>
              <PlanItem>Market Description</PlanItem>
              <PlanDescription>{market.description}</PlanDescription>
              <FlexRow>
                <PlanLineItem>Included Instagram Accounts</PlanLineItem>
                <Filler></Filler>
                <PlanLineValue>{market.ig_handles.length}</PlanLineValue>
              </FlexRow>
            </Section>
            <HDivider />
            <Section>
              <PlanItem>Access to Image Insights</PlanItem>
              <PlanDescription>Gain access to all image insight concept groupings and gain deep understanding of what kind of images concepts engage or disengage the market audience.</PlanDescription>
              <FlexRow>
                <PlanLineItem>Number of Image Insight Concepts</PlanLineItem>
                <Filler></Filler>
                <PlanLineValue>{market.ig_handles.length * 30 + 30}</PlanLineValue>
              </FlexRow>
            </Section>
            <Section>
              <PlanItem>Access to Market Insights</PlanItem>
              <PlanDescription>Gain access to statistics for all {market.ig_handles.length} Instagram accounts in this market</PlanDescription>
              <FlexRow>
                <PlanLineItem>Number of Accessible Account Statistics</PlanLineItem>
                <Filler></Filler>
                <PlanLineValue>{market.ig_handles.length * 11}</PlanLineValue>
              </FlexRow>
            </Section>
            <Section>
              <PlanItem>Market Unlock Bonus</PlanItem>
              <PlanDescription>Receive 100 additional Image Rankings to your Joot account everytime you unlock a Joot Market.</PlanDescription>
              <FlexRow>
                <PlanLineItem>Additional Image Rankings</PlanLineItem>
                <Filler></Filler>
                <PlanLineValue>+100</PlanLineValue>
              </FlexRow>
            </Section>
            <HDivider />
          </StepBody>
        </LeftPanel>
        <RightPanel>
          <StepBody>
            <Section>
              <Text>Unlock Joot Market:</Text>
              <FlexRow>
                <Plan>{market.market_name}</Plan>
                <Filler></Filler>
                <Text16>$19.99/mo</Text16>
              </FlexRow>
              <HDivider />
              <PaymentTitle>Payment Details</PaymentTitle>
              {card && (<FormControl>
                <FormControl.Label>Use Your existing Payment Method</FormControl.Label>
                <StripeCardDetail card={card} />
              </FormControl>)}
              {!card && <StripeCardForm onCardChange={handleCardChange} />}
            </Section>
            <FlexRow>
              <LockIcon fill={lockColor}/>
              <LockText>All payments are secure and encrypted.</LockText>
            </FlexRow>
            <StripeRow>
              <StyledPoweredByStripe width={150} />
            </StripeRow>
          </StepBody>
        </RightPanel>
      </Panels>
    </>
  );
};

export default memo(UnlockMarketPaymentForm);
