import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

// Hooks
import { useFacebookData } from '../../../hooks/apiHooks/facebookQueryHooks';
import useModal from '../../../hooks/useModal';

// Context
import { useAppContext } from '../../../contexts/AppContext';

// Components
import SelectedSetting from './SelectedSetting';
import FacebookIcon from '../../../components/icons/FacebookIcon';
import Button from '../../../components/Button';
import ModalFacebookAuth from './ModalFacebookAuth';
import IgAccountAdding from './IgAccountAdding';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import useAppToasts from '../../../hooks/useToasts';
import { removeAccount } from '../../../services';
import config from '../../../utils/config';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Styles
const StyledAccountsTab = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`;
const FacebookAuth = styled(Button)`
  position: absolute;
  top: 12px;
  right: 30px;
  display: flex;
  align-items: center;
`;
const Text = styled.div`
  font-size: 10px;
  font-weight: 600;
  margin-right: 5px;
`;

const AccountsTab = () => {
  const { errorToast } = useAppToasts();
  const { isVisible, toggle } = useModal();
  const handleDesconnect = useCallback(() => toggle(), [toggle]);
  const { igAccounts = [], igAccount, invalidateIgAccounts, markets } = useAppContext();
  const [removeName, setRemoveName] = useState();
  const [loading, setLoading] = useState();
  const { data: { igAccountsNoConnected = [], ...facebookData } = {} } = useFacebookData();
  const handleDelete = useMemo(() => {
    if (igAccounts.length === 1) {
      return null; // Don't show the remove icon.
    }
    return (item) => {
      setRemoveName(item.username);
    };
  }, [igAccounts]);
  const [showAddModal, setShowAddModal] = useState(false);
  const deleteHandler = useCallback(async () => {
    setLoading(true);
    try {
      await removeAccount(removeName);
      markets.invalidateQuery();
      invalidateIgAccounts();
      setRemoveName(false);
    } catch (e) {
      errorToast(e.message);
    }
    setLoading(false);
  }, [errorToast, removeName, invalidateIgAccounts, markets]);
  const stripePromise = loadStripe(config.stripe.key);

  return (
    <StyledAccountsTab>
      {facebookData && (
        <FacebookAuth onClick={handleDesconnect}>
          <Text>{facebookData?.name ? facebookData.name : 'Connect Your Facebook Account'}</Text>
          <FacebookIcon width={24} height={24} />
        </FacebookAuth>
      )}
      <ModalFacebookAuth facebookData={facebookData} maxWidth="520px" isVisible={isVisible} onHide={toggle} />
      <Elements stripe={stripePromise}>
        <IgAccountAdding isVisible={showAddModal} maxWidth="520px" onHide={() => setShowAddModal(false)} />
      </Elements>
      {/*igAccounts &&
        igAccounts.map((item) => (
          <SelectedSetting
            key={item.username}
            current={item.username === igAccount?.username}
            igAccount={item}
            onDelete={handleDelete}
          />
        ))*/}
      {/*igAccountsNoConnected && igAccountsNoConnected.length > 0 && (
        <SelectedSetting
          isAddOn
          label="Add Account and Associated Market"
          onAddClick={() => setShowAddModal(true)}
        />
      )*/}
      <ConfirmModal
        isVisible={!!removeName}
        onHide={() => setRemoveName(false)}
        onCancel={() => setRemoveName(false)}
        onConfirm={deleteHandler}
        message={'Delete this market?'}
        loading={loading}
      />
    </StyledAccountsTab>
  );
};

export default AccountsTab;
