import React from 'react';
import styled from 'styled-components';

// Hooks
import useShowAside from '../hooks/useShowAside';

// Components
import Header from '../components/header/Header';

// Styles
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
  width: 100vw;
  position: relative;
  grid-template-areas:
    "header"
    "main";
  }
`;
const Main = styled.main`
  background: ${({ theme }) => theme.color.white};
  grid-area: main;
  display: grid;
  grid-template-columns: 195px 1fr ${({ showAside }) => (showAside ? '294px' : null)};
  grid-template-areas:
    "nav article ${({ showAside }) => (showAside ? 'aside' : null)}";
  }
`;

const Layout = ({ children }) => {
  const showAside = useShowAside();
  return (
    <Wrapper>
      <Header />
      <Main showAside={showAside}>{children}</Main>
    </Wrapper>
  );
};

export default Layout;
