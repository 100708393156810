import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Components
import Modal from '../Modal';
import Title from '../Title';
import Button from '../Button';

// Styles
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
`;
const ModalFooter = styled.div`
  display: flex;
  padding: 40px;
  justify-content: flex-end;
  overflow: hidden;
  > button {
    margin-left: 20px;
  }
`;

const ConfirmModal = ({ isVisible, loading, message, onCancel, onConfirm, ...rest }) => {
  return (
    <Modal maxWidth="500px" {...rest} isVisible={isVisible}>
      <ModalHeader>
        <Title h5>{message}</Title>
      </ModalHeader>
      <ModalFooter>
        <Button primary onClick={onCancel}>
          No
        </Button>
        <Button loading={loading} primary onClick={onConfirm}>
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  isVisible: PropTypes.bool,
  message: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ConfirmModal;
