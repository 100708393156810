import React from 'react';
import styled from 'styled-components';

// Components
import Button from '../../../components/Button';

const StyledImageActions = styled.div`
  display: flex;
  align-items: center;
`;
const ActionButton = styled(Button)`
  margin: 0 0 0 20px;
  &:first-of-type {
    margin-left: 0;
  }
`;

const ImageActions = ({ onSelectDeselectAll, hasImagesSelected, onDelete, starImageSelected, hasImages }) => {
  return (
    <StyledImageActions>

      <ActionButton mini onClick={onSelectDeselectAll} disabled={!hasImages}>
        {hasImagesSelected ? 'Deselect all' : 'Select All'}
      </ActionButton>
      <ActionButton mini onClick={starImageSelected} disabled={!hasImagesSelected}>
        Star Image
      </ActionButton>
      <ActionButton mini danger onClick={onDelete} disabled={!hasImagesSelected}>
        Remove
      </ActionButton>
    </StyledImageActions>
  );
};

export default ImageActions;
