import { useState } from 'react';

const useModal = (initialState=false) => {
  const [isVisible, setIsVisible] = useState(initialState);

  const toggle = () => {
    setIsVisible(!isVisible);
  };

  return {
    isVisible,
    toggle,
  };
};

export default useModal;
