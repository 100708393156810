import React from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Components
import { Input } from '../../../components/form';

// Styles
const StyledInput = styled(Input)`
  background: transparent;
  color: ${({ theme }) => theme.joot.jootBlack};
  border: 2px solid ${({ theme }) => theme.joot.jootBlack};
  font-size: 15px;
  letter-spacing: -0.26px;
  padding: 15px;
  &::placeholder {
    font-weight: 400;
    color: ${({ theme }) => rgba(theme.joot.jootBlack, 0.5)};
  }
  ${(props) => props.hasError && `border: 2px solid ${props.theme.joot.cinnabar};`}
`;

const LoginInput = React.forwardRef(function PasswordInput(props, ref) {
  return <StyledInput {...props} ref={ref} className="login-input" />;
});

export default LoginInput;
