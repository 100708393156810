import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// Hooks
import { useCreateMarket } from '../../hooks/apiHooks/instagramQueryHooks';

// Context
import { useAppContext } from '../../contexts/AppContext';
import { useSignupContext } from './context/SignupContext';

// Components
import Navigation from '../../components/Navigation';
import Box from '../../components/box/Box';
import MarketForm from '../../components/marketForm';

// Styles
const StepBox = styled(Box)`
  width: 700px;
  border-radius: 5px;
  margin: 0 auto;
  padding: 20px 25px 50px 25px;
`;

const Footer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row-reverse;
  flex-content: flex-start;
  align-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
`;

const SignupStepDefineMarket = () => {
  const { push } = useHistory();
  const { 
    igAccount,
    invalidateIgAccounts,
    markets
  } = useAppContext();
  const [form, setForm] = useState();
  const handleForm = useCallback((value) => setForm(value), []);
  const { 
    changeState,
    setOnNext,
    handlerNext,
    loadingNext,
    disabledNext,
    errors,
   } = useSignupContext(3);
  const { submit: createMarket } = useCreateMarket();
  useEffect(() => {
    if (markets.status !== 'idle' && (!markets.MARKET_UNDEFINED || markets.MARKET_ACTIVE)) {
      push('/signup/four');
    }
  }, [markets, push]);
  useEffect(() => {
    changeState('disabledNext', !form);
  }, [form, changeState]);
  const handleError = useCallback(
    (errors) => {
      changeState('errors', errors);
    },
    [changeState],
  );
  useEffect(() => {
    setOnNext(async () => {
      await createMarket({
        market_name: form.market,
        competitors: form.competitors,
      });
      await invalidateIgAccounts();
      return true;
    });
    return () => setOnNext(null);
  }, [form, setOnNext, createMarket, invalidateIgAccounts]);

  return (
    <>
      <StepBox>
        <MarketForm
          igAccount={igAccount}
          onChange={handleForm}
          onError={handleError}
        />
        <Footer>
          <Navigation loading={loadingNext} onNext={handlerNext} disabledNext={disabledNext} errors={errors}/>
        </Footer>
      </StepBox>
    </>
  );
};

export default SignupStepDefineMarket;
