import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Hooks
import useRedirectionHandler from './hooks/useRedirectionHandler';

// Components
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import LayoutRoute from './components/LayoutRoute';

// Containers
import Media from './containers/Media';
// import Dashboard from './containers/Dashboard';
// import Integrations from './containers/Integrations';
// import Publisher from './containers/Publisher';
// import Post from './containers/Post';
// import PostCreate from './containers/PostCreate';
// import Settings from './containers/Settings';
import Signup from './containers/Signup';
import NotFound from './containers/NotFound';

// Layouts
import Layout from './containers/Layout';
// import LayoutDefault from './containers/LayoutDefault';
// import LayoutFull from './containers/LayoutFull';
import LayoutAuth from './containers/LayoutAuth';
import LayoutSignup from './containers/LayoutSignup';
import Login from './containers/Login';
import SignupCreate from './containers/SignupCreate';

export default function Routes() {
  //useRedirectionHandler(false);

  return (
    <Switch>
      {/* Dashboard */}
      {/* <AuthenticatedRoute path="/dashboard" component={Dashboard} layout={LayoutDefault} /> */}

      {/* Integrations */}
      {/* <AuthenticatedRoute path="/integrations" component={Integrations} layout={LayoutDefault} /> */}

      {/* Publisher */}
      {/* <AuthenticatedRoute path="/publisher" component={Publisher} layout={LayoutDefault} /> */}

      {/* Settings */}
      {/* <AuthenticatedRoute path="/settings" component={Settings} layout={LayoutDefault} /> */}

      {/* Post */}
      {/* <AuthenticatedRoute path="/post/create" component={PostCreate} layout={LayoutFull} /> */}
      {/* <AuthenticatedRoute path="/post" exact component={Post} layout={LayoutDefault} /> */}

      {/* Auth */}
      {/* <UnauthenticatedRoute path="/signup/create" exact component={SignupCreate} layout={LayoutAuth} /> */}
      {/* <AuthenticatedRoute path="/signup" component={Signup} layout={LayoutSignup} /> */}
      {/* <UnauthenticatedRoute path="/login" component={Login} layout={LayoutAuth} /> */}


      {/* Media */}
      {/* <UnauthenticatedRoute path="/scored-media" component={Media} layout={Layout} /> */}
      {/* <AuthenticatedRoute path="/" component={Media} layout={Layout} /> */}

      <LayoutRoute path="/" component={Media} layout={Layout} />

      {/* Unmatched routes */}
      <Route component={NotFound} layout={Layout} />
    </Switch>
  );
}
