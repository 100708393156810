import React from 'react';
import styled from 'styled-components';

// Styles
const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  grid-area: nav;
  background: ${({ theme }) => theme.joot.white};
  padding: 20px 10px 15px 20px;
  z-index: 1;
  position: relative;
  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 1px;
    top: 0px;
    right: 0;
    bottom: 0px;
    background: ${({ theme }) => theme.joot.paleGray};
  }
`;

const Nav = ({ children }) => {
  return <StyledNav>{children}</StyledNav>;
};

export default Nav;
