import { useMemo, useCallback, useState } from "react";

const filterSelected = (items, itemsSelected, filterValue, type) => {
  const selectedByType = itemsSelected.filter(item => item.type === type)
  return items
    .filter(item => !selectedByType.find(({ value }) => value === item.value))
    .filter(({ name }) => name.toLowerCase().includes(filterValue))
    .map(item => ({ ...item, type }));
}

export const useSearch = ({ tags, dates, ai }, name, itemsSelected, onChange) => {
  const [filterValue, setFilterValue] = useState('')
  const options = useMemo(() => {
    return [
      { seccion: 'Tags', items: filterSelected(tags, itemsSelected, filterValue, 'tag') },
      { seccion: 'Date', items: filterSelected(dates, itemsSelected, filterValue, 'date') },
      { seccion: 'AI', items: filterSelected(ai, itemsSelected, filterValue, 'ai') },
    ]
  }, [tags, dates, itemsSelected, ai, filterValue])
  const sendChange = useCallback(
    value => onChange && onChange({ target: { name, value } }),
    [onChange, name]
  )
  const handleChange = useCallback(
    (item) => {
      const value = [item, ...itemsSelected];
      sendChange(value)
    },
    [itemsSelected, sendChange],
  );
  const handleRemove = useCallback(item => {
    const value = itemsSelected.filter(({ value, type }) => {
      if (value !== item.value) {
        return true;
      }
      return type !== item.type;
    })
    sendChange(value)
  }, [itemsSelected, sendChange])
  const handleFilterChange = useCallback(({ target: { value }}) => setFilterValue(value), [])

  return {
    options,
    handleRemove,
    handleChange,
    handleFilterChange,
    filterValue,
  }
};