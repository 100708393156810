export default {
  joot: {
    black: '#000000',
    white: '#FFFFFF',
    jootBlack: '#1a1a1a',
    jootWhite: '#fdfdff',
    shark: '#f5f5f5',
    paleGray: '#f1f4f7',
    blueGray: '#ccd5da',
    slate: '#556c74',
    mustard: '#f4bb41',
    cinnabar: '#df5141',
    flamingo: '#e09594',
    potteryBlue: '#367ca3',
    iceBlue: '#e2f6ff',
    carolinaBlue: '#4aa6e2',
    emeraldGreen: '#4a9480',
    lightPink: '#ffe2e7',
    violetPink: '#fa6bfa',
    aquaMarine: '#47cbb1',
    buff: '#ffeda0',
    squash: '#f3a510',
    lightGrey: '#a4bfd0',
    lightGrayAlt: '#9fb5c1',
    moonDust: '#a8b8c0',
    mud: '#2B2B2B',
    grayIce: '#027EA7',

    facebook: '#1877f2',
    facebookLight: '#eff5fd',
  },
  color: {
    black: '#000000',
    white: '#FFFFFF',
    error: '#C12B21',
    facebook: '#1877f2',
    facebookLight: '#eff5fd',
    discord: '#B795FF',

    one: '#191E2A',
    two: '#282A5B',
    three: '#494C9E',
    four: '#7483A6',
    five: '#82839D',
    six: '#9D9DBB',
    seven: '#8795B8',
    eight: '#FEF4DB',
    nine: '#F7F8FB',
    ten: '#E0E4EE',
    eleven: '#DDEDF5',
    twelve: '#4EA9E6',
    thirteen: '#8B8CE8',
    fourteen: '#5657B3',
    fifteen: '#D0D1FF',
    sixteen: '#E5E5FD',
    seventeen: '#ECF0FF',
    eighteen: '#949FC2',
    nineteen: '#B3BCD9;',
    twenty: '#C9CFE1',
    twentyOne: '#E9E9F1',
    twentyTwo: '#D1DBFF',
    twentyThree: '#8DA2F2',
    twentyFour: '#F4F8FA',
    twentyFive: '#DFE0EC',
    twentySix: '#AEAEAE',
    twentySeven: '#8082A5',
    twentyEight: '#2F680B',
    twentyNine: '#BD0F0F',
    thirty: '#BBC1D0',
    thirtyOne: '#A3A5FF',
    thirtyTwo: '#242E46',
    thirtyThree: '#F6F8FF',
    thirtyFour: '#DCAF3F',
    thirtyFive: '#3198F6',
    thirtySix: '#8386A1',
    thirtySeven: '#FFDE8B',
    thirtyEight: '#DEE7FD',
    thirtyNine: '#E9FEDC',
    fourty: '#25510A',
    fourtyOne: '#04051A',
    fourtyTwo: '#9091B5',
    fourtyThree: '#B0B0B1',
    fourtyFour: '#ABA188',
    fourtyFive: '#D7DDED',
    fourtySix: '#C1C2DF',
    fourtySeven: '#999FB0',
    fourtyEight: '#69a742',
    fourtyNine: '#dfb257',
    fifty: '#b9bad7',
    fiftyOne: '#6b3dbf',
    fiftyTwo: '#b1bee9',
    fiftyThree: '#E3D4AF',
    fiftyFour: '#D6D6F2',
    fiftyFive: '#B4B4DB',
    fiftySix: '#e9e9fc',
    fiftySeven: '#497a2a',
    fiftyEight: '#2c2d32',
  },
  transition: {
    cubicEase: 'cubic-bezier(0.68, 0.38, 0.21, 1.01)',
  },
  font: {
    sansSerif: '"Lato", system-ui, sans-serif',
  },
  metric: {
    oneBorder: '#553AA1',
    twoBorder: '#3AA182',
    threeBorder: '#C2E730',
    fourBorder: '#DC8A3E',
    fiveBorder: '#B80CD3',
    oneBg: 'rgba(85, 58, 161, 0.05)',
    twoBg: 'rgba(58, 161, 130, 0.05)',
    threeBg: 'rgba(194, 231, 48, 0.05)',
    fourBg: 'rgba(220, 138, 62, 0.05)',
    fiveBg: 'rgba(184, 12, 211, 0.05)',
  },
};
