import React, { memo, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { VictoryPie } from 'victory';

const paddingLeftRight = 0;
const Wrapper = styled.div`
  padding: 0 ${paddingLeftRight}px;
`;
const MarketInsightsChartSvg = styled.svg`
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.color.white};
`;

const styles = {
  data: {
    stroke: '#fff',
    strokeWidth: 2,
    fill: ({ datum: { color } }) => color,
  },
};

const MarketInsightsChart = ({ chartData }) => {
  const data = useMemo(() => chartData.map(({ key, value, color }) => ({ x: key, y: value, color })), [chartData]);

  const [widthContainer, setWidth] = useState(0);
  const bodyRef = useRef();
  useLayoutEffect(() => {
    if (bodyRef.current) {
      const { width } = bodyRef.current.getBoundingClientRect();
      setWidth(width);
    }
  }, []);

  const widthAndHeight = useMemo(() => widthContainer - paddingLeftRight * 2, [widthContainer]);
  const viewBox = useMemo(() => `0 0 ${widthAndHeight} ${widthAndHeight}`, [widthAndHeight]);

  return (
    <Wrapper ref={bodyRef}>
      {widthAndHeight > 0 && (
        <MarketInsightsChartSvg viewBox={viewBox}>
          <rect width={10} height={10} fill="rgb(0,0,255)" strokeWidth="3" />
          <VictoryPie
            padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
            standalone={false}
            width={widthAndHeight}
            height={widthAndHeight}
            style={styles}
            data={data}
          />
        </MarketInsightsChartSvg>
      )}
    </Wrapper>
  );
};

export default memo(MarketInsightsChart);
