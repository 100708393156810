import React, { memo, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Components
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

const StyledCardElement = styled(CardElement)`
  font-weight: 400;
  line-height: 16px;
  font-size: 12px;
  padding: 9px 16px;

  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.joot.paleGray};
  color: ${(props) => props.theme.joot.jootBlack};
  background-color: ${(props) => props.theme.joot.jootWhite};

  input {
    letter-spacing: -0.37px;
    font: 400 12px ${(props) => props.theme.font.sansSerif} !important;
    &::placeholder {
      font-weight: 400;
      font: 400 12px ${(props) => props.theme.font.sansSerif} !important;
      color: ${(props) => rgba(props.theme.joot.jootBlack, 0.3)};
    }
  }
`;

export const useStripeCard = () => {
  const stripe = useStripe();
  const elements = useElements();

  const saveCard = useCallback(
    async (name, client_secret) => {
      if (!name) {
        return false;
      }
      if (!stripe || !elements) {
        return false;
      }
      const card = elements.getElement(CardElement);
      const result = await stripe.confirmCardSetup(client_secret, {
        payment_method: {
          card,
          billing_details: { name },
        },
      });
      if (result.error) {
        return false;
      }
      return true;
    },
    [elements, stripe],
  );

  return {
    saveCard,
  };
};

const StripeCard = ({ name }) => {
  const { joot, font } = useTheme();

  return (
    <StyledCardElement
      options={{
        hidePostalCode: true,
        style: {
          base: {
            fontSize: '12px',
            fontWeight: '400',
            fontFamily: `${font.sansSerif}`,
            letterSpacing: '-0.37px',
            color: `${joot.jootBlack}`,
            '::placeholder': {
              fontWeight: '400',
              fontFamily: `${font.sansSerif}`,
              letterSpacing: '-0.37px',
              color: `${rgba(joot.jootBlack, 0.3)}`,
            },
          },
          invalid: {
            color: `${joot.cinnabar}`,
          },
        },
      }}
    />
  );
};

export default memo(StripeCard);
