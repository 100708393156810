import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Reset } from 'styled-reset';
import { AppContextProvider } from './contexts/AppContext';
import { Auth } from 'aws-amplify';
import { ConfigProvider } from 'react-avatar';
import config from './utils/config';
import theme from './utils/theme';
import Routes from './Routes';
import { AuthContext } from './contexts/AuthContext';
import { ToastProvider } from 'react-toast-notifications';
import Progress from './components/progress/Progress';
import AppLoader from './components/AppLoader';
import { ModalProvider } from './contexts/ModalContext';
import Toaster from './components/Toaster';

function loadFacebookSDK() {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: config.social.FB,
      xfbml: true,
      version: 'v7.0',
    });
    window.FB.AppEvents.logPageView();
  };

  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
}

function App(props) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      console.log("User is signed in.");
    } catch (e) {
      if (e !== 'No current user') {
        console.log(e);
      }
    }

    setIsAuthenticating(false);
    loadFacebookSDK();
  }

  return (
    !isAuthenticating && (
      <ConfigProvider avatarRedirectUrl={config.avatar.AVATAR_REDIRECT_URL}>
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <AuthContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
              <AppContextProvider>
                <ToastProvider
                  autoDismiss
                  autoDismissTimeout={5000}
                  components={{ Toast: Toaster }}
                  placement="bottom-center"
                >
                  <Reset />
                  <Routes />
                  `<Progress />
                </ToastProvider>
                <AppLoader />
              </AppContextProvider>
            </AuthContext.Provider>
          </ModalProvider>
        </ThemeProvider>
      </ConfigProvider>
    )
  );
}

export default App;
