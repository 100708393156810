import { useToasts } from 'react-toast-notifications';

function useAppToasts() {
  const { addToast } = useToasts();

  return {
    successToast: (msg) => addToast(msg, { appearance: 'success' }),
    errorToast: (msg) => addToast(msg, { appearance: 'error' }),
    warningToast: (msg) => addToast(msg, { appearance: 'warning' }),
    infoToast: (msg) => addToast(msg, { appearance: 'info' }),
  };
}

export default useAppToasts;
