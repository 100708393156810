import { useMutation } from "react-query";

export const useCreateMutation = (func, options) => {
  const [mutate, { status, data, error, reset }] = useMutation(func, options);

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
}
