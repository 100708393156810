import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import config from '../../../utils/config';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';

// Hooks
import { useUnlockMarket } from '../hooks/useUnlockMarket';

// Context
import { useAppContext } from '../../../contexts/AppContext';

// Components
import ModalForm from '../../../components/modals/ModalForm';
import { useMarket } from '../hooks/useMarket';
import useModal from '../../../hooks/useModal';
import useAppToasts from '../../../hooks/useToasts';
import UnlockMarketPaymentForm from '../../../components/WelcomeForms/UnlockMarketPaymentForm';
import MarketUnlockSuccessForm from '../../../components/WelcomeForms/MarketUnlockSuccessForm';
import { useGetBillingDetail } from '../../../hooks/apiHooks/billingApiHook';

const CARD_STEP = 'CARD_STEP';
const SUCCESS_STEP = 'SUCCESS_STEP';

const FORM_INIT_DATA = {
  card_name: '',
  use_existing_card: false,
  subscription_type: 'monthly'
};

const Form = ({ from, onHide, isVisible, skipInfo = false, ...props }) => {
  const { errorToast } = useAppToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(CARD_STEP);
  const {
    jootAccount,
    currentMarket,
    markets: { invalidateQuery }
  } = useAppContext();
  const {
    promoCode,
    invalidateQuery: invalidateBilling
  } = useGetBillingDetail();
  const [form, setForm] = useState({ ...FORM_INIT_DATA });
  const [hideCancel, setHideCancel] = useState();
  //const { isVisible, toggle } = useModal(true);
  const { push } = useHistory();
  useEffect(() => {
    if (!isVisible) {
      setStep(CARD_STEP);
      setForm({ ...FORM_INIT_DATA });
    }
  }, [isVisible]);
  const handleChange = useCallback(({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  }, []);
  const onHideForm = useCallback(() => {
    // TODO: depending on if the form was canceled or closed, return to Market Insights or to the page clicked.
    onHide();
    //toggle();
    //push(from);
  },[onHide, /*toggle*/, push]);
  const { unlockMarket } = useUnlockMarket();
  const { save, loading } = useMarket();
//  const handleCardUpdate = useCallback(() => invalidateQuery(), [invalidateQuery]);
  const handleOk = useCallback(async () => {
    if (step === SUCCESS_STEP) {
      onHideForm();
    } else { // CARD_STEP
      if (form.use_existing_card || form.card_name ) {
        setIsLoading(true);
        try {
          await unlockMarket(jootAccount.account_name, currentMarket.market_name, form); // unlock the current market
          invalidateQuery();
          invalidateBilling();
        } catch (e) {
          console.log(e);
          errorToast('There was a problem upgrading the account. Contact Joot Support.');
          setIsLoading(false);
          //onHideForm();
          return;
        }
        setIsLoading(false);
        setStep(SUCCESS_STEP);
      }
    }
  }, [form, unlockMarket, save, onHideForm, jootAccount, promoCode, invalidateQuery, invalidateBilling, errorToast]);
  const handleCardChange = useCallback((value) => handleChange({ target: { name: 'card_name', value } }),
  [ handleChange ]);
  const handleUseExistingCardChange = useCallback((value) => {
    console.log("use_existing_card: " + value);
    handleChange({ target: { name: 'use_existing_card', value } })
  }, [ handleChange ]);
  const buttonText = useMemo(() => {
    if (step === CARD_STEP) {
      return 'Unlock...';
    }
    setHideCancel(true);
    return 'Close';
  }, [step, skipInfo]);
  const handleForm = useCallback((form) => setForm(form), []);

  return (
    <ModalForm
      {...props}
      onHide={onHideForm}
      isVisible={isVisible}
      okText={buttonText}
      onOk={handleOk}
      title="Upgrade this Market"
      isLoading={isLoading}
      hideCancel={hideCancel}
      hideBack={true}
      maxWidth="800px"
    >
      <>
        {step === CARD_STEP && <UnlockMarketPaymentForm 
          onCardChange={handleCardChange}
          onUseExistingCardChange={handleUseExistingCardChange}
          cardName={form.card_name}
          subscriptionType={form.subscription_type}
          market={currentMarket}
          />}
        {step === SUCCESS_STEP && <MarketUnlockSuccessForm />}
      </>
    </ModalForm>
  );
};

const MarketUpgrade= ({ from, show, onHide, ...props }) => {
  const stripePromise = loadStripe(config.stripe.key);
  return (
    <Elements stripe={stripePromise}>
      <Form from={from} show={show} onHide={onHide} {...props} />
    </Elements>
  );
};

export default memo(MarketUpgrade);

