import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

// Assets
import { ReactComponent as ArrowLong } from '../../assets/svg/arrow-long.svg';

// Hooks
import { useForm } from 'react-hook-form';
import useFormTouched from '../../hooks/useFormTouched';

// Context
import { useAuthContext } from '../../contexts/AuthContext';
import { useAppContext } from '../../contexts/AppContext';

// Components
import { FormControl, PasswordInput, ErrorMessage } from '../../components/form';
import Button from '../../components/Button';
import LoginLabel from '../../features/auth/components/LoginLabel';
import LoginInput from '../../features/auth/components/LoginInput';
import ProxyUserForm from './ProxyUser'
import { getProxyUser } from '../../services/userApiServices';
import FunnelUserForm from './FunnelUser';
import Title from '../../components/Title';


// Styles
const StyledLoginForm = styled.div`
  display: block;
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
const ForgotButton = styled(Button)`
  position: relative;
  font-size: 13px;
  color: ${({ theme }) => theme.joot.carolinaBlue};
`;
const CreateAccount = styled(Button)`
  position: relative;
  font-size: 13px;
  color: ${({ theme }) => theme.joot.carolinaBlue};
`;
const AuthTitle = styled.div`
  display: flex;
  flex-flow: column;
  align-items: baseline;
  justify-content: space-between;
  padding: 20px 0 40px 0px;
`;
const Text = styled(Title)`
  margin: 0 0 0 5px;
  font-size: 13px;
  color: ${({ theme }) => theme.joot.lightGrayAlt};
`;
const SignInText = styled(Text)`
  margin: 4px 0px 0px 0px;
  color: ${({ theme }) => theme.joot.jootBlack};
`;
const RowFlow = styled.div`
  display: flex;
  flex-flow: row;
`;
const FormRow = styled.div`
  position: relative;
  margin-top: -15px;
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

export default function LoginForm({ onForgotPassword, onCreateAccount }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { userHasAuthenticated } = useAuthContext();
  const { changeAppState } = useAppContext();
  const { push } = useHistory();
  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const { isValid } = formState;
  const [touched, handleBlur] = useFormTouched({
    email: false,
    password: false,
  });
  const [proxyUser, setProxyUser] = useState()
  const [funnelUser, setFunnelUser] = useState()

  async function onSubmit(data) {
    setIsLoading(true);
    changeAppState('animateProgress', true);
    setError('');
    try {
      const currentUser = await Auth.signIn(data.email ? data.email.toLowerCase() : data.email, data.password);
      if (currentUser.challengeName === 'NEW_PASSWORD_REQUIRED' || currentUser.challengeName === 'RESET_REQUIRED') {
        setFunnelUser( { user: currentUser, userEmail: data.email } );
      } else {
        try {
          const proxyData = await getProxyUser()
          setProxyUser({ ...proxyData, ...{ currentEmail: data.email } })
        } catch (e) { // The user isn't admin
          userHasAuthenticated(true);
        }
      }
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
      return
    }
  }

  const forgotPasswordLinkHandler = useCallback(() => {
    push('/login/forgot-password');
  }, [push]);

  const createAccountLinkHandler = useCallback(() => {
    push('/signup/create');
  }, [push]);

  useEffect(() => {
    changeAppState('animateProgress', false);
    // eslint-disable-next-line
  }, []);

  const handleUserProxy = useCallback(async ({ email }) => {
    userHasAuthenticated(true);
  }, [userHasAuthenticated])

  const handleFunnelUser = useCallback(async () => {
    console.log("handlefunnelUser");
    userHasAuthenticated(true);
  }, [userHasAuthenticated])

  return (
    <StyledLoginForm>
      {error && <ErrorMessage dark>{error}</ErrorMessage>}
      {funnelUser ? (
        <FunnelUserForm data={funnelUser} onChange={handleFunnelUser} />
      ) : (proxyUser ? (
        <ProxyUserForm data={proxyUser} onChange={handleUserProxy} />
      ) : (
        <>
          <AuthTitle>
            <Title h3 noMargin>
              Sign in
            </Title>
          </AuthTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl controlid="email">
              <LoginLabel htmlFor="email">Email</LoginLabel>
              <LoginInput
                name="email"
                id="email"
                placeholder="Email..."
                type="email"
                autoFocus
                ref={register}
                hasError={touched.email && errors.email}
                onBlur={handleBlur}
              />
            </FormControl>
            <FormControl controlid="password">
              <LoginLabel htmlFor="password">Password</LoginLabel>
              <PasswordInput
                name="password"
                id="password"
                placeholder="Password..."
                ref={register}
                hasError={touched.password && errors.password}
                autoComplete="new-password"
                onBlur={handleBlur}
                input={LoginInput}
              />
              <Actions>
                <ForgotButton black type="button" onClick={onForgotPassword}>
                  Forgot Password?
                </ForgotButton>
              </Actions>
            </FormControl>
            <FormControl>
              <Button primary block disabled={!isValid || isLoading} type="submit">
                {isLoading ? 'Signing in...' : 'Sign In'}
                <ArrowLong />
              </Button>
              <Actions>
                <SignInText h6>New to Joot?&nbsp;&nbsp;</SignInText>
                <a href="https://getjoot.com/pricing/" target="_blank" rel="noopener noreferrer">Create an Account</a>
                <CreateAccount black type="button" onClick={onCreateAccount}>
                </CreateAccount>
              </Actions>
            </FormControl>
          </form>
        </>
      ))}
    </StyledLoginForm>
  );
}
