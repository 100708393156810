import React from 'react';
import styled from 'styled-components';

// Components
import AuthIntro from './AuthIntro';

// Styles
const StyledLoginLayout = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 7vw;
  padding: 6vw 13vw;
  align-items: center;
`;

const LoginLayout = ({ children }) => {
  return (
    <StyledLoginLayout>
      <AuthIntro />
      {children}
    </StyledLoginLayout>
  );
};

export default LoginLayout;
