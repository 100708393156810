import React from 'react';
import styled from 'styled-components';

// Styles
const Pallete = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  border-radius: 15px;
  overflow: hidden;
  width: ${(props) => props.width};
  > div {
    height: ${(props) => props.height};
  }
`;
const Color = styled.div`
  background: ${({ color }) => color};
`;
const Middle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const PalleteGrid = ({
  color: { foreground, background, subject_color_primary, subject_color_secondary },
  onClick,
  ...props
}) => {
  return (
    <Pallete onClick={onClick} {...props}>
      <Color color={foreground} />
      <Middle>
        <Color color={subject_color_primary} />
        <Color color={subject_color_secondary} />
      </Middle>
      <Color color={background} />
    </Pallete>
  );
};

PalleteGrid.defaultProps = {
  width: '100px',
  height: '28px',
};

export default PalleteGrid;
