import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useAppContext } from '../../../contexts/AppContext';
import { DEFAULT_QUERY_OPTIONS } from '../../../hooks/apiHooks/api-hooks-utils';
import { getImagesInsights, getImagesInsightsAll, getMarketInsights } from '../../../services/insightsApiServices';
import { useRetryMarket } from '../../../hooks/apiHooks/instagramQueryHooks';

export const sortOptions = [
  { value: 'engagement', name: 'Engagement' },
  { value: 'avg_likes', name: 'Likes' },
  { value: 'avg_comments', name: 'Comments' },
];
export const sortPostOptions = [
  { value: 'engagement', name: 'Engagement' },
  { value: 'like_count', name: 'Likes' },
  { value: 'comments_count', name: 'Comments' },
];
const defaultSort = { value: 1, name: 'engagement' };
const defaultSortPost = { value: 'engagement', name: 'Engagement' };

export const useMarketInsights = () => {
  const { accountName } = useAppContext();
  const { market, isReady } = useRetryMarket('marketInsights');
  const query = ['market_insights', accountName, market?.market_name];
  const { data = {}, ...state } = useQuery(query, async () => getMarketInsights(accountName, market?.market_name, market?.market_system_type), {
    enabled: accountName && market?.market_name,
    ...DEFAULT_QUERY_OPTIONS,
  });
  const [sortBy, setSortBy] = useState(defaultSort);
  const [sortPostBy, setSortPostBy] = useState(defaultSortPost);
  const [showGiveaways, setShowGiveaways] = useState(true);
  const competitors = useMemo(() => {
    if (!data.competitors) {
      return [];
    }
    const result = data.competitors.sort((a, b) => {
      return a[sortBy.name] > b[sortBy.name] ? sortBy.value : sortBy.value * -1;
    });
    return result;
  }, [data, sortBy]);
  const preparePost = useCallback(
    (item) => {
      const map = {
        engagement: "top_posts",
        like_count: "top_posts_by_likes",
        comments_count: "top_posts_by_comments",
      }
      const post = item[map[sortPostBy.value]]
      // Don't show videos.
      return post
        .filter(({ image_url }) => image_url && !image_url.includes('.mp4'))
        .filter(({ is_giveaway }) => showGiveaways || (!showGiveaways && !is_giveaway))
        .sort((a, b) => (a[sortPostBy.value] > b[sortPostBy.value] ? -1 : 1))
        .splice(0, 10);
    },
    [sortPostBy, showGiveaways],
  );

  return {
    ...state,
    competitors,
    userData: data.userData,
    isReady,
    market,
    sortOptions,
    sortPostOptions,
    sortBy,
    sortPostBy,
    setSortBy,
    setSortPostBy,
    preparePost,
    showGiveaways,
    setShowGiveaways,
  };
};

export const useImagesInsights = (extraParameters = {}) => {
  const { accountName } = useAppContext();
  const { market, isReady } = useRetryMarket('imageInsights');
  const urlParameters = Object.entries(extraParameters).map(([key, value]) => `${key}=${value}`).join('&')
  const query = ['images_insights_data', accountName, market?.market_name, urlParameters];
  const { data = {}, ...state } = useQuery(query, async () => getImagesInsights(accountName, market?.market_name, urlParameters), {
    enabled: accountName && market?.market_name,
    ...DEFAULT_QUERY_OPTIONS,
  });

  return {
    data,
    isReady,
    ...state,
  };
};

export const useImagesInsightsAll = () => {
  const { accountName } = useAppContext();
  const { market, isReady } = useRetryMarket('imageInsights');
  const query = ['images_insights_data', accountName, market?.market_name, market?.market_system_type];
  const { data = {}, ...state } = useQuery(query, async () => getImagesInsightsAll(accountName, market?.market_name, market?.market_system_type), {
    enabled: accountName && market?.market_name,
    ...DEFAULT_QUERY_OPTIONS,
  });

  return {
    data,
    market,
    isReady,
    ...state,
  };
};
