import React from 'react';
import { MediaContextProvider } from '../features/media/contexts/MediaContext';

// Components
import MediaLayout from '../features/media/MediaLayout';
import MediaRoutes from '../features/media/routes';

export default function Media() {
  return (
    <MediaContextProvider>
      <MediaLayout>
        <MediaRoutes />
      </MediaLayout>
    </MediaContextProvider>
  );
}
