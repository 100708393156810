import React from 'react';
import styled from 'styled-components';

// Utils
import composeRefs from '../../utils/composeRefs';

// Components
import OptionsMenu from './OptionsMenu';
import DropdownIcon from '../../components/icons/DropdownIcon';
import InputAppearance from '../form/mixins/InputAppearance';

// Styles
const Toggle = styled.div`
  ${InputAppearance};
  display: flex;
  align-items: center;
  // padding: 5px 0;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.joot.blueGray};
`;
const Text = styled.span`
  font-size: 13px;
  font-weight: 700;
`;
const DropdownIconStyled = styled(DropdownIcon)`
  fill: ${({ theme }) => theme.joot.blueGray};
  margin-left: auto;
`;

const SortByMediaMenu = React.forwardRef(function MarketMenu(props, ref) {
  return (
    <OptionsMenu {...props} width={'234px'}>
      {({ triggerRef, toggle, selectedText }) => (
        <Toggle ref={composeRefs(triggerRef, ref)} onClick={toggle} style={props.style}>
          <Text>{selectedText}</Text>
          <DropdownIconStyled fill={'white'} />
        </Toggle>
      )}
    </OptionsMenu>
  );
});

export default SortByMediaMenu;
