import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

// Assets
import { ReactComponent as ArrowLong } from '../../assets/svg/arrow-long.svg';

// Hooks
import { useForm } from 'react-hook-form';
import useFormTouched from '../../hooks/useFormTouched';

// Context
import { useAuthContext } from '../../contexts/AuthContext';
import { useAppContext } from '../../contexts/AppContext';

// Components
import { FormControl, PasswordInput, ErrorMessage } from '../../components/form';
import Button from '../../components/Button';
import LoginLabel from './components/LoginLabel';
import LoginInput from './components/LoginInput';
import ProxyUserForm from './ProxyUser'
import { getProxyUser } from '../../services/userApiServices';
import FunnelUserForm from './FunnelUser';


// Styles
const StyledLoginForm = styled.div`
  display: block;
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
const ForgotButton = styled(Button)`
  position: relative;
  font-size: 12px;
`;
const CreateAccount = styled(Button)`
  position: relative;
  font-size: 12px;
`;
const Slash = styled.span`
  opacity: 0.1;
  margin: 0 10px;
  color: ${({ theme }) => theme.joot.jootWhite};
`;

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

export default function LoginForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { userHasAuthenticated } = useAuthContext();
  const { changeAppState } = useAppContext();
  const { push } = useHistory();
  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const { isValid } = formState;
  const [touched, handleBlur] = useFormTouched({
    email: false,
    password: false,
  });
  const [proxyUser, setProxyUser] = useState()
  const [funnelUser, setFunnelUser] = useState()

  async function onSubmit(data) {
    setIsLoading(true);
    changeAppState('animateProgress', true);
    setError('');
    try {
      const currentUser = await Auth.signIn(data.email ? data.email.toLowerCase() : data.email, data.password);
      if (currentUser.challengeName === 'NEW_PASSWORD_REQUIRED' || currentUser.challengeName === 'RESET_REQUIRED') {
        setFunnelUser( { user: currentUser, userEmail: data.email } );
      } else {
        try {
          const proxyData = await getProxyUser()
          setProxyUser({ ...proxyData, ...{ currentEmail: data.email } })
        } catch (e) { // The user isn't admin
          userHasAuthenticated(true);
        }
      }
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
      return
    }
  }

  const forgotPasswordLinkHandler = useCallback(() => {
    push('/login/forgot-password');
  }, [push]);

  const createAccountLinkHandler = useCallback(() => {
    push('/signup/create');
  }, [push]);

  useEffect(() => {
    changeAppState('animateProgress', false);
    // eslint-disable-next-line
  }, []);

  const handleUserProxy = useCallback(async ({ email }) => {
    userHasAuthenticated(true);
  }, [userHasAuthenticated])

  const handleFunnelUser = useCallback(async () => {
    console.log("handlefunnelUser");
    userHasAuthenticated(true);
  }, [userHasAuthenticated])

  return (
    <StyledLoginForm>
      {error && <ErrorMessage dark>{error}</ErrorMessage>}
      {funnelUser ? (
        <FunnelUserForm data={funnelUser} onChange={handleFunnelUser} />
      ) : (proxyUser ? (
        <ProxyUserForm data={proxyUser} onChange={handleUserProxy} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl controlid="email">
            <LoginLabel htmlFor="email">Email</LoginLabel>
            <LoginInput
              name="email"
              id="email"
              placeholder="Email..."
              type="email"
              autoFocus
              ref={register}
              hasError={touched.email && errors.email}
              onBlur={handleBlur}
            />
          </FormControl>
          <FormControl controlid="password">
            <LoginLabel htmlFor="password">Password</LoginLabel>
            <PasswordInput
              name="password"
              id="password"
              placeholder="Password..."
              ref={register}
              hasError={touched.password && errors.password}
              autoComplete="new-password"
              onBlur={handleBlur}
              input={LoginInput}
            />
            <Actions>
              <ForgotButton white type="button" onClick={forgotPasswordLinkHandler}>
                Forgot Password
              </ForgotButton>
              <Slash>/</Slash>
              <CreateAccount white type="button" onClick={createAccountLinkHandler}>
                Create Account
              </CreateAccount>
            </Actions>
          </FormControl>
          <FormControl>
            <Button primary block disabled={!isValid || isLoading} type="submit">
              {isLoading ? 'Logging in...' : 'Login'}
              <ArrowLong />
            </Button>
          </FormControl>
        </form>
      ))}
    </StyledLoginForm>
  );
}
