export const STRINGS = {
  errorMessages: {
    requestGeneral: 'Request general error',
    UserNotFoundException: 'User not found',
    InternalErrorException: 'Internal error',
    CodeMismatchException: 'Invalid confirmation code',
    ExpiredCodeException: 'The code has expired',
    invalidEmails: 'Invalid email list',
    notAvailableIgAccounts: 'No professional Instagram account has been connected.',
    notAddedCompetitorAccounts: 'Cannot be added, does not exist, or is not a professional account',
    notCompetitorFound: 'Cannot be added, does not exist or is not a professional account.',
  },
  successMessages: {
    forgotPassword: 'Password successfully changed!',
    successOnboarding: 'Our A.I. is hard at work analyzing your images',
    inviteCreated: 'Invitation successfully created',
    inviteDeleted: 'Invitation successfully deleted',
    imageDeleted: 'Item successfully deleted',
    imageStar: 'Item successfully starred',
    tagsAdded: 'Tags added!',
    tagsRemoved: 'Tags removed!',
    integrationRequestEmail: 'The message was sent.',
    imageAvatarWasUploaded: 'The avatar image was uploaded.',
  },
  labels: {
    emptyInvites: 'No invite data',
  },
};
