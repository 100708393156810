import React, { memo, useCallback, useState, useMemo } from 'react';
import { FormControl, Input, Checkbox } from './form';
import StripeCard from './StripeCard';

const StripeCardForm = ({ value, onCardChange }) => {
  const [nameError, setNameError] = useState('');
  const handleCardChange = useCallback(({ target: { value } }) => {
    onCardChange(value)
    setNameError(value ? '' : 'Required');
  }, [onCardChange]);

  return (
    <>
      <FormControl>
        <Input
          type="text"
          name="name_card"
          value={value}
          onChange={handleCardChange}
          placeholder="Name on card..."
        />
        {nameError && <FormControl.Error>{nameError}</FormControl.Error>}
      </FormControl>
      <FormControl>
        <FormControl.Label htmlFor="name">Card Details</FormControl.Label>
        <StripeCard />
      </FormControl>
    </>
  );
};

export default memo(StripeCardForm);
