import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

// Hooks
import { useForm } from 'react-hook-form';
import useFormTouched from '../../hooks/useFormTouched';
import useAppToasts from '../../hooks/useToasts';
import { useForgotPassword, useForgotPasswordConfirm } from '../../hooks/apiHooks/authApiHooks';

// Strings
import { STRINGS } from '../../utils/strings';

// Assets
import { ReactComponent as ArrowLong } from '../../assets/svg/arrow-long.svg';

// Components
import { FormControl, PasswordInput } from '../../components/form';
import Button from '../../components/Button';
import Title from '../../components/Title';
import LoginLabel from '../../features/auth/components/LoginLabel';
import LoginInput from '../../features/auth/components/LoginInput';

// Styles
const StyledLoginForgot = styled.div`
  display: block;
`;
const LoginTitle = styled(Title)`
  margin-bottom: 3vw;
`;
export const InfoLabel = styled.div`
  font-size: 14px;
  opacity: 0.8;
  padding-top: ${({ danger }) => (danger ? '0' : '10px')};
  padding-bottom: ${({ danger }) => (danger ? '10px' : '0')};
  color: ${({ danger, theme }) => danger && theme.color.error};
  > a {
    font-weight: 700;
    color: ${({ theme }) => theme.joot.jootWhite};
  }
`;

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required')
});

export default function LoginForgot( { onPasswordChanged }) {
  const { errorToast } = useAppToasts();
  const [wasSuccess, setWasSuccess] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const { register, handleSubmit, errors, formState, getValues, reset: resetForm } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const { register: register2, handleSubmit: handleSubmit2, errors: errors2, formState: formState2, getValues: getValues2, reset: resetForm2 } = useForm({
    mode: 'onSubmit'
  });
  const [touched, handleBlur] = useFormTouched({
    email: false,
    password: false,
  });
  const { isValid } = formState;
  const { isValid: isValid2 } = formState2;
  const {
    submit: submitForgotPassword,
    isLoading: isLoadingForgotPassword,
    isSuccess: isSuccessForgotPassword,
    reset: resetForgotPasswordHook,
  } = useForgotPassword(getValues('email'));

  const {
    submit: submitConfirm,
    isLoading: isLoadingConfirm,
    isSuccess: isSuccessConfirm,
    reset: resetConfirmHook,
    error: errorConfirm,
  } = useForgotPasswordConfirm({ username: userEmail, code: getValues2('code'), password: getValues2('password') });

  async function onForgotPasswordSubmit() {
    try {
      setUserEmail(getValues('email'));
      await submitForgotPassword();
    } catch (error) {
      if (STRINGS.errorMessages[error.code]) {
        errorToast(STRINGS.errorMessages[error.code]);
        return;
      }
      errorToast(STRINGS.errorMessages.requestGeneral);
    }
  }

  function onForgotPasswordConfirmSubmit() {
    errorToast("passwordconfirmsubmit");
    submitConfirm().catch((error) => {
      if (STRINGS.errorMessages[error.code]) {
      console.log("errorMessage.errorcode");
        errorToast(STRINGS.errorMessages[error.code]);
        return;
      }
      if (error.code === 'InvalidPasswordException') {
      console.log("errorMessage.invalidpassword");
        errorToast(error.message);
        return;
      }
      console.log("errorMessage.requestGeneral");
      errorToast(STRINGS.errorMessages.requestGeneral);
    });
    errorToast("no error");
  }

  useEffect(() => {
    if (isSuccessForgotPassword) {
      setWasSuccess(true);
    }
  }, [isSuccessForgotPassword, formState]);

  const resetFlow = useCallback(() => {
    resetForm();
    resetForm2();
    resetConfirmHook();
    resetForgotPasswordHook();
    setWasSuccess(false);
    setUserEmail('');
  }, [resetConfirmHook, resetForgotPasswordHook, resetForm, resetForm2]);

  return (
    <StyledLoginForgot>
      {!wasSuccess && !isSuccessConfirm && (
        <form onSubmit={handleSubmit(onForgotPasswordSubmit)}>
          <FormControl controlid="email">
            <LoginLabel htmlFor="forgotPassword">Forgot Password Email</LoginLabel>
            <LoginInput
              name="email"
              id="email"
              placeholder="Email..."
              type="email"
              autoFocus
              ref={register({ required: 'Email is required' })}
              hasError={touched.email && errors.email}
              onBlur={handleBlur}
            />
          </FormControl>
          <FormControl>
            <Button primary block disabled={!isValid || isLoadingForgotPassword} type="submit">
              {isLoadingForgotPassword ? 'Sending...' : 'Send Instructions'}
              <ArrowLong />
            </Button>
          </FormControl>
        </form>
      )}
      {wasSuccess && !isSuccessConfirm && (
        <form onSubmit={handleSubmit2(onForgotPasswordConfirmSubmit)}>
          <FormControl controlid="code">
            <LoginLabel htmlFor="forgotPassword">Verification Code</LoginLabel>
            <LoginInput
              name="code"
              id="code"
              placeholder="Code..."
              type="text"
              autoFocus
              ref={register2({ required: 'Code is required' })}
              hasError={errors2.code}
            />
          </FormControl>
          <FormControl controlid="password">
            <LoginLabel htmlFor="password">New Password</LoginLabel>
            <PasswordInput
              name="password"
              id="password"
              placeholder="Password..."
              ref={register2({
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters long.',
                },
              })}
              hasError={errors2.password}
              input={LoginInput}
            />
          </FormControl>
          {errorConfirm && (
            <InfoLabel danger>
              Code is invalid or has expired{' '}
              <Link to="/login/forgot-password" onClick={resetFlow}>
                Retry
              </Link>
            </InfoLabel>
          )}
          <FormControl>
            <Button primary block disabled={/*!isValid2 ||*/ isLoadingConfirm} type="submit">
              {isLoadingConfirm ? 'Sending...' : 'Done'}
              <ArrowLong />
            </Button>
          </FormControl>
        </form>
      )}
      {isSuccessConfirm && (
        <>
          <LoginTitle h3>
            {STRINGS.successMessages.forgotPassword}
          </LoginTitle>
          <Button primary block onClick={onPasswordChanged}>
            Sign In
            <ArrowLong />
          </Button>
        </>
      )}
    </StyledLoginForgot>
  );
}
