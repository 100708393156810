import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import config from '../../../utils/config';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';

// Hooks
import { useUpgradeMarket } from '../hooks/useUpgradeMarket';

// Context
import { useAppContext } from '../../../contexts/AppContext';

// Components
import ModalForm from '../../../components/modals/ModalForm';
import { useMarket } from '../hooks/useMarket';
import useModal from '../../../hooks/useModal';
import useAppToasts from '../../../hooks/useToasts';
import PaymentForm from '../../../components/WelcomeForms/PaymentForm';
import SubscriptionPlan from '../../../components/WelcomeForms/SubscriptionPlan';
import SuccessForm from '../../../components/WelcomeForms/SuccessForm';
import MarketForm from '../../../components/marketForm';
import { useGetBillingDetail } from '../../../hooks/apiHooks/billingApiHook';

const MARKET_STEP = 'MARKET_STEP';
const CARD_STEP = 'CARD_STEP';
const PLAN_STEP = 'PLAN_STEP';
const SUCCESS_STEP = 'SUCCESS_STEP';

const POSTS_LIMIT = 3000;

const FORM_INIT_DATA = {
  card_name: '',
  use_existing_card: false,
  plan_type: 'professional'

};

const calculateTotalPost = (competitors) =>
  Object.entries(competitors)
    .map(([_, item]) => item)
    .filter((item) => item)
    .reduce((act, item) => act + item.media_count, 0);

const Form = ({ from, onHide, isVisible, skipInfo = false, ...props }) => {
  const { errorToast } = useAppToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(MARKET_STEP);
  const {
    jootAccount,
  } = useAppContext();
  const {
    promoCode,
    invalidateQuery
  } = useGetBillingDetail();
  const [form, setForm] = useState({ ...FORM_INIT_DATA });
  const [hideCancel, setHideCancel] = useState();
  const [hideBack, setHideBack] = useState();
  //const { isVisible, toggle } = useModal(true);
  const { push } = useHistory();
  useEffect(() => {
    if (!isVisible) {
      setStep(MARKET_STEP);
      setForm({ ...FORM_INIT_DATA });
      form.plan_type = jootAccount?.account_plan_type === "professional" ? "professional_additional" : (jootAccount?.account_plan_type == "business" ? "business_additional" : "professional");
    }
  }, [isVisible, jootAccount]);
  const handleChange = useCallback(({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  }, []);

  const onHideForm = useCallback(() => {
    // TODO: depending on if the form was canceled or closed, return to Market Insights or to the page clicked.
    onHide();
    //toggle();
    push('/scored-media');
  },[onHide, /*toggle*/, push]);
  const onBackForm = useCallback(() => {
    if (step === CARD_STEP) {
      setStep(PLAN_STEP);
  }},[]);
  const { upgrade } = useUpgradeMarket();
  const { save, loading } = useMarket();
//  const handleCardUpdate = useCallback(() => invalidateQuery(), [invalidateQuery]);
  const handleOk = useCallback(async () => {
    let doUpgrade = false;
    if (step === SUCCESS_STEP) {
      onHideForm();
    } else if (step === MARKET_STEP) {
      if (!form || form.errors.length !== 0) {
        console.log(!form ? "empty form" : "form has errors");
        return;
      }
      try {
        setIsLoading(true);
        await save(jootAccount.account_name, form.market, form.competitors, form.primaryAccount);
      } catch (e) {
        errorToast(e.message);
      }
      setIsLoading(false);
      if(!jootAccount.account_plan_type || jootAccount.account_plan_type === 'free' || jootAccount.account_plan_type === 'professional')
        setStep(PLAN_STEP);
      else if (jootAccount.market_credit_count > 0)
        doUpgrade = true;
      else {
        if(jootAccount.account_plan_type) {
          if (jootAccount.account_plan_type === 'business')
            form.plan_type = "business_additional";
          else // professional
            form.plan_type = "professional_additional";
        }
        setStep(CARD_STEP);
      }
    } else if (step === PLAN_STEP) {
      setStep(CARD_STEP);
    } else { // CARD_STEP
      doUpgrade = true;
    }

    if (doUpgrade) {
      if (form.use_existing_card || form.card_name || (promoCode && promoCode === 'JOOTJUICE2021')) {
        setIsLoading(true);
        try {
          await upgrade(jootAccount.account_name, form.market, form, (promoCode && promoCode === 'JOOTJUICE2021')); // upgrade to pro and create a market credit
          invalidateQuery();
        } catch (e) {
          console.log(e);
          errorToast('There was a problem upgrading the account. Contact Joot Support.');
          setIsLoading(false);
          //onHideForm();
          return;
        }
        setIsLoading(false);
        setStep(SUCCESS_STEP);
      }
    }
  }, [form, upgrade, save, onHideForm, jootAccount, promoCode, invalidateQuery, errorToast]);
  const handlePlanChange = useCallback((value) => handleChange({ target: { name: 'plan_type', value } }),
  [ handleChange ]);
  const handleCardChange = useCallback((value) => handleChange({ target: { name: 'card_name', value } }),
  [ handleChange ]);
  const handleUseExistingCardChange = useCallback((value) => {
    handleChange({ target: { name: 'use_existing_card', value } })
  }, [ handleChange ]);
  const buttonText = useMemo(() => {
    if (step === MARKET_STEP || step == PLAN_STEP) {
      setHideBack(true);
      setHideCancel(false);
      return 'Continue';
    }
    if (step === CARD_STEP) {
      if(!jootAccount.account_plan_type || jootAccount.account_plan_type === 'free')
        setHideBack(false);
      setHideCancel(false);
      return 'Purchase';
    }
    setHideCancel(true);
    setHideBack(true);
    return 'Finish';
  }, [step, skipInfo]);
  const handleForm = useCallback((newForm) => setForm(Object.assign(form, newForm)), [form]);

  return (
    <ModalForm
      {...props}
      onHide={onHideForm}
      onBack={onBackForm}
      isVisible={isVisible}
      okText={buttonText}
      onOk={handleOk}
      title="Upgrade this Market"
      isLoading={isLoading}
      hideCancel={hideCancel}
      hideBack={hideBack}
      maxWidth="800px"
    >
      <>
        {step === MARKET_STEP && <MarketForm 
          onChange={handleForm}
          jootAccount={jootAccount} />}
        {step === PLAN_STEP && <SubscriptionPlan
          onPlanChange={handlePlanChange}
          value={form.plan_type || 'professional'}
          hideFree={true}/>}
        {step === CARD_STEP && <PaymentForm 
          onCardChange={handleCardChange}
          onUseExistingCardChange={handleUseExistingCardChange}
          cardName={form.card_name}
          planType={form.plan_type}/>}
        {step === SUCCESS_STEP && <SuccessForm 
          title="Awesomeness is brewing"
          header="We're building a customized AI model for your market."
          message="This can take a few hours as we process all of your market's content. Relax. We'll send you an email when it's ready." />}
      </>
    </ModalForm>
  );
};

const MarketUpgrade= ({ from, show, onHide, ...props }) => {
  const stripePromise = loadStripe(config.stripe.key);
  return (
    <Elements stripe={stripePromise}>
      <Form from={from} show={show} onHide={onHide} {...props} />
    </Elements>
  );
};

export default memo(MarketUpgrade);

