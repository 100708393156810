import { useMemo } from "react";
import { useQuery } from "react-query";
import { useAppContext } from "../../../contexts/AppContext";
import { DEFAULT_QUERY_OPTIONS } from "../../../hooks/apiHooks/api-hooks-utils";
import { getImages } from "../../../services";

export const useSimilarImages = (tagsToCheck, count = 3) => {
  const { accountName, marketName, marketType, libraryName } = useAppContext();
  const query = useMemo(() => ['media_list', accountName, marketName], [accountName, marketName]);
  const { data = [] } = useQuery(query, async () => getImages(accountName, marketName, marketType, libraryName), {
    enabled: accountName,
    ...DEFAULT_QUERY_OPTIONS,
  });
  const similars = useMemo(() => {
    const items = []
    for (let i = 0; i < data.length; i++) {
      const current = data[i]
      if (!current.ai_detected_tags)
        continue;
      const tags = (current.ai_detected_tags.concat(current.tags || []));
      const filteredArray = tagsToCheck.filter(value => tags.includes(value));
      //if (filteredArray.length >= tags.length * 0.70 ) {
      if (filteredArray.length >= tagsToCheck.length * 0.50 ) {
        //current.similar_score = filteredArray.length / tags.length;
        current.similar_score = filteredArray.length / tagsToCheck.length;
        items.push(current)
      }
    }
    const ret_items = items.sort((a, b) => {
      if (a.similar_score > b.similar_score) return -1;
      if (a.similar_score < b.similar_score) return 1;
      return 0;
    }).splice(0, count);
    return ret_items;
  }, [tagsToCheck, data, count])

  return similars
}
