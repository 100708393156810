import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import ResizeObserver from 'resize-observer-polyfill';
import composeRefs from '../../utils/composeRefs';
import PropTypes from 'prop-types';

// Hooks
import useMenu from '../../hooks/useMenu';

// Components
import DropdownIcon from '../icons/DropdownIcon';
import Menu, { MenuItem } from './Menu';
import { ToggleLayer } from 'react-laag';
import AvatarMenu, { TextMenuItem, SpinnerIconContainer } from './AvatarMenu';
import SpinnerIcon from '../icons/SpinnerIcon';

// Styles
const Toggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Text = styled.span`
  color: ${({ theme }) => theme.joot.jootBlack};
  font-size: 13px;
  font-weight: 700;
  // margin-left: auto;
`;
const DropdownIconStyled = styled(DropdownIcon)`
  fill: ${({ theme }) => theme.joot.paleBlue};
  margin-left: 10px;
`;

const MenuItemStyled = styled(MenuItem)`
  padding-left: 40px;
`;

// Values
const PLACEMENT = {
  anchor: 'BOTTOM_LEFT',
  snapToAnchor: false,
  triggerOffset: 5,
  scrollOffset: 16,
  preferX: 'RIGHT',
};

const OptionsMenu = React.forwardRef(function OptionsMenu(props, ref) {
  const {
    items: options,
    title,
    children,
    initialSelection,
    onSelectItemMenu,
    offsetY,
    hideCheck,
    onOutsideClick,
    onClose,
    MenuItemCmp,
    isLoading,
    emptyText,
    loadingText,
    onChange,
    onEdit,
    onSpecialOption,
    canEdit,
    name,
    value,
    placement,
    ...rest
  } = props;
  const labelField = 'name';
  const { selectedText, handleSelection, isSelected, selected } = useMenu({
    labelField,
    emptySelectionText: '',
    initialSelection: !initialSelection ? { value: 'mr', name: 'Most Recent' } : initialSelection,
    value,
  });
  const menuPlacememt = useMemo(() => {
    return placement || PLACEMENT;
  }, [placement]);
  const handleClick = useCallback(
    (item, props) => {
      return function onClick() {
        handleSelection(item);
        onChange && onChange({ target: { name, value: item } });
        const canClose = onSelectItemMenu && onSelectItemMenu(item);
        if (canClose !== false) {
          props.close();
        }
      };
    },
    [handleSelection, name, onChange, onSelectItemMenu],
  );
  const handleExtraOptionClick = useCallback(
    (props) => {
      return function onClick() {
        onSpecialOption && onSpecialOption();
        props.close();
      };
    },
    [onSpecialOption],
  );
  const handleEditItem = useCallback(
    (item, props) => {
      return function onClick() {
        onEdit && onEdit({ target: { name, value: item } });
        props.close();
      };
    },
    [handleSelection, name, onEdit, onSelectItemMenu],
  );

  return (
    <ToggleLayer
      onOutsideClick={() => onOutsideClick && onOutsideClick()}
      fixed={true}
      ResizeObserver={ResizeObserver}
      closeOnOutsideClick
      closeOnDisappear={true}
      onDisappear={() => onClose && onClose()}
      renderLayer={(props) => {
        return (
          <AnimatePresence>
            {props.isOpen && Array.isArray(options) && options.length > 0 ? (
              <Menu
                ref={props.layerProps.ref}
                style={props.layerProps.style}
                arrowStyle={props.arrowStyle}
                layerSide={props.layerSide}
                label={title}
                offsetY={offsetY}
                {...rest}
              >
                {!isLoading &&
                  Array.isArray(options) &&
                  options.map((item, i) => (
                    <React.Fragment key={i}>
                      {MenuItemCmp ? (
                        <MenuItemCmp
                          key={i}
                          onClick={handleClick(item, props)}
                          onEdit={handleEditItem(item, props)}
                          isSelected={isSelected(item)}
                          hideCheck={hideCheck}
                          style={item.style}
                          {...item}
                        />
                      ) : (
                        <MenuItemStyled
                          key={i}
                          onClick={handleClick(item, props)}
                          onEdit={handleEditItem(item, props)}
                          isSelected={isSelected(item)}
                          hideCheck={hideCheck}
                          style={item.style}
                          canEdit={canEdit}
                        >
                          {item[labelField]}
                        </MenuItemStyled>
                      )}
                    </React.Fragment>
                  ))}
                {!isLoading && (
                  /*
                  <TextMenuItem>
                    {emptyText}
                  </TextMenuItem>
                  */
                  <MenuItemStyled
                    onClick={handleExtraOptionClick(props)}
                    isSelected={false}
                    hideCheck={true}
                  >
                    {emptyText}
                  </MenuItemStyled>
                )}
                {!isLoading && (!Array.isArray(options) || options.length === 0) && (
                  <TextMenuItem>
                    {emptyText}
                  </TextMenuItem>
                )}
                {isLoading && (
                  <TextMenuItem>
                    <SpinnerIconContainer>
                      <SpinnerIcon /> {loadingText}
                    </SpinnerIconContainer>
                  </TextMenuItem>
                )}
              </Menu>
            ) : null}
          </AnimatePresence>
        );
      }}
      placement={menuPlacememt}
    >
      {({ isOpen, triggerRef, toggle }) => {
        return (
          <>
            {!children && (
              <Toggle ref={composeRefs(triggerRef, ref)} onClick={toggle} style={props.style}>
                <Text>{selectedText}</Text>
                <DropdownIconStyled />
              </Toggle>
            )}
            {children && children({ isOpen, triggerRef, toggle, selectedText, selected })}
          </>
        );
      }}
    </ToggleLayer>
  );
});

OptionsMenu.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  children: PropTypes.any,
  initialSelection: PropTypes.any,
  onSelectItemMenu: PropTypes.func,
  offsetY: PropTypes.string,
  hideCheck: PropTypes.bool,
  onOutsideClick: PropTypes.func,
  onClose: PropTypes.func,
  MenuItemCmp: PropTypes.any,
  isLoading: PropTypes.bool,
  emptyText: PropTypes.string,
  loadingText: PropTypes.string,
};

AvatarMenu.defaultProps = {
  selected: {},
  onSelect: () => null,
};

export default OptionsMenu;
