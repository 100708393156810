import * as React from 'react';

function EyeClosedIcon(props) {
  return (
    <svg fill="none" viewBox="0 0 21 17" {...props}>
      <path
        fill="#999FB0"
        fillRule="evenodd"
        d="M18.768.567a.625.625 0 01-.018.884l-15.624 15a.625.625 0 01-.866-.902l15.625-15a.625.625 0 01.883.018zm-2.441 4.965a.625.625 0 01.873-.137c.963.703 1.853 1.5 2.656 2.381l.002.003a2.01 2.01 0 010 2.692l-.002.002c-2.183 2.402-5.804 4.96-9.485 4.902a8.943 8.943 0 01-2.713-.402.625.625 0 11.371-1.194 7.698 7.698 0 002.34.346h.015c3.15.053 6.454-2.19 8.546-4.492a.76.76 0 000-1.016 16.517 16.517 0 00-2.466-2.212.625.625 0 01-.137-.873zm-5.952-2.657a8.582 8.582 0 012.16.253.625.625 0 11-.302 1.213 7.332 7.332 0 00-1.852-.216h-.018C7.27 4.073 3.955 6.266 1.82 8.615a.76.76 0 000 1.016c.639.7 1.337 1.345 2.086 1.926a.625.625 0 11-.767.987 17.96 17.96 0 01-2.244-2.072l-.003-.003a2.01 2.01 0 010-2.691l.002-.003c2.23-2.454 5.86-4.956 9.481-4.9z"
        clipRule="evenodd"
      />
      <path
        fill="#999FB0"
        fillRule="evenodd"
        d="M10.373 6.625a2.5 2.5 0 00-2.5 2.5.625.625 0 11-1.25 0 3.75 3.75 0 013.75-3.75.625.625 0 010 1.25zM13.498 8.5c.346 0 .626.279.626.624a3.75 3.75 0 01-3.75 3.751.625.625 0 110-1.25 2.502 2.502 0 002.5-2.5c0-.346.28-.626.624-.626z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default EyeClosedIcon;
