import React from 'react';

function SvgComponent({ active, ...props }) {
  return (
    <svg  height="18" viewBox="0 0 48 48" width="18">
        <path d="M0 0h48v48H0z" fill="none"/>
        <path fill={active ? '#FFEDA0': '#9FB5C1'} d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm2 34h-4v-4h4v4zm4.13-15.49l-1.79 1.84C26.9 25.79 26 27 26 30h-4v-1c0-2.21.9-4.21 2.34-5.66l2.49-2.52C27.55 20.1 28 19.1 28 18c0-2.21-1.79-4-4-4s-4 1.79-4 4h-4c0-4.42 3.58-8 8-8s8 3.58 8 8c0 1.76-.71 3.35-1.87 4.51z"/>
    </svg>
  );
}

export default SvgComponent;
