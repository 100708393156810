import React from 'react';
import styled, { css } from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Components
import BaseLoader from './Loader';

// Styles
const StyledButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  justify-self: baseline;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  transition: 0.2s all ${({ theme }) => theme.transition.cubicEase};
  backface-visibility: hidden;
  border-radius: 0;
  border: 1px solid transparent;
  background: transparent;
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-family: ${({ theme }) => theme.font.sansSerif};
  color: ${({ theme }) => theme.joot.jootBlack};
  font-size: ${(props) => {
    if (props.big) return '15px';
    return '13px';
  }};

  // Hover
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  // Focus
  &:focus {
    outline: none;
  }

  // Active
  &:active {
    transform: scale(0.97);
  }

  // Disabled
  &.disabled,
  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }

  // Icon
  > img,
  > svg {
    margin: 0 10px 0 0;
    display: inline-flex;
    // fill: currentColor;
    vertical-align: middle;
  }
  ${(props) => {
    return (
      props.primary &&
      css`
        padding: 14px 30px;
        font-size: 15px;
        border: 1px solid ${({ theme }) => theme.joot.emeraldGreen};
        background: ${({ theme }) => theme.joot.emeraldGreen};
        color: ${({ theme }) => theme.joot.jootWhite};
      `
    );
  }}
  ${(props) => {
    return (
      props.secondary &&
      css`
        padding: 14px 30px;
        font-size: 15px;
        background: trasparent;
        color: ${(props) => rgba(props.theme.joot.jootBlack, 0.6)};
      `
    );
  }}
  ${(props) => {
    return (
      props.white &&
      css`
        color: ${({ theme }) => theme.joot.jootWhite};
      `
    );
  }}
  ${(props) => {
    return (
      props.block &&
      css`
        width: 100%;
        display: flex;
        text-align: left;
        justify-content: space-between;
      `
    );
  }}
  ${(props) => {
    return (
      props.icon &&
      css`
        padding: 0;
        border-radius: 0;
        border: none;
        > svg {
          margin: 0 auto;
        }
      `
    );
  }}
  ${(props) => {
    return (
      props.mini &&
      css`
        font-size: 12px;
        padding: 1px 20px;
        letter-spacing: -0.37px;
        border-radius: 3px;
        color: ${({ theme }) => theme.joot.potteryBlue};
        background: ${({ theme }) => theme.joot.iceBlue};
      `
    );
  }}
  ${(props) => {
    return (
      props.small &&
      css`
        padding: 11px 25px;
        font-size: 13px;
      `
    );
  }}
  ${({ warning, theme }) =>
    warning &&
    `
      color: ${theme.joot.cinnabar};
      border-color: transparent;
  `};
  ${({ danger, theme }) =>
    danger &&
    `
      color: ${theme.joot.cinnabar};
      background: ${theme.joot.lightPink};
      border-color: transparent;
  `};
  ${({ green, theme }) =>
    green &&
    `
      color: ${theme.joot.jootWhite};
      background: ${theme.joot.emeraldGreen};
      border-color: transparent;
  `};
`;
const Loader = styled(BaseLoader)`
  width 17px;
  height: 17px;
`;

const Button = ({
  primary,
  secondary,
  white,
  block,
  icon,
  mini,
  small,
  loading,
  children,
  hasIcon,
  loaderProps,
  disabled,
  hideTextInLoader = false,
  ...props
}) => {
  return (
    <StyledButton
      primary={primary}
      secondary={secondary}
      white={white}
      block={block}
      icon={icon}
      mini={mini}
      small={small}
      disabled={disabled}
      {...props}
    >
      {(!loading || (loading && !hideTextInLoader)) && <>{children}</>}
      {loading && <Loader {...loaderProps} />}
    </StyledButton>
  );
};

export default Button;
