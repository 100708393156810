import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Styles
const Table = styled.table`
  min-width: 100%;
  font-family: ${(props) => props.theme.font.sanSerif};
  border-spacing: 0;
`;

const Thead = styled.thead`
  color: ${(props) => props.theme.joot.jootBlack};
`;

const Tbody = styled.tbody`
  color: ${(props) => props.theme.joot.jootBlack};
`;

const Td = styled.td`
  padding: 20px 0;
  font-size: 13px;
  letter-spacing: -0.17px;
  border-bottom: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.1)};
  vertical-align: middle;
  &:last-of-type {
    text-align: right;
  }
`;

const Tr = styled.tr`
  &:last-of-type {
    td {
      border-bottom: none;
    }
  }
`;

const Th = styled.th`
  text-transform: uppercase;
  text-align: left;
  font-size: 12px;
  padding: 10px 0;
  letter-spacing: 0.32px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.3)};
  &:last-of-type {
    text-align: right;
  }
`;

Table.thead = Thead;
Table.tbody = Tbody;
Table.td = Td;
Table.tr = Tr;
Table.th = Th;

export default Table;
