import React from 'react';
import styled from 'styled-components';

// Assets
import { ReactComponent as ArrowMed } from '../assets/svg/arrow-med.svg';

// Components
import Button from './Button';

// Styles
const NavControl = styled(Button)`
  position: relative;
  justify-self: center;
  text-align: center;
  align-items: center;
  display: flex;
  font-size: 13px;
  width: 160px;
  > svg {
    vertical-align: inherit;
    margin-left: 20px;
  }
`;
const Text = styled.div`
  ${({ isLoading }) => isLoading ? 'padding-right: 30px;' : ''}
`
const NavErrors = styled.div`
  position: absolute;
  top: 120%;
  left: 0;
`;
const Error = styled.div`
  color: ${({ theme }) => theme.joot.cinnabar};
  font-size: 13px;
  letter-spacing: -0.26px;
  margin-bottom: 6px;
  display: block;
  text-align: left;
`;

const Navigation = ({
  loading,
  onNext,
  disabledNext,
  errors,
}) => {

  return (
    <>
      <NavControl primary loading={loading} outline={!disabledNext} onClick={onNext} disabled={disabledNext}>
        <Text isLoading={loading}>Next</Text>
        {!loading && <ArrowMed />}
        <NavErrors>
          {errors && errors.map(item => <Error key={item}>{item}</Error>)}
        </NavErrors>
      </NavControl>
    </>
  );
};

export default Navigation;
