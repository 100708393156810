import React, {memo, useState, useCallback} from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import InputAppearance from './mixins/InputAppearance';

const StyledTextarea = styled.textarea`
  ${space};
  ${InputAppearance};
  resize: none;
`;

const getSelectionText = () => {
  const textObject = {};
  const activeEl = document.activeElement;
  const activeElTagName = activeEl ? activeEl.tagName.toLowerCase() : null;
  if (activeElTagName === 'textarea') {
    textObject.text = (activeEl.value.slice(activeEl.selectionStart, activeEl.selectionEnd)).trim();
    textObject.textSelectionStart = activeEl.selectionStart;
    textObject.textSelectionEnd = activeEl.selectionEnd;
  }
  return textObject;
};

const Textarea = React.forwardRef(function AvatarMenu({onCursorChanged, onDelete, ...rest}, ref) {
  const [cursorPos, setCursorPos] = useState(null);

  const onMouseSelect = useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const textSelect = getSelectionText();
    setCursorPos(textSelect.textSelectionEnd);
    onCursorChanged && onCursorChanged(textSelect.textSelectionEnd);
  }, [onCursorChanged]);

  const cursorChanged = useCallback((event) => {
    if (event.keyCode === 8) {
      onDelete && onDelete(event.target.selectionEnd);
    }
    const newPosition = event.target.selectionEnd;
    if (newPosition !== cursorPos) {
      setCursorPos(newPosition);
      return onCursorChanged ? onCursorChanged(newPosition) : null;
    }
  }, [cursorPos, onCursorChanged, onDelete]);

  return <StyledTextarea
    {...rest}
    ref={ref}
    onMouseUp={onMouseSelect}
    onClick={cursorChanged}
    onKeyDown={cursorChanged}
    onFocus={cursorChanged}
  />
});

export default memo(Textarea);
