import axios from 'axios';
import { API } from '@aws-amplify/api';
import config from '../utils/config';
// import instagramSearchMock from './instagramsearch.json'

const basePath = 'social';

/**
 * Get a list of Instagram competitor Accounts linked to this Joot Account
 */
export const linkedCompetitors = async (accountName, igUserName) => {
  const data = await API.get(config.apiName, `${basePath}/${accountName}/ig/${igUserName}/comp`, {});

  return data;
};

/**
 * Get a list of Instagram competitor Accounts linked to this Joot Account
 */
export const linkedCompetitorsMock = async (accountName) => {
  const { data } = await axios.get(`http://localhost:3004/${basePath}/${accountName}/ig/comp`);

  return data;
};

/**
 * Search for instagrams accounts
 */
export const searchAccounts = async (query) => {
  // const data = { ...instagramSearchMock }
  // // if (query === 'no') {
  // //   delete data.users
  // // }
  // // if (query === 'cero') {
  // //   data.users = []
  // // }
  // return data;
  const { data } = await axios.get(`${config.instagram.API_SEARCH_URL}/?context=blended&query=${query}`);

  return data;
};

/**
 * Get ig account details
 */
export const igAccountDetails = async (igUsername, igOriginId, accessToken) => {
  const { data } = await axios.get(
    `${config.instagram.API_URL}/${igOriginId}?fields=business_discovery.username(${igUsername}){media_count,username,profile_picture_url}&access_token=${accessToken}`,
  );

  return data;
};

/**
 * Get ig account details
 */
export const jootIgAccountDetails = async (igUsername) => {
  const { data } = await API.get(config.apiName, `${basePath}/ig/${igUsername}`, {});

  return data;
};

/**
 * Returns a list of social accounts that this Joot Account has authorized access to.
 */
export const socialAccountList = async (jootAccountName) => {
  const data = await API.get(config.apiName, `${basePath}/${jootAccountName}`, {});
  return data;
};

/**
 * Returns a list of social accounts that this Joot Account has authorized access to.
 */
export const accountList = async () => {
  const data = await API.get(config.apiName, `${basePath}/joot`, {});
  return data;
};

/**
 * Link Instagram Accounts to a Joot Account
 */
export const linkInstagramAccounts = async (ig_username) => {
  const data = await API.post(config.apiName, `${basePath}/ig`, {
    body: {
      ig_username,
    },
  });
  return data;
};

/**
 * Add one or more competitor Instagram account for the given Instagram account
 */
export const addCompetitorAccounts = async (accountName, igUsername, competitorsAccounts) => {
  const data = await API.post(config.apiName, `${basePath}/${accountName}/joot/${igUsername}/comp`, {
    body: {
      competitors: competitorsAccounts,
    },
  });
  return data;
};

export const createMarket = async (accountName, market_name, competitors, primaryAccount, skipAutoPurchase = false) => {
  console.log("posting to createMarket: " + JSON.stringify(primaryAccount));
  const data = await API.post(config.apiName, `${basePath}/joot/${accountName}/markets`, {
    body: {
      market_name,
      competitors: competitors.map(item => item.username),
      primary_account: primaryAccount?.username,
      skip_auto_purchase: skipAutoPurchase,
    },
  });
  console.log("posted");
  return data;
};

export const updateMarket = async (accountName, market_name, oldName, competitors) => {
  const data = await API.put(config.apiName, `${basePath}/joot/${accountName}/markets/${oldName}`, {
    body: {
      market_name,
      competitors: competitors.map(item => item.username),
    },
  });
  return data;
};

export const removeMarket = (accountName, market_name) => {
  return API.del(config.apiName, `${basePath}/joot/${accountName}/markets/${market_name}`);
};

export const getMarket = async (accountName) => {
  console.log("GETTING MARKETS");
  const { markets } = await API.get(config.apiName, `${basePath}/joot/${accountName}/markets`, {});
  return markets;
};

export const saveSelectedMarket = async (accountName, market_name, market_type) => {
  const data = await API.put(config.apiName, `${basePath}/joot/${accountName}/markets`, {
    body: {
      market_name,
      market_type
    },
  });
  return data;
};