import React, { useCallback } from 'react';
import styled from 'styled-components';
import DropboxChooser from 'react-dropbox-chooser';

// Assets
import { ReactComponent as DropboxSmall } from '../../../assets/svg/dropboxSmall.svg';
import { ReactComponent as DriveSmall } from '../../../assets/svg/driveSmall.svg';
import { ReactComponent as InstagramSmall } from '../../../assets/svg/instagramSmall.svg';
import { ReactComponent as ComputerSmall } from '../../../assets/svg/computerSmall.svg';
import { ReactComponent as Dropbox } from '../../../assets/svg/dropbox.svg';
import { ReactComponent as Drive } from '../../../assets/svg/drive.svg';
import { ReactComponent as Instagram} from '../../../assets/svg/instagram.svg';
import { ReactComponent as Computer } from '../../../assets/svg/computer.svg';

// Components
import Button from '../../../components/Button';
import GooglePicker from './GooglePicker';
import config from '../../../utils/config';

const StyledUploadOptions = styled.div`
  display: flex;
  align-items: center;
`;
const UploadTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: ${({ theme }) => theme.joot.grayIce};
`;
const UploadButton = styled(Button)`
  margin: 0 12px 0 0px;
  svg {
    margin: 0;
  }
`;

const UploadOptions = ({ onComputerLoad, onDropboxUpload, onGoogleUpload, onInstagramUpload, extensions, hasImages, hideTitle, small }) => {
  const handleGoogle = useCallback(
    ({ target: { value } }) => {
      onGoogleUpload(value);
    },
    [onGoogleUpload],
  );

  return (
    <StyledUploadOptions>
      <DropboxChooser appKey={config.dropbox.KEY} success={onDropboxUpload} multiselect={true} extensions={extensions}>
        <UploadButton icon>
          {small ? (
            <DropboxSmall />
          ) : (
            <Dropbox />
          )}
        </UploadButton>
      </DropboxChooser>
      <GooglePicker GOOGLE_CLIENT_ID={config.google.CLIENT_ID} GOOGLE_KEY={config.google.API_KEY} onChange={handleGoogle}>
        <UploadButton icon>
          {small ? (
            <DriveSmall/>
          ) : (
            <Drive/>
          )}
        </UploadButton>
      </GooglePicker>
      <UploadButton icon onClick={onInstagramUpload}>
          {small ? (
            <InstagramSmall/>
          ) : (
            <Instagram/>
          )}
      </UploadButton>
      <UploadButton icon onClick={onComputerLoad}>
          {small ? (
            <ComputerSmall/>
          ) : (
            <Computer/>
          )}
      </UploadButton>
      {!hideTitle && <UploadTitle onClick={onComputerLoad}>Upload Media</UploadTitle>}
    </StyledUploadOptions>
  );
};

export default UploadOptions;
