import * as React from 'react';

function DraftsIcon(props) {
  return (
    <svg width={11} height={14} fill='none' viewBox='0 0 11 14' {...props}>
      <path
        fill='#E9E9F1'
        d='M9 14H2a2 2 0 01-2-2V2C0 .9.9 0 2 0h5.5l1.75 2L11 4v8a2 2 0 01-2 2z'
        className='light'
      />
      <path
        fill='#B3BCD9'
        fillRule='evenodd'
        d='M7 1H2a1 1 0 00-1 1v10a1 1 0 001 1h7a1 1 0 001-1V4.5H8a1 1 0 01-1-1V1zm4 3v8a2 2 0 01-2 2H2a2 2 0 01-2-2V2C0 .9.9 0 2 0h5.5l1.75 2L11 4zm-1.77-.5L8 2.09V3.5h1.23z'
        clipRule='evenodd'
        className='dark'
      />
    </svg>
  );
}

export default DraftsIcon;
