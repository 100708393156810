import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Utils
import { throwError } from '../../../utils/exception';

// Hooks
import useFacebookConnect from '../../../hooks/useFacebookConnect';
import { useFacebookLink } from '../../../hooks/apiHooks/facebookQueryHooks';

// Context
import { useAppContext } from '../../../contexts/AppContext';

// Components
import Avatar from 'react-avatar';
import Modal from '../../../components/Modal';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import FacebookIcon from '../../../components/icons/FacebookIcon';

// Styles
const ModalHeader = styled.div`
  display: block;
  padding: 40px;
`;
const ModalBody = styled.div`
  display: block;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 80px;
`;
const ModalFooter = styled.div`
  display: flex;
  padding: 40px;
  justify-content: flex-end;
  overflow: hidden;
`;
const Text = styled.p`
  display: block;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.26px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.3)};
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.joot.facebook};
  background: ${({ theme }) => theme.joot.facebookLight};
  border-radius: 6px;
`;
const FacebookDetails = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;
const AccountTitle = styled(Title)`
  margin-left: 14px;
  line-height: 22px;
  > span {
    display: block;
    font-weight: 400;
  }
`;
const ButtonProcess = styled(Button)`
  width: 160px;
`;

const ModalFacebookAuth = ({ facebookData, isVisible, onHide, ...props }) => {
  const { facebookConnectHandler, data: fbData, reset } = useFacebookConnect();
  useEffect(() => {
    if (!isVisible) {
      reset();
    }
  }, [isVisible, reset]);
  const [loading, setLoading] = useState(false);
  const { submit: fbSubmit } = useFacebookLink();
  const { invalidateFacebook } = useAppContext();
  const handleEdit = useCallback(async () => {
    if (!fbData) {
      return facebookConnectHandler();
    }
    try {
      setLoading(true);
      await fbSubmit({ accessToken: fbData.accessToken });
      await invalidateFacebook();
      setLoading(false);
      onHide();
    } catch (e) {
      setLoading(false);
      throw throwError(e);
    }
  }, [fbData, facebookConnectHandler, invalidateFacebook, fbSubmit, onHide]);
  const newFacebookData = useMemo(() => {
    if (fbData) {
      return fbData;
    }
    return facebookData;
  }, [facebookData, fbData]);
  const fbDataUpdated = useMemo(() => {
    if (fbData) {
      return true;
    }
    return false;
  }, [fbData]);
  const isAdd = useMemo(() => !fbDataUpdated && !facebookData?.name, [facebookData, fbDataUpdated]);

  return (
    <Modal onHide={onHide} isVisible={isVisible} {...props}>
      <ModalHeader>
        <Title h5>Facebook Connection</Title>
        <Text>
          Add, Update or change your connected Facebook account.  You must have an Instagram Business or Creator Account to connect your Facebook Account to Joot.
        </Text>
      </ModalHeader>
      <ModalBody>
        <Flex onClick={handleEdit}>
          {facebookData?.name && <Avatar name={newFacebookData.name} src={newFacebookData.picture_url} size={70} round="7px" />}
          <FacebookDetails>
            <FacebookIcon width={42} height={42} />
            <AccountTitle h5 noMargin>
              <span>{newFacebookData?.name ? 'Connected to Facebook as' : 'Facebook Account not connected'}</span> {newFacebookData?.name}
            </AccountTitle>
          </FacebookDetails>
        </Flex>
      </ModalBody>
      <ModalFooter>
        {!fbDataUpdated &&
        <Button secondary onClick={onHide}>
          Cancel
        </Button>
        }
        <ButtonProcess
          danger={!fbDataUpdated}
          primary
          type="submit"
          onClick={handleEdit}
          loading={loading}
          hideTextInLoader={true}
        >
          {(fbDataUpdated ? 'Finish' : isAdd ? 'Add Connection' : 'Edit Connection')}
        </ButtonProcess>
      </ModalFooter>
    </Modal>
  );
};

export default ModalFacebookAuth;
