import React, { memo, useCallback, useState, useRef } from 'react';
import styled from 'styled-components';

// Hooks
import useClickOutside from '../../hooks/useClickOutside';

// Assets
import { ReactComponent as Add } from '../../assets/svg/add.svg';

// Components
import Input from './Input';

// Styles
const OptionsWrapper = styled.div`
  position: relative;
`;
const Options = styled.ul`
  position: absolute;
  border: 1px solid;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  z-index: 10;
  background: white;
`;

const TagSelect = ({ name, value, query, options, onQueryChange, onChange }) => {
  const [open, setOpen] = useState(false);
  const handleOnChange = useCallback((event) => onQueryChange(event.target.value), [onQueryChange]);
  const containerRef = useRef();
  useClickOutside(() => setOpen(false), containerRef);
  const handleAdd = useCallback(
    (item) => {
      onChange({ target: { name, value: [...value, item] } });
      setOpen(false);
    },
    [onChange, name, value],
  );
  const handleInputClick = useCallback(() => {
    if (options.length) {
      setOpen(true);
    }
  }, [options]);
  const handleRemove = useCallback(
    (item) => {
      const currentValue = value.filter((current) => current !== item);
      onChange({ target: { name, value: currentValue } });
      setOpen(false);
    },
    [onChange, name, value],
  );

  return (
    <div ref={containerRef}>
      <Input
        name="tag"
        type="text"
        value={query}
        onClick={handleInputClick}
        onChange={handleOnChange}
        placeholder="Add tags..."
        IconComponent={Add}
      />
      {open && (
        <OptionsWrapper>
          <Options>
            {options &&
              options.map((item) => (
                <li key={item}>
                  <button type="button" onClick={() => handleAdd(item)}>
                    {item}
                  </button>
                </li>
              ))}
          </Options>
        </OptionsWrapper>
      )}
      Selected...
      <ul>
        {value.map((item) => (
          <li key={item}>
            <button type="button" onClick={() => handleRemove(item)}>
              {item}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(TagSelect);
