import { useMemo, useCallback } from "react"
import { useAppContext } from "../../../contexts/AppContext";
import { allImageTags, addImagesTags, removeImagesTags } from "../../../services";
import useAppToasts from "../../../hooks/useToasts";
import { STRINGS } from "../../../utils/strings";
import { useQuery, queryCache } from "react-query";
import { DEFAULT_QUERY_OPTIONS } from "../../../hooks/apiHooks/api-hooks-utils";

const tagsToObjects = tags => tags ? tags.map(name => ({ name, value: name })) : []

export const useTagHook = () => {
  const { accountName, libraryName } = useAppContext();
  const query = useMemo(() => ['image_tags', accountName, libraryName], [accountName, libraryName])
  const { data: optionsIn = [] } = useQuery(query, allImageTags, {
    enabled: !!accountName,
    ...DEFAULT_QUERY_OPTIONS,
  });
  const tags = useMemo(() => tagsToObjects(optionsIn), [optionsIn])
  const refreshTags = useCallback(() => queryCache.invalidateQueries(query), [query])

  return {
    tags,
    refreshTags,
  }
}

export const useTagManagerHook = (selectedMedias, refreshImages, refreshTags, currentImages, changeCache) => {
  const { accountName, libraryName } = useAppContext();
  const { errorToast, successToast } = useAppToasts();
  const doOperation = useCallback(
    async ({ target: { value } }, operation, message) => {
      const newImages = currentImages.map((item) => {
        const newItem = selectedMedias.find((current) => current.id === item.id);
        if (!newItem) {
          return item
        }
        const currentTags = newItem.tags ? newItem.tags : []
        let tags = currentTags.filter(tag => tag !== value.name)
        if (operation === "add") {
          tags = [...tags, value.name]
        }
        return {
          ...newItem,
          tags: tags.sort(),
        }
      });
      changeCache(newImages);
      const method = operation === "add" ? addImagesTags : removeImagesTags
      try {
        await method(
          accountName,
          libraryName,
          selectedMedias,
          [value.name]
        );
        refreshTags();
        refreshImages()
        successToast(message);
      } catch (e) {
        errorToast(e.message);
      }
    },
    [
      accountName,
      libraryName,
      selectedMedias,
      successToast,
      errorToast,
      refreshImages,
      refreshTags,
      currentImages,
      changeCache,
    ]
  );
  const handleSave = useCallback(
    async (event) => {
      return doOperation(event, "add", STRINGS.successMessages.tagsAdded)
    },
    [doOperation],
  );
  const handleRemove = useCallback(async (event) => {
    return doOperation(event, "remove", STRINGS.successMessages.tagsRemoved)
  }, [doOperation]);
  const detectedTags = useMemo(() => {
    let tags = {}
    const aiTags = {}
    selectedMedias.forEach(item => {
      if (!item) {
        return
      }
      if (item.tags) {
        item.tags.forEach(tag => {
          tags[tag] = tags[tag] ? tags[tag] + 1 : 1;
        })
      }
      if (item.ai_detected_tags) {
        item.ai_detected_tags.forEach(tag => {
          aiTags[tag] = aiTags[tag] ? aiTags[tag] + 1 : 1;
        })
      }
    })
    return {
      detectedTags: Object.keys(tags).map(key => ({ name: key, value: key, type: "tag", quantity: tags[key] })),
      detectedAITags: Object.keys(aiTags).map(key => ({ name: key, value: key, type: 'ai', quantity: aiTags[key] })),
    }
  }, [selectedMedias])

  return {
    ...detectedTags,
    handleSave,
    handleRemove,
  }
}
