import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import styled, { useTheme } from 'styled-components';

// Context
import { useAppContext } from '../../contexts/AppContext';
import { useAuthContext } from '../../contexts/AuthContext';

// Hooks
import useShowAside from '../../hooks/useShowAside';

// Components
import Nav from '../../components/nav/Nav';
import NavItem from '../../components/nav/NavItem';
import Aside from '../../components/aside/Aside';
import Article from '../../components/article/Article';
import LogoJoot from '../../components/logo/LogoJoot';

import AccountToggle from './components/AccountToggle';
import TagsSidebar from './TagsSidebar';

import ScoredMediaIcon from '../../components/icons/ScoredMediaIcon';
import ImageRankIcon from '../../components/icons/ImageRankIcon';
import MarketInsightsIcon from '../../components/icons/MarketInsightsIcon3';
import ImageInsightsIcon from '../../components/icons/ImageInsightsIcon3';

const Logout = styled.button`
  border: 0;
  background-color: white;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const LogoWrapper = styled.div`
  margin-bottom: 10px;
`;

const MediaLayout = ({ children }) => {
  const showAside = useShowAside();
  const history = useHistory();
  const { userHasAuthenticated } = useAuthContext();
  const { accountMarkets } = useAppContext();
  const handleLogout = useCallback(async () => {
    await Auth.signOut();
    userHasAuthenticated(false);
    console.log("User has signed out.");
    history.push('/');
  }, [userHasAuthenticated, history]);
  const hasAccountMarkets = useMemo(() => accountMarkets?.length === 0, [accountMarkets]);
  const { joot } = useTheme();

  return (
    <>
      <Nav>
        <NavItem exact to="/scored-media" flat={false}>
          <ImageRankIcon/>
          Image Rank 
        </NavItem>
        <NavItem to="/image-insights" flat={false}>
          <ImageInsightsIcon />
          Image Insights 
        </NavItem>
        <NavItem to="/market-insights" flat={false}>
          <MarketInsightsIcon />
          Market Insights
        </NavItem>
        <AccountToggle />
      </Nav>
      <Article>{children}</Article>
      {showAside ? (
        <Aside>
          <TagsSidebar />
        </Aside>
      ) : null}
    </>
  );
};

export default MediaLayout;
