import React, { memo } from 'react';
import styled from 'styled-components';

// Components
import Modal from '../Modal';
import Button from '../Button';
// import Title from '../Title';

// Styles
// const ModalHeader = styled.div`
//   display: block;
//   padding: 40px;
// `;
const ModalBody = styled.div`
  display: block;
  padding: 30px 30px 10px 30px;
`;
const ModalFooter = styled.div`
  display: flex;
  padding: 40px;
  justify-content: flex-end;
  overflow: hidden;
`;
const OkButton = styled(Button)`
  min-width: 170px;
  justify-content: center;
`;

const ModalForm = ({ onHide, isLoading, onOk, onBack, title, okText, hideCancel, hideBack, hideOk, children, ...props }) => {
  return (
    <Modal onHide={onHide} {...props}>
      {/* <ModalHeader>
        <Title h5>{title}</Title>
      </ModalHeader> */}
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        {!hideBack && (<Button secondary onClick={onBack}>
          Back          
        </Button>)}
        {!hideCancel && (<Button secondary onClick={onHide}>
          Cancel
        </Button>)}
        {!hideOk && <OkButton primary disabled={isLoading} loading={isLoading} onClick={onOk} type="submit">
          {!isLoading && <>{okText}</>}
        </OkButton>}
      </ModalFooter>
    </Modal>
  );
};

export default memo(ModalForm);
