import React, { memo, useCallback } from 'react';
import BaseGooglePicker from 'react-google-picker';

const SCOPE = [
  'https://www.googleapis.com/auth/drive.readonly',
  'https://www.googleapis.com/auth/drive',
  'https://www.googleapis.com/auth/drive.file',
  'https://www.googleapis.com/auth/drive.metadata.readonly',
  'https://www.googleapis.com/auth/drive.appdata',
  'https://www.googleapis.com/auth/drive.metadata',
  'https://www.googleapis.com/auth/drive.photos.readonly',
];

const GooglePicker = ({ name, children, GOOGLE_CLIENT_ID, GOOGLE_KEY, onChange }) => {
  const handleGoogle = useCallback(
    (data, oauthToken) => {
      if (data.action === 'picked' && oauthToken) {
        onChange({
          target: {
            name,
            value: data.docs.map((item) => ({
              ...item,
              fetch: () =>
                fetch(`https://www.googleapis.com/drive/v2/files/${item.id}?alt=media`, {
                  headers: {
                    Authorization: `Bearer ${oauthToken}`,
                    responseType: 'blob',
                  },
                }).then((res) => res.blob()),
            })),
          },
        });
      }
    },
    [onChange, name],
  );

  return (
    <BaseGooglePicker
      clientId={GOOGLE_CLIENT_ID}
      developerKey={GOOGLE_KEY}
      scope={SCOPE}
      navHidden={true}
      authImmediate={false}
      viewId={'DOCS'}
      createPicker={(google, oauthToken) => {
        const googleViewId = google.picker.ViewId.DOCS;
        const docsView = new google.picker.DocsView(googleViewId)
          .setIncludeFolders(true)
          .setMimeTypes(['image/png', 'image/jpeg', 'image/jpg'])
          .setSelectFolderEnabled(true);

        const picker = new window.google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .addView(docsView)
          .setOAuthToken(oauthToken)
          .setDeveloperKey(GOOGLE_KEY)
          .setCallback((data) => handleGoogle(data, oauthToken));

        picker.build().setVisible(true);
      }}
    >
      {children}
    </BaseGooglePicker>
  );
};

export default memo(GooglePicker);
