import styled from 'styled-components';

// Styles
const StyledSignupLayout = styled.section`
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 6vw 13vw;
  z-index: 1;
  ${({ currentStep }) =>
    currentStep === 'create' &&
    `
    grid-template-columns: 1fr 1fr;
    grid-gap: 7vw;
  `}
  ${({ currentStep }) => ['one', 'two', 'three', 'four'].includes(currentStep) && `padding: 0 13vw;`}
  ${({ currentStep }) => ['two', 'three'].includes(currentStep) && `grid-row-gap: 2vw;`}
`;

export default StyledSignupLayout;
