import React from 'react';
import styled from 'styled-components';

// Context
import { useAppContext } from '../../../contexts/AppContext';

// Assets
import { ReactComponent as ArrowRightSmall } from '../../../assets/svg/arrow-right-small.svg';

// Components
import Image from '../../../components/Image';
import Avatar from 'react-avatar';
import Tag, { getTagColor } from './Tag';
import Title from '../../../components/Title';
import PalleteGrid from './PalleteGrid';

// Styles
const StyledPostDetail = styled.div`
  display: grid;
  grid-template-columns: 390px 1fr;
  grid-column-gap: 30px;
  padding-left: 18px;
  width: 100%;
`;
const PostImage = styled(Image)`
  width: 100%;
  border-radius: 15px;
`;
const PostDetails = styled.div``;
const Label = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.joot.jootBlack};
  margin: 0 0 10px 0;
`;
const TagGrid = styled.div`
  display: flex;
  margin-bottom: 12px;
  flex-wrap: wrap;
`;
const AccountDetails = styled.div`
  display: flex;
  margin: 0 0 20px 0;
`;
const Meta = styled.div`
  padding: 5px 0 0 15px;
`;
const SeeOnInstagram = styled.a`
  font-size: 11px;
  font-weight: 600;
  color: ${({ theme }) => theme.joot.potteryBlue};
  display: block;
  text-decoration: none;
  margin-top: 5px;
  > svg {
    margin-left: 10px;
  }
`;

const PostDetail = ({ image }) => {
  const { tag_insights } = useAppContext();
  let iaTags = [];
  if (image.ai_detected_tags_3_for_3) {
    iaTags = [...iaTags, ...image.ai_detected_tags_3_for_3];
  }
  if (image.ai_detected_tags_2_for_3) {
    iaTags = [...iaTags, ...image.ai_detected_tags_2_for_3];
  }
  const sortTags = (a, b) => {
    const colorA = getTagColor(a, tag_insights);
    const colorB = getTagColor(b, tag_insights);
    if (colorA === colorB) {
      return 0;
    }
    if (colorA === 'emeraldGreen' || (colorA === 'mustard' && colorB === 'cinnabar')) {
      return -1;
    }
    return 1;
  };
  iaTags.sort(sortTags);
  const tags_unique = image.ai_detected_tags_unique ? image.ai_detected_tags_unique : [];
  tags_unique.sort(sortTags);

  return (
    <StyledPostDetail>
      <PostImage alt="Example" src={image?.image_urls?.url_400} src2x={image?.image_urls?.url_800} height={390} />
      <PostDetails>
        <AccountDetails>
          <Avatar name={image.ig_username} size={52} round={true} src={image.ig_profile_picture_url} />
          <Meta>
            <Title h6>@{image.ig_username}</Title>
            <SeeOnInstagram href={image.ig_post_url} target="_black">
              See Post on Instagram <ArrowRightSmall />
            </SeeOnInstagram>
          </Meta>
        </AccountDetails>
        <Label>Concept Tags</Label>
        <TagGrid>
          {iaTags.map((item) => (
            <Tag hasRemove={false} key={item} value={item}>
              {item}
            </Tag>
          ))}
        </TagGrid>
        <Label>Unique Image Tags</Label>
        <TagGrid>
          {tags_unique.map((item) => (
            <Tag hasRemove={false} key={item} value={item}>
              {item}
            </Tag>
          ))}
        </TagGrid>
      </PostDetails>
    </StyledPostDetail>
  );
};

export default PostDetail;
