import React from 'react';
import styled from 'styled-components';

// Assets
import { ReactComponent as LogoWord } from '../../assets/svg/logo-word-black.svg';

// Styles
const StyledAuthIntro = styled.div`
  display: block;
  position: relative;
`;

const AuthIntro = () => {
  return (
    <StyledAuthIntro>
      <LogoWord />
    </StyledAuthIntro>
  );
};

export default AuthIntro;
