import React, { useCallback, useState, memo, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import rgba from 'polished/lib/color/rgba';

// Components
import SimpleImage from 'react-simple-image';
import Skeleton from 'react-loading-skeleton';
import SingleSelectOverlay from './SingleSelectOverlay';
import MediaRating from './MediaRating';
import MediaOverlay from './MediaOverlay';
import Loader from '../Loader';

const height = '200px';

//  Styles

const Figure = styled(motion.figure)`
  position: relative;
  margin: 0;
  width: 100%;
  ${({ height }) =>
    height &&
    `
     height: ${height};
  `};
  display: block;
  ${({ isSidebar }) =>
    isSidebar &&
    `
    padding: 0;
    overflow: hidden;
    background: none;
  `}
`;
const MediaImage = styled(SimpleImage)`
  width: 100%;
  ${({ width }) =>
    width &&
    `
     width: ${width};
  `};
  display: block;
`;
const MediaLoading = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  background-color: ${(props) => rgba(props.theme.joot.jootWhite, 0.6)};
`;
const ImgCrop = styled.div`
  min-height: ${height};
  min-width: 100%;
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-size: cover;
`;

const MediaFigure = ({
  mediaData,
  onSelect,
  onSingleSelect,
  onDelete,
  onTag,
  onView,
  isSelected,
  multiSelect,
  hideOptions,
  isSidebar,
  starSingleImage,
  width,
  onClick,
  ...props
}) => {
  const [isRendering, setIsRendering] = useState(true);
  const handleEvents = useCallback(
    (event) => {
      return () => {
        if (mediaData.isLoading) {
          return;
        }
        event();
      };
    },
    [mediaData.isLoading],
  );
  useEffect(() => {
    const createPromise = (src) =>
      new Promise((resolved) => {
        const image = new Image();
        image.src = src;
        image.onload = () => resolved();
      });
    Promise.all([createPromise(mediaData.src1x), createPromise(mediaData.src2x)]).then(() => setIsRendering(false));
  }, [mediaData]);
  const handleClick = useCallback(() => {
    if (isRendering) {
      return;
    }
    onClick(mediaData);
  }, [isRendering, onClick, mediaData]);

  return (
    <Figure initial="rest" isSidebar={isSidebar} {...props}>
      {mediaData.isLoading && (
        <MediaLoading>
          <Loader />
        </MediaLoading>
      )}
      {multiSelect && !(isRendering || mediaData.isLoading) && (
        <MediaOverlay
          onClick={handleClick}
          onSelectClick={handleEvents(onSelect)}
          isSelected={isSelected}
          onDelete={handleEvents(onDelete)}
          onTag={handleEvents(onTag)}
          onView={handleEvents(onView)}
          hideOptions={hideOptions}
          starSingleImage={handleEvents(starSingleImage)}
          isstared={mediaData.starred}
        />
      )}
      {!multiSelect && <SingleSelectOverlay onClick={onSingleSelect} />}
      {mediaData.isLoading ? (
        <ImgCrop src={mediaData.src} />
      ) : (
        <>
          {!isSelected ? <MediaRating rating={mediaData.rating} order={mediaData.order} quantile={mediaData.quantile} /> : null}
          {isRendering ? (
            <Skeleton height={height} />
          ) : (
            <>
              <MediaImage
                alt="Example"
                src={mediaData.src}
                srcSet={{ '1x': mediaData.src1x, '2x': mediaData.src2x }}
                width={width}
              />
            </>
          )}
        </>
      )}
    </Figure>
  );
};

export default memo(MediaFigure);
