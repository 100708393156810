import React, { memo, useCallback, useEffect } from 'react';

// Components
import InputCompetitor from './Input';
import Selected from './Selected';

const hasCompetitor = (item) => !!item;

const Competitor = ({ value: competitors, readonly, onChange, placeholder }) => {
  const handleChange = useCallback(
    ({ target: { name: index, value } }) => {
      const newCompetitors = [...competitors];
      const found = Object.entries(newCompetitors)
        .map(([_, item]) => item)
        .filter((item) => item && value)
        .find((item) => item.username === value.username);
      if (!found) {
        newCompetitors[index] = value;
      }
      onChange(newCompetitors);
    },
    [competitors, onChange],
  );
  const handleRemove = useCallback(
    ({ target: { name } }) => {
      handleChange({ target: { name, value: null } });
    },
    [handleChange],
  );
  const handleSetPrimary = useCallback(
    ({ target: { name: index } }) => {
      const newCompetitors = [...competitors];
      newCompetitors.forEach((item) => { if(item) item.isPrimary = false;}); //unset isPrimary for all competitors
      newCompetitors[index].isPrimary = true;
      onChange(newCompetitors);
    },
    [competitors, onChange],
  );

  return (
    <>
      {competitors &&
        competitors.map((item, index) => (
          <React.Fragment key={index}>
            {hasCompetitor(item) && <Selected value={item} readonly={readonly} onDelete={handleRemove} onSetPrimary={handleSetPrimary} name={index} isPrimary={item.isPrimary} />}
            {!hasCompetitor(item) && <InputCompetitor name={index} value={item} onChange={handleChange} placeholder={placeholder}/>}
          </React.Fragment>
        ))}
    </>
  );
};

export default memo(Competitor);
