import React, { useContext, createContext, useCallback, useEffect, useState } from 'react';
import { useAppContext } from '../../../contexts/AppContext';
import { getImages, deleteImage, predictImage, uploadImages, starImage, loadImagesUntilScored} from '../../../services';
import useAppToasts from '../../../hooks/useToasts';
import { STRINGS } from '../../../utils/strings';
import { useMutation } from 'react-query';
import useStateVars from '../../../hooks/useStateVars';
import { useMediaList } from '../../../hooks/apiHooks/mediaApiHooks';
import useMediaSelection from '../../../hooks/useMediaSelection';
import { useTagHook, useTagManagerHook } from '../hooks/useTagHook';
import { useModalConfirm } from '../../../contexts/ModalContext';

const MediaContext = createContext(null);

const preloadImage = (url) => ({
  id: Math.floor(Math.random() * 1000000),
  src: url,
  src1x: url,
  src2x: url,
  isLoading: true,
});

const loadImages = async (images, currentImages, changeCache, accountName, marketName, marketType) => {
  const promises = images.map((item) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        resolve(preloadImage(e.target.result));
      };
      reader.readAsDataURL(item);
    });
  });
  const preload = await Promise.all(promises);
  currentImages = currentImages || {};
  changeCache([...preload, ...currentImages]);
  return uploadImages(accountName, marketName, marketType, images);
};

const loadGoogle = async (images, currentImages, changeCache, accountName, marketName, marketType) => {
  const promises = images.map(({ name, fetch }) => Promise.all([name, fetch()]))
  const blobsName = (await Promise.all(promises)).map(([name, blob]) => ({ name, blob }))
  const preload = blobsName.map(({ blob }) => {
    const url = window.URL || window.webkitURL;
    const src = url.createObjectURL(blob);
    return preloadImage(src)
  });
  changeCache([...preload, ...currentImages]);
  return uploadImages(
    accountName,
    marketName,
    marketType,
    blobsName.map(({ name, blob }) => {
      blob.name = name;
      return blob;
    }),
  );
}

const loadDropbox = async (images, currentImages, changeCache, accountName, marketName, marketType ) => {
  const preload = images.map((item) => preloadImage(item.thumbnailLink));
  changeCache([...preload, ...currentImages]);
  const promises = images
    .map((item) => ({
      name: item.name,
      link: item.link.replace('www.dropbox.com', 'dl.dropboxusercontent.com'),
    }))
    .map(({ link, name }) => Promise.all([name, fetch(link).then((response) => response.blob())]));
  const imagesBlob = (await Promise.all(promises)).map(([name, file]) => {
    file.name = name;
    return file;
  });
  return uploadImages(accountName, marketName, marketType, imagesBlob);
};

const doStarImages = async (
  images,
  starred,
  currentImages,
  changeCache,
  invalidateQuery,
  accountName,
  marketName,
  errorToast,
  successToast,
) => {
  const imagesstar = images.map((item) => ({
    ...item,
    starred,
  }));
  currentImages = currentImages || [];
  const newImages = currentImages.map((item) => {
    const newItem = imagesstar.find((current) => current.id === item.id);
    return newItem ? newItem : item;
  });
  changeCache(newImages);
  try {
    await starImage(images, accountName, marketName);
    invalidateQuery();
    successToast(STRINGS.successMessages.imageStar);
  } catch (e) {
    errorToast(e.message);
  }
};

const initialStates = {
  actionSelected: null,
  confirm: '',
  filters: {
    sortBy: { value: 'rating', name: 'Rank' },
    optionsSearch: [],
  },
};

export const sortOptions = [
  { value: 'rating', name: 'Rank' },
  { value: 'createdAt', name: 'Date Uploaded' },
  { value: 'capturedAt', name: 'Date Captured' },
  { value: 'starred', name: 'Starred' },
];

export function MediaContextProvider(props) {
  const { accountName, marketName, marketType, setUploads } = useAppContext();
  const { errorToast, successToast } = useAppToasts();
  const { state, changeState, toggleState } = useStateVars(initialStates);
  const { tags, refreshTags } = useTagHook()
  const {
    data: imagesList = [],
    sampleImages,
    showSampleImages,
    continuePolling,
    activeImports,
    activeScoring,
    invalidateQuery,
    fitlterBy,
    changeCache,
    searchOptions,
    isLoaded: isLoadedImages = true
  } = useMediaList(state.filters, tags);
  const { selectedMedias, selectionChangeHandler, setSelectedMedias, isSelected } = useMediaSelection(imagesList);
  const tagManager = useTagManagerHook(
    selectedMedias.length > 0 ? selectedMedias : imagesList,
    invalidateQuery,
    refreshTags,
    imagesList,
    changeCache,
  )

  const selectDeselectAll = useCallback(() => {
    if (selectedMedias.length) {
      return setSelectedMedias([]);
    }
    setSelectedMedias(imagesList);
  }, [setSelectedMedias, imagesList, selectedMedias]);

  const changeFilter = useCallback(
    (fitlers) => {
      setSelectedMedias([]);
      changeState('filters', fitlers);
    },
    [changeState, setSelectedMedias],
  );

  const handleRemove = useCallback(
    async (images) => {
      try {
        setSelectedMedias([]);
        const ids = images.map(({ id }) => id);
        fitlterBy((item) => !ids.includes(item.id));
        await deleteImage(accountName, marketName, images);
        invalidateQuery();
        //successToast(STRINGS.successMessages.imageDeleted);
      } catch (e) {
        errorToast(e.message);
      }
    },
    [accountName, marketName, setSelectedMedias, fitlterBy, successToast, errorToast, invalidateQuery],
  );
  const handleRank = useCallback(
    async (images) => {
      try {
        setSelectedMedias([]);
        images.map((item) => item.order = 0);
        const result = await predictImage(accountName, marketName, marketType, images);
        console.log(JSON.stringify(result));
        setUploads(result.remaining_rankings);
        invalidateQuery();
        //successToast(STRINGS.successMessages.imageDeleted);
      } catch (e) {
        errorToast(e.message);
      }
    },
    [accountName, marketName, marketType, setSelectedMedias, fitlterBy, successToast, errorToast, invalidateQuery],
  );

  const { open } = useModalConfirm();
  const onDeleteMany = useCallback(() => {
    open('Remove these images from your image collection?', async () => {
      handleRemove(selectedMedias);
    });
  }, [open, handleRemove, selectedMedias]);
  const onRankMany = useCallback(() => {
    handleRank(selectedMedias);
  }, [handleRank, selectedMedias]);

  const onDeleteOne = useCallback(
    (payload) => {
      open('Remove this image from your image collection?', async () => {
        handleRemove([payload]);
      });
    },
    [open, handleRemove],
  );

  const onAddTags = useCallback(
    (item) => {
      if (item) {
        setSelectedMedias([item]);
      }
    },
    [setSelectedMedias],
  );

  const [handleUploadByUrl, uploadImagesUrlState] = useMutation(({ images, type }) => {
    let method = null;
    if (type === 'dropbox') {
      method = loadDropbox
    }
    if (type === 'google') {
      method = loadGoogle
    }
    return method(images, imagesList, changeCache, accountName, marketName, marketType).then(() => {
      // TODO: Implement the add tags feature.
      invalidateQuery();
    });
  });
  const [handleUpload, uploadImagesState] = useMutation((images) => {
    return loadImages(images, imagesList, changeCache, accountName, marketName, marketType).then(() => {
      // TODO: Implement the add tags feature.
      invalidateQuery();
    });
  });
  /*
  const scoresDataQuery = useMemo(() => ['score_data', accountName, marketName], [accountName, marketName]);
  const { data: scoreData } = useQuery(scoresDataQuery, loadImageScores(accountName, marketName, imagesList), {
    enable: imagesList && accountName && marketName,
    ...DEFAULT_QUERY_OPTIONS,
  });
  */

  const [previousScoring, setPreviousScoring] = useState();

  useEffect(() => {
    if (!isLoadedImages || !continuePolling) {
      return;
    }
    const intervalId = setInterval(() => {
      console.log("Getting Images for: " + accountName + ", market: " + marketName + ", library: " + marketName);
      getImages(accountName, marketName, marketType).then(({ continuePolling } ) => {
        if (!continuePolling) {
          clearInterval(intervalId);
          invalidateQuery();
        } else if (activeScoring != previousScoring) // if the number of images being scored has changed but not all scoring is complete yet
          invalidateQuery();
        setPreviousScoring(activeScoring);
      });
    }, 10000);
    return () => clearInterval(intervalId);
  }, [isLoadedImages, accountName, marketName, imagesList, continuePolling]);

  const scoreData= [];
  
  const starImages = useCallback(
    (images, star) => {
      doStarImages(
        images,
        star,
        imagesList,
        changeCache,
        invalidateQuery,
        accountName,
        marketName,
        errorToast,
        successToast,
      );
    },
    [imagesList, changeCache, invalidateQuery, accountName, marketName, errorToast, successToast],
  );
  const starSingleImage = useCallback(
    (img) => {
      const starred = !img.starred;
      starImages([img], starred);
    },
    [starImages],
  );
  const starImageSelected = useCallback(() => {
    starImages(selectedMedias, true);
  }, [starImages, selectedMedias]);

  return (
    <MediaContext.Provider
      value={{
        ...state,
        tagsReadOnly: selectedMedias.length === 0,
        //imagesList: imagesWithScores,
        imagesList,
        sampleImages,
        showSampleImages,
        continuePolling,
        activeImports,
        activeScoring,
        starSingleImage,
        starImageSelected,
        refresh: invalidateQuery,
        selectedMedias,
        isSelected,
        changeState,
        toggleState,
        selectionChangeHandler,
        onDeleteOne,
        onAddTags,
        onDeleteMany,
        onRankMany,
        handleUpload,
        uploadImagesState,
        scoreData,
        changeFilter,
        selectDeselectAll,
        handleUploadByUrl,
        uploadImagesUrlState,
        searchOptions,
        tagManager,
        isLoadedImages,
        tags,
      }}
      {...props}
    />
  );
}

/**
 *
 * @returns {{toggleState: *, tagManager: *, tags: *, refresh: *, scoreData: *, onTagItem: *,
 * filters: *, selectionChangeHandler: *, onDeleteMany: *, uploadImagesState: *, confirm: *,
 * handleUpload: *, onDeleteOne: *, onTagItemsSelected: *, onAddTags: *, changeState: *, selectedMedias: *,
 * imagesList: *, isSelected, changeFilter: *, selectDeselectAll: *, handleUploadByUrl: *, uploadImagesUrlState: *}}
 */
export function useMediaContext() {
  const state = useContext(MediaContext);

  return state;
}
