import React, { memo } from 'react';
import styled, { useTheme } from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Components
import BaseLoader from '../../../components/Loader';
import Title from '../../../components/Title';

// Styles
const Wrapper = styled.div`
  position: relative;
  padding-top: 30px;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 50px;
`;
const Loader = styled(BaseLoader)`
  width 36px;
  height: 36px;
  margin: 0 0 20px 0;
`;
const MessageWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;
const Message = styled(Title)`
  white-space: pre-line;
  line-height: normal;
  font-weight: 400;
`;
const Text = styled.p`
  white-space: pre-line;
  font-size: 13px;
  line-height: 16px;
  margin: 10px 0 50px 0;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.7)};
  width: 590px;
  text-align: center;
`;

const EmptyMessage = ({ title, subtitle, message, loading, children }) => {
  const { joot } = useTheme();
  return (
    <Wrapper>
      <MessageWrapper>
        {loading && <Loader style={{ stroke: `${joot.potteryBlue}` }} />}
        <Message h4>{title}</Message>
        {subtitle && <Message h5>{subtitle}</Message>}
        {message && <Text>{message}</Text>}
        {children}
      </MessageWrapper>
    </Wrapper>
  );
};

export default memo(EmptyMessage);
