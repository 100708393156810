import * as React from 'react';

function EyeIcon(props) {
  return (
    <svg fill="none" viewBox="0 0 21 14" {...props}>
      <path
        fill="#999FB0"
        fillRule="evenodd"
        d="M10.374.947c3.62-.056 7.25 2.445 9.482 4.9l.002.002a2.01 2.01 0 010 2.692l-.001.002c-2.183 2.402-5.803 4.959-9.483 4.902-3.68.057-7.3-2.5-9.48-4.902L.892 8.54a2.01 2.01 0 010-2.692l.002-.002c2.23-2.455 5.86-4.956 9.48-4.9zM1.82 6.687a.76.76 0 000 1.016c2.09 2.302 5.395 4.545 8.544 4.492h.02c3.15.053 6.454-2.19 8.547-4.492a.761.761 0 000-1.016c-2.138-2.35-5.452-4.542-8.546-4.49h-.021c-3.094-.052-6.408 2.14-8.544 4.49z"
        clipRule="evenodd"
      />
      <path
        fill="#999FB0"
        fillRule="evenodd"
        d="M11.331 4.886a2.5 2.5 0 10-1.913 4.618 2.5 2.5 0 001.913-4.618zm-.957-1.44a3.749 3.749 0 013.75 3.75m-3.75-3.75a3.75 3.75 0 103.75 3.75"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default EyeIcon;
