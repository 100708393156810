import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import ResizeObserver from 'resize-observer-polyfill';
import composeRefs from '../../../utils/composeRefs';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { motion } from 'framer-motion';
import { Segment, Icon } from 'semantic-ui-react'

// Hooks
import useMenu from '../../../hooks/useMenu';

// Components
import DropdownIcon from '../../icons/DropdownIcon';
import MarketplaceCategory from './MarketplaceCategory';
import { ToggleLayer } from 'react-laag';
import { TextMenuItem, SpinnerIconContainer } from '../AvatarMenu';
import SpinnerIcon from '../../icons/SpinnerIcon';

// Styles
const DisplayBase = styled(motion.div)`
  transition: color 0.15s, background-color 0.15s, width 0.25s ease-in-out;
  position: absolute;
  width: 1400px;
  height: 850px;
  margin: 0;
  padding: 20px;
  background-clip: padding-box;
  backface-visibility: hidden;
  border-radius: 3px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  background-color: ${({ theme }) => theme.joot.jootWhite};
  color: ${({ theme }) => theme.joot.jootBlack};
  border: 1px solid ${({ theme }) => theme.joot.blueGray};
  font-family: ${({ theme }) => theme.font.sansSerif};
  z-index: 150;
  margin-top: ${({ offsetY }) => offsetY};
  margin-left: ${({ offsetX }) => offsetX};
  display: flex;
  flex-flow: column;
`;
const Display = styled.div`
  width: 100%;
  height: 95%;
  display: flex;
  flex-flow: row
`;
const Toggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Text = styled.span`
  color: ${({ theme }) => theme.joot.jootBlack};
  font-size: 16px;
  padding: 5px 0;
  font-weight: 700;
  // margin-left: auto;
`;
const DropdownIconStyled = styled(DropdownIcon)`
  fill: ${({ theme }) => theme.joot.paleBlue};
  margin-left: 10px;
`;
const MarketplaceCategoryStyled = styled(MarketplaceCategory)`
  padding: 0 0 0 0;
`;
const Nav = styled.div`
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
  width: 350px;
`;
const Marketplace = styled.div`
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  width: 100%;
`;

// Values
const PLACEMENT = {
  anchor: 'BOTTOM_LEFT',
  snapToAnchor: false,
  triggerOffset: 20,
  scrollOffset: 16,
  preferX: 'RIGHT',
};

const CreateOptions = (items) => (
  items.map((item) => ({
    item,
    value: item.market_name,
    name: item.market_name,
  })));


const sortCategories = (a, b) => {
      if (a[0] === "My Custom Markets") return -1;
      if (a[0] < b[0]) return -1;
      if (a[0] > b[0]) return 1;
      else return 0;
    };

const sortMarkets = (a, b) => {
      if (a.market_name < b.market_name) return -1;
      if (a.market_name > b.market_name) return 1;
      else return 0;
    };

const MarketplaceMenu= React.forwardRef(function MarketplaceMenu(props, ref) {
  const {
    categories,
    title,
    children,
    initialSelection,
    onSelectItemMenu,
    offsetY,
    hideCheck,
    onOutsideClick,
    onClose,
    CategoryComponent,
    isLoading,
    firstTime,
    emptyText,
    loadingText,
    onChange,
    onEdit,
    onSpecialOption,
    onCreateMarket,
    canEdit,
    width,
    className,
    name,
    value,
    ...rest
  } = props;
  const labelField = 'name';
  const { selectedText, handleSelection, isSelected, selected } = useMenu({
    labelField,
    emptySelectionText: '',
    initialSelection: !initialSelection ? { value: 'mr', name: 'Select a Market' } : initialSelection,
    value,
  });
  const handleClick = useCallback(
    (item, props) => {
      return function onClick() {
        handleSelection(item);
        onChange && onChange({ target: { name, value: item } });
        const canClose = onSelectItemMenu && onSelectItemMenu(item);
        if (canClose !== false) {
          setOpen(false);
        }
      };
    },
    [handleSelection, name, onChange, onSelectItemMenu],
  );
  const handleCreateCustomMarket= useCallback(
    (item, props) => {
      return function onClick() {
        onCreateMarket && onCreateMarket();
        setOpen(false);
      };
    },
    [onCreateMarket],
  );
  const availableMarketsOption = useMemo(() => {
    const res = categories && categories.find((item) => item[0] === "Available Markets");
    return res;
  }, [categories])
  const myCustomMarketsOption = useMemo(() => {
    const res = categories && categories.find((item) => item[0] === "My Custom Markets");
    return res;
  }, [categories])
  const allMarketsOption = useMemo(() => {
    let res = categories && categories.filter((item) => (item[0] !== "Available Markets"/* && item[0] !== "Available Markets"*/));
    res = res.sort(sortCategories);
    for(let cat of res)
      cat[1] = cat[1].sort(sortMarkets);
    return res;
  }, [categories])
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    if (categories.length > 1) {
      setOpen(!value);
    }
  }, [value, categories]);

  return (
    <ToggleLayer
      onOutsideClick={() => onOutsideClick && onOutsideClick()}
      fixed={true}
      ResizeObserver={ResizeObserver}
      closeOnOutsideClick
      isOpen={isOpen}
      closeOnDisappear={true}
      onDisappear={() => onClose && onClose()}
      renderLayer={(props) => {
        return (
          <AnimatePresence>
            {props.isOpen && Array.isArray(categories) && categories.length > 0 ? (
              <>
                <DisplayBase
                  ref={props.layerProps.ref}
                  className={className}
                  style={props.layerProps.style}
                  width={width}
                  initial={{ opacity: 0, scale: 0.85 }} // animate from
                  animate={{ opacity: 1, scale: 1 }} // animate to
                  exit={{ opacity: 0, scale: 0.85 }} // animate exit
                  offsetY={offsetY}
                  offsetX={'-88px'}
                  transition={{
                    type: 'spring',
                    stiffness: 800,
                    damping: 35,
                    staggerChildren: false,
                  }}
                  {...rest}
                >
                  {!value && (
                    <Segment inverted color='green' tertiary raised>
                      <Icon name="arrow alternate circle down outline" inverted size="large"/>
                      Start using Joot by selecting a market below, or choose 'Create a Market' to create your own market.  You may switch markets at any time.
                    </Segment>
                  )}
                  <Display>
                    {/*
                    <Nav>
                      {!isLoading &&
                        Array.isArray(myCustomMarketsOption) &&
                        <MarketplaceCategoryStyled
                          key={1}
                          onClick={handleClick}
                          isSelected={isSelected}
                          hideCheck={hideCheck}
                          style={myCustomMarketsOption.style}
                          canEdit={canEdit}
                          label={myCustomMarketsOption[0]}
                          options={CreateOptions(myCustomMarketsOption[1])}
                          props={props}
                        />
                      }
                      {!isLoading &&
                        Array.isArray(availableMarketsOption) &&
                        <MarketplaceCategoryStyled
                          key={0}
                          onClick={handleClick}
                          isSelected={isSelected}
                          hideCheck={hideCheck}
                          style={availableMarketsOption.style}
                          canEdit={canEdit}
                          label={availableMarketsOption[0]}
                          options={CreateOptions(availableMarketsOption[1])}
                          props={props}
                          value={value}
                        />
                      }
                    </Nav>
                    */}
                    <Marketplace>
                      {/*
                      <Scrollbars
                        autoHide
                        autoHeight
                        thumbMinSize={30}
                        universal
                        autoHeightMax={900}
                        // hide horizontal scroll
                        renderTrackHorizontal={() => <div />}
                        renderThumbHorizontal={() => <div />}
                      >
                      */}
                        {!isLoading &&
                          Array.isArray(allMarketsOption) &&
                          allMarketsOption.map((item, i) => (
                            <React.Fragment key={i}>
                              {CategoryComponent ? (
                                <CategoryComponent
                                  key={i}
                                  onClick={handleClick}
                                  isSelected={isSelected}
                                  hideCheck={hideCheck}
                                  style={item.style}
                                  label={item[0]}
                                  options={CreateOptions(item[1])}
                                  props={props}
                                  {...item}
                                />
                              ) : (
                                <MarketplaceCategoryStyled
                                  key={i}
                                  onClick={handleClick}
                                  onCreateCustomMarket={handleCreateCustomMarket}
                                  isSelected={isSelected}
                                  hideCheck={hideCheck}
                                  style={item.style}
                                  label={item[0]}
                                  options={CreateOptions(item[1])}
                                  props={props}
                                  {...item}
                                />
                              )}
                            </React.Fragment>
                        ))}
                      {/*</Scrollbars>*/}
                    </Marketplace>
                    {isLoading && (
                      <TextMenuItem>
                        <SpinnerIconContainer>
                          <SpinnerIcon /> {loadingText}
                        </SpinnerIconContainer>
                      </TextMenuItem>
                    )}
                  </Display>
                </DisplayBase>
              </>
            ) : null}
          </AnimatePresence>
        );
      }}
      placement={PLACEMENT}
    >
      {({ isOpen, triggerRef, toggle }) => {
        return (
          <>
            {!children && (
              <Toggle ref={composeRefs(triggerRef, ref)} onClick={() => setOpen(!isOpen)} style={props.style}>
                <Text>{selectedText}</Text>
                <DropdownIconStyled />
              </Toggle>
            )}
            {children && children({ isOpen, triggerRef, toggle, selectedText, selected })}
          </>
        );
      }}
    </ToggleLayer>
  );
});

MarketplaceMenu.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  children: PropTypes.any,
  initialSelection: PropTypes.any,
  onSelectItemMenu: PropTypes.func,
  offsetY: PropTypes.string,
  hideCheck: PropTypes.bool,
  onOutsideClick: PropTypes.func,
  onClose: PropTypes.func,
  MenuItemCmp: PropTypes.any,
  isLoading: PropTypes.bool,
  emptyText: PropTypes.string,
  loadingText: PropTypes.string,
};

MarketplaceMenu.defaultProps = {
  width: '1000px',
};

export default MarketplaceMenu;
