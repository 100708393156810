import { useHistory } from 'react-router-dom';

const useShowAside = () => {
  const {
    location: { pathname },
  } = useHistory();
  return pathname === '/scored-media';
};

export default useShowAside;
