import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ResizeObserver from 'resize-observer-polyfill';
import { AnimatePresence } from 'framer-motion';

// Utils
import composeRefs from '../../utils/composeRefs';
import { withSlug } from '../../utils/math';

// Components
import { ToggleLayer } from 'react-laag';
import Avatar from 'react-avatar';
import Menu, { MenuItem } from './Menu';
import DropdownIcon from '../icons/DropdownIcon';
import SpinnerIcon from '../icons/SpinnerIcon';
import CheckIcon from '../icons/CheckIcon';

// Styles
const Toggle = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px 0 20px;
  padding: 5px 10px 5px 5px;
  border: 1px solid ${({ theme }) => theme.color.ten};
  border-radius: 4px;
  cursor: pointer;
`;
const Text = styled.span`
  color: ${({ theme }) => theme.color.two};
  margin-left: 10px;
  font-size: 13px;
  font-weight: 700;
`;
const AccountDetails = styled.div`
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: ${({ isMultiSelect }) => (isMultiSelect ? `15px 36px 1fr` : 'auto 100%')};
  grid-column-gap: 10px;
  padding: 5px 0;
`;
const Handle = styled.div`
  color: ${({ theme }) => theme.joot.jootBlack};
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
`;
const Followers = styled.div`
  color: ${({ theme }) => theme.joot.blueGray};
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
`;
const StyledCheckIcon = styled(CheckIcon)`
  margin-left: auto;
`;
const DropdownIconStyled = styled(DropdownIcon)`
  fill: ${({ theme }) => theme.joot.jootBlack};
  margin-left: auto;
`;

// Values
const PLACEMENT = {
  anchor: 'BOTTOM_CENTER',
  autoAdjust: true,
  snapToAnchor: false,
  triggerOffset: 35,
  scrollOffset: 16,
  preferX: 'RIGHT',
};

const MenuItemStyledLabel = styled(MenuItem)`
  padding: 15px 10px 15px 40px;
`;

export const TextMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  pointer-events: none;
`;

export const SpinnerIconContainer = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 5px;
  }
`;

const isMenuItemSelected = (selected, currentItem) => {
  if (Array.isArray(selected)) {
    return selected.filter((item) => item.username === currentItem.username).length > 0;
  }

  return selected.username === currentItem.username;
};

const AvatarMenu = React.forwardRef(function AvatarMenu(props, ref) {
  const {
    selected,
    items,
    onSelect,
    isMultiSelect,
    allSelectedText,
    emptySelectionText,
    children,
    width,
    subfieldValue,
    subfieldText,
    isLoading,
    loadingText,
    emptyText,
    srcField,
    avatarNameField,
    placement = {},
  } = props;
  const placementProp = useMemo(() => ({ ...PLACEMENT, ...placement }), [placement]);

  const isAllSelected = selected && items.length === selected.length;

  const selectedText = useMemo(() => {
    if (!isMultiSelect) {
      return selected.username;
    }

    if (isAllSelected) {
      return allSelectedText;
    }

    if (selected.length === 0) {
      return emptySelectionText;
    }

    let text = '';
    if (Array.isArray(selected) && selected.length > 0) {
      text = selected[0].username;
      text +=
        selected.length > 2
          ? `, ${selected[1].username} +${selected.length - 2}`
          : selected.length === 1
          ? ''
          : `, ${selected[1].username}`;
    }

    return text;
  }, [isMultiSelect, isAllSelected, selected, allSelectedText, emptySelectionText]);

  const selectAllHandler = useCallback(() => {
    return onSelect([...items]);
  }, [items, onSelect]);

  return (
    <ToggleLayer
      ResizeObserver={ResizeObserver}
      closeOnOutsideClick
      renderLayer={(props) => {
        function selectHandler(item, index) {
          if (!isMultiSelect) {
            onSelect(item);
          }

          if (isMultiSelect) {
            let selectedArray = !Array.isArray(selected) ? [selected] : [...selected];

            const findItem = selectedArray.find((account) => account.username === item.username);

            if (!findItem) {
              onSelect([...selectedArray, item]);
            } else {
              selectedArray.splice(index, 1);
              onSelect(selectedArray);
            }
          }

          if (!isMultiSelect) {
            props.close();
          }
        }

        return (
          <AnimatePresence>
            {props.isOpen ? (
              <Menu
                width={width}
                ref={props.layerProps.ref}
                style={props.layerProps.style}
                arrowStyle={props.arrowStyle}
                layerSide={props.layerSide}
              >
                {isMultiSelect && (
                  <MenuItemStyledLabel onClick={selectAllHandler}>
                    <Handle>Select All</Handle>
                  </MenuItemStyledLabel>
                )}
                {isLoading && (
                  <TextMenuItem>
                    <SpinnerIconContainer>
                      <SpinnerIcon /> {loadingText}
                    </SpinnerIconContainer>
                  </TextMenuItem>
                )}
                {!isLoading &&
                  Array.isArray(items) &&
                  items.map((item, i) => (
                    <MenuItem
                      key={i}
                      onClick={() => selectHandler(item, i)}
                      isMultiSelect={isMultiSelect}
                      isSelected={isMenuItemSelected(selected, item)}
                    >
                      <AccountDetails isMultiSelect={isMultiSelect}>
                        {isMultiSelect && isMenuItemSelected(selected, item) && <StyledCheckIcon stroke={'#1a1a1a'} />}
                        {isMultiSelect && !isMenuItemSelected(selected, item) && <div />}
                        <Avatar
                          name={item[avatarNameField]}
                          instagramId={item.ig_id}
                          size={43}
                          round={true}
                          src={srcField ? item[srcField] : undefined}
                        />
                        <div>
                          <Handle h6>@{item.username}</Handle>
                          <Followers>
                            {withSlug(item[subfieldValue])} {subfieldText}
                          </Followers>
                        </div>
                      </AccountDetails>
                    </MenuItem>
                  ))}
                {!isLoading && (!Array.isArray(items) || items.length === 0) && (
                  <TextMenuItem>{emptyText}</TextMenuItem>
                )}
              </Menu>
            ) : null}
          </AnimatePresence>
        );
      }}
      placement={placementProp}
    >
      {({ isOpen, triggerRef, toggle }) => {
        return (
          <>
            {!children && (
              <Toggle ref={composeRefs(triggerRef, ref)} onClick={toggle} style={props.style}>
                <Avatar
                  name={selected ? selected.name : ''}
                  instagramId={selected ? selected.ig_id : ''}
                  size={24}
                  round={true}
                />
                <Text>{selectedText}</Text>
                <DropdownIconStyled />
              </Toggle>
            )}
            {children && children({ isOpen, triggerRef, toggle, selectedText, selected })}
          </>
        );
      }}
    </ToggleLayer>
  );
});

AvatarMenu.propTypes = {
  selected: PropTypes.any,
  items: PropTypes.array,
  allSelectedText: PropTypes.string,
  emptySelectionText: PropTypes.string,
  onSelect: PropTypes.func,
  isMultiSelect: PropTypes.bool,
  isOpen: PropTypes.bool,
  width: PropTypes.string,
  subfieldValue: PropTypes.string,
  subfieldText: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  emptyText: PropTypes.string,
  srcField: PropTypes.string,
  avatarNameField: PropTypes.string,
};

AvatarMenu.defaultProps = {
  selected: {},
  items: [],
  onSelect: () => null,
  subfieldValue: 'followers_count',
  subfieldText: 'Followers',
  loadingText: 'Loading',
  emptyText: 'No items found',
  avatarNameField: 'name',
};

export default AvatarMenu;
