import React from 'react';
import styled from 'styled-components';
import composeRefs from '../../utils/composeRefs';

// Components
import OptionsMenu from './OptionsMenu';
import NavItem from '../../components/nav/NavItem';
import QuestionIconFilled from '../../components/icons/QuestionIconFilled';

// Styles
const Toggle = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
`;

const PLACEMENT = {
  anchor: 'RIGHT_BOTTOM',
  snapToAnchor: false,
  triggerOffset: 5,
  scrollOffset: 16,
  preferX: 'RIGHT',
};

const HelpMenu = React.forwardRef(function HelpMenu(props, ref) {
  const { options, value } = props;

  return (
    <OptionsMenu
      {...props}
      items={options}
      value={value}
      title={'Help Options'}
      placement={PLACEMENT}
      hideCheck={true}
    >
      {({ triggerRef, toggle }) => (
        <Toggle ref={composeRefs(triggerRef, ref)} onClick={toggle} style={props.style}>
            <NavItem to="" onClick={toggle}>
              <QuestionIconFilled />
              Help 
            </NavItem>
        </Toggle>
      )}
    </OptionsMenu>
  );
});

export default HelpMenu;
