import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import WelcomeForm from '../../components/WelcomeForms/welcome';

// Assets
import { ReactComponent as ArrowMed } from '../../assets/svg/arrow-med.svg';

// Context
import { useSignupContext } from './context/SignupContext';

// Components
import Box from '../../components/box/Box';
import Button from '../../components/Button';

// Styles
const Text = styled.div`
  ${({ isLoading }) => isLoading ? 'padding-right: 30px;' : ''}
`
const StepBox = styled(Box)`
  width: 700px;
  border-radius: 5px;
  margin: 0 auto;
  padding: 20px 25px 20px 25px;
  display: flex;
  flex-direction: column;
`;

const StepBoxFooter = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row-reverse;
  flex-content: flex-start;
  align-content: flex-end;
  flex-wrap: wrap;
`;

const NavControl = styled(Button)`
  justify-self: center;
  text-align: center;
  align-items: center;
  display: flex;
  font-size: 13px;
  width: 160px;
  > svg {
    vertical-align: inherit;
    margin-left: 20px;
  }
`;

const SignupStepWelcome = () => {
  const { setOnNext, handlerNext, changeState } = useSignupContext(4);
  useEffect(() => {
    setOnNext(async () => {
      return true;
    });
    return () => setOnNext(null);
  }, [setOnNext]);
  useEffect(() => {
    changeState('disabledNext', false);
  }, [changeState]);

  return (
    <>
      <StepBox>
        <WelcomeForm>
        </WelcomeForm>
        <StepBoxFooter>
          <NavControl primary onClick={handlerNext}>
            <Text>Let's Go</Text>
            <ArrowMed />
          </NavControl>
        </StepBoxFooter>
      </StepBox>
    </>
  );
};

export default SignupStepWelcome;
