import { API } from 'aws-amplify';
import config from '../utils/config';

const basePath = 'billing/ig';
const plansPath = 'billing/plans';

export const getBillingDetail = async (accountName) => {
  const { billing_details } = await API.get(config.apiName, `${basePath}/${accountName}`);
  return billing_details;
}

export const getBillingPlans = async () => {
  const { billing_plans } = await API.get(config.apiName, `${plansPath}`);
  return billing_plans;
}

export const createSetupIntention = async (accountName, create_new_setup_intent = false) => {
  const { client_secret } = await API.post(config.apiName, `${basePath}/${accountName}`, { body: { create_new_setup_intent }});
  return client_secret;
}

export const buyMarket = (accountName, market_name, is_business = false) => {
  return API.post(config.apiName, `${basePath}/${accountName}/buy/market`, { body: { market_name, is_business } });
}

export const unlockMarket = (accountName, market_name, is_business = false) => {
  return API.post(config.apiName, `${basePath}/${accountName}/unlock/market`, { body: { market_name, is_business } });
}

export const buyUploads = (accountName, image_upload_tier) => {
  return API.post(config.apiName, `${basePath}/${accountName}/buy/uploads`, { body: {
    image_upload_tier,
  }});
}

export const createStripeCustomer = (email, name, company, industry) => {
  return API.post(config.apiName, `billing/stripe/customer`, { body: {
    email,
    name,
    company,
    industry
  }});
}
