import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styles
const ActiveCard = styled.div`
  display: flex;
  align-items: center;
`;
const CardImg = styled.img`
  width: 40px;
`;
const LastFour = styled.span`
  font-size: 12px;
  letter-spacing: -0.26px;
  margin-left: 20px;
  text-transform: capitalize;
`;

const StripeCardDetail = ({ card }) => {
  const [cardImage, setCardImage] = useState(null);
  useEffect(() => {
    card && import(`payment-icons/min/flat/${card.brand}.svg`).then((data) => setCardImage(data.default));
  }, [card]);

  return card && (
    <ActiveCard>
      {cardImage && <CardImg src={cardImage} alt="card" />}
      <LastFour>
        {card.brand} ending in {card.last4}, exp. {card.exp_month}/{card.exp_year}
      </LastFour>
    </ActiveCard>
  );
};
StripeCardDetail.propTypes = {
  card: PropTypes.object,
};

export default memo(StripeCardDetail);
