import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

// Utils
import { STRINGS } from '../../utils/strings';

// Hooks
import { useForm } from 'react-hook-form';
import { useFacebookLink } from '../../hooks/apiHooks/facebookQueryHooks';

// Context
import { useAppContext } from '../../contexts/AppContext';
import { useSignupContext } from './context/SignupContext';

// Assets
import { ReactComponent as FacebookAuth } from '../../assets/svg/facebook-auth.svg';

// Components
import Button from '../../components/Button';
import Navigation from '../../components/Navigation';
import FacebookIcon from '../../components/icons/FacebookIcon';
import { ErrorMessage } from '../../components/form';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router-dom';

// Styles
const Wrapper = styled.article`
  max-width: 33vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Text = styled.p`
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.26px;
  margin: 60px 0 20px 0;
  color: ${({ theme }) => theme.joot.jootWhite};
  > a {
    font-weight: 700;
    color: ${({ theme }) => theme.joot.facebook};
  }
`;
const FacebookButton = styled(Button)`
  background: ${({ theme }) => theme.joot.facebook};
  padding: 8px 10px;
  width: 100%;
  color: ${({ theme }) => theme.joot.jootWhite};
  box-shadow: 0 4px 12px 0 rgba(85, 58, 161, 0.1);
  font-size: 15px;
`;

const Header = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row-reverse;
  flex-content: flex-start;
  align-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-end;
`;

const Footer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row-reverse;
  flex-content: flex-start;
  align-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
`;

const SignupStepLinkFacebook = () => {
  const { push } = useHistory();
  const [error, setError] = useState('');
  const { facebook, markets } = useAppContext();

  const {
    changeState,
    facebookConnectHandler,
    fbProfile,
    isFbAuthLogin: isFbAuthLoading,
    facebookDisconnectHandler,
    selectIgAccount,
    setOnNext,
    handlerNext,
    loadingNext,
    disabledNext,
    errors,
  } = useSignupContext(1);
  useEffect(() => {
    if (markets.MARKET_ACTIVE) {
      push('/signup/two');
    }
  }, [markets, push]);
  const { formState } = useForm({
    mode: 'onChange',
  });
  const { submit: fbSubmit } = useFacebookLink();
  const { isValid } = formState;
  useEffect(() => {
    setOnNext(async () => {
      setError('');
      const {
        account: { ig_accounts },
      } = await fbSubmit({ accessToken: fbProfile.accessToken });
      if (!ig_accounts.length) {
        setError(STRINGS.errorMessages.notAvailableIgAccounts);
        return false;
      }
      if (ig_accounts.length === 1) {
        selectIgAccount(ig_accounts[0]);
      }
      await facebook.invalidateQuery();
      return true;
    });
    return () => setOnNext(null);
  }, [setOnNext, fbProfile, facebook, fbSubmit, selectIgAccount]);

  useEffect(() => {
    changeState('disabledNext', !fbProfile || !isValid);
  }, [fbProfile, isValid, changeState]);

  const disconectHandler = useCallback(() => {
    setError('');
    facebookDisconnectHandler();
  }, [facebookDisconnectHandler]);

  return (
    <>
      <Wrapper>
        <Header>
        {fbProfile ? (
          <>
            <Avatar name={fbProfile.name} size={132} round="7px" src={fbProfile.picture_url} />
          </>
        ) : (
          <FacebookAuth />
        )}
        </Header>
        <Text>
          In order to use Joot, you must have at least one Professional or Creator Instagram Account linked to a Facebook Page. If
          you need instructions on how to do this, please refer to{' '}
          <a href="https://jootbusinesssetup.carrd.co/" target="_blank" rel="noopener noreferrer">
            This Guide
          </a>{' '}
          -- Don't worry, its easy :).
        </Text>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {!fbProfile && (
          <FacebookButton onClick={facebookConnectHandler} disabled={isFbAuthLoading}>
            <FacebookIcon width={32} height={32} />
            Authorize Facebook Account
          </FacebookButton>
        )}
        {fbProfile && (
          <FacebookButton onClick={disconectHandler} disabled={isFbAuthLoading || fbProfile.account_type}>
            <FacebookIcon width={32} height={32} />
            {isFbAuthLoading ? (
              'Disconnecting...'
            ) : (
              <>
                Connected as &nbsp;<strong>{fbProfile.name}</strong>
              </>
            )}
          </FacebookButton>
        )}
        <Footer>
          <Navigation loading={loadingNext} onNext={handlerNext} disabledNext={disabledNext} errors={errors}/>
        </Footer>
      </Wrapper>
    </>
  );
};

export default SignupStepLinkFacebook;
