import { DEFAULT_QUERY_OPTIONS } from './api-hooks-utils';
import { useAppContext } from '../../contexts/AppContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery, queryCache, useMutation } from 'react-query';
import { getImages, uploadImages, /*getLibraries, createLibrary, setLibraryMarket, renameLibrary, deleteLibrary,*/ addIGPostsToMarket, setShowSampleImages } from '../../services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as moment from 'moment';

export const useMediaList = ({ optionsSearch: optionsSearchIn = [], sortBy }, tags) => {
  const { accountName, marketName, marketType } = useAppContext();
  const query = useMemo(() => ['media_list', accountName, marketName], [accountName, marketName]);
  const state = useQuery(query, async () => {
    console.log("# Getting Images for: " + accountName + ", market: " + marketName + ", library: " + marketName);
    return getImages(accountName, marketName, marketType)
  }, {
    enabled: accountName,
    ...DEFAULT_QUERY_OPTIONS,
  });
  const dataIn = useMemo(() => state?.data?.images || [], [state]);
  const sampleImages = useMemo(() => state?.data?.sampleImages || [], [state]);
  const showSampleImages = useMemo(() => state?.data?.showSampleImages || true, [state]);
  const continuePolling = useMemo(() => state?.data?.continuePolling || false, [state]);
  const activeImports = useMemo(() => state?.data?.activeImports || 0, [state]);
  const activeScoring = useMemo(() => state?.data?.activeScoring || 0, [state]);
  const searchOptions = useMemo(() => {
    const dates = {};
    const aiTags = {};
    dataIn.forEach((item) => {
      dates[item.createdAt] = {
        name: moment(item.createdAt).format('D/M/Y'),
        value: item.createdAt,
      };
      if (item.ai_detected_tags) {
        item.ai_detected_tags.forEach((tag) => {
          aiTags[tag] = { name: tag, value: tag };
        });
      }
    });

    return {
      dates: Object.keys(dates).map((key) => dates[key]),
      ai: Object.keys(aiTags).map((key) => aiTags[key]),
      tags,
    };
  }, [dataIn, tags]);
  const data = useMemo(() => {
    let tempData = [...dataIn];
    if (optionsSearchIn.length) {
      const dates = [];
      const tags = [];
      const aiTags = [];
      optionsSearchIn.forEach(({ type, value }) => {
        if (type === 'date') {
          dates.push(value);
        }
        if (type === 'tag') {
          tags.push(value);
        }
        if (type === 'ai') {
          aiTags.push(value);
        }
      });
      const filterByTag = (item, field, tags) => {
        if (tags.length === 0) {
          return true;
        }
        if (!item[field]) {
          return false
        }
        for (let tag of tags) {
          if (!item[field].includes(tag)) {
            return false;
          }
        }
        return true;
      };
      tempData = tempData.filter((item) => {
        return filterByTag(item, 'tags', tags)
          && filterByTag(item, 'ai_detected_tags', aiTags)
          && (dates.length === 0 || dates.includes(item.createdAt));
      });
    }
    const compareDates = (a, b, field) => {
      const dateA = moment(a[field]);
      const dateB = moment(b[field]);
      if (dateA.isBefore(dateB)) {
        return 1;
      }
      if (dateB.isBefore(dateA)) {
        return -1;
      }
      return 0;
    };
    const compareRating = (a, b) => {
      if (a.rating > b.rating) {
        return -1;
      }
      if (a.rating < b.rating) {
        return 1;
      }
      return 0;
    }
    const compareStarred = (a, b) => {
      if (a.starred !== b.starred) {
        if (a.starred) {
          return -1
        }
        return 1
      }
      return compareRating(a, b)
    }
    const sortFuns = {
      createdAt: (a, b) => compareDates(a, b, 'createdAt'),
      capturedAt: (a, b) => compareDates(a, b, 'capturedAt'),
      rank: (a, b) => compareRating(a, b),
      starred: (a, b) => compareStarred(a, b),
    };
    return tempData.sort(sortFuns[sortBy.value]);
  }, [dataIn, sortBy, optionsSearchIn]);

  const invalidateQuery = useCallback(() => {
    queryCache.invalidateQueries(query);
  }, [query]);
  const changeCache = useCallback((data) => {
    const imageData = state.data ? state.data : {}
    imageData.images = data;
    queryCache.setQueryData(query, imageData);
  }, [query, state]);
  const fitlterBy = useCallback(
    (predicate) => {
      changeCache(data.filter((item) => predicate(item)));
    },
    [data, changeCache],
  );

  return {
    ...state,
    invalidateQuery,
    changeCache,
    fitlterBy,
    data,
    sampleImages,
    showSampleImages,
    searchOptions,
    continuePolling,
    activeImports,
    activeScoring,
    isLoaded: !!state.data,
  };
};

export const useImagesUpload = () => {
  const { jootAccount, marketName, marketType } = useAppContext();

  const [mutate, state] = useMutation(async ({ images }) => uploadImages(jootAccount.accountName, marketName, marketType, images), {
    throwOnError: true,
  });

  return {
    ...state,
    submit: mutate,
  };
};

/*
export const useCreateLibrary = () => {
  const { jootAccount } = useAppContext()
  const [
    mutate,
    { status, data, error, reset },
  ] = useMutation(async ({ library_name, default_market_name }) =>
    createLibrary(jootAccount.account_name, library_name, default_market_name ),
    { throwOnError: true }
  );

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
}

export const useRenameLibrary = () => {
  const { jootAccount } = useAppContext()
  const [
    mutate,
    { status, data, error, reset },
  ] = useMutation(async ({ library_name, new_library_name, default_market_name }) =>
    renameLibrary(jootAccount.account_name, library_name, new_library_name, default_market_name ),
    { throwOnError: true }
  );

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
}

export const useSetLibraryMarket = () => {
  const { jootAccount } = useAppContext()
  const [
    mutate,
    { status, data, error, reset },
  ] = useMutation(async ({ library_name, market_name }) => {
    return setLibraryMarket(jootAccount.account_name, library_name, market_name );},
    { throwOnError: true }
  );

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
}

export const useDeleteLibrary = () => {
  const { jootAccount } = useAppContext()
  const [
    mutate,
    { status, data, error, reset },
  ] = useMutation(async ({ library_name }) =>
    deleteLibrary(jootAccount.account_name, library_name),
    { throwOnError: true }
  );

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
}
*/

/*
export const useGetCreateLibrary = (jootAccount, options = {}) => {
  const [_currentLibrary, setCurrentLibrary] = useState(null);
  const { isAuthenticated } = useAuthContext();
  const query = ['libraries', jootAccount?.account_name];
  const { data, ...state } = useQuery(
    query,
    async () => {
      console.log("Calling getLibraries(" + jootAccount?.account_name + ")");
      return getLibraries(jootAccount?.account_name);
    },
    {
      enabled: isAuthenticated && jootAccount?.account_name,
      ...DEFAULT_QUERY_OPTIONS,
      ...options,
    },
  );
  const invalidateQuery = useCallback(() => {
    return queryCache.invalidateQueries(query);
  }, [query]);
  const changeCache = useCallback((data) => queryCache.setQueryData(query, data), [query]);
  useEffect(() => {
    setCurrentLibrary(() => {
      if (!currentLibrary && data && data.length > 0) {
        console.log("setting the current library to " + JSON.stringify(data[0].library_name));
        return data[0];
      }
      return currentLibrary;
    });
  }, [data]);
  const currentLibrary = useMemo(() => {
    if (_currentLibrary?.account_name !== jootAccount?.account_name) {
      return null;
    }
    return _currentLibrary;
  }, [_currentLibrary, jootAccount])

  return {
    ...state,
    data,
    currentLibrary,
    setCurrentLibrary,
    invalidateQuery,
    changeCache,
  }
};
*/

export const useDownloadInstagramPosts = () => {
  const { jootAccount } = useAppContext()
  const [
    mutate,
    { status, data, error, reset },
  ] = useMutation(async ({ market_name, username, count }) =>
    addIGPostsToMarket(jootAccount.account_name, market_name, username, count ),
    { throwOnError: true }
  );

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
}

export const useSetShowSampleImages = () => {
  const { jootAccount } = useAppContext()
  const [
    mutate,
    { status, data, error, reset },
  ] = useMutation(async ({ library_name, show }) => {
    return setShowSampleImages(jootAccount.account_name, library_name, show);},
    { throwOnError: true }
  );

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
}