import * as React from 'react';

function NextIcon(props) {
  return (
    <svg width={5} height={8} viewBox='0 0 5 8' {...props}>
      <path
        fill='#494C9E'
        fillRule='evenodd'
        d='M1.768.306a1.029 1.029 0 00-1.465 0 1.051 1.051 0 000 1.478L2.5 4 .303 6.216a1.051 1.051 0 000 1.478c.405.408 1.06.408 1.465 0l2.929-2.955a1.051 1.051 0 000-1.478L1.767.306z'
      />
    </svg>
  );
}

export default NextIcon;
