const slugConversion = {
  k: 1000,
};

export function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const toNumber = (amount) => {
  if (!amount) {
    return 0;
  }

  if (!amount.replace) {
    return amount;
  }

  const slug = amount.replace(/\d+|^\s+|\s+$/g, '');
  const number = amount.replace(/\D/g, '');
  const factor = slugConversion[slug] ? slugConversion[slug] : 1;
  const value = number * factor;

  return value;
};

export const withSlug = (value) => {
  let stringValue = value;
  let decimals = 1;

  if (value > 1000) {
    const rest = value % 1000;
    if (rest < 100) {
      decimals = 0;
    }
    stringValue = (stringValue / 1000).toFixed(decimals) + 'k';
  }

  return stringValue;
};
