import { API } from 'aws-amplify';
import config from '../utils/config';
import { generateFilename } from '../utils/objectUtils';

const basePath = 'image';
const intervalIds = [];

export const deleteImage = (accountName, marketName, images) => {
  const encodedMarketName = encodeURIComponent(marketName);
  //const promises = images.map(({ id }) => API.del(config.apiName, `${basePath}/${accountName}?imageId=${id}&library=${encodedLibraryName}`, {
  const ids = images.map(({ id }) => id ); 
  console.log("deleting images: " + JSON.stringify(ids));
  return API.del(config.apiName, `${basePath}/${accountName}`, {
    queryStringParameters: {
      imageId: ids,
      library: encodedMarketName 
    },
  });
  //return Promise.all(promises);
};

export const predictImage = (accountName, marketName, marketType, images) => {
  const ids = images.map(({ id }) => id);
  return API.put(config.apiName, `${basePath}/${accountName}/predict`, {
    queryStringParameters: {
      imageId: ids,
      library: marketName,
      marketName: marketName,
      marketType: marketType
    }});
};

export const allImageTags = async (_, accountName, marketName) => {
  const encodedMarketName = encodeURIComponent(marketName);
  const { tags } = await API.get(config.apiName, `${basePath}/${accountName}/tag?library=${encodedMarketName}`);
  return tags;
};

export const starImage = async (images, accountName, marketName ) => {
  const encodedMarketName = encodeURIComponent(marketName);
  const toStar = images.filter((item) => !item.starred).map((item) => item.id);
  const toRemoveStar = images.filter((item) => item.starred).map((item) => item.id);
  const doOperation = (method, imageId) => {
    return imageId.length === 0
      ? null
      : API[method](config.apiName, `${basePath}/${accountName}/star`, {
        queryStringParameters: {
          imageId,
          library: encodedMarketName
        },
      });
  };
  return Promise.all([doOperation('put', toStar), doOperation('del', toRemoveStar)]);
};

const doTagOperation = async (method, accountName, marketName, images, tags) => {
  if (!images.length || !tags.length) {
    return;
  }
  const encodedMarketName = encodeURIComponent(marketName);
  // url encode tags?
  const imageId = images.map((item) => item.id);
  return API[method](config.apiName, `${basePath}/${accountName}/tag`, {
    queryStringParameters: {
      imageId,
      tag: tags,
      library: encodedMarketName
    },
  });
};

export const addImagesTags = async (accountName, marketName, images, tags) => {
  return doTagOperation('put', accountName, marketName, images, tags);
};

export const removeImagesTags = async (accountName, marketName, images, tags) => {
  return doTagOperation('del', accountName, marketName, images, tags);
};

export const uploadImages = async (accountName, marketName, marketType, images) => {
  let fileAndFilename = images.map((file) => ({
    file,
    name: generateFilename(file.name),
  }));
  const fileNames = fileAndFilename.map(({ name }) => name);
  const { presigned_urls } = await API.get(config.apiName, `${basePath}/${accountName}/presigned`, {
    queryStringParameters: {
      filename: fileNames,
      library: marketName
    },
  });
  fileAndFilename = fileAndFilename.map((item, index) => ({
    ...item,
    url: presigned_urls[index],
  }));
  let promises = [];
  for (let index in fileAndFilename) {
    const file = fileAndFilename[index].file;
    const url = fileAndFilename[index].url;
    promises.push(
      fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      }),
    );
    if (index % 5 === 0) {
      // Send the images 5 by 5
      await Promise.all(promises);
      promises = [];
    }
  }
  if (promises.length > 0) {
    await Promise.all(promises);
  }
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      getImages(accountName, marketName, marketType, marketName).then(({ images, continuePolling }) => {
        const currentFilesName = images.map((item) => item.original_image_name);
        let difference = fileNames.filter((x) => !currentFilesName.includes(x));
        if (difference.length === 0 && !continuePolling) {
          clearInterval(intervalId);
          resolve(images);
        }
      });
    }, 3000);
    intervalIds.push(intervalId);
  });
 /*
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      getImages(igUserName, marketName).then((images) => {
        const currentFilesName = images.map(({ data: { original_image_name } }) => original_image_name);
        let difference = fileNames.filter((x) => !currentFilesName.includes(x));
        if (difference.length === 0) {
          const currentRatings = images.map(({ rating }) => rating);
          difference = currentRatings.filter((x) => x == 0);
          if (difference.length === 0) {
            clearInterval(intervalId);
            resolve();
          }
        }
      });
    }, 3000);
  });
  */
};

export const getImages = async (accountName, marketName, marketType ) => {
  // url encode marketName libraryName
  const encodedMarketName = encodeURIComponent(marketName);
  const results = await API.get(config.apiName, `${basePath}/${accountName}`, { queryStringParameters: { marketName: encodedMarketName, marketType: marketType, library: encodedMarketName} });
  const images = results.images;
  const activeImports = results.active_imports;
  const continuePolling = (results.active_imports && results.active_imports > 0) || images.find((val) => val.order === 0); 
  const sampleImages = results.sample_images;
  const showSampleImages = results.show_sample_images;
  // sort by score and add the order property to each image
  const compareScore = (a, b) => {
    if (a.rating > b.rating) {
      return -1;
    }
    if (a.rating < b.rating) {
      return 1;
    }
    return 0;
  };
  const scores = images.sort(compareScore); // sorted scored items
  return { images: scores, activeImports, continuePolling, sampleImages, showSampleImages };
};

/*
export const getScores = async (igUserName, marketName) => {
  const { scores } = await API.get(config.apiName, `${basePath}/${igUserName}/score`, { queryStringParameters: { market_name: marketName } });
  return scores;
};

export const getImages = async (igUserName) => {
  if (!igUserName)
    return;
  const results = await  API.get(config.apiName, `${basePath}/${igUserName}`);
  const images = results.images;
  const ret_images = images.map((item) => ({
    ...item,
    createdAt: moment(item.data.upload_date).format('YYYY-MM-DD'),
    capturedAt: moment(item.data.capture_date).format('YYYY-MM-DD'),
    ...item.data,
    ...{
      src: item.data.presigned_url,
      src1x: item.data.presigned_url_200,
      src2x: item.data.presigned_url_400,
    },
  }));

  return ret_images;
};
*/

export const loadImagesUntilScored = async (accountName, marketName, marketType ) => {
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      console.log("Getting Images for: " + accountName + ", market: " + marketName + ", library: " + marketName);
      getImages(accountName, marketName, marketType, marketName).then(( { continuePolling } ) => {
        if (!continuePolling) {
          clearInterval(intervalId);
          resolve();
        }
      });
    }, 10000);
    intervalIds.push(intervalId);
  });
};

export const getImageScores = async (accountName, marketName ) => {
  const { scores } = await API.get(config.apiName, `${basePath}/${accountName}/score`, { queryStringParameters: { market_name: marketName, library: marketName} });
  return scores;
};

export const getImageScore = (accountName) => {
  return new Promise((resolve) => {
    return resolve([
      {
        rating: 5,
        uploads: 5,
        percent: 5,
      },
      {
        rating: 4,
        uploads: 4,
        percent: 4,
      },
      {
        rating: 3,
        uploads: 3,
        percent: 3,
      },
      {
        rating: 2,
        uploads: 2,
        percent: 2,
      },
      {
        rating: 1,
        uploads: 1,
        percent: 1,
      },
    ]);
  });
};

/*
export const getLibraries = async (accountName) => {
  const { libraries } = await API.get(config.apiName, `${basePath}/${accountName}/libraries`, {});
  return libraries;
};

export const createLibrary = async (accountName, library_name, default_market_name ) => {
  const data = await API.post(config.apiName, `${basePath}/${accountName}/libraries`, {
    body: {
      library_name,
      default_market_name
    },
  });
  return data;
};

export const renameLibrary = async (accountName, library_name, new_library_name, default_market_name) => {
  const data = await API.put(config.apiName, `${basePath}/${accountName}/libraries`, {
    body: {
      library_name,
      new_library_name,
      default_market_name
    },
  });
  return data;
};
*/

/*
export const setLibraryMarket = async (accountName, library_name, market_name) => {
  console.log("setLibraryMarket: library_name: " + library_name + " market_name: " + market_name);
  const encodedURI = encodeURI(`${basePath}/${accountName}/libraries/${library_name}/market`);
  const data = await API.put(config.apiName, encodedURI, {
    body: {
      market_name: market_name
    },
  });
  return data;
};
*/

/*
export const deleteLibrary = async (accountName, libraryName) => {
  const encodedURI = encodeURI(`${basePath}/${accountName}/libraries/${libraryName}`);
  const data = await API.del(config.apiName, encodedURI, {});
  return data;
};
*/

export const addIGPostsToMarket = async (accountName, marketName, username, count) => {
  const encodedURI = encodeURI(`${basePath}/${accountName}/libraries/${marketName}/ig`);
  const data = await API.put(config.apiName, encodedURI, {
    body: {
      username,
      count
    },
  });
  return data;
};

export const setShowSampleImages = async (accountName, library_name, show) => {
  const encodedURI = encodeURI(`${basePath}/${accountName}/libraries/${library_name}/samples`);
  const data = await API.put(config.apiName, encodedURI, {
    body: {
      show: show 
    },
  });
  return data;
};