import React from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

// Assets
import { ReactComponent as AddSvg } from '../../assets/svg/plus-add.svg';

const StyledOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 3px;
  overflow: hidden;
  will-change: opacity;
  background: ${({ theme }) => rgba(theme.color.two, 0.7)};
  a > svg {
    transform: scale(0.9);
    transform-origin: center;
    transition: 0.2s transform ${({ theme }) => theme.transition.cubicEase};
  }
`;
const AddLink = styled(NavLink)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    svg {
      transform: scale(1);
    }
  }
`;

// Motion
const overlayMotion = {
  rest: {
    opacity: 0,
    transition: {
      type: 'spring',
      delay: 0,
      stiffness: 500,
      damping: 60,
      mass: 1,
    },
  },
  hover: {
    opacity: 1,
    transition: {
      type: 'spring',
      delay: 0,
      stiffness: 500,
      damping: 60,
      mass: 1,
    },
  },
};

const SingleSelectOverlay = (props) => {
  return (
    <StyledOverlay variants={overlayMotion} {...props}>
      <AddLink to='#'>
        <AddSvg />
      </AddLink>
    </StyledOverlay>
  );
};

export default SingleSelectOverlay;
