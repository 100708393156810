import React from 'react';

function SvgComponent({ active, ...props }) {
  return (
	<svg height="18" width="18" viewBox="0 0 24 24">
		<path fill={active ? '#367ca3': '#9FB5C1'} d="M17,5.1l-5-5l-5,5l1.4,1.4L11,3.9V24h2V4l2.6,2.6L17,5.1z M0,15l1.4,1.4L4,13.9V22H0v2h6V13.8l2.7,2.7l1.4-1.4  l-5-5L0,15z M20,13.8l2.7,2.7l1.4-1.4l-5-5l-5,5l1.4,1.4l2.6-2.6V24h6v-2h-4V13.8z"/>
	</svg>
  );
}

export default SvgComponent;
