import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Context
import { useAppContext } from '../../../contexts/AppContext';

// Assets
import { ReactComponent as AddItem } from '../../../assets/svg/add-item.svg';
import { ReactComponent as CheckItem } from '../../../assets/svg/selected.svg';

// Components
import Avatar from 'react-avatar';
import Button from '../../../components/Button';
import DeleteIcon from '../../../components/icons/DeleteIcon';
import Title from '../../../components/Title';

// Styles
const CenterAlign = css`
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
`;
const StyledSelectedSetting = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 15px;
  border: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.1)};
`;
const StyledDeleteIcon = styled(DeleteIcon)`
  ${CenterAlign};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
const StyledPrice = styled.span`
  ${CenterAlign};
  font-size: 16px;
  letter-spacing: -0.31px;
  text-align: right;
  pointer-events: none;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.6)};
`;
const ButtonText = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.joot.potteryBlue};
  svg {
    margin-right: 20px;
  }
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const AccountTitle = styled(Title)`
  margin-left: 20px;
  line-height: 18px;
  > span {
    display: block;
    font-size: 12px;
    letter-spacing: -0.31px;
    font-weight: 300;
    color: ${(props) => rgba(props.theme.joot.jootBlack, 0.6)};
  }
`;
const CompetitorAccountWrapper = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 15px 0 0 0;
  padding: 0px 20px 0px 0px;
  z-index: 1;
`;
const Competitor = styled.li`
  position: relative;
  width: 30px;
  &:nth-child(1) {
    z-index: 1;
  }
  &:nth-child(2) {
    z-index: 2;
  }
  &:nth-child(3) {
    z-index: 3;
  }
  &:nth-child(4) {
    z-index: 4;
  }
  &:nth-child(5) {
    z-index: 5;
  }
  &:nth-child(6) {
    z-index: 6;
  }
  &:nth-child(7) {
    z-index: 7;
  }
  &:nth-child(8) {
    z-index: 8;
  }
  &:nth-child(9) {
    z-index: 9;
  }
`;
const MarketWrapper = styled.div`
  display: block;
`;
const MarketTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;
const EditButton = styled(Button)`
  font-size: 10px;
  color: ${({ theme }) => theme.joot.potteryBlue};
  margin-left: 10px;
`;

// TODO: This component has a lot of logic. It would be great to make refactory.
const SelectedSetting = ({
  children,
  hasRemove,
  isAddOn,
  isOption,
  isMarket,
  igAccount,
  current,
  onAddClick = () => {},
  onOptionClick = () => {},
  onEditClick = () => {},
  onDelete = () => {},
  market,
  isAddOnProps = {},
  isOptionProps = {},
  ...props
}) => {
  const { joot } = useTheme();
  let deleteColor = rgba(joot.jootBlack, 0.3);

  return (
    <StyledSelectedSetting {...props}>
      {igAccount && (
        <>
          <Flex>
            <Avatar
              name={igAccount?.name}
              src={igAccount?.profile_picture_url}
              instagramId={igAccount?.ig_id}
              size={47}
              round={true}
            />
            <AccountTitle h5 noMargin>
              {current && <span>Currently logged in as</span>}
              {igAccount?.username ? `@${igAccount.username}` : ''}
            </AccountTitle>
          </Flex>
          {onDelete && (
            <StyledDeleteIcon onClick={() => onDelete(igAccount)} width={18} height={16} fill={`${deleteColor}`} />
          )}
        </>
      )}
      {isMarket && (
        <MarketWrapper>
          <MarketTitle>
            {market.market_name} {market.market_type === 'free' && <EditButton onClick={() => onEditClick(market)}>Edit</EditButton>}
          </MarketTitle>
          <CompetitorAccountWrapper>
            {(!market.market_system_type || market.market_system_type == "ACCOUNT") && market.market_accounts
              .map((member) => (
                <Competitor key={member.username}>
                  <Avatar
                    name={member.name}
                    src={member.profile_picture_url}
                    instagramId={member.ig_id}
                    size={47}
                    round={true}
                  />
                </Competitor>
              ))}
          </CompetitorAccountWrapper>
          {onDelete && (
            <StyledDeleteIcon onClick={() => onDelete(market)} width={18} height={16} fill={`${deleteColor}`} />
          )}
        </MarketWrapper>
      )}
      {isAddOn && (
        <>
          <ButtonText onClick={onAddClick}>
            {isAddOnProps.selected ? <CheckItem /> : <AddItem />}
            {props.label}
          </ButtonText>
          {props.price && <StyledPrice>+ ${props.price}</StyledPrice>}
        </>
      )}
      {isOption && (
        <>
          <ButtonText onClick={onOptionClick}>
            {isOptionProps.selected ? <CheckItem /> : <AddItem />}
            {props.label}
          </ButtonText>
        </>
      )}
    </StyledSelectedSetting>
  );
};

export default SelectedSetting;
