import React, { memo } from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Components
import Title from '../Title';

// Styles
const VideoArea = styled.div`
  align-items: center;
  margin-top: 30px;
  margin-left: 40px;
`;
const StepBody = styled.div`
  padding: 20px 0px 20px 0px;
`;
const AccountTitle = styled(Title)`
  line-height: 20px;
  > span {
    margin-top: 30px;
    display: block;
    font-weight: 800;
    font-size: 38pt;
    text-align: center;
  }
`;
const SubTitle = styled(Title)`
  line-height: 20px;
  > span {
    margin-top: 30px;
    display: block;
    font-weight: 600;
    font-size: 20pt;
    text-align: center;
  }
`;
const TitleArea = styled.div`
  align-items: center;
`;
const Text = styled.p`
  margin-top: 30px;
  font-size: 23px;
  line-height: 23px;
  font-weight: 700;
  letter-spacing: -0.26px;
  text-align: center;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;

const WelcomeForm= () => {

  return (
    <>
      <TitleArea>
        <AccountTitle h5 noMargin>
          <span>CONGRATULATIONS! &#127881;</span>
        </AccountTitle>
        <SubTitle h5 noMargin>
          <span>You’re now signed up for Social Vision™.</span>
        </SubTitle>
      </TitleArea>
      <StepBody>
        <VideoArea h5 noMargin>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/TWzIscAFGQA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </VideoArea>
        <Text>View the short Product Tour to get started.</Text>
      </StepBody>
    </>
  );
};

export default memo(WelcomeForm);
