import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import Box from './box/Box';
import { fadeIn, slideDown } from './form/mixins/animations';

// Assets
import { ReactComponent as CloseSvg } from '../assets/svg/close.svg';
import { ReactComponent as Prev } from '../assets/svg/prev.svg';
import { ReactComponent as Next } from '../assets/svg/next.svg';

// Components
import Button from './Button';

// Styles
const ModalOverlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: ${(props) => rgba(props.theme.joot.jootBlack, 0.5)};
  cursor: pointer;
  z-index: 999;
  animation: ${fadeIn} 0.15s ease-out;
`;

const ModalContainer = styled(Box)`
  position: relative;
  width: 100%;
  z-index: 998;
  border-radius: 4px;
  cursor: default;
  animation: ${slideDown} 0.15s ease-out;
  max-width: ${(props) => props.maxWidth};
`;

const Overflow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 4px;
  padding: ${(props) => props.padding};
  cursor: default;
  animation: ${slideDown} 0.15s ease-out;
  background-color: ${(props) => props.theme.joot.jootWhite};
  width: 100%;
  max-height: 80vh;
  min-height: 10rem;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`;

const Close = styled(CloseSvg)`
  pointer-events: none;
  position: absolute;
  top: -35px;
  right: 5px;
  animation: ${fadeIn} 0.15s ease-out;
`;
const ButtonPrev = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) scale(1);
  left: -70px;
  padding: 20px;
  animation: ${fadeIn} 0.15s ease-out;
  &:active {
    transform: translateY(-50%) scale(0.97);
  }
  > svg {
    margin: 0;
  }
`;
const ButtonNext = styled(ButtonPrev)`
  left: unset;
  right: -70px;
`;

export const stopPropagation = (e) => {
  e.stopPropagation();
};

const Modal = ({ isVisible, isSlider, isCancelable, onHide, children, onPrevClick, onNextClick, ...props }) => {
  return isVisible
    ? ReactDOM.createPortal(
        <ModalOverlay onClick={isCancelable ? onHide : undefined} >
          <ModalContainer maxWidth={props.maxWidth} onClick={stopPropagation} isSlider={isSlider}>
            <Overflow padding={props.padding}>
              {children}
              {isCancelable && <Close />}
              {isSlider ? (
                <>
                  <ButtonPrev onClick={onPrevClick}>
                    <Prev />
                  </ButtonPrev>
                  <ButtonNext onClick={onNextClick}>
                    <Next />
                  </ButtonNext>
                </>
              ) : null}
            </Overflow>
          </ModalContainer>
        </ModalOverlay>,
        document.body,
      )
    : null;
};

Modal.defaultProps = {
  isSlider: false,
  maxWidth: '80vw',
  padding: '0',
};

export default Modal;
