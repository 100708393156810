import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import rgba from 'polished/lib/color/rgba';

// Utils
import config from '../../../utils/config';

// Hooks
import { useCreateSetupIntention, useGetBillingDetail } from '../../../hooks/apiHooks/billingApiHook';

// Context
import { useAppContext } from '../../../contexts/AppContext';
import { useMediaContext } from '../contexts/MediaContext';

// Components
import Button from '../../../components/Button';
import Table from '../../../components/Table';
import Title from '../../../components/Title';
import { useStripeCard } from '../../../components/StripeCard';
import { FormControl } from '../../../components/form';
import StripeCardForm from '../../../components/StripeCardForm';
import Avatar from 'react-avatar';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCardDetail from '../../../components/StripeCardDetail';

// Styles
const Wrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.1)};
  display: flex;
`;
const WrapperCol = styled(Wrapper)`
  flex-flow: Column;

`;
const SettingStyle = css`
  position: relative;
  padding: 15px 20px;
  border-radius: 15px;
  border: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.1)};
`;
const StyledBillingTab = styled.div`
  display: block;
`;
const BillingDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  align-items: flex-start;
  padding-bottom: 50px;
`;
const BillingTable = styled.div`
  border-top: 1px solid ${({ theme }) => theme.joot.paleGray};
`;
const BillSummary = styled.div`
  ${SettingStyle};
`;
const Item = styled.div`
  font-size: 12px;
  letter-spacing: -0.31px;
  margin-bottom: 10px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.3)};
`;
const Total = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.1)};
`;
const ActiveAccount = styled(Avatar)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
const PaymentMethod = styled.div`
  ${SettingStyle};
  padding-bottom: 20px;
`;
const Receipt = styled(Button)`
  color: ${({ theme }) => theme.joot.potteryBlue};
  display: unset;
  margin-left: auto;
`;
const CancelSub = styled(Button)`
  color: ${({ theme }) => theme.joot.potteryBlue};
  display: unset;
  margin-left: auto;
`;
const PaymentTitle = styled(Title)`
  display: flex;
  margin: 0;
`;
const EditButton = styled(Button)`
  font-size: 10px;
  color: ${({ theme }) => theme.joot.potteryBlue};
  margin-left: 10px;
`;

const CreditCard = ({ paymentMethod: { card }, onUpdate }) => {
  const [loader, setLoader] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const handleEdit = useCallback((e) => {
    setShowEdit((prev) => !prev);
  }, []);
  const { accountName } = useAppContext();
  const { saveCard } = useStripeCard();
  const [name, setName] = useState('');
  const handleNameChange = useCallback((value) => {
    setName(() => value);
  }, []);
  const { data: client_secret, submit } = useCreateSetupIntention(false);
  useEffect(() => {
    if (accountName) {
      submit(accountName);
    }
  }, [submit, accountName]);
  const handleSend = useCallback(async () => {
    if (name) {
      setLoader(true);
      await saveCard(name, client_secret);
      setLoader(false);
      setShowEdit(false);
      onUpdate();
    }
  }, [name, saveCard, onUpdate, client_secret]);

  return (
    <PaymentMethod>
      <PaymentTitle menu>
        Payment Method
        <EditButton onClick={handleEdit}>{showEdit ? 'Cancel' : 'Edit'}</EditButton>
      </PaymentTitle>
      {!showEdit && <StripeCardDetail card={card} />}
      {showEdit && (
        <>
          <FormControl.Label>Existing Payment Method</FormControl.Label>
          <Wrapper>
            <StripeCardDetail card={card} />
          </Wrapper>
          <FormControl.Label>Replace with New Payment Method</FormControl.Label>
          <WrapperCol>
            <StripeCardForm value={name} onCardChange={handleNameChange} />
          </WrapperCol>
          <FormControl>
            <Button primary onClick={handleSend} disabled={loader}>
              Update
            </Button>
          </FormControl>
        </>
      )}
    </PaymentMethod>
  );
};

const BillingTab = () => {
  const { igAccount } = useAppContext();
  const {
    data: { num_markets, image_uploads_remaining, payments, subscriptions } = {},
    paymentMethod,
    invalidateQuery,
  } = useGetBillingDetail();
  const { imagesList } = useMediaContext();
  const stripePromise = loadStripe(config.stripe.key);
  const handleCardUpdate = useCallback(() => invalidateQuery(), [invalidateQuery]);
  const totalImages = useMemo(
    () => (imagesList ? imagesList.length + image_uploads_remaining : image_uploads_remaining),
    [image_uploads_remaining, imagesList],
  );
  const handlerCancelClick = () => { };
  //const handlerRecieptClick = (url) => { window.open(url); };

  return (
    <StyledBillingTab>
      <BillingDetails>
        <BillSummary>
          <ActiveAccount
            name={igAccount?.name}
            src={igAccount?.profile_picture_url}
            instagramId={igAccount?.ig_id}
            size={43}
            round={true}
          />
          <Title menu>Bill Summary</Title>
          {num_markets && (
            <Item>
              {num_markets} {num_markets > 1 ? 'Annual Market subsriptions' : 'Annual Market subscription'}
            </Item>
          )}
          <BillingTable>
            <Table>
              <Table.thead>
                <Table.tr>
                  <Table.th>Market Name</Table.th>
                  <Table.th>Subscription Renewal Date</Table.th>
                  <Table.th>Amount</Table.th>
                  <Table.th>Status</Table.th>
                  <Table.th>Action</Table.th>
                </Table.tr>
              </Table.thead>
              <Table.tbody>
                {subscriptions &&
                  subscriptions.map((item) => (
                    <Table.tr key={item.id}>
                      <Table.td>{item.metadata.market_name}</Table.td>
                      <Table.td>{moment.unix(item.current_period_end).format('D MMM YYYY')}</Table.td>
                      <Table.td>${item.amount/100}</Table.td>
                      <Table.td>{item.status}</Table.td>
                      <Table.td>
                        <CancelSub onClick={handlerCancelClick}>Cancel</CancelSub>
                      </Table.td>
                    </Table.tr>
                  ))}
              </Table.tbody>
            </Table>
          </BillingTable>
        </BillSummary>
        <Elements stripe={stripePromise}>
          {paymentMethod && (
            <CreditCard paymentMethod={paymentMethod} onUpdate={handleCardUpdate} />
          )}
        </Elements>
      </BillingDetails>
      <BillingTable>
        <Title menu>Past Payments</Title>
        <Table>
          <Table.thead>
            <Table.tr>
              <Table.th>Date</Table.th>
              <Table.th>Description</Table.th>
              <Table.th>Amount</Table.th>
              <Table.th>Action</Table.th>
            </Table.tr>
          </Table.thead>
          <Table.tbody>
            {payments &&
              payments.map((item) => (
                <Table.tr key={item.id}>
                  <Table.td>{moment.unix(item.created).format('D MMM YYYY')}</Table.td>
                  <Table.td>{item.description} ({item.metadata.market_name})</Table.td>
                  <Table.td>${item.amount/100}</Table.td>
                  <Table.td>
                    <Receipt onClick={() => {window.open(item.receipt_url);}}>View Reciept</Receipt>
                  </Table.td>
                </Table.tr>
              ))}
          </Table.tbody>
        </Table>
      </BillingTable>
    </StyledBillingTab>
  );
};

export default BillingTab;
