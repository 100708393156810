import {useCallback, useEffect, useState} from 'react';

const useMediaSelection = (imagesList) => {
  const [selectedMedias, setSelectedMedias] = useState([]);
  useEffect(() => {
    if (imagesList.length) {
      setSelectedMedias(prev => {
        const idbyPosition = {};
        imagesList.forEach(item => idbyPosition[item.id] = item);
        return prev.map(({ id }) => idbyPosition[id]);
      })
    }
  }, [imagesList])

  const selectionChangeHandler = useCallback(
    (image) => {
      let newSelected = [...selectedMedias];

      const imageFind = newSelected.find((item) => item.id === image.id);
      if (!imageFind && image) {
        newSelected.push(image);
      } else if (image) {
        newSelected = newSelected.filter((item) => item.id !== image.id);
      }
      setSelectedMedias(newSelected);
    },
    [selectedMedias],
  );

  const isSelected = useCallback(
    (image) => {
      const imageFind = selectedMedias.find((item) => item.id === image.id);
      return imageFind ? true : false;
    },
    [selectedMedias],
  );

  const selectionOrder = useCallback(
    (image) => {
      const index = selectedMedias.findIndex((item) => item.id === image.id);
      return index === -1 ? undefined : index + 1;
    },
    [selectedMedias],
  );

  return {
    selectedMedias,
    setSelectedMedias,
    selectionChangeHandler,
    isSelected,
    selectionOrder
  };
};

export default useMediaSelection;
