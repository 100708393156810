import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// Hooks
import { useForm } from 'react-hook-form';
import { useSignup, useSignupConfirm } from '../../hooks/apiHooks/authApiHooks';

// Contexts
import { useAuthContext } from '../../contexts/AuthContext';

// Assets
import { ReactComponent as ArrowLong } from '../../assets/svg/arrow-long.svg';

// Components
import { ErrorMessage, FormControl, PasswordInput, Checkbox} from '../../components/form';
import Button from '../../components/Button';
import AuthIntro from './AuthIntro';
import LoginLabel from './components/LoginLabel';
import LoginInput from './components/LoginInput';
import Title from '../../components/Title';

// Styles
const StyledLink = styled(Link)`
  margin-left: 5px;
  color: ${({ theme }) => theme.joot.cinnabar};
`;

const Text = styled(Title)`
  margin: 0 0 0 5px;
  font-size: 13px;
  color: ${({ theme }) => theme.joot.jootWhite};
`;
const HeaderText = styled(Text)`
  margin: 0 0 40px 0px;
  font-size: 24px;
  color: ${({ theme }) => theme.joot.jootWhite};
`;

const SignInText = styled(Text)`
  margin: 4px 0px 0px 0px;
`;

const SignIn = styled(Button)`
  position: relative;
  font-size: 14px;
  color: 'rgb(223, 81, 65)';
  text-decoration: underline;
  margin-top; -3px;
`;

const RowFlow = styled.div`
  display: flex;
  flex-flow: row;
`;

const aStyle = {
  color: 'rgb(223, 81, 65)',
};

export default function SignupStepCreate() {
  const { push } = useHistory();
  const [successSignup, setSuccessSignup] = useState(false);
  const [error, setError] = useState('');
  const { userHasAuthenticated } = useAuthContext();
  const [credentials, setCredentials] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const toggle = () => setIsChecked(!isChecked);

  const { register, handleSubmit, errors, formState, getValues, reset: resetForm } = useForm({
    mode: 'onChange',
  });
  const { isValid } = formState;

  const SignInLinkHandler = useCallback(() => {
    push('/login');
  }, [push]);

  const {
    submit: signupSubmit,
    isLoading: isLoadingSignup,
    isSuccess: isSuccessSignup,
    reset: resetSignupHook,
  } = useSignup({
    username: getValues('email'),
    password: getValues('password'),
  });

  const {
    error: confirmError,
    submit: confirmSubmit,
    isLoading: isLoadingConfirm,
    reset: resetConfirmHook,
  } = useSignupConfirm(credentials.email, getValues('code'));

  useEffect(() => {
    if (isSuccessSignup) {
      setSuccessSignup(true);
    }
  }, [isSuccessSignup]);

  async function onSignUpSubmit(data) {
    setError('');
    try {
      await signupSubmit();
      setCredentials(data);
    } catch (e) {
      // if the user already exists, just resend the signup silently
      if (e.code === 'UsernameExistsException') {
        try {
          await Auth.resendSignUp(data.email.toLowerCase());
          setCredentials(data);
          setSuccessSignup(true);
        } catch (e) {
          setError(e.message);
        }
        return;
      }
      setError(e.message);
    }
  }

  async function onConfirmSubmit(data) {
    setError('');
    try {
      await confirmSubmit();
      await Auth.signIn(credentials.email.toLowerCase(), credentials.password);
      userHasAuthenticated(true);
      push('/signup/one')
    } catch (e) {
      setError(e.message);
    }
  }

  const resetFlow = useCallback(() => {
    resetForm();
    resetConfirmHook();
    resetSignupHook();
    setSuccessSignup(false);
    setCredentials({});
  }, [resetConfirmHook, resetForm, resetSignupHook]);

  function renderVerificationForm() {
    return (
      <>
        <form key={2} onSubmit={handleSubmit(onConfirmSubmit)}>
          <FormControl controlid="code">
            <LoginLabel htmlFor="verificationCode">Email Verification Code</LoginLabel>
            <LoginInput
              name="code"
              id="code"
              placeholder="Verification code..."
              autoFocus
              type="tel"
              ref={register({ required: true })}
            />
            {confirmError && (
              <FormControl.Error danger>
                Code is invalid or has expired
                <StyledLink to="/signup/create" onClick={resetFlow}>
                  Retry
                </StyledLink>
              </FormControl.Error>
            )}
            <FormControl.Error>{errors.code && 'Verification code is required.'}</FormControl.Error>
          </FormControl>
          <FormControl>
            <Button primary block disabled={isLoadingConfirm || !isValid} type="submit">
              {isLoadingConfirm ? 'Confirming...' : 'Confirm Account'}
            </Button>
          </FormControl>
        </form>
      </>
    );
  }

  function renderSignupForm() {
    return (
      <>
        <form key={1} onSubmit={handleSubmit(onSignUpSubmit)}>
          <HeaderText h3>Sign up for Free.</HeaderText>
          <FormControl controlid="email">
            <LoginLabel htmlFor="email">Email</LoginLabel>
            <LoginInput
              name="email"
              id="email"
              placeholder="Email..."
              type="email"
              autoFocus
              ref={register({ required: true })}
            />
            <FormControl.Error>{errors.email && 'Email is required'}</FormControl.Error>
          </FormControl>
          <FormControl controlid="password">
            <LoginLabel htmlFor="password">Password</LoginLabel>
            <PasswordInput
              name="password"
              id="password"
              placeholder="Password..."
              input={LoginInput}
              ref={register({
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters long.',
                },
              })}
            />
            <FormControl.Error>{errors.password && errors.password.message}</FormControl.Error>
          </FormControl>
          <FormControl>
            <Checkbox checked={isChecked} onChange={toggle}>
              <Text h6>I agree to the <a style={aStyle} href="https://joot-publicweb.s3-us-west-2.amazonaws.com/Privacy+Policy.pdf">Privacy Policy</a> & <a style={aStyle} href="https://joot-publicweb.s3-us-west-2.amazonaws.com/Terms+of+Service.pdf">Terms of Service</a>.</Text>
            </Checkbox>
          </FormControl>
          <FormControl>
            <Button primary block disabled={!isChecked || !isValid || isLoadingSignup} type="submit">
              {isLoadingSignup ? 'Creating Account...' : 'Create Account'}
              <ArrowLong />
            </Button>
          </FormControl>
          <FormControl>
            <RowFlow>
              <SignInText h6>Already have a Joot account?&nbsp;&nbsp;</SignInText><SignIn style={aStyle} type="button" onClick={SignInLinkHandler}>Sign In</SignIn>
            </RowFlow>
          </FormControl>
        </form>
      </>
    );
  }

  return (
    <>
      <AuthIntro />
      <div>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {!successSignup ? renderSignupForm() : renderVerificationForm()}
      </div>
    </>
  );
}
