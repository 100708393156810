import { useCallback, useState } from "react";
import { useStripeCard } from "../../../components/StripeCard";
import { createSetupIntention } from "../../../services";
import { useMarket } from "./useMarket";
import { throwError } from "../../../utils/exception";

export const useUpgradeMarket = () => {
  const [loading, setLoading] = useState(false);
  const { saveCard } = useStripeCard();
  const { buy } = useMarket();

  const upgrade = useCallback(async(accountName, marketName, form, bypassBilling = false) => {
    setLoading(true);
    try {
      if (!bypassBilling) {
        const clientSecret = await createSetupIntention(accountName, !form.use_existing_card); // setup billing
        if (!form.use_existing_card) {
          const saved = await saveCard(form.card_name, clientSecret);
        }
      }
      await buy(accountName, marketName, form.plan_type == "business");
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      throw throwError(e);
    }
  }, [buy, setLoading, saveCard]);

  return {
    upgrade,
    loading,
  }
};