import React, { useMemo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { NavLink, useHistory } from 'react-router-dom';

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  margin: 15px 0px 0px 0px;
  letter-spacing: -0.4px;
  font-size: 13px;
  font-weight: 550;
  color: ${({ theme }) => theme.joot.slate};
  text-decoration: none;
  transition: 0.2s all ${({ theme }) => theme.transition.cubicEase};
  &:hover,
  &.active {
    color: ${({ theme }) => theme.joot.facebook};
  }
  > svg {
    margin-right: 12px;
    path,
    circle {
      transition: 0.2s fill ${({ theme }) => theme.transition.cubicEase};
    }
  }
`;

const StyledNavLinkFlat = styled(StyledNavLink)`
  padding: 14px 20px;
  border-radius: 0px;
`;

const NavItem = ({ children: originalChildren, to, flat, ...props }) => {
  const {
    location: { pathname },
  } = useHistory();
  const [isHover, setIsHover] = useState(false);
  const handleMouseMove = useCallback(() => setIsHover((prev) => !prev), []);
  const children = useMemo(
    () =>
      React.Children.map(originalChildren, (child) => {
        if (typeof child !== 'object') {
          return child;
        }
        return React.cloneElement(child, {
          active: pathname === to || isHover,
        });
      }),
    [pathname, to, originalChildren, isHover],
  );
  return flat ? (
    <StyledNavLinkFlat
      {...props}
      to={to}
      onMouseEnter={handleMouseMove}
      onMouseLeave={handleMouseMove}
      activeClassName="active"
    >
      {children}
    </StyledNavLinkFlat>
    ) : (
    <StyledNavLink
      {...props}
      to={to}
      onMouseEnter={handleMouseMove}
      onMouseLeave={handleMouseMove}
      activeClassName="active"
    >
      {children}
    </StyledNavLink>
  );
};

export default NavItem;
