
import React from 'react';

function SvgComponent({ active, ...props }) {
  return (
    <svg height="18" with="18" viewBox="0 0 64 64">
        <g>
            <path fill='#9FB5C1' d="M60.4372,54.6948,49.6858,43.9433a27.52,27.52,0,0,1-2.683,3.1182,27.8238,27.8238,0,0,1-3.1149,2.6858L54.6355,60.4955a4.1021,4.1021,0,1,0,5.8017-5.8007Z"/>
            <path fill={active ? '#367ca3': '#9FB5C1'} d="M45.0935,10.1662a24.7389,24.7389,0,1,0,0,34.9861A24.739,24.739,0,0,0,45.0935,10.1662ZM27.6013,43.6176a16.19,16.19,0,1,1,16.19-16.19A16.19,16.19,0,0,1,27.6013,43.6176Z"/>
            <path fill='#9FB5C1' d="M32.4153,20.1391a4.9331,4.9331,0,0,0-4.2531,1.9458.7019.7019,0,0,1-1.12.0039,4.9191,4.9191,0,0,0-4.6237-1.91,5.4864,5.4864,0,0,0-4.3831,5.5925c0,2.4085,1.3018,4.1315,3.1155,5.8633l5.0315,4.2816a2.1888,2.1888,0,0,0,2.8368,0l5.0316-4.2816c1.9854-1.9956,3.1154-3.4548,3.1154-5.8633A5.4515,5.4515,0,0,0,32.4153,20.1391Z"/>
        </g>
    </svg>
  );
}

export default SvgComponent;
