import React, { memo, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { Segment, Icon, Label, Step } from 'semantic-ui-react'

// Utils
import { withSlug } from '../../utils/math';

// Components
import Competitor from './Competitor';
import { FormControl, Input, Radio } from '../form';
import Title from '../Title';
import { ReactComponent as Instagram} from '../../assets/svg/instagram.svg';

// Styles
const AccountTitle = styled(Title)`
  margin-left: 30px;
  line-height: 20px;
  > span {
    display: block;
    font-weight: 400;
  }
`;
const Bold = styled.span`
  font-weight: 700;
`;
const Market = styled.div`
  margin: 10px 0 20px 0;
`;
const Competitors = styled.div`
  margin-top: 20px;
`;
const TotalPosts = styled.div`
  margin-bottom: 20px;
`;
const TotalPostsLabel = styled.span`
  font-size: 13px;
  color: ${(props) => props.theme.joot.jootBlack};
`;
const TotalPostsAlertLabel = styled.span`
  font-size: 13px;
  padding-left: 10px;
  color: ${(props) => props.theme.joot.cinnabar};
`;
const Wrapper = styled.div`
  position: relative;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.1)};
`;
const ListItem = styled.li`
  font-size: 13px;
  list-style: circle;
  line-height: 20px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;
const OverviewList = styled.ul`
  margin-left: 30px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;
const Section = styled.div`
  flex-flow: column;
  margin-bottom: 10px;
`;
const SectionBlue = styled(Section)`
  padding: 10px 15px;
  border-radius: 3px;
  background: ${(props) => rgba(props.theme.joot.mustard, 0.1)};
  border: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.1)};
`;
const Panels = styled.div`
  display: flex;
  flex-flow: row;
`;
const LeftPanel = styled.div`
  display: flex;
  flex-flow: column;
  width: 500px;
`;
const RightPanel = styled.div`
  display: flex;
  flex-flow: column;
  width: 600px;
  margin-left: 20px;
`;
const Text = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;
const Text16 = styled(Text)`
  margin: 0 5px 0 5px;
  font-size: 16px;
`;
const LockText = styled(Text)`
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.5)};
  margin-left: 5px;
`;
const Plan = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => rgba(props.theme.joot.mustard, 0.9)};
`;
const PlanItem = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => rgba(props.theme.joot.mustard, 1.0)};
  margin-bottom: 15px;
`;
const PlanDescription= styled(Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
  margin-bottom: 6px;
`;
const PlanLineItem = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0 10px 0;
`;
const StepLineItem = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  margin: 0 0 0 10px;
`;
const StepLineItemWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
  margin: 0 0 5px; 0;
`;
const InputWrapper= styled.div`
  margin: 0 0 0 40px;
`;
const RadioWrapper= styled.div`
  display: flex;
  flex-flow: row;
  margin: 0 0 15px 40px;
`;
const PaymentTitle = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => rgba(props.theme.joot.potteryBlue, 1.0)};
  margin: 0 0 10px 10px;
`;
const HDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0px;
  background: ${({ theme }) => theme.joot.paleGray};
`;
const StepBody = styled.div`
  padding: 10px;
`;
const PaddedLeft = styled.div`
  padding: 0 0 0 20px;
  width: 100%;
`;
const StepBodyWhite = styled(StepBody)`
  padding: 10px 15px 10px 15px;
`;
const StepBodyBlue = styled(StepBody)`
  padding: 10px 15px;
  border-radius: 3px;
  background: ${(props) => rgba(props.theme.joot.carolinaBlue, 0.1)};
  border: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.1)};
`;
const FlexRow = styled.div`
  margin: 0px auto 0px auto;
  display: flex;
  flex-flow: row;
  align-items: center;
`;
const FlexCol = styled.div`
  margin: 0px auto 0px auto;
  display: flex;
  flex-flow: column;
  align-items: left;
`;
const Filler = styled.div`
  width: 100%;
`;
const VFiller = styled.div`
  height: 100%;
`;

const POSTS_LIMIT = 3000;
const COUNT_LIMIT = 1;

const calculateTotalPost = (competitors, primaryAccount) => {
  let total = Object.entries(competitors)
    .map(([_, item]) => item)
    .filter((item) => item)
    .reduce((act, item) => act + item.media_count, 0);
  total += Object.entries(primaryAccount)
    .map(([_, item]) => item)
    .filter((item) => item)
    .reduce((act, item) => act + item.media_count, 0);
  return total;
};

const validatePostLimit = (competitors, primaryAccount) => calculateTotalPost(competitors, primaryAccount) >= POSTS_LIMIT;
const validateCountLimit = (value) => {
  const count = Object.entries(value).filter(([_, item]) => item).length;
  return count >= COUNT_LIMIT;
};

const schema = yup.object().shape({
  market: yup.string().required(),
  competitors: yup.array().test(
    'test-name',
    () => {
      return 'A valid market must contain at least 1 account.';
    },
    function (value) {
      //return validatePostLimit(value) && validateCountLimit(value);
      return validateCountLimit(value);
    },
  ),
});

const MarketForm = ({
  market,
  igAccount,
  name,
  onChange,
  marketNameHelpText,
  competitorsHelpText,
  card,
  onError = () => {},
}) => {
  const {
    register,
    control,
    watch,
    errors,
    formState: { isValid, isDirty },
    getValues,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const [isCompetitor, setIsCompetitor] = useState(false);
  useEffect(() => {
    let value = null;
    const formValue = {
      market: watch('market'),
      competitors: watch('competitors'),
      primaryAccount: watch('primaryAccount'),
      errors: [],
    };
    const competitors = Object.entries(formValue.competitors)
      .filter(([_, item]) => item)
      .map(([_, item]) => item);
    const primaryAccount = formValue.primaryAccount ? (Object.entries(formValue.primaryAccount)
      .filter(([_, item]) => item)
      .map(([_, item]) => item)) : [];
    const errors = [];
    /*
    if (!validatePostLimit(formValue.competitors)) {
      errors.push(`${POSTS_LIMIT} post images required`);
    }
    */
    if (!validateCountLimit(formValue.competitors)) {
      errors.push(`${COUNT_LIMIT} competitors required`);
    }
    if (isValid) {
      value = { ...formValue, competitors: competitors, primaryAccount: (primaryAccount.length > 0 ? primaryAccount[0] : undefined), errors: errors };
    }
    if (isDirty) {
      onError(errors.length ? errors : []);
    }
    onChange(name ? { target: { name, value } } : value);
  }, [isValid, onChange, getValues, onError, isDirty, watch, name]);
  const totalPosts = useMemo(() => calculateTotalPost(watch('competitors') || [], watch('primaryAccount') || []), [watch]);
  const competitors = useMemo(() => {
    if (!market) {
      const comp = [igAccount, null, null, null, null, null];
      setValue("competitors", comp);
      return comp;
    }
    let comp = [null, null, null, null, null, null];
    market.market_accounts.forEach((item, index) => {
      comp[index] = item;
    });
    //return market.market_accounts.filter((item) => item.username !== igAccount.username);
    return comp;
  }, [market, igAccount, setValue]);
  const primaryAccount = useMemo(() => {
    let primary = [null];
    if (!market) {
      return primary;
    }
    const foundPrimary = market.market_accounts.find((item) => item.is_primary );
    primary[0] = foundPrimary ? foundPrimary : null;
    //return market.market_accounts.filter((item) => item.username !== igAccount.username);
    return primary;
  }, [market, setValue]);
  const { joot } = useTheme();
  let lockColor = joot.moonDust;

  return (
    <>
      <Panels>
        <LeftPanel>
          <StepBody>
            <Section>
              <PlanItem>Create your Custom Market</PlanItem>
              <FlexCol>
                <PlanDescription>
                  Custom markets produce clearer insights and better image ranking and engagement predictions.
                </PlanDescription>
              </FlexCol>
            </Section>
            <HDivider />
            <SectionBlue>
              <PlanLineItem>For best results, your Custom Market should include:</PlanLineItem>
              <OverviewList>
                <ListItem>5 or more Instagram accounts.</ListItem>
                <ListItem>Accounts that have 5K+ followers.</ListItem>
                <ListItem>Accounts that have 300+ posts.</ListItem>
                <ListItem>Accounts whose followers represent your target audience.</ListItem>
                <ListItem>Include your own IG account if you meet the criteria above and are currently competing in your Custom Market.</ListItem>
              </OverviewList>
            </SectionBlue>
          </StepBody>
          <VFiller/>
        </LeftPanel>
        <RightPanel>
          <StepBodyWhite>
            <FormControl>
              <StepLineItemWrapper>
                <Label circular color='blue' key='step1'>1</Label>
                <StepLineItem>Name your Custom Market</StepLineItem>
              </StepLineItemWrapper>
              <InputWrapper>
                <Input
                  onChange={() => {}}
                  hasError={false}
                  name="market"
                  id="market"
                  placeholder="For example 'Competitors'..."
                  type="text"
                  autoFocus
                  ref={register}
                  defaultValue={market?.market_name}
                />
                {errors.market?.message && <FormControl.Error>{errors.market?.message}</FormControl.Error>}
              </InputWrapper>
            </FormControl>
          </StepBodyWhite>
          <StepBodyWhite>
            <FormControl>
              <StepLineItemWrapper>
                <Label circular color='blue' key='step2'>2</Label>
                <StepLineItem>Do you compete in this Market?</StepLineItem>
                <Radio
                  id={'no'}
                  checked={!isCompetitor}
                  onChange={() => setIsCompetitor(false)}
                >
                  <Text16>No</Text16>
                </Radio>
                &nbsp;
                <Radio
                  id={'yes'}
                  checked={isCompetitor}
                  onChange={() => setIsCompetitor(true)}
                >
                  <Text16>Yes</Text16>
                </Radio>
              </StepLineItemWrapper>
              {isCompetitor &&
                <InputWrapper>
                  <Controller
                    as={Competitor}
                    name="primaryAccount"
                    control={control}
                    defaultValue={primaryAccount}
                    readonly={false}
                    placeholder="Type your Instagram handle and press Enter..."
                  />
                </InputWrapper>
              }
            </FormControl>
          </StepBodyWhite>
          <StepBodyBlue>
            <Section>
              <FlexRow>
                <Label circular color='blue' key='step3'>3</Label>
                <PaymentTitle>Instagram accounts that define your market</PaymentTitle>
                {/*<Instagram />*/}
              </FlexRow>
            </Section>
            <Competitors>
              <FormControl>
                <Controller
                  as={Competitor}
                  name="competitors"
                  control={control}
                  defaultValue={competitors}
                  readonly={false}
                  placeholder="Type a handle and press Enter..."
                />
              </FormControl>
              <TotalPosts>
                {totalPosts > 0 && <TotalPostsLabel>Total posts: {withSlug(totalPosts)} </TotalPostsLabel>}
                {errors.competitors?.message && <TotalPostsAlertLabel>{errors.competitors?.message}</TotalPostsAlertLabel>}
              </TotalPosts>
            </Competitors>
            <FlexRow>
              <LockText>*Total combined posts must equal 3000 or more.</LockText>
            </FlexRow>
          </StepBodyBlue>
        </RightPanel>
      </Panels>
    </>
  );
};

export default memo(MarketForm);
