import React, { memo, useMemo } from 'react';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';

const StarIcon = ({ active, activeColor, inactiveColor, ...props }) => {
  const { joot } = useTheme();
  const color = useMemo(() => (active ? (activeColor || joot.mustard) : (inactiveColor || joot.jootWhite)), [active, activeColor, inactiveColor, joot.mustard, joot.jootWhite]);
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill={color}
        d="M15.1433 16.9999C15.3545 17.0033 15.5567 16.9172 15.6918 16.7664C15.827 16.6155 15.8807 16.4169 15.8377 16.2252L14.6403 10.8336L19.0378 7.12179C19.241 6.95133 19.317 6.68712 19.2329 6.44591C19.1479 6.20386 18.9188 6.03086 18.6458 6.00273L12.7287 5.39079L10.2696 0.379273C10.1568 0.148301 9.90806 0 9.63333 0C9.3586 0 9.1098 0.148299 8.99706 0.379273L6.53793 5.39079L0.620818 6.00273C0.347872 6.03086 0.118775 6.20387 0.0337571 6.44591C-0.050365 6.68712 0.0257029 6.95132 0.228847 7.12179L4.62636 10.8336L3.42896 16.2252C3.37437 16.4723 3.48087 16.7272 3.70191 16.8763C3.92385 17.0246 4.21827 17.04 4.45633 16.9155L9.63346 14.1958L14.8106 16.9155C14.9117 16.9684 15.026 16.9982 15.1433 16.9999Z"
      />
    </svg>
  );
};
StarIcon.propTypes = {
  active: PropTypes.bool,
};

export default memo(StarIcon);
