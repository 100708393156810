import React from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { motion } from 'framer-motion';

// Component
import Loader from '../Loader';

// Styles
const StyledMediaRating = styled(motion.div)`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: ${({ theme }) => rgba(theme.joot.jootBlack, 0.6)};
  color: ${({ theme }) => theme.joot.jootWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  pointer-events: none;
  &.green {
    background: ${({ theme }) => rgba(theme.joot.emeraldGreen, 0.9)};
  }
  &.yellow {
    background: ${({ theme }) => rgba(theme.joot.mustard, 0.9)};
  }
  &.red {
    background: ${({ theme }) => rgba(theme.joot.cinnabar, 0.9)};
  }
`;

const LoadingMediaRating = styled(motion.div)`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: ${({ theme }) => rgba(theme.joot.jootBlack, 0.0)};
  color: ${({ theme }) => theme.joot.jootWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;
const StyledLoader = styled(Loader)`
  width: 17px;
  height: 17px;
  align-self: center;
`;

// Motion
const ratingMotion = {
  hover: {
    opacity: 0,
    transition: {
      type: 'spring',
      delay: 0,
      stiffness: 500,
      damping: 60,
      mass: 1,
    },
  },
  chris: {
    opacity: 0,
  },
};

const MediaRating = ({ order, rating, quantile }) => {
  return order > 0 ? (
    <StyledMediaRating variants={ratingMotion} className={quantile?.toLowerCase()}>
      {order}
    </StyledMediaRating>
  ) : (order == 0 ? (
    <StyledMediaRating>
      <StyledLoader />
    </StyledMediaRating>
  ) : <></> );

};

MediaRating.defaultProps = {
  rating: 0,
};

export default MediaRating;
