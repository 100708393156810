import React from 'react';
import styled from 'styled-components';

// Component
import CheckIcon from './icons/CheckIcon';
import AlertIcon from './icons/AlertIcon';

// Styles
const StyledToaster = styled.div`
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.joot.jootBlack};
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
  color: ${({ theme }) => theme.joot.jootWhite};
  min-width: 200px;
  max-width: 600px;
  padding: 14px 24px;
  pointer-events: initial;
  z-index: 2;
  transition-property: transform;
  transition-duration: 220ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transform-origin: center bottom;
  transform: translate3d(0px, 0px, 0px) scale(1);
`;
const Text = styled.span`
  margin-left: 10px;
`;

const Toaster = ({ children, appearance }) => {
  return (
    <StyledToaster>
      {appearance === 'success' && <CheckIcon stroke={'#fff'} />}
      {appearance === 'error' && <AlertIcon  />}
      <Text>{children}</Text>
    </StyledToaster>
  );
};

export default Toaster;
