import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';

// Hooks
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';

// Context
import { useAppContext } from '../../../contexts/AppContext';

// Assets
import { ReactComponent as ArrowMed } from '../../../assets/svg/arrow-med.svg';

// Components
import { FormControl, Input, PasswordInput, ErrorMessage } from '../../../components/form';
import Button from '../../../components/Button';

// Styles
const StyledProfileTab = styled.div`
  display: grid;
`;
const Form = styled.form`
  width: 100%;
`;
const FormGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  border: none;
  margin: 0;
  padding: 0;
  align-items: flex-start;
`;
const SaveButton = styled(Button)`
  position: relative;
  justify-self: center;
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-top: 30px;
  font-size: 13px;
  width: 160px;
  > svg {
    vertical-align: inherit;
    margin-left: 20px;
  }
`;

const FormProfile = ({ user, updateUserAttributes }) => {
  const [error, setError] = useState('');
  const [showButton, setShowButton] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // Form setup
  const { register, handleSubmit, errors, formState, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: user.attributes.given_name,
      lastName: user.attributes.family_name,
      email: user.attributes.email,
      oldPassword: '••••••••',
    },
  });
  const { isValid } = formState;

  const newPassword = watch('newPassword', '');
  const confirmPassword = watch('confirmPassword', '');

  // Methods
  async function handleSubmitClick(data, e) {
    if (!isValid) {
      return;
    }
    setError('');
    setLoading(true);

    const attrPayload = {
      email: data.email,
      family_name: data.lastName,
      given_name: data.name,
    };

    try {
      await Auth.updateUserAttributes(user, attrPayload);

      if (changePassword && newPassword && confirmPassword) {
        const currentUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(currentUser, data.oldPassword, data.newPassword);
      }
      // Reset form password fields
      setValue('oldPassword', '********');
      setValue('newPassword', undefined);
      setValue('confirmPassword', undefined);
      setChangePassword(false);
      updateUserAttributes(attrPayload);
      setShowButton(false);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  }

  const setChangePasswordHandler = useCallback(() => {
    setChangePassword(true);
    setValue('oldPassword', undefined);
  }, [setValue]);

  const handleChange = useCallback(() => setShowButton(true), []);

  return (
    <Form onSubmit={handleSubmit(handleSubmitClick)}>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {/* Name */}
      <FormGroup>
        <FormControl>
          <FormControl.Label htmlFor="name">First Name</FormControl.Label>
          <Input
            name="name"
            id="name"
            placeholder="First name..."
            type="text"
            ref={register({ required: 'Firt name is required' })}
            hasError={errors.name}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormControl.Label htmlFor="lastName">Last Name</FormControl.Label>
          <Input
            name="lastName"
            id="lastName"
            placeholder="Last name..."
            type="text"
            ref={register({ required: 'Last name is required' })}
            hasError={errors.lastName}
            onChange={handleChange}
          />
        </FormControl>
      </FormGroup>

      {/* Password */}
      <FormGroup>
        <FormControl>
          <FormControl.Label htmlFor="email">Email Address</FormControl.Label>
          <Input
            disabled
            name="email"
            id="email"
            placeholder="Email..."
            type="email"
            ref={register({ required: 'Email is required' })}
            hasError={errors.email}
          />
        </FormControl>
        <FormControl>
          <FormControl.Label htmlFor="oldPassword">{changePassword ? 'Old Password' : 'Password'}</FormControl.Label>
          <PasswordInput
            name="oldPassword"
            id="oldPassword"
            ref={register({ required: true })}
            type="password"
            disabled={!changePassword}
          />
          {!changePassword && (
            <FormControl.Action onClick={setChangePasswordHandler}>Change Password</FormControl.Action>
          )}
        </FormControl>
      </FormGroup>

      {/* New Password */}
      {changePassword && (
        <FormGroup>
          <FormControl>
            <FormControl.Label htmlFor="email">New Password</FormControl.Label>
            <Input
              name="newPassword"
              ref={register({
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters long.',
                },
              })}
              type="password"
              hasError={errors.newPassword}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <FormControl.Label htmlFor="oldPassword">Confirm Password</FormControl.Label>
            <Input
              name="confirmPassword"
              ref={register({
                required: 'Confirm Password is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters long.',
                },
                validate: (value) => value === newPassword || 'The passwords do not match',
              })}
              type="password"
              hasError={errors.confirmPassword}
              onChange={handleChange}
            />
          </FormControl>
        </FormGroup>
      )}
      {showButton && (
        <SaveButton primary loading={loading}>
          Save {!loading && <ArrowMed />}
        </SaveButton>
      )}
    </Form>
  );
};

const ProfileTab = () => {
  const { user, updateUserAttributes } = useAppContext();

  return (
    <StyledProfileTab>
      {user && <FormProfile user={user} updateUserAttributes={updateUserAttributes} />}
    </StyledProfileTab>
  );
};

export default memo(ProfileTab);
