import React, { memo } from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Components
import Title from '../Title';
import overviewImg from '../../assets/img/rankedMediaOverview.png';

// Styles
const StepBody = styled.div`
  padding: 20px 30px 20px 30px;
`;

const ImageArea = styled.div`
  align-items: center;
  margin-left: 90px;
`;

const AccountTitle = styled(Title)`
  line-height: 20px;
  > span {
    margin-top: 20px;
    margin-bottom: 50px;
    display: block;
    font-weight: 400;
    font-size: 40pt;
    text-align: center;
  }
`;
const TitleArea = styled.div`
  align-items: center;
`;

const Text = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  margin-top: 20px;
  margin-bottom: 20px;
  letter-spacing: -0.26px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;

const OverviewList = styled.ul`
  margin-top: 20px;
  margin-left: 20px;
  letter-spacing: -0.26px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;

const ListItem = styled.li`
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -0.26px;
  list-style-type: disc;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;

const OverviewImage = styled.img`
  border-radius: 4px;
  grid-area: image;
  width: 100%;
`;
const Emphasis = styled.span`
  color: ${(props) => rgba(props.theme.joot.carolinaBlue, 0.9)};
  text-decoration: bold;
`;

const EngagementConceptsOverview = () => {

  return (
    <>
      <TitleArea>
        <AccountTitle h3 noMargin>
          <span>Unlock <Emphasis>Engagement Concepts</Emphasis></span>
        </AccountTitle>
      </TitleArea>
      <ImageArea h5 noMargin>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/2T8_0vVQ-tM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </ImageArea>
      <StepBody>
        <Text>Upgrade now to:</Text>
        <OverviewList>
          <ListItem>Become an <Emphasis>EXPERT</Emphasis> at what engages your market audience.</ListItem>
          <ListItem>Learn how to create unlimited amounts of winning content.</ListItem>
          <ListItem>Know exactly what type of content to avoid producing.</ListItem>
          <ListItem>Research new markets to grow your business.</ListItem>
        </OverviewList>
      </StepBody>
    </>
  );
};

export default memo(EngagementConceptsOverview);
