import { API } from 'aws-amplify';
import config from '../utils/config';

const basePath = 'insights';

export const getMarketInsights = async (accountName, marketName, marketType) => {
  const { market_insights: { market_accounts } } = await API.get(config.apiName, `${basePath}/${accountName}/market/${marketName}`, {
    queryStringParameters: {
      marketType: marketType,
    }});
  const competitors = []
  if (!market_accounts[0]) {
    return {}
  }
  let userData = null;
  for (let item of market_accounts) {
    //if (item.username === igUserName) {
    if (item.is_primary) {
      userData = item
      continue
    }
    competitors.push(item)
  }
  return { competitors, userData };
};

export const getImagesInsights = async (accountName, marketName, urlParameters) => {
  let url = `${basePath}/${accountName}/image/${marketName}`
  if (urlParameters) {
    url = `${url}?${urlParameters}`
  }
  const { image_insights } = await API.get(config.apiName, url);
  return image_insights;
};

export const getImagesInsightsAll = async (accountName, marketName, marketType) => {
  let url = `${basePath}/${accountName}/all/image/${marketName}`
  const queryStringParameters = {
    queryStringParameters: {
      marketType: marketType ? marketType : "ACCOUNT"
  }};
  const { image_insights } = await API.get(config.apiName, url, queryStringParameters);
  return image_insights;
};

export const getColorTags = async (accountName, marketName, marketType) => {
  if (!accountName || !marketName || marketType != "ACCOUNT")
    return;

  let url = `${basePath}/${accountName}/tags/${marketName}`
  const { tag_insights } = await API.get(config.apiName, url, {
    queryStringParameters: {
      marketType: marketType,
    }});
  return tag_insights
}
