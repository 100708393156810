import { useNProgress } from '@tanem/react-nprogress';
import React from 'react';
import Bar from './Bar';
import Container from './Container';
import { useAppContext } from '../../contexts/AppContext';


const Progress = () => {
  const { animateProgress } = useAppContext();

  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: animateProgress,
  });

  return (
    <Container isFinished={isFinished} animationDuration={animationDuration}>
      <Bar progress={progress} animationDuration={animationDuration} />
    </Container>
  );
};

export default Progress;
