import { API } from 'aws-amplify';
import config from '../utils/config';
// for mocking
import axios from 'axios';

const basePath = 'user';

/**
 * Retrieve a list of all Joot Account invites for the authorized user.
 */
export const userInvites = async () => {
  const data = await API.get(config.apiName, `${basePath}/invites`, {});
  return data;
};

export const getProxyUser = async () => {
  const data = await API.get(config.apiName, `admin/user/proxy`, {});
  return data;
}

export const setProxyUser = async (proxy) => {
  const data = await API.post(config.apiName, `admin/user/proxy`, { body: { proxy }} );
  return data;
}

/**
 * Retrieve a list of all Joot Account invites for the authorized user.
 * todo: remove mocking
 */
export const userInvitesMock = async () => {
  const { data } = await axios.get('http://localhost:3004/invites');
  return data;
};

/**
 * Accept or Reject an invite from a specific Joot Account for the authorized user
 */
export const acceptInvite = async (accountName, accept) => {
  const data = await API.put(config.apiName, `${basePath}/invites/${accountName}`, {
    body: accept ? 'ACCEPT' : 'REJECT',
  });
  return data;
};
