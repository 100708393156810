import { queryCache, useQuery, useMutation } from "react-query";
import { getBillingDetail, getBillingPlans, createSetupIntention, unlockMarket, buyMarket, buyUploads, createStripeCustomer } from "../../services/billingApiServices";
import { DEFAULT_QUERY_OPTIONS } from "./api-hooks-utils";
import { useAppContext } from "../../contexts/AppContext";
import { useCreateMutation } from "../useCreateMutation";
import { useCallback, useMemo } from "react";

export const useGetBillingPlans = () => {
  const query = ['billing_plans'];
  const data = useQuery(['billing_plans'], async () => getBillingPlans(), {
    ...DEFAULT_QUERY_OPTIONS,
  });
  const invalidateQuery = useCallback(() => {
    queryCache.invalidateQueries(query);
  }, [query]);
  const { data: { image_upload_plans = [] } = {} } = data;
  const { data: { account_upgrade_plans = [] } = {} } = data;

  return {
    ...data,
    image_upload_plans,
    account_upgrade_plans,
    invalidateQuery,
  }
};

export const useGetBillingDetail = () => {
  const { accountName } = useAppContext();
  const query = ['billing_info', accountName];
  const data = useQuery(['billing_info', accountName], async () => getBillingDetail(accountName), {
    enabled: accountName,
    ...DEFAULT_QUERY_OPTIONS,
  });
  const invalidateQuery = useCallback(() => {
    queryCache.invalidateQueries(query);
  }, [query]);
  const { data: { payment_methods = [] } = {} } = data;
  const paymentMethod = useMemo(() => (payment_methods.length ? payment_methods[0] : {}), [payment_methods]);
  const promoCode = useMemo(() => ( data?.data?.promo_code ), [data]);

  return {
    ...data,
    promoCode,
    paymentMethod,
    invalidateQuery,
  }
};

export const useCreateSetupIntention = (useExistingCard) => {
  return useCreateMutation(accountName => createSetupIntention(accountName, !useExistingCard ), {
    throwOnError: true,
  })
}

export const useBuyMarket = () => {
  return useCreateMutation(({ accountName, market_name, is_business }) => buyMarket(accountName, market_name, is_business), {
    throwOnError: true,
  })
}

export const useUnlockMarket = () => {
  return useCreateMutation(({ accountName, market_name, subscription_type }) => unlockMarket(accountName, market_name, subscription_type == 'yearly'), {
    throwOnError: true,
  })
}

export const useBuyUploads = () => {
  return useCreateMutation(
    ({ accountName, image_upload_tier }) => buyUploads(accountName, image_upload_tier),
    {
      throwOnError: true,
    },
  )
}

export const useStripeCustomerSignup = (params) => {
  const [mutate, { status, data, error, reset }] = useMutation(async () => {
    params.email = params.email ? params.email.toLowerCase() : params.email;
    createStripeCustomer(params.email, params.name, params.company, params.industry);
  }, {
    throwOnError: true,
  });

  return {
    submit: mutate,
    status,
    data,
    error,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    reset,
  };
};
