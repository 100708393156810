import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

// Components
import ScoredMedia from '../pages/ScoredMedia';
import MarketInsights from '../pages/MarketInsights';
import ImageInsights from '../pages/ImageInsights';
import Settings from '../pages/Settings';

const Routes = () => {
  const match = useRouteMatch();

  return (
    <Route path={'/'}>
      <Switch>
        <Route exact path={`${match.path}market-insights`} component={MarketInsights} />
        <Route exact path={`${match.path}image-insights`} component={ImageInsights} />
        <Route exact path={`${match.path}settings`} component={Settings} />
        <Route exact path={`${match.path}scored-media`} component={ScoredMedia} />
        <Route path="/">
          <Redirect to={`${match.path}scored-media`} />
        </Route>
      </Switch>
    </Route>
  );
};

export default Routes;
