import React from 'react';
import styled from 'styled-components';

// Assets
import { ReactComponent as ArrowMed } from '../assets/svg/arrow-med.svg';

// Components
import Button from './Button';
//import Navigation from './Navigation';

// Styles
const NavRow = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
`;
const NavWrapper = styled(Button)`
  position: relative;
`;
const NavItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  opacity: 0.4;
  margin-right: 30px;
  ${({ disabled }) => disabled? '': 'opacity: 1'}
`;
const NavText = styled.div`
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.17px;
  color: ${({ theme }) => theme.joot.jootWhite};
`;
const NavArrow = styled(ArrowMed)`
  margin: 0 0 0 30px !important;
`;

const StepperNavigation = ({
  onGoToItem,
  navigationSteps,
}) => {

  return (
    <>
      <NavRow>
        {navigationSteps.map((item, idx) => (
          idx > 2 ? <></> : (
          <NavWrapper key={item.key}>
            <NavItem
              onClick={() => onGoToItem(item)}
              disabled={item.disabled}
            >
              <NavText>{item.text}</NavText>
              {idx > 1 /*=== navigationSteps.length - 1*/ ? null : <NavArrow />}
            </NavItem>
          </NavWrapper>
            )))}
      </NavRow>
    </>
  );
};

StepperNavigation.defaultProps = {
  navItemSeparation: '4vw',
};

export default StepperNavigation;
