import React, { memo, useCallback, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { rgba } from 'polished';

// Utils
import { withSlug } from '../../utils/math';

// Components
import Avatar from 'react-avatar';
import DeleteIcon from '../icons/DeleteIcon';
import StarIcon from '../icons/StarIcon';
import Tooltip from '../../components/Tooltip';

// Styles
const SelectedCompetitorContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 8px 10px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.joot.jootBlack};
`;
const Details = styled.div`
  margin-left: 13px;
`;
const StyledDeleteIcon = styled(DeleteIcon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
const StyledStar = styled.div`
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
const Handle = styled.div`
  display: block;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.16px;
  color: ${({ theme }) => theme.joot.jootBlack};
`;
const Primary = styled(Handle)`
  color: ${({ theme }) => rgba(theme.joot.jootBlack, 0.3)};
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
`;
const Posts = styled.div`
  margin-top: 3px;
  font-size: 11px;
  letter-spacing: -0.16px;
  color: ${({ theme }) => rgba(theme.joot.jootBlack, 0.3)};
`;

const Selected = ({ value, name, readonly, onDelete, isPrimary, onSetPrimary }) => {
  const [isPrimaryAccount, setIsPrimaryAccount] = useState(isPrimary);
  const { joot } = useTheme();
  const showPrimarySelector = false; // for now we will 
  let deleteColor = joot.cinnabar;
  let starColor = joot.mustard;
  let starInactiveColor = joot.paleGray;
  const handleDelete = useCallback(() => {
    onDelete({ target: { name, value } });
  }, [name, value, onDelete]);
  const handleSetPrimary = useCallback(() => {
    console.log("handleSetPrimary");
    !isPrimaryAccount && setIsPrimaryAccount(true);
    onSetPrimary({ target: { name } });
  }, [name, value, onSetPrimary]);

  return (
    <SelectedCompetitorContainer>
      <Avatar name={value.username} size={34} round={true} src={value.profile_picture_url} />
      <Details>
        <Handle>{value.username}</Handle>
        <Posts>{withSlug(value.media_count)} posts</Posts>
      </Details>
      {showPrimarySelector && <Tooltip text="Uploaded images will be scored for this account.">
        <StyledStar>
          <StarIcon active={isPrimary} widht={18} height={16} onClick={!readonly && handleSetPrimary} activeColor={starColor} inactiveColor={starInactiveColor} />
        </StyledStar>
      </Tooltip>}
      {!readonly && <StyledDeleteIcon width={18} height={16} fill={`${deleteColor}`} onClick={handleDelete} />}
    </SelectedCompetitorContainer>
  );
};

export default memo(Selected);
