import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// Components
import Title from '../../components/Title';
import Button from '../../components/Button';

// Context
import { useAuthContext } from '../../contexts/AuthContext';

// Styles
const StyledAuthFooter = styled.div`
  display: flex;
`;
const Text = styled(Title)`
  margin: 0 40px 0 0;
  font-size: 13px;
  color: ${({ theme }) => theme.joot.jootWhite};
`;

const aStyle = {
  color: 'rgb(223, 81, 65)',

  ':hover': {

    opacity: 0.7,
  }
};

const AuthFooter = () => {

  const history = useHistory();
  const { userHasAuthenticated, isAuthenticated } = useAuthContext();
  const handleLogout = useCallback(async () => {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push('/');
  }, [userHasAuthenticated, history]);

  return (
    <StyledAuthFooter>
      <Text h6>(801) 341-9112</Text>
      <Text h6>support@getjoot.com</Text>
      <Text h6><Button><a style={aStyle} href="https://joot-publicweb.s3-us-west-2.amazonaws.com/Privacy+Policy.pdf">Privacy Policy</a></Button></Text>
      <Text h6><Button><a style={aStyle} href="https://joot-publicweb.s3-us-west-2.amazonaws.com/Terms+of+Service.pdf">Terms of Service</a></Button></Text>
      {isAuthenticated && (
        <Text h6><Button><div style={aStyle} onClick={handleLogout} href="">Logout</div></Button></Text>
      )}
    </StyledAuthFooter>
  );
};

export default AuthFooter;
