import { css } from 'styled-components';
import rgba from 'polished/lib/color/rgba';

const InputAppearance = css`
  appearance: none;
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.joot.jootBlack};
  border: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.3)};
  background-color: ${(props) => props.theme.joot.jootWhite};
  border-radius: 3px;
  padding: 9px 16px;
  letter-spacing: -0.37px;
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  font-family: ${(props) => props.theme.font.sansSerif};
  transition: 0.2s all ${({ theme }) => theme.transition.cubicEase};

  &:disabled {
    color: ${(props) => rgba(props.theme.joot.jootBlack, 0.5)};
    background-color: ${(props) => props.theme.joot.paleGray};
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-weight: 400;
    color: ${(props) => rgba(props.theme.joot.jootBlack, 0.3)};
  }

  ${(props) => props.hasError && `border: 1px solid ${props.theme.joot.cinnabar};`}
`;

export default InputAppearance;
