import React, { memo, useCallback, useState, useMemo } from 'react';
import { FormControl, Input, Checkbox } from '../form';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import StripeCardDetail from '../StripeCardDetail';
import StripeCardForm from '../StripeCardForm';
import { useGetBillingDetail } from '../../hooks/apiHooks/billingApiHook';
import LockIcon from '../../components/icons/LockIcon';
import PoweredByStripeIcon from '../icons/PoweredByStripeIcon';
import Title from '../Title';

const StyledPoweredByStripe = styled(PoweredByStripeIcon)`
  margin: 30px auto 0px auto;
  position: relative;
`;
const Section = styled.div`
  flex-flow: column;
  margin-bottom: 20px;
`;
const Panels = styled.div`
  display: flex;
  flex-flow: row;
  padding: 0px;
`;
const LeftPanel = styled.div`
  display: flex;
  flex-flow: column;
  width: 600px;
  border-radius: 3px;
  border: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.1)};
  padding: 12px;
`;
const RightPanel = styled.div`
  display: flex;
  flex-flow: column;
  width: 500px;
  margin-left: 20px;
  margin-top: 20px;
`;
const Text = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 23px;
  white-space: nowrap;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;
const Text16 = styled(Text)`
  font-size: 16px;
`;
const LockText = styled(Text)`
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.5)};
  margin-left: 5px;
`;
const Plan = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => rgba(props.theme.joot.mustard, 1.0)};
`;
const PlanItem = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => rgba(props.theme.joot.potteryBlue, 0.9)};
  margin: 10px 0 0 0;
`;
const PlanDescription= styled(Text)`
  font-size: 11px;
  font-weight: 400;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
  margin-top: -3px;
`;
const PlanLineItem = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0 0 10px;
`;
const PlanLineValue = styled.div`
  padding: 8px;
  background: ${({ theme }) => theme.joot.paleGray};
  font-weight: 400;
  text-align: center;
  margin: 10px 0 0 10px;
`;
const PaymentTitle = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => rgba(props.theme.joot.mustard, 1.0)};
  margin: 10px 0 10px 0;
`;
const HDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0px;
  background: ${({ theme }) => theme.joot.paleGray};
`;
const StepBody = styled.div`
  padding: 10px;
`;
const FlexRow = styled.div`
  margin: 0px auto 0px auto;
  display: flex;
  flex-flow: row;
  align-items: center;
`;
const StripeRow = styled(FlexRow)`
  margin-top: 20px
`;
const Filler = styled.div`
  width: 100%;
`;

const planInfo = {
  professional: {
    name: 'Professional Plan',
    amount: '$99.00/mo',
    included_markets: 1,
    included_users: 1,
    monthly_rankings: 100
  },
  professional_additional: {
    name: 'Additional Market (Professional)',
    amount: '$99.00/mo',
    included_markets: 1,
    included_users: 1,
    monthly_rankings: 100
  },
  business: {
    name: 'Business Plan',
    amount: '$299.00/mo',
    included_markets: 5,
    included_users: 1,
    monthly_rankings: 500
  },
  business_additional: {
    name: 'Additional Market (Business)',
    amount: '$49.00/mo',
    included_markets: 1,
    included_users: 1,
    monthly_rankings: 100
  }
}

const PaymentForm = ({ value, planType, onCardChange, onUseExistingCardChange }) => {
  const {
    paymentMethod: { card },
  } = useGetBillingDetail();
  const handleCardChange = useCallback((value) => {
    onCardChange(value)
  }, [onCardChange]);
  useMemo(() => {
    onUseExistingCardChange && onUseExistingCardChange(!!card);
  }, [card]);
  const { joot } = useTheme();
  let lockColor = joot.moonDust;

  console.log(JSON.stringify(planInfo));
  console.log(planType);

  return (
    <>
      <Panels>
        <LeftPanel>
          <StepBody>
            <Section>
              <Text>Your New Account:</Text>
              <FlexRow>
                <Plan>{planInfo[planType].name}</Plan>
                <Filler></Filler>
                <Text16>{planInfo[planType].amount}</Text16>
              </FlexRow>
            </Section>
            <HDivider />
            <Section>
              <PlanItem>Custom Markets</PlanItem>
              <PlanDescription>Provides custom prediction and insights.</PlanDescription>
              <FlexRow>
                <PlanLineItem>Included custom markets</PlanLineItem>
                <Filler></Filler>
                <PlanLineValue>{planInfo[planType].included_markets}</PlanLineValue>
              </FlexRow>
            </Section>
            <HDivider />
            <Section>
              <PlanItem>Users</PlanItem>
              <PlanDescription>Additional account user management coming soon.</PlanDescription>
              <FlexRow>
                <PlanLineItem>Included users</PlanLineItem>
                <Filler></Filler>
                <PlanLineValue>{planInfo[planType].included_users}</PlanLineValue>
              </FlexRow>
            </Section>
            <HDivider />
            <Section>
              <PlanItem>Image Rank</PlanItem>
              <PlanDescription>Monthly image rankings included.</PlanDescription>
              <FlexRow>
                <PlanLineItem>Included Image Rankings</PlanLineItem>
                <Filler></Filler>
                <PlanLineValue>{planInfo[planType].monthly_rankings}/mo</PlanLineValue>
              </FlexRow>
            </Section>
          </StepBody>
        </LeftPanel>
        <RightPanel>
          <StepBody>
            <Section>
              <PaymentTitle>Payment Details</PaymentTitle>
              {card && (<FormControl>
                <FormControl.Label>Use Your existing Payment Method</FormControl.Label>
                <StripeCardDetail card={card} />
              </FormControl>)}
              {!card && <StripeCardForm onCardChange={handleCardChange} />}
            </Section>
            <FlexRow>
              <LockIcon fill={lockColor}/>
              <LockText>All payments are secure and encrypted.</LockText>
            </FlexRow>
            <StripeRow>
              <StyledPoweredByStripe width={150} />
            </StripeRow>
          </StepBody>
        </RightPanel>
      </Panels>
    </>
  );
};

export default memo(PaymentForm);
