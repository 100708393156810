import React from 'react';
import styled from 'styled-components';

// Styles
const StyledOverflow = styled.div`
  // display: grid;
  // grid-template-rows: max-content;
  // align-items: start;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: ${(props) => props.padding};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const Overflow = ({ children, ...props }) => {
  return <StyledOverflow padding={props.padding}>{children}</StyledOverflow>;
};

Overflow.defaultProps = {
  padding: '0',
};

export default Overflow;
