import React, { useMemo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom';

// Components
import MarketplaceMenu from '../../components/menus/marketplace/MarketplaceMenu';
import LogoJoot from '../logo/LogoJoot';
import { useAppContext } from '../../contexts/AppContext';
import MarketUpgrade from '../../features/media/components/MarketUpgrade';
import MarketUnlock from '../../features/media/components/MarketUnlock';
import Title from '../Title';

// Styles
const MarketTitle = styled(Title)`
  margin: 0 10px 0px 0;
  color: ${({ theme }) => theme.joot.carolinaBlue};
`;

const StyledHeader = styled.header`
  grid-area: header;
  display: grid;
  grid-template-columns: auto 220px;
  align-items: center;
  justify-content: left;
  background: ${({ theme }) => theme.joot.jootWhite};
  padding: 20px 20px;
  z-index: 2;
`;

const StyledHeader2 = styled.header`
  display: flex;
  align-items: center;
  justify-content: left;
  background: ${({ theme }) => theme.joot.jootWhite};
  z-index: 2;
`;

const LogoWrapper = styled.div`
  margin: 20px;
`;

const Divider = styled.span`
  width: 1px;
  height: 100%;
  margin: 0 20px;
  background: ${({ theme }) => theme.joot.paleGray};
`;

const MarketplaceMenuWrapper = styled.div`
  margin-right: 20px;
  white-space: nowrap;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  margin-right: 20px;
`;

const createOption = (item) => ({
  item,
  value: item.market_name,
  name: item.market_name,
})
const Header = () => {
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [showUnlock, setShowUnlock] = useState(false);
  const { 
    markets: { data: marketsObjects },
    markets: { marketplace },
    jootAccount,
    currentMarket,
    setCurrentMarket
  } = useAppContext();
  const history = useHistory();
  const initialSelection = useMemo(
    () => currentMarket ? { name: currentMarket.market_name, value: currentMarket.market_name } : null,
    [currentMarket]
  )
  const handleMarketChange = useCallback(({ target: { value: { item } } }) => {
    setCurrentMarket(item);
  }, [setCurrentMarket]);
  const handleCreateMarket = useCallback(() => {
    setShowUpgrade(true);
  }, [setShowUpgrade]);
  const handleUpgrade= useCallback(() => {
    // nav to settings page
    history.push('/settings');
  }, []);
  const onHideUpgrade= useCallback((libraryName, market) => {
    setShowUpgrade(false);
  }, [setShowUpgrade]);
  const handleUnlockMarket = useCallback(() => {
    setShowUnlock(true);
  }, [setShowUnlock]);
  const onHideUnlock = useCallback((libraryName, market) => {
    setShowUnlock(false);
  }, [setShowUnlock]);

  return (
    <>
      <StyledHeader2>
        <LogoWrapper>
          <LogoJoot />
        </LogoWrapper>
        <Divider />
        {/*initialSelection &&*/ (
          <>
            <MarketTitle h6>
              Market:
            </MarketTitle>
            <MarketplaceMenuWrapper>
              <MarketplaceMenu
                categories={Object.entries(marketplace)}
                name="markets"
                title="Select a Market"
                onChange={handleMarketChange}
                onCreateMarket={handleCreateMarket}
                value={currentMarket && createOption(currentMarket)}
                emptyText="Create a Market.."
                width={'1000px'}
              />
            </MarketplaceMenuWrapper>
            <ButtonsWrapper>
              {currentMarket && currentMarket.is_locked && currentMarket.exists_insights &&
                <Button floated='left' inverted color="blue" animated="vertical" onClick={handleUnlockMarket}>
                  <Button.Content visible>Unlock this Market</Button.Content>
                  <Button.Content hidden>$19.99 a month</Button.Content>
                </Button>
              }
              <Button floated='left' inverted color="green" animated="vertical" onClick={handleCreateMarket}>
                <Button.Content visible>Create a Market</Button.Content>
                <Button.Content hidden>$99 a month</Button.Content>
              </Button>
              {jootAccount?.account_plan_type === undefined &&
                <Button floated='right' inverted color="green" onClick={handleUpgrade}>
                  <Button.Content visible>Upgrade</Button.Content>
                </Button>
              }
            </ButtonsWrapper>
          </>
        )}
      </StyledHeader2>
      <MarketUpgrade from={''} onHide={onHideUpgrade} isVisible={showUpgrade}/>
      <MarketUnlock from={''} onHide={onHideUnlock} isVisible={showUnlock}/>
    </>
  );
};

export default Header;
