import React from 'react';
import styled from 'styled-components';

// Components
import Overflow from '../ Overflow';

// Styles
const StyledAside = styled.aside`
  grid-area: aside;
  position: relative;
  overflow: hidden;
  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 1px;
    top: 0px;
    left: 0;
    bottom: 0px;
    background: ${({ theme }) => theme.joot.paleGray};
  }
`;

const Aside = ({ children, ...props }) => {
  return (
    <StyledAside>
      <Overflow padding={props.padding}>{children}</Overflow>
    </StyledAside>
  );
};

export default Aside;
