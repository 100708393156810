import { API } from 'aws-amplify';
import config from '../utils/config';

const basePath = 'social';

/**
 * Get the list of Facebook Pages this Joot Account has authorization to use
 */
export const pages = async (accountName) => {
  const { data } = await API.get(config.apiName, `${basePath}/${accountName}/fb`);
  return data;
};

/**
 * Get a list of Instagram Accounts this Joot User is linked to.
 */
export const igAccounts = async () => {
  try {
    const { instagram_accounts } = await API.get(config.apiName, `${basePath}/ig`);
    return instagram_accounts;
  } catch (e) {
    return []
  }
};

export const jootAccounts = async () => {
  try {
    const { accounts } = await API.get(config.apiName, `${basePath}/joot`);
    return accounts;
  } catch (e) {
    return []
  }
};

export const removeAccount = async (account_name) => {
  return API.del(config.apiName, `${basePath}/ig/${account_name}`);
}

/**
 * Link a Facebook Account to a Joot Account
 */
export const link = async (accessToken) => {
  const data = await API.post(config.apiName, `${basePath}/fb`, { body: { accessToken } });
  return data;
};

export const getFacebookData = async () => {
  try {
    const { facebook_account } = await API.get(config.apiName, `${basePath}/fb`);
    return facebook_account;
  } catch (e) {
    return {}
  }
};

/**
 * Get the list of this Joot Account's Facebook Connections
 */
export const facebookConnections = async (accountName) => {
  if (!accountName) {
    return;
  }
  const data = await API.get(config.apiName, `${basePath}/${accountName}/fb`, {});
  return data;
};
