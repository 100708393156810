import React from 'react';
import styled, { css } from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { motion } from 'framer-motion';
import Loader from '../../../components/Loader';
import { Segment, Popup, Item, Grid, List, Label, Header, Icon } from 'semantic-ui-react'

// Components
import CheckIcon from '../../icons/CheckIcon';
import PlusIcon from '../../icons/PlusIcon';
import LockIcon from '../../icons/LockIcon';
import ImageRankIcon from '../../icons/ImageRankIcon';
import MarketHandles from './MarketHandles';
import MarketInsightsChart from '../../../features/media/components/MarketInsightsChart';
import { grid } from 'styled-system';
/*import NewIcon from '../../icons/NewIcon';*/

// Styles

const PopupStyled = styled(Popup)`
  z-index: 250;
`
const CategoryBase = styled(motion.ul)`
  transition: color 0.15s, background-color 0.15s, width 0.25s ease-in-out;
  width: ${(props) => props.width};
  margin: 5px 0;
  list-style: none;
  background-clip: padding-box;
  backface-visibility: hidden;
  border-radius: 3px;
  background-color: ${(props) => rgba(props.theme.joot.blueGray, 0.1)};
  color: ${({ theme }) => theme.joot.jootWhite};
  font-family: ${({ theme }) => theme.font.sansSerif};
  z-index: 150;
  margin-top: ${({ offsetY }) => offsetY};
  margin-left: ${({ offsetX }) => offsetX};
  padding-bottom: 10px;
  &:first-of-type {
    background-color: ${(props) => rgba(props.theme.joot.mustard, 0.1)};
    padding-bottom: 10px;
  }
`;

const MarketBase = styled.li`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 4px 15px;
  overflow: hidden;
  color: ${({ theme }) => theme.joot.jootWhite};
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  cursor: pointer;
  position: relative;
  background-color: ${(p) => (p.isOpen ? '#ECF0FF' : 'transparent')};
  font-family: ${({ theme }) => theme.font.sansSerif};
  &:hover {
    background-color: ${(props) => rgba(props.theme.joot.blueGray, 0.3)};
    color: ${({ theme }) => theme.joot.jootBlack};
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${(props) => rgba(props.theme.joot.blueGray, 0.3)};
      color: ${theme.joot.jootBlack};
    `}
`;
const UnavailableMarketBase = styled(MarketBase)`
  &:hover {
    background-color: ${(props) => rgba(props.theme.joot.blueGray, 0.0)};
    color: ${({ theme }) => theme.joot.jootBlack};
  }
`;
const AddMarket = styled(MarketBase)`
  margin: 10px 0px;
  &:hover {
    background-color: 'tranparent'
    color: ${({ theme }) => theme.joot.jootBlack};
  }
`;
const LockIconStyled = styled.div`
  margin: 0 15px 0 auto;
  align-self: center;
`;
const ImageRankStyled = styled(ImageRankIcon)`
  margin-left: 5px;
  align-self: center;
`;
const IconWrapper = styled.div`
  margin-left: 5px;
  align-self: center;
`;
const ChartWrapper = styled.div`
  display: flex;
  flex-flow: row;
`;
const Chart = styled.div`
  width: 30%;
`;
const Legend = styled.ul`
  padding-left: 20px;
`;
const Li = styled.li`
  position: relative;
  font-size: 12px;
  margin-bottom: 10px;
  padding-left: 15px;
  &:last-child {
    margin-bottom: 0;
  }
  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 9px;
    height: 9px;
    border-radius: 100%;
    display: block;
    content: '';
    background: ${({ color }) => color};
    font-weight: bold;
    display: inline-block;
  }
`;
/*
const NewIconStyled = styled(NewIcon)`
  margin: 0 15px 0 auto;
  align-self: center;
`;
*/
const ItemLabel = styled.li`
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.7)};
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  padding: 5px 10px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  margin-left: -24px;
  position: absolute;
`;
const StyledPlusIcon = styled(PlusIcon)`
  position: relative;
`;
const MarketContainer = styled.div`
  display: flex;
  align-items: center;
`;
const LoaderWrapper = styled.div`
  margin-left: 10px;
`;
const AddMarketLabel = styled.div`
  margin-left: 8px;
  font-size: 15px;
  color: ${(props) => rgba(props.theme.joot.emeraldGreen, 0.9)};
`;
const AvailableMarket = styled.div`
    color: ${(props) => rgba(props.theme.joot.potteryBlue, 1.0)};
    margin-right: 5px;
`;
const AvailableUnlockedMarket = styled.div`
    color: ${(props) => rgba(props.theme.joot.emeraldGreen, 1.0)};
    margin-right: 5px;
`;
const UnavailableMarket = styled.div`
    color: ${(props) => rgba(props.theme.joot.jootBlack, 0.3)};
    margin-right: 5px;
`;
const addMarketOption = {
  name: "Create a Market",
  value: "Create a Market",
}

const MarketplaceCategory = React.forwardRef(function MarketplaceCategory(
  { className, style, children, label, options, onClick, onCreateCustomMarket, isSelected, hideCheck, offsetY, width, props, ...rest }, ref, )
{
  return (
    <CategoryBase
      ref={ref}
      className={className}
      style={style}
      width={width}
      //initial={{ opacity: 0, scale: 0.85 }} // animate from
      //animate={{ opacity: 1, scale: 1 }} // animate to
      //exit={{ opacity: 0, scale: 0.85 }} // animate exit
      offsetY={offsetY}
      //transition={{
      //  type: 'spring',
      //  stiffness: 800,
      //  damping: 35,
      //  staggerChildren: false,
      //}}
      {...rest}
    >
      {' '}
      {label ? <ItemLabel>{label}</ItemLabel> : null}
      {options &&
        options.map((option, i) => (
          <React.Fragment key={i}>
            {((option.item.market_system_type == "SYSTEM" && !option.item.is_available) || 
              option.item.market_system_type != "SYSTEM" && option.item.status != "social_concepts_complete") ? (
            <UnavailableMarketplaceMarket
              key={i}
              hideCheck={true}
              isAvailable={option.item.is_available}
              isCustom={!option.item.market_system_type || option.item.market_system_type === "ACCOUNT"}
              isReady={option.item.is_ready}
              style={option.style}
              {...option}
            >
              {option.name}
            </UnavailableMarketplaceMarket>
            ) : ( option.item.market_system_type != "SYSTEM" ? ( // no popup
              <MarketplaceMarket
                key={i}
                onClick={(option.item.status == "social_concepts_complete" || (option.item.is_available)) && onClick(option, props)}
                isSelected={isSelected(option)}
                hideCheck={hideCheck}
                isNew={option.item.is_new}
                isAvailable={option.item.is_available}
                isLocked={option.item.is_locked}
                hasImages={option.item.has_images}
                isCustom={!option.item.market_system_type || option.item.market_system_type === "ACCOUNT"}
                style={option.style}
                {...option}
              >
                {option.name}
              </MarketplaceMarket>
            ) : (
            <PopupStyled
              position='bottom left'
              wide='very'
              mouseEnterDelay={200}
              trigger={
                <MarketplaceMarket
                  key={i}
                  onClick={(option.item.status == "social_concepts_complete" || (option.item.is_available)) && onClick(option, props)}
                  isSelected={isSelected(option)}
                  hideCheck={hideCheck}
                  isNew={option.item.is_new}
                  isAvailable={option.item.is_available}
                  isLocked={option.item.is_locked}
                  hasImages={option.item.has_images}
                  isCustom={!option.item.market_system_type || option.item.market_system_type === "ACCOUNT"}
                  style={option.style}
                  {...option}
                >
                  {option.name}
                </MarketplaceMarket>
              }
            >
              <Popup.Content>
                <Grid>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Item.Group>
                        <Item>
                          <Item.Image size='small' src={option.item.image_url} />
                          <Item.Content verticalAlign='top'>
                            <Item.Header as='a'>{option.item.market_name}</Item.Header>
                            <Item.Meta>
                              <span className='price'>{option.item.subscription_price}/month</span>
                              <span className='stay'>updated monthly</span>
                            </Item.Meta>
                            <Item.Description>
                              <p>{option.item.description}</p>
                            </Item.Description>
                          </Item.Content>
                        </Item>
                      </Item.Group>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <MarketHandles market_accounts={option.item.ig_handles}/>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <List divided selection>
                        <List.Item>
                          <Label horizontal>
                            Sampled Audience Size
                          </Label>
                          <Header as='h5' floated="right">
                            {option.item.stats.audience_size == 0 ? '-' : option.item.stats.audience_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </Header>
                        </List.Item>
                        <List.Item>
                          <Label horizontal>
                            Avg. Engagement
                          </Label>
                          <Header as='h5' floated="right">
                            {option.item.stats.avg_engagement == 0 ? '-' : (option.item.stats.avg_engagement.toFixed(2) + ' %')}
                          </Header>
                        </List.Item>
                        <List.Item>
                          <Label horizontal>
                           Daily Follower Growth
                          </Label>
                          <Header as='h5' floated="right">
                            {option.item.stats.avg_new_daily_followers == 0 ? '-' : (option.item.stats.avg_new_daily_followers > 0 ? '+' : '') + option.item.stats.avg_new_daily_followers.toFixed(0)}
                          </Header>
                        </List.Item>
                      </List>
                    </Grid.Column>
                    <Grid.Column>
                      <Segment>
                        <Label attached='top'>
                          Media Types
                        </Label>
                          <ChartWrapper>
                            <Chart>
                              <MarketInsightsChart chartData={option.item.stats.chartData} />
                            </Chart>
                            <Legend>
                              {option.item.stats.chartData.map(({ value, color, key }) => (
                                <Li key={key} color={color}>
                                  {key} ({value.toFixed(2)}%)
                                </Li>
                              ))}
                            </Legend>
                          </ChartWrapper>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Popup.Content>
            </PopupStyled>
            ))}
          </React.Fragment>
      ))}
      {label && label === 'My Custom Markets' && 
        <React.Fragment key={'Add Market'}>
          <MarketplaceAddMarket
            key={'Add Market'}
            onClick={onCreateCustomMarket(addMarketOption, props)}
            hideCheck={true}
            style={addMarketOption.style}
            {...addMarketOption}
          >
            <AddMarketLabel>
              {addMarketOption.name}
            </AddMarketLabel>
          </MarketplaceAddMarket>
        </React.Fragment>
      }
    </CategoryBase>
  );
});

export const MarketplaceMarket = React.forwardRef(function MarketplaceMarket(
  { className, style, children, isOpen, isNew, isAvailable, isLocked, isSelected, isCustom, hasImages, nested, hideCheck, width, canEdit, onEdit, ...rest },
  ref,
) {
  return (
    <MarketBase ref={ref} className={className} style={style} isOpen={isOpen} {...rest}>
      <MarketContainer>
        {isSelected && !hideCheck && <StyledCheckIcon stroke={'#1a1a1a'} />}
        {(isCustom || isAvailable) && isLocked && <AvailableMarket>{children}</AvailableMarket>}
        {(isCustom || isAvailable) && !isLocked && <AvailableUnlockedMarket>{children}</AvailableUnlockedMarket>}
        {(!isCustom && !isAvailable) && <UnavailableMarket>{children}</UnavailableMarket>}
      </MarketContainer>
      {/*!isLocked && <Icon name='unlock' color='grey' />*/}
      {/*isNew && <NewIconStyled/>*/}
      {hasImages && <Icon name='images empty' color='grey' />}
    </MarketBase>
  );
});

export const UnavailableMarketplaceMarket = React.forwardRef(function UnavailableMarketplaceMarket(
  { className, style, children, isCustom, isReady, nested, width, canEdit, onEdit, ...rest },
  ref,
) {
  return (
    <UnavailableMarketBase ref={ref} className={className} style={style} {...rest}>
      <MarketContainer>
        <UnavailableMarket>{children}</UnavailableMarket>
      </MarketContainer>
      {!isReady && isCustom &&
        <LoaderWrapper>
          <Loader width={'12px'} height={'12px'} stroke="#4aa6e2" />
        </LoaderWrapper>
      }
    </UnavailableMarketBase>
  );
});

export const MarketplaceAddMarket = React.forwardRef(function MarketplaceAddMarket(
  { className, style, children, isOpen, width, ...rest },
  ref,
) {
  return (
    <AddMarket ref={ref} className={className} style={style} isOpen={isOpen} {...rest}>
      <MarketContainer>
        <StyledPlusIcon fill={'#4a9480'} />
        {children}
      </MarketContainer>
    </AddMarket>
  );
});

MarketplaceCategory.defaultProps = {
  width: '220px',
};

export default MarketplaceCategory;
