import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

import Amplify, { Auth } from 'aws-amplify';
import config from './utils/config';

import './utils/index.css';
import App from './App';

import 'semantic-ui-css/semantic.min.css'

import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Lato:300,400,600,700', 'sans-serif'],
  },
});

// Amplify refers to Cognito as Auth, S3 as Storage, and API Gateway as API.
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    customPrefix: { public: '' },
  },
  API: {
    endpoints: [
      {
        name: 'joot',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

// Cognito Hosted UI configuration
const oauth = {
  domain: '71bje0dozl.execute-api.us-west-2.amazonaws.com',
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: 'http://localhost:3000/',
  redirectSignOut: 'http://localhost:3000/',
  responseType: 'token', // or 'code', note that REFRESH token will only be generated when the responseType is code
};

Auth.configure({ oauth });

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
