import React from 'react';

const AlertIcon = (props) => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0)">
      <path d="M0 8a8 8 0 1116 0A8 8 0 010 8z" fill="#df5141" />
      <path d="M8 4v4M8 11.7h0" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 8a8 8 0 1116 0A8 8 0 010 8z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default AlertIcon;
