import { useEffect, useMemo, useState } from 'react';
import { useAppContext } from '../../../contexts/AppContext';
import { useImagesInsightsAll } from './useInsights';
import { useSimilarImages } from './useSimilarImages';

const TEXTS_OPTION = {
  top_ai_groupings: 'High Performing Concepts',
  bottom_ai_groupings: 'Low Performing Concepts',
};

const VIEW_OPTION = {
  market_view: 'Market View',
  account_view: 'Account View',
};

const VIEW_SELECTED = { name: 'All Accounts', value: 'market_view' }

export const useImagesInsightPage = () => {
  const [viewSelected, setViewSelected] = useState(VIEW_SELECTED)
  //const { data: dataMarket, isReady } = useImagesInsights();
  const all = useImagesInsightsAll();
  const { data: dataMarket, market, isReady } = useImagesInsightsAll();

  const viewOptions = useMemo(() => {
    const accounts = [{ value: 'market_view', name: 'All Accounts'}];
    return dataMarket.insights && accounts.concat(dataMarket.insights
      .filter((item) => item.account_name != '__market')
      .map((insight) => ({ value: insight.account_name, name: "@" + insight.account_name })));
  }, [dataMarket]);
  const { top_ai_groupings, bottom_ai_groupings, top_color_groupings, bottom_color_groupings } = useMemo(() => {
    if (viewSelected.value === "market_view") {
      const insight = dataMarket.insights && dataMarket.insights.find((item) => item.account_name === '__market');
      return insight || {};
    }
    return dataMarket.insights.find((item) => item.account_name === viewSelected.value) || {};
  }, [dataMarket, viewSelected])

  const groupOptions = useMemo(() => {
    if (!top_ai_groupings || !bottom_ai_groupings) {
      return [];
    }
    return Object.entries({
      top_ai_groupings: {
        groupings: top_ai_groupings,
        color: top_color_groupings,
      },
      bottom_ai_groupings: {
        groupings: bottom_ai_groupings,
        color: bottom_color_groupings,
      },
    }).map(([key, { groupings, color }]) => ({
      color,
      value: key,
      name: TEXTS_OPTION[key],
      groupings: groupings.filter((item) => item.images && item.images.length),
    }));
  }, [bottom_ai_groupings, top_ai_groupings, bottom_color_groupings, top_color_groupings]);
  const [groupSelected, setGroupSelected] = useState(null);
  const [postSelected, setPostSelected] = useState(false);
  useEffect(() => setGroupSelected(null), [viewSelected, groupOptions])
  useEffect(() => {
    if (!groupSelected && groupOptions) {
      setGroupSelected(groupOptions[0]);
      setPostSelected(groupOptions[0] ? groupOptions[0].groupings[0] : false);
      setImageSelected(null)
    } else {
      setImageSelected(postSelected.images[0]);
    }
  }, [groupSelected, groupOptions, postSelected]);
  //const viewOptions = Object.entries(VIEW_OPTION).map(([value, name]) => ({ value, name }))
  const [_imageSelected, setImageSelected] = useState(null);
  const imageSelected = useMemo(() => {
    if (postSelected) {
      return _imageSelected ? _imageSelected : postSelected.images[0]
    }
  }, [_imageSelected, postSelected])
  const tagsToMatch = useMemo(() => {
    if (!imageSelected) {
      return []
    }
    return [...imageSelected.ai_detected_tags_3_for_3, ...imageSelected.ai_detected_tags_2_for_3]
  }, [imageSelected])
  const similars = useSimilarImages(tagsToMatch)

  return {
    setImageSelected,
    imageSelected,
    similars,
    groupOptions,
    viewOptions,
    viewSelected,
    setViewSelected,
    setGroupSelected,
    groupSelected,
    postSelected,
    setPostSelected,
    isReady,
    market,
  };
};
