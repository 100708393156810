import React, { memo } from 'react';
import EmptyMessage from './EmptyMessage';

const MarketUnavailable = ({title, message, loading = true}) => {
  return (
    <EmptyMessage
    loading={loading}
      title={title}
      message={message}
    />
  );
};

export default memo(MarketUnavailable);
