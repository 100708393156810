import React, { memo, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Image from 'react-simple-image';

// Hooks
import { useForm } from 'react-hook-form';
import { useSignup, useSignupConfirm } from '../../hooks/apiHooks/authApiHooks';
import { useStripeCustomerSignup } from '../../hooks/apiHooks/billingApiHook';

// Contexts
import { useAuthContext } from '../../contexts/AuthContext';

// Assets
import { ReactComponent as ArrowLong } from '../../assets/svg/arrow-long.svg';
import facebook from '../../assets/img/facebook_logo.png';
import google from '../../assets/img/google_logo.png';

// Strings
import { STRINGS } from '../../utils/strings';

// Components
import { ErrorMessage, FormControl, PasswordInput, Checkbox} from '../../components/form';
import Button from '../../components/Button';
import AuthIntro from '../../features/auth/AuthIntro';
import LoginLabel from '../../features/auth/components/LoginLabel';
import LoginInput from '../../features/auth/components/LoginInput';
import Title from '../../components/Title';
import rgba from 'polished/lib/color/rgba';

// Styles
const PostImage = styled(Image)`
  border-radius: 4px;
  grid-area: image;
  width: 320px;
`;
const ThirdPartyLogin = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 20px;
`;
const StyledLink = styled(Link)`
  margin-left: 5px;
  color: ${({ theme }) => theme.joot.cinnabar};
`;
const Text = styled(Title)`
  margin: 0 0 0 5px;
  font-size: 13px;
  color: ${({ theme }) => theme.joot.lightGrayAlt};
`;
const InfoText = styled(Text)`
  margin: -10px 0 15px 0px;
  font-size: 14px;
  color: ${({ theme }) => rgba(theme.joot.jootBlack, 0.7)};
`;
const SignInText = styled(Text)`
  margin: 4px 0px 0px 0px;
  color: ${({ theme }) => theme.joot.jootBlack};
`;
const SignIn = styled(Button)`
  position: relative;
  font-size: 14px;
  color: 'rgb(223, 81, 65)';
  text-decoration: underline;
  margin-top; -3px;
`;
const RowFlow = styled.div`
  display: flex;
  flex-flow: row;
`;
const aStyle = {
  color: 'rgb(223, 81, 65)',
};
const AuthTitle = styled.div`
  display: flex;
  flex-flow: column;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 0 5px 0px;
`;
const SubTitle = styled.div`
  font-size: 14px;
  padding: 5px 0 30px 0px;
  color: ${({ theme }) => theme.joot.lightGrayAlt};
`;

const SignupForm = ( { onSignIn } ) => {
  const { push } = useHistory();
  const [successSignup, setSuccessSignup] = useState(false);
  const [error, setError] = useState('');
  const { userHasAuthenticated } = useAuthContext();
  const [credentials, setCredentials] = useState({});

  const { register, handleSubmit, errors, formState, getValues, reset: resetForm } = useForm({
    mode: 'onChange',
  });
  const { isValid } = formState;

  const SignInLinkHandler = useCallback(() => {
    push('/login');
  }, [push]);

  const {
    submit: signupSubmit,
    isLoading: isLoadingSignup,
    //isSuccess: isSuccessSignup,
    reset: resetSignupHook,
  } = useSignup({
    username: getValues('email'),
    password: getValues('password'),
  });

  const {
    submit: stripeCustomerSubmit,
    isLoading: isLoadingStripeSignup,
    isSuccess: isSuccessStripeSignup,
    reset: resetStripeCustomerHook,
  } = useStripeCustomerSignup({
    email: getValues('email'),
    name: getValues('name'),
    company: getValues('companyName'),
    industry: getValues('industry'),
  });

  const {
    error: confirmError,
    submit: confirmSubmit,
    isLoading: isLoadingConfirm,
    reset: resetConfirmHook,
  } = useSignupConfirm(credentials.email, getValues('code'));

  useEffect(() => {
    if (isSuccessStripeSignup) {
      setSuccessSignup(true);
    }
  }, [isSuccessStripeSignup]);

  async function onSignUpSubmit(data) {
    setError('');
    try {
      await signupSubmit();
      await stripeCustomerSubmit();
      setCredentials(data);
    } catch (e) {
      // if the user already exists, just resend the signup silently
      if (e.code === 'UsernameExistsException') {
        try {
          await Auth.resendSignUp(data.email.toLowerCase());
          setCredentials(data);
          setSuccessSignup(true);
        } catch (e) {
          setError(e.message);
        }
        return;
      }
      setError(e.message);
    }
  }

  async function onConfirmSubmit(data) {
    setError('');
    try {
      await confirmSubmit();
      await Auth.signIn(credentials.email.toLowerCase(), credentials.password);
      userHasAuthenticated(true);
    } catch (e) {
      if (STRINGS.errorMessages[e.code]) {
        setError(STRINGS.errorMessages[e.code]);
        return;
      }
      if (e.code === 'InvalidPasswordException') {
        setError(e.message);
        return;
      }
      if (e.message.startsWith('User cannot be confirmed')) {
        if (e.message.endsWith("CONFIRMED")) { // user is confirmed already
          await Auth.signIn(credentials.email.toLowerCase(), credentials.password);
          userHasAuthenticated(true);
        } else {
          setError(e.message);
        }
        return;
      }
      setError(STRINGS.errorMessages.requestGeneral);
    }
  }

  const resetFlow = useCallback(() => {
    resetForm();
    resetConfirmHook();
    resetSignupHook();
    resetStripeCustomerHook();
    setSuccessSignup(false);
    setCredentials({});
  }, [resetConfirmHook, resetForm, resetSignupHook]);

  function renderVerificationForm() {
    return (
      <>
        <InfoText>Check your email for a confirmation message. If you cannot find an email from Joot, be sure to check your spam folder.</InfoText>
        <form key={2} onSubmit={handleSubmit(onConfirmSubmit)}>
          <FormControl controlid="code">
            <LoginLabel htmlFor="verificationCode">Email Verification Code</LoginLabel>
            <LoginInput
              name="code"
              id="code"
              placeholder="Verification code..."
              autoFocus
              type="tel"
              ref={register({ required: true })}
            />
            {confirmError && (
              <FormControl.Error danger>
                Code is invalid or has expired
                <StyledLink to="/signup/create" onClick={resetFlow}>
                  Retry
                </StyledLink>
              </FormControl.Error>
            )}
            <FormControl.Error>{errors.code && 'Verification code is required.'}</FormControl.Error>
          </FormControl>
          <FormControl>
            <Button primary block disabled={isLoadingConfirm || !isValid} type="submit">
              {isLoadingConfirm ? 'Confirming...' : 'Confirm Account'}
            </Button>
          </FormControl>
        </form>
      </>
    );
  }

  function renderSignupForm() {
    return (
      <>
      {/*
        <ThirdPartyLogin>
          {false && <div class="fb-login-button" data-width="420" data-size="large" data-button-type="continue_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false"></div>}
          <PostImage
            alt={'facebook'}
            src={facebook}
            srcSet={{
              '1x': facebook,
              '2x': facebook,
            }}
          />
          <PostImage
            alt={'google'}
            src={google}
            srcSet={{
              '1x': google,
              '2x': google,
            }}
          />
        </ThirdPartyLogin>
          */}
        <form key={1} onSubmit={handleSubmit(onSignUpSubmit)}>
          <FormControl controlid="name">
            <LoginLabel htmlFor="name">Name</LoginLabel>
            <LoginInput
              name="name"
              id="name"
              placeholder="Firat and Last Name..."
              type="text"
              autoFocus
              ref={register({ required: false })}
            />
          </FormControl>
          <FormControl controlid="name">
            <LoginLabel htmlFor="companyName">Company</LoginLabel>
            <LoginInput
              name="companyName"
              id="companyName"
              placeholder="Company Name..."
              type="text"
              ref={register({ required: false })}
            />
          </FormControl>
          <FormControl controlid="industry">
            <LoginLabel htmlFor="industry">Industry</LoginLabel>
            <LoginInput
              name="industry"
              id="industry"
              placeholder="What is your company's industry?"
              type="text"
              ref={register({ required: false })}
            />
          </FormControl>
          <FormControl controlid="email">
            <LoginLabel htmlFor="email">Email*</LoginLabel>
            <LoginInput
              name="email"
              id="email"
              placeholder="Email..."
              type="email"
              ref={register({ required: true })}
            />
            <FormControl.Error>{errors.email && 'Email is required'}</FormControl.Error>
          </FormControl>
          <FormControl controlid="password">
            <LoginLabel htmlFor="password">Password*</LoginLabel>
            <PasswordInput
              name="password"
              id="password"
              placeholder="Password..."
              input={LoginInput}
              ref={register({
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters long.',
                },
              })}
            />
            <FormControl.Error>{errors.password && errors.password.message}</FormControl.Error>
          </FormControl>
          <FormControl>
            <Button primary block disabled={!isValid || isLoadingSignup || isLoadingStripeSignup} type="submit">
              {(isLoadingSignup || isLoadingStripeSignup) ? 'Creating Account...' : 'Create Account'}
              <ArrowLong />
            </Button>
          </FormControl>
          <FormControl>
            <RowFlow>
              <SignInText h6>Already have a Joot account?&nbsp;&nbsp;</SignInText><SignIn style={aStyle} type="button" onClick={onSignIn}>Sign In</SignIn>
            </RowFlow>
          </FormControl>
          <FormControl>
            <RowFlow>
              <Text h6>By creating an account, you’re opting-in to receive marketing and product updates by email. You can always unsubscribe, any time.</Text>
            </RowFlow>
          </FormControl>
          <FormControl>
            <RowFlow>
              <Text h6>By creating an account you agree to our <a style={aStyle} href="https://joot-publicweb.s3-us-west-2.amazonaws.com/Privacy+Policy.pdf">Privacy Policy</a> & <a style={aStyle} href="https://joot-publicweb.s3-us-west-2.amazonaws.com/Terms+of+Service.pdf">Terms of Service</a>.</Text>
            </RowFlow>
          </FormControl>
        </form>
      </>
    );
  }

  return (
    <>
      <AuthTitle>
        <Title h3 noMargin>
          {successSignup ? 'Verify Email' : 'Create an Account'}
        </Title>
        <SubTitle>{!successSignup ? 'Free forever. No credit card required.' : ''}</SubTitle>
      </AuthTitle>
      <div>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {!successSignup ? renderSignupForm() : renderVerificationForm()}
      </div>
    </>
  );
};

export default memo(SignupForm);

