import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react'

// Hooks
import { useImagesInsightPage } from '../hooks/useImagesInsightPage';

// Components
import MarketMenu from '../../../components/menus/MarketMenu';
import Title from '../../../components/Title';
import OptionsMenu from '../../../components/menus/OptionsMenu';
import GroupingGrid from '../components/GroupingGrid';
import PostSelected from '../components/PostSelected';
import MarketUnavailable from '../components/MarketUnavailable';
import AccountUpgrade from '../components/AccountUpgrade';
import EmptyImageInsights from '../components/EmptyImageInsights';
import { useAppContext } from '../../../contexts/AppContext';

// Styles
const StyledImageInsights = styled.section`
  display: grid;
  grid-template-rows: 40px 414px 40px 330px;
  min-height: 100vh;
`;
const ToolTitle = styled(Title)`
  margin-right: 10px;
`;
const GroupingWrapper= styled.div`
  padding: 20px 20px;
`;
const GroupingDetail = styled.article`
  position: relative;
  display: grid;
  grid-gap: 20px;
  ${({ postSelected }) => (postSelected ? 'align-content: flex-end' : '')}
`;
const DetailHeader = styled.div`
  // margin-top: 60px;
  margin-top: auto;
  padding-bottom: 20px;
`;
const DetailTitle = styled(Title)`
  display: flex;
  align-items: center;
`;
const ConceptsMenu = styled(OptionsMenu)`
  margin-left: unset;
`;
const Empty = styled(Title)`
  align-self: center;
  justify-self: center;
  opacity: 0.3;
`;
const Grid = styled.article`
  position: relative;
  display: grid;
  width: 100%;
  grid-template-rows: auto 1fr;
  grid-gap: 20px;
`;
const GridLabel = styled.div`
  font-size: 13px;
  font-weight: 700;
  padding-left: 30px;
  color: ${({ theme }) => theme.joot.jootBlack};
`;
const GridHeader = styled.div`
  display: grid;
  grid-template-columns: 937px auto;
  grid-column-gap: 30px;
  margin-top: 20px;
`;
const GridMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InsightTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px 0 0;
`;
const MediaHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 20px;
  align-self: flex-end;
`;
const HDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0px;
  background: ${({ theme }) => theme.joot.paleGray};
`;
const MenuContainer= styled.div`
  display: flex;
  min-width: 200px;
  flex-flow: column;
  align-items: baseline;
`;
const MenuTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-right: 10px;
  letter-spacing: -0.37px;
  color: ${({ theme }) => theme.joot.grayIce};
`;
const UnscoredDivider = styled.div`
  display: flex;
  flex-flow: row;
  margin: 20px 0 20px 0;
`;
const UnscoredTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin: 0 20px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: ${({ theme }) => theme.joot.grayIce};
`;

const createOption = (item) => ({
  item,
  value: item.market_name,
  name: item.market_name,
})

export default function ImageInsights() {
  const {
    viewOptions,
    viewSelected,
    setViewSelected,
    groupOptions,
    groupSelected,
    setGroupSelected,
    postSelected,
    setPostSelected,
    setImageSelected,
    imageSelected,
    similars,
    isReady,
    market,
  } = useImagesInsightPage();
  const {
    jootAccount,
    currentMarket,
  } = useAppContext();
  const isProfessionalAccount = useMemo(() => jootAccount?.account_type === "PROFESSIONAL", [jootAccount]);
  const handleChangeGroup = useCallback(({ target: { value } }) => setGroupSelected(value), [setGroupSelected]);
  const handleChangeView = useCallback(({ target: { value } }) => setViewSelected(value), [setViewSelected]);
  const handleMarketUnlocked = useCallback(() => {
    // TODO: cause the insights view to refresh
  });

  return (
    <>
    {/*!isProfessionalAccount && <AccountUpgrade from={'image insights'} isVisible={true} onHide />*/}
    {/*isProfessionalAccount && !hasAccountMarkets && <AccountUpgrade from={'image insights'} isVisible={true} onHide />*/}
    {/*isProfessionalAccount && hasAccountMarkets && market && */}
    {/*hasAccountMarkets && market && */
      <>
        <MediaHeader>
          <InsightTitle>
            <ToolTitle h3 noMargin>Image Insights</ToolTitle>
            <Popup
              trigger={<Icon name='help' size='small' color="grey" circular />}
              content='The Image Insights tool provides a view into groups of images that demonstrate key concepts that have high or low performing engagement in the selected market. This tool is useful for content creators as a means to gain insights into "what to do" and "what not to do" when creating image content for this market.  Click an Image Concept Grouping to see a list of 2-3 images that represent a particular engaging or disengaging concept.'
              position='right'
            />
          </InsightTitle>
          {/*initialMarketSelection &&*/ (
            <>
              <MenuContainer>
                <MenuTitle>View By</MenuTitle>
                <MarketMenu
                  options={viewOptions}
                  initialSelection={viewSelected}
                  name="views"
                  title="Select a Market View"
                  onChange={handleChangeView}
                  value={viewSelected}
                />
              </MenuContainer>
              <MenuContainer>
                <MenuTitle>Performance</MenuTitle>
                <MarketMenu
                  options={groupOptions}
                  initialSelection={groupSelected}
                  initialSelection={(groupSelected ? groupSelected : { name: "High Performing Concepts", value: "High Performing Concepts" })}
                  name="concepts"
                  title="Select an Engagement Level"
                  onChange={handleChangeGroup}
                  value={groupSelected}
              />
            </MenuContainer>
            </>
          )}
        </MediaHeader>
      </>
    }
    {currentMarket && currentMarket.market_system_type === "SYSTEM" && !currentMarket.exists_insights &&
      <EmptyImageInsights></EmptyImageInsights>
    }
    <StyledImageInsights>
      {!isReady && market && market.market_type !== 'free' && market.market_type != "SYSTEM" &&
        <MarketUnavailable
          title={market ? `Our AI is currently building your Custom Market model...\n This may take a few hours.` : ``}
          message={market ? `We'll send you an email notification when your Engagement Concepts are available.`: ``}
        />}
      {isReady && (
        <>
          <UnscoredDivider>
            <UnscoredTitle>
              Similar {groupSelected ? groupSelected.name : "High Performing Concepts"} for {viewSelected ? viewSelected.name : "All Accounts"}
            </UnscoredTitle>
            <HDivider/>
          </UnscoredDivider>
          <GroupingWrapper>
            <GroupingDetail postSelected={postSelected}>
              {!postSelected && (
                <Empty h4 noMargin>
                  Loading Image Insights...
                </Empty>
              )}
              {postSelected && (
                <>
                  <PostSelected post={postSelected} setImageSelected={setImageSelected} imageSelected={imageSelected} />
                </>
              )}
            </GroupingDetail>
          </GroupingWrapper>
          <UnscoredDivider>
            <UnscoredTitle>
              Image Concept Groupings
            </UnscoredTitle>
            <HDivider/>
          </UnscoredDivider>
          {groupSelected && (
            <GroupingWrapper>
              <GroupingGrid
                onPostClick={setPostSelected}
                postSelected={postSelected}
                groupings={groupSelected.groupings}
                color={groupSelected.color}
                similars={similars}
                isDemo={currentMarket.is_locked}
                onMarketUnlocked={handleMarketUnlocked}
              />
            </GroupingWrapper>
          )}
        </>
      )}
    </StyledImageInsights>
    </>
  );
}
