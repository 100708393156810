import React, { memo, useCallback, useState } from 'react';
import { ErrorMessage, FormControl, PasswordInput } from '../../components/form';
import LoginLabel from './components/LoginLabel';
import BaseButton from '../../components/Button';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import useFormTouched from '../../hooks/useFormTouched';
import useAppToasts from '../../hooks/useToasts';
import { ReactComponent as ArrowLong } from '../../assets/svg/arrow-long.svg';
import styled from 'styled-components';
import { useCompleteNewPassword } from '../../hooks/apiHooks/authApiHooks';
import { STRINGS } from '../../utils/strings';
//import InfoLabel from './LoginForgot';

const Button = styled(BaseButton)`
  margin-bottom: 10px;
`
const InfoLabel = styled.div`
  font-size: 14px;
  opacity: 0.8;
  padding-top: ${({ danger }) => (danger ? '0' : '10px')};
  padding-bottom: ${({ danger }) => (danger ? '10px' : '0')};
  color: ${({ danger, theme }) => danger && theme.color.error};
  > a {
    font-weight: 700;
    color: ${({ theme }) => theme.joot.jootWhite};
  }
`;

const validationSchema = yup.object({
  password: yup.string().required('Password is required'),
});

const FunnelUserForm = ({ onChange, data }) => {
  const { errorToast } = useAppToasts();
  const [error, setError] = useState('');
  const { register, handleSubmit, errors, formState, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema)
  });
  //const { isSuccess } = formState;
  const [touched, handleBlur] = useFormTouched({
    password: false,
  });
  //const [isLoading, setIsLoading] = useState(false);
  async function onSubmit() {
    //setIsLoading(true)
    setError("")
    try {
      await submitCompleteNewPassword();
      onChange();
      onChange();
    } catch (error) {
      if (STRINGS.errorMessages[error.code]) {
        errorToast(STRINGS.errorMessages[error.code]);
        return;
      }
      errorToast(STRINGS.errorMessages.requestGeneral);
    }
    //setIsLoading(false)
  }

  const {
    submit: submitCompleteNewPassword,
    isLoading: isLoading,
    isSuccess: isSuccess,
    error: errorPassword
  } = useCompleteNewPassword({ user: data.user, username: data.userEmail, newPassword: getValues('password') });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error && <ErrorMessage dark>{error}</ErrorMessage>}
      <FormControl controlid="password">
        <LoginLabel htmlFor="password">Create a new password</LoginLabel>
        <PasswordInput
          name="password"
          id="password"
          placeholder="Password..."
          ref={register({
            required: 'Password is required',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters long.',
            },
          })}
          hasError={errors.password}
          /*input={LoginInput}*/
        />
      </FormControl>
      {errorPassword && (
        <InfoLabel danger>
          Password must be at least 8 characters, contain at least 1 number and 1 symbol.{' '}
        </InfoLabel>
      )}
      <FormControl>
        <Button primary block disabled={/*!isSuccess ||*/ isLoading} type="submit">
          {isLoading ? 'Sending...' : 'Done'}
          <ArrowLong />
        </Button>
      </FormControl>
    </form>
  );
};

export default memo(FunnelUserForm);
