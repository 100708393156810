import { useCallback, useState } from 'react';

function usePasswordInput() {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPasswordHandler = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  return { showPassword, toggleShowPasswordHandler };
}

export default usePasswordInput;
