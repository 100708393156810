import { useCallback, useState } from "react";
import { useStripeCard } from "../../../components/StripeCard";
import { createSetupIntention } from "../../../services";
import { useMarket } from "./useMarket";
import { throwError } from "../../../utils/exception";

export const useUnlockMarket = () => {
  const [loading, setLoading] = useState(false);
  const { saveCard } = useStripeCard();
  const { unlock } = useMarket();

  const unlockMarket = useCallback(async(accountName, marketName, form, bypassBilling = false) => {
    console.log("upgrading 2");
    setLoading(true);
    try {
      console.log("bypassBilling: " + bypassBilling);
      if (!bypassBilling) {
        const clientSecret = await createSetupIntention(accountName, !form.use_existing_card); // setup billing
        if (!form.use_existing_card) {
          const saved = await saveCard(form.card_name, clientSecret);
        }
      }
      await unlock(accountName, marketName, form.subscription_type);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      throw throwError(e);
    }
  }, [unlock, setLoading, saveCard]);

  return {
    unlockMarket,
    loading,
  }
};