import React, { useCallback } from 'react';
import styled from 'styled-components';
import DropboxChooser from 'react-dropbox-chooser';

// Assets
import { ReactComponent as Dropbox } from '../../../assets/svg/dropboxI.svg';
import { ReactComponent as Drive } from '../../../assets/svg/driveI.svg';
import { ReactComponent as Computer } from '../../../assets/svg/computerI.svg';
import { ReactComponent as Instagram} from '../../../assets/svg/instagramI.svg';

// Components
import Button from '../../../components/Button';
import GooglePicker from './GooglePicker';
import config from '../../../utils/config';

const StyledUploadOptionsInitial = styled.div`
  display: flex;
  align-items: center;
`;
const UploadTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
  letter-spacing: -0.37px;
`;
const UploadButton = styled(Button)`
  margin: 0 0 0 12px;
  svg {
    margin: 0;
  }
`;

const UploadOptionsInitial = ({ onComputerLoad, onDropboxUpload, onGoogleUpload, onInstagramLoad, extensions, hasImages }) => {
  const handleGoogle = useCallback(
    ({ target: { value } }) => {
      onGoogleUpload(value);
    },
    [onGoogleUpload],
  );

  return (
    <StyledUploadOptionsInitial>
      <UploadTitle>Upload Media</UploadTitle>
      <DropboxChooser appKey={config.dropbox.KEY} success={onDropboxUpload} multiselect={true} extensions={extensions}>
        <UploadButton icon>
          <Dropbox />
        </UploadButton>
      </DropboxChooser>
      <GooglePicker GOOGLE_CLIENT_ID={config.google.CLIENT_ID} GOOGLE_KEY={config.google.API_KEY} onChange={handleGoogle}>
        <UploadButton icon>
          <Drive />
        </UploadButton>
      </GooglePicker>
      <UploadButton icon onClick={onInstagramLoad}>
        <Instagram />
      </UploadButton>
      <UploadButton icon onClick={onComputerLoad}>
        <Computer />
      </UploadButton>
    </StyledUploadOptionsInitial>
  );
};

export default UploadOptionsInitial;
