import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import SupportOverview from '../../components/WelcomeForms/supportOverview';
import rgba from 'polished/lib/color/rgba';

// Assets
import { ReactComponent as ArrowMed } from '../../assets/svg/arrow-med.svg';

// Context
import { useSignupContext } from './context/SignupContext';

// Components
import Box from '../../components/box/Box';
import Button from '../../components/Button';

// Styles
const Text = styled.div`
  ${({ isLoading }) => isLoading ? 'padding-right: 30px;' : ''}
`
const StepBox = styled(Box)`
  width: 700px;
  border-radius: 5px;
  margin: 0 auto;
  padding: 20px 25px 20px 25px;
  display: flex;
  flex-direction: column;
`;

const StepBoxFooter = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row-reverse;
  flex-content: flex-start;
  align-content: flex-end;
  flex-wrap: wrap;
`;

const NavControl = styled(Button)`
  justify-self: center;
  text-align: center;
  align-items: center;
  display: flex;
  font-size: 13px;
  width: 160px;
  > svg {
    vertical-align: inherit;
    margin-left: 20px;
  }
`;

const NavControlBack = styled(NavControl)`
  background: transparent;
  border: 0px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.4)};
`;

const Discord = styled(NavControl)`
  height: 48px;
  color: ${({ theme }) => theme.joot.jootWhite};
  background: ${({ theme }) => theme.color.discord};
`;

const DiscordNew = styled(NavControlBack)`
  flex-direction: column;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.9)};
`;

const Fill = styled.div`
  display: flex;
  flex: auto;
`;

const SignupStepSupport = () => {
  const { setOnNext, handlerNext, handlerBack, changeState } = useSignupContext(5);
  useEffect(() => {
    setOnNext(async () => {
      return true;
    });
    return () => setOnNext(null);
  }, [setOnNext]);
  useEffect(() => {
    changeState('disabledNext', false);
  }, [changeState]);
  const handlerDiscord = () => { window.open("https://discord.com/invite/Jf28jTx"); };
  const handlerNewDiscord = () => { window.open("https://player.vimeo.com/video/536178919"); };

  return (
    <>
      <StepBox>
        <SupportOverview>
        </SupportOverview>
        <StepBoxFooter>
          <NavControl primary onClick={handlerNext}>
            <Text>Finish</Text>
            <ArrowMed />
          </NavControl>
          <NavControlBack onClick={handlerBack}>
            <Text>Back</Text>
          </NavControlBack>
          <Fill/>
          <DiscordNew onClick={handlerNewDiscord}>
            <Text>Don't have Discord?</Text>
            <Text>Click here</Text>
          </DiscordNew>
          <Discord onClick={handlerDiscord}>
            <Text>JOIN DISCORD</Text>
          </Discord>
        </StepBoxFooter>
      </StepBox>
    </>
  );
};

export default SignupStepSupport;

