import React from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Components
import Image from '../../../components/Image';

// Styles
const StyledPostAi = styled.div`
  display: grid;
  grid-row-gap: 15px;
  align-content: flex-start;
  padding: 15px;
  background-color: ${({ theme }) => rgba(theme.joot.emeraldGreen, 0.3)};
  background-clip: padding-box;
  backface-visibility: hidden;
  border-radius: 10px;
`;
const PostImage = styled(Image)`
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s all ${({ theme }) => theme.transition.cubicEase};
  box-shadow: 0 0 0 0 ${({ theme }) => theme.joot.emeraldGreen};
  &:hover {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.joot.emeraldGreen};
  }
  ${({ selected, theme }) => (selected ? `box-shadow: 0 0 0 3px ${theme.joot.emeraldGreen};` : '')}
`;
const ColLabel = styled.div`
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  color: ${({ theme }) => theme.joot.jootBlack};
`;

const PostAi = ({ images, onSelected, imageSelected }) => {
  return (
    <StyledPostAi>
      {images.map((item, i) => (
        <div key={i} onClick={() => onSelected(item)}>
          <PostImage
            selected={imageSelected.ig_post_url === item.ig_post_url}
            alt="Example"
            src={item?.image_urls?.url_150}
            src2x={item?.image_urls?.url_300}
            height={120}
          />
        </div>
      ))}
    </StyledPostAi>
  );
};

export default PostAi;
