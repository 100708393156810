import React from 'react';
import styled, { css } from 'styled-components';
import { ControlContainer, HiddenSelectableAppearance, Label } from './mixins/SelectableAppearance';
import rgba from 'polished/lib/color/rgba';

const HiddenToggle = styled.input.attrs({ type: 'checkbox' })`
  ${HiddenSelectableAppearance};
`;

const StyledToggle = styled.div`
  width: 28px;
  height: 16px;
  background-color: ${(props) => rgba(props.theme.color.fourtySix, 1)};
  transition: background-color 0.1s ease-out;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.0843805);
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  &:after {
    content: '';
    width: 14px;
    height: 14px;
    background-color: ${(props) => props.theme.color.white};
    transition: left 0.1s ease-out;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
  }

  ${(props) =>
    props.checked &&
    css`
      background-color: ${props.theme.color.three};
      font-weight: 700;
      &:after {
        left: calc(100% - 15px);
      }
    `}
`;

export default React.forwardRef((props, ref) => {
  const { id, children, checked, onChange, ...rest } = props;
  return (
    <ControlContainer>
      <HiddenToggle id={id} checked={checked} ref={ref} readOnly {...rest} />
      <StyledToggle checked={checked} onClick={onChange} />
      <Label onClick={onChange} htmlFor={id}>
        {children}
      </Label>
    </ControlContainer>
  );
});
