import * as React from 'react';

function SelectedIcon({ fill, fillOpacity, ...props }) {
  return (
    <svg width={27} height={27} viewBox="0 0 27 27" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 0C6.044 0 0 6.044 0 13.5S6.044 27 13.5 27 27 20.956 27 13.5 20.956 0 13.5 0zM8.832 12.263l-1.468 1.91 5.434 4.553 7.786-8.52-1.763-1.615-6.17 6.904-3.819-3.232z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

export default SelectedIcon;
