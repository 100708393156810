import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

// Hooks
import { useIgAccount } from '../hooks/useIgAccount';

// Context
import { useAppContext } from '../../../contexts/AppContext';

// Components
import ModalForm from '../../../components/modals/ModalForm';
import ChooseIgAccount from '../../../components/ChooseIgAccount';
import MarketForm from '../../../components/marketForm';
import useAppToasts from '../../../hooks/useToasts';
import StripeCardForm from '../../../components/StripeCardForm';

const IG_ACCOUNT_STEP = 'IG_ACCOUNT_STEP';
const MARKET_STEP = 'MARKET_STEP';
const CARD_STEP = 'CARD_STEP';

const FORM_INIT_DATA = {
  card_name: '',
  use_existing_card: false,
  subscription_type: 'monthly'
};

const IgAccountAdding = ({ isVisible, onHide, ...props }) => {
  const { errorToast } = useAppToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(IG_ACCOUNT_STEP);
  const {
    facebook: { data: facebookData },
  } = useAppContext();
  const [form, setForm] = useState({ ...FORM_INIT_DATA });
  useEffect(() => {
    if (!isVisible) {
      setStep(IG_ACCOUNT_STEP);
      setForm({ ...FORM_INIT_DATA });
    }
  }, [isVisible]);
  const handleChange = useCallback(({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  }, []);
  const { create } = useIgAccount();
  const handleOk = useCallback(async () => {
    if (!form.ig) {
      return;
    }
    setStep(MARKET_STEP);
    if (!form.market) {
      return;
    }
    setStep(CARD_STEP);
    console.log(form);
    if (form.use_existing_card || form.card_name) {
      setIsLoading(true);
      try {
        await create(form);
        onHide();
      } catch (e) {
        errorToast('There was a problem to connect the new IG account.');
        onHide();
      }
      setIsLoading(false);
    }
  }, [form, create, onHide, errorToast]);
  const handleCardChange = useCallback((value) => handleChange({ target: { name: 'card_name', value } }), [
    handleChange,
  ]);
  const handleSubscriptionChange = useCallback((value) => handleChange({ target: { name: 'subscription_type', value } }), [
    handleChange,
  ]);
  const handleUseExistingCardChange = useCallback((value) => {
    handleChange({ target: { name: 'use_existing_card', value } })
  }, [
    handleChange,
  ]);
  const buttonText = useMemo(() => {
    if (step === IG_ACCOUNT_STEP) {
      return 'Create Market';
    }
    if (step === MARKET_STEP) {
      return 'Purchase';
    }
    return 'Finish';
  }, [step]);

  return (
    <ModalForm
      {...props}
      onHide={onHide}
      isVisible={isVisible}
      okText={buttonText}
      onOk={handleOk}
      title="Connect Instagram Account"
      isLoading={isLoading}
    >
      <>
        {step === IG_ACCOUNT_STEP && (
          <ChooseIgAccount facebookData={facebookData} onChange={handleChange} value={form.ig} name="ig" />
        )}
        {step === MARKET_STEP && <MarketForm igAccount={form.ig} onChange={handleChange} name="market" />}
        {step === CARD_STEP && <StripeCardForm 
          onCardChange={handleCardChange}
          onSubscriptionChange={handleSubscriptionChange}
          onUseExistingCardChange={handleUseExistingCardChange}
          cardName={form.card_name}
          subscriptionType={form.subscription_type}/>}
      </>
    </ModalForm>
  );
};

export default memo(IgAccountAdding);
