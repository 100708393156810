import React, { memo, useCallback, useState, useEffect} from 'react';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { Button, Card, Header, Icon, Statistic, Image, Divider, List } from 'semantic-ui-react'

const SubscriptionPlan = ({ value, onPlanChange, hideFree, upgradePath}) => {
  const [plan, setPlan] = useState(value || 'free');
  const handlePlanChange = useCallback((value) => {
    onPlanChange(value)
    if (!upgradePath)
      setPlan(value);
  }, [onPlanChange, setPlan]);
  useEffect(() => {
    setPlan(value);
  }, [value]);


  console.log("plan: " + plan);

  return (
  <>
  {!upgradePath && (
    <>
      <Header as='h2' color='grey'>Choose your preferred plan</Header>
      <Divider hidden/>
    </>
  )}
  <Card.Group centered>
    {!hideFree &&
      <Card>
        <Card.Content>
          <Header as='h1'>
            Free
            <Header.Subheader>Limited Use</Header.Subheader>
          </Header>
          <Divider />
          <Statistic horizontal>
            <Statistic.Value>$0</Statistic.Value>
          </Statistic>
          <Card.Description>
            Learn the most engaging images to publish for ads, e-commerce & social media, in your market.
          </Card.Description>
          <Header as='h6'>Includes:</Header>
          <List>
            <List.Item>
              30 FREE Image Rankings
            </List.Item>
            <List.Item>
              1 User
            </List.Item>
          </List>
        </Card.Content>
        {plan == 'free' &&
          <Card.Content extra>
            <div className='ui buttons'>
              <Button disabled>
                Current Plan
              </Button>
            </div>
          </Card.Content>
        }
      </Card>
    }
    {
      <Card color='blue'>
        <Card.Content>
          <Header as='h1' color='blue'>
            Professional 
            <Header.Subheader>1 Custom Market</Header.Subheader>
          </Header>
          <Divider />
          <Statistic horizontal>
            <Statistic.Value>$99</Statistic.Value>
            <Statistic.Label>Monthly</Statistic.Label>
          </Statistic>
          <Card.Description>
            Create your own custom market using direct competitors and companies of your choice.
          </Card.Description>
          <Header as='h6'>Includes:</Header>
          <List>
            <List.Item>
              1 Custom Market Model
            </List.Item>
            <List.Item>
              100 Image Rankings/mo
            </List.Item>
            <List.Item>
              Image and Market Insights
            </List.Item>
          </List>
        </Card.Content>
        {upgradePath && plan == 'business' ? (
          <></>
        ) : (
          <Card.Content extra>
            <div className='ui buttons'>
              {(upgradePath && plan == 'professional') ? (
                <Button disabled>
                  Current Plan
                </Button>
              ) : (
                <Button toggle active={plan != 'professional'} onClick={() => handlePlanChange('professional')}>
                  {plan == 'professional' ? 'Selected' : (upgradePath ? 'Upgrade' : 'Select Plan')}
                </Button>
              )}
            </div>
          </Card.Content>
        )}
      </Card>
    }
    <Card color='orange'>
      <Card.Content>
        <Header as='h1' color='orange'>
          Business 
          <Header.Subheader>5 Custom Markets + All Access</Header.Subheader>
        </Header>
        <Divider />
        <Statistic horizontal>
          <Statistic.Value>$299</Statistic.Value>
          <Statistic.Label>Monthly</Statistic.Label>
        </Statistic>
        <Card.Description>
          Leverage Joot across brands to improve ROAS, increase conversions, and win new business.
        </Card.Description>
        <Header as='h6'>Includes:</Header>
        <List>
          <List.Item>
            5 Custom Market Models
          </List.Item>
          <List.Item>
            500 Image Rankings/mo
          </List.Item>
          <List.Item>
            Image and Market Insights
          </List.Item>
          <List.Item>
            Full access to 100+ Joot Markets
          </List.Item>
        </List>
      </Card.Content>
      <Card.Content extra>
        <div className='ui buttons'>
            {(upgradePath && plan == 'business') ? (
              <Button disabled>
                Current Plan
              </Button>
            ) : (
              <Button toggle active={plan != 'business'} onClick={() => handlePlanChange('business')}>
                {plan == 'business' ? 'Selected' : (upgradePath ? 'Upgrade' : 'Select Plan')}
              </Button>
            )}
        </div>
      </Card.Content>
    </Card>
  </Card.Group>
  </>
  );
};

export default memo(SubscriptionPlan);
