import React, { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import SimpleImage from 'react-simple-image';

const MediaImage = styled(SimpleImage)`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  display: block;
`;

const ImageLoader = ({ src, src2x, width, height, className, ...props }) => {
  const [isRendering, setIsRendering] = useState(true);
  useEffect(() => {
    const createPromise = (src) => !src ? null : new Promise((resolved) => {
      const image = new Image();
      image.src = src;
      image.onload = () => resolved();
    });
    setIsRendering(true)
    Promise.all([
      createPromise(src),
      createPromise(src2x)
    ]).then(() => setIsRendering(false));
  }, [src, src2x]);

  return (
    <div className={className}>
      {isRendering ? (
        <Skeleton height={height} />
        ) : (
          <MediaImage
            {...props}
            className={className}
            src={src}
            srcSet={{ '1x': src, '2x': src2x }}
            width={width}
            height={height}
          />
      )}
    </div>
  );
};

export default memo(ImageLoader);