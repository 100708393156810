import React from 'react';
import styled from 'styled-components';

// Components
import { Label } from '../../../components/form/mixins/SelectableAppearance';

const StyledSidebarItem = styled.section`
  display: block;
  padding: 20px 0;
`;
const ItemTitle = styled(Label)`
  display: block;
  margin-bottom: 15px;
`;

const SidebarItem = ({ title, children }) => {
  return (
    <StyledSidebarItem>
      <ItemTitle>{title}</ItemTitle>
      {children}
    </StyledSidebarItem>
  );
};

export default SidebarItem;
