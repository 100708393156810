import { useCallback, useReducer } from 'react';

export function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_ALL':
      return {
        ...action.payload.state,
      };
    case 'UPDATE':
      let addState = action.payload.field;

      if (typeof action.payload.field !== 'object') {
        addState = {
          [action.payload.field]: action.payload.value,
        };
      }

      return {
        ...state,
        ...addState,
      };
    case 'TOGGLE':
      return {
        ...state,
        [action.payload.field]: !state[action.payload.field],
      };
    default:
      return state;
  }
}

function useStateVars(initialState) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const changeState = useCallback((field, value) => {
    dispatch({ type: 'UPDATE', payload: { field, value } });
  }, []);

  const setState = useCallback((state) => {
    dispatch({ type: 'UPDATE_ALL', payload: { state } });
  }, []);

  const toggleState = useCallback((field) => {
    dispatch({ type: 'TOGGLE', payload: { field } });
  }, []);

  return { state, changeState, toggleState, setState };
}

export default useStateVars;
