import styled from 'styled-components';
import { space, color, layout, grid, flexbox, border } from 'styled-system';

// Styles
const Box = styled.div`
  ${space}
  ${color}
  ${layout}
  ${grid}
  ${flexbox}
  ${border}
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.0726617);
`;

export default Box;
