import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';

// Hooks
import { useBuyUploads, useGetBillingDetail } from '../../../hooks/apiHooks/billingApiHook';

// Context
import { useAppContext } from '../../../contexts/AppContext';

// Components
import Modal from '../../../components/Modal';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import SelectedSetting from './SelectedSetting';
import StripeCardForm from '../../../components/StripeCardForm';
import StripeCardDetail from '../../../components/StripeCardDetail';
import { useStripeCard } from '../../../components/StripeCard';
import { createSetupIntention } from "../../../services";
import { ErrorMessage, FormControl } from '../../../components/form';
import PoweredByStripeIcon from '../../../components/icons/PoweredByStripeIcon';

// Styles
const ModalHeader = styled.div`
  display: block;
  padding: 40px;
`;
const ModalBody = styled.div`
  display: block;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 0px;
`;
const ModalFooter = styled.div`
  display: flex;
  padding: 40px;
  justify-content: flex-end;
  overflow: hidden;
`;
const Text = styled.p`
  display: block;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.26px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.3)};
`;
const StyledSelectedSetting = styled(SelectedSetting)`
  margin-bottom: 10px;
  padding: 23px 20px;
  background-color: ${({ theme, isAddOnProps }) =>
    isAddOnProps?.selected ? rgba(theme.joot.emeraldGreen, 0.05) : theme.joot.jootWhite};
  border-color: ${({ theme, isAddOnProps }) =>
    isAddOnProps?.selected ? theme.joot.emeraldGreen : rgba(theme.joot.jootBlack, 0.1)};
  box-shadow: inset 0 0 0 1px
    ${({ theme, isAddOnProps }) => (isAddOnProps?.selected ? theme.joot.emeraldGreen : 'transparent')};
  > button {
    color: ${({ theme, isAddOnProps }) => (isAddOnProps?.selected ? theme.joot.emeraldGreen : theme.joot.potteryBlue)};
  }
`;
const Wrapper = styled.div`
  position: relative;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid ${(props) => rgba(props.theme.joot.jootBlack, 0.1)};
`;
const PurchaseButton = styled(Button)`
  min-width: 170px;
  justify-content: center;
`;
const FlexRow = styled.div`
  margin: 20px auto 0px auto;
  display: flex;
  flex-flow: row;
  align-items: center;
`;
const StyledPoweredByStripe = styled(PoweredByStripeIcon)`
  margin: 20px auto 0px auto;
  position: relative;
`;

const UPLOADS_OPTIONS = [
  {
    value: 'PREDICT_100',
    number: 100,
    price: 5.00,
  },
  {
    value: 'PREDICT_250',
    number: 250,
    price: 10.00,
  },
  {
    value: 'PREDICT_500',
    number: 500,
    price: 15.00,
  },
];

const ModalAddUploads = ({ onCancel, isVisible, onHide, ...props }) => {
  const {
    paymentMethod: { card },
    invalidateQuery,
  } = useGetBillingDetail();
  const { submit: buyUploads, isLoading: buyUploadsLoading } = useBuyUploads();
  const { accountName, invalidateJootAccounts } = useAppContext();
  const { saveCard } = useStripeCard();
  const [optionError, setOptionError] = useState('');
  const [optionSelected, setOptionSelected] = useState();
  const [purchasing, setPurchasing] = useState(false);
  const [cardName, setCardName] = useState(false);
  useEffect(() => {
    if (!isVisible) {
      setOptionError('');
      setOptionSelected('');
    }
  }, [isVisible]);
  const handleAddMore = useCallback(async () => {
    if (!optionSelected) {
      return setOptionError('Select one option');
    }
    if (!card && !cardName) {
      return setOptionError('Select one option');
    }
    setPurchasing(true);
    if (!card) {
      try {
        const clientSecret = await createSetupIntention(accountName, !card); // setup billing
        await saveCard(cardName, clientSecret);
      } catch (e) {
        console.error(e);
        setPurchasing(false);
        return setOptionError('Error saving card');
      }
    }
    try {
      await buyUploads({ accountName, image_upload_tier: optionSelected.value });
    } catch (e) {
      console.error(e);
      setPurchasing(false);
      return setOptionError('Error purchasing uploads');
    }
    invalidateQuery();
    invalidateJootAccounts();
    setPurchasing(false);
    onHide();
  }, [buyUploads, accountName, invalidateQuery, optionSelected, onHide]);
  const handleOptionSelected = useCallback((value) => {
    setOptionError('');
    setOptionSelected(value);
  }, []);
  const handleCardChange = useCallback((value) => {
    setCardName(value);
  }, [setCardName]);

  return (
    <Modal onCancel={onCancel} onHide={onHide} isVisible={isVisible} {...props}>
      <ModalHeader>
        <Title h5>Add Image Rankings</Title>
        <Text>How many additional image rankings you would like to add to your account?</Text>
      </ModalHeader>
      <ModalBody>
        <FormControl controlid="uploads">
          <FormControl.Label htmlFor="uploads">Select Amount</FormControl.Label>
          {optionError && <ErrorMessage>{optionError}</ErrorMessage>}
          {UPLOADS_OPTIONS.map((item) => (
            <StyledSelectedSetting
              key={item.value}
              isAddOnProps={{ selected: optionSelected?.value === item.value }}
              onClick={() => handleOptionSelected(item)}
              isAddOn
              label={`${item.number} Image Rankings`}
              price={item.price}
            />
          ))}
        </FormControl>
        <FormControl>
          <FormControl.Label>Payment Method</FormControl.Label>
          <Wrapper>
            {card && <StripeCardDetail card={card} />}
            {!card && <StripeCardForm onCardChange={handleCardChange} />}
            </Wrapper>
        </FormControl>
        <FlexRow>
          <StyledPoweredByStripe width={150} />
        </FlexRow>
      </ModalBody>
      <ModalFooter>
        <Button secondary onClick={onCancel}>
          Cancel
        </Button>
        <PurchaseButton
          primary
          disabled={buyUploadsLoading}
          loading={buyUploadsLoading || purchasing}
          onClick={handleAddMore}
          type="submit"
        >
          {!buyUploadsLoading && (
            <>{optionSelected ? `Purchase for $${optionSelected.price}` : 'Please Select an Option'}</>
          )}
        </PurchaseButton>
      </ModalFooter>
    </Modal>
  );
};

export default ModalAddUploads;
