import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import Title from './Title';
import rgba from 'polished/lib/color/rgba';
import Avatar from 'react-avatar';
import FacebookIcon from './icons/FacebookIcon';
import { Radio } from './form';

// Styles
const StepBody = styled.div`
  padding: 0;
`;
const FacebookDetails = styled.div`
  display: flex;
  align-items: center;
  margin-left: 50px;
`;
const AccountTitle = styled(Title)`
  margin-left: 14px;
  line-height: 20px;
  > span {
    display: block;
    font-weight: 400;
  }
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;
const Text = styled.p`
  font-size: 13px;
  line-height: 16px;
  margin: 30px 0 40px 0;
  white-space: pre-line;
  letter-spacing: -0.26px;
  color: ${(props) => rgba(props.theme.joot.jootBlack, 0.4)};
`;
const InstagramAccounts = styled.ul`
  margin-top: 40px;
`;
const Instagram = styled.li`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  label {
    margin-left: 30px;
    display: flex;
    align-items: center;
  }
`;
const Handle = styled(Title)`
  margin-left: 20px;
`;

const ChooseIgAccount = ({ name, onChange, value, facebookData, helpText }) => {
  const handleChange = useCallback(value => onChange({ target: { name, value } }), [onChange, name])
  return (
    <>
      {facebookData && (
        <Flex>
          <Avatar name={facebookData.name} size={100} round="4px" src={facebookData.picture_url} />
          <FacebookDetails>
            <FacebookIcon width={42} height={42} />
            <AccountTitle h5 noMargin>
              <span>Connected to Facebook as</span> {facebookData.name}
            </AccountTitle>
          </FacebookDetails>
        </Flex>
      )}
      <StepBody>
        {helpText && (
          <Text>
            {`Select which Instagram Account you would like to use in this Joot Account.\n You can always add more Instagram Accounts later in Settings.`}
          </Text>
        )}
        <InstagramAccounts>
          {facebookData.igAccountsNoConnected && facebookData.igAccountsNoConnected.map((item, index) => (
            <Instagram key={index}>
              <Radio
                id={item.id}
                checked={value?.id === item.id}
                onChange={() => handleChange(item)}
              >
                <Avatar name={item.username} size={50} round={true} src={item.profile_picture_url} />
                <Handle h5>{item.username}</Handle>
              </Radio>
            </Instagram>
          ))}
        </InstagramAccounts>
      </StepBody>
    </>
  );
};

export default memo(ChooseIgAccount);