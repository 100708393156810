import * as React from 'react';

function DropdownIcon(props) {
  return (
    <svg
      width={10}
      height={10}
      viewBox='0 0 10 10'
      fill={props.fill}
      {...props}>
      <path
        d='M8.54 5.7a1 1 0 10-1.42-1.4L5 6.4 2.88 4.3a1 1 0 10-1.42 1.42L4.3 8.54a1 1 0 001.42 0L8.54 5.7z'
        clipRule='evenodd'
      />
    </svg>
  );
}

export default DropdownIcon;
