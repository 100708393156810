import * as React from 'react';

function CheckIcon(props) {
  return (
    <svg width={11} height={9} fill="none" {...props}>
      <path
        {...props}
        stroke={props.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 4.9l3.05 2.73L9.39 1"
      />
    </svg>
  );
}

export default CheckIcon;
